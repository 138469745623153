import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { Observable, map } from "rxjs";
import { RechercheSearchFormData } from "src/app/core/stores/recherche/recherche.model";
import { ContentCommunityOptions } from "src/app/models/enums/content-community";
import { toSelectOptionRecord } from "src/app/models/enums/enum-base";
import { GenreOptions } from "src/app/models/enums/genre";
import { KanalOptions } from "src/app/models/enums/kanal";
import { PlanungskontextOptions } from "src/app/models/enums/planungskontext";
import { RedaktionOptions } from "src/app/models/enums/redaktion";
import { BoxedProperties } from "src/app/utils/ngrx-forms";
import { RechercheFacade } from "../recherche.facade";

type FilterObject<T extends object> = {
  key: BoxedProperties<T>;
  value: string;
  displayValue: string;
};

export type SearchFormFilterObject = FilterObject<RechercheSearchFormData>;

@Component({
  selector: "app-filter-chip-list",
  templateUrl: "./filter-chip-list.component.html",
  styleUrls: ["./filter-chip-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterRowComponent {
  public readonly facade = inject(RechercheFacade);

  filterValues$: Observable<SearchFormFilterObject[]> = this.facade.formValue$.pipe(
    map((formValue) => this.getFiltersFromSearchForm(formValue)),
  );

  // Übersetzen zwischen den technischen Enum-Werten und den für den Anwender angezeigten SelectOptions
  translations = {
    genreSelected: toSelectOptionRecord(GenreOptions),
    redaktionenSelected: toSelectOptionRecord(RedaktionOptions),
    planungskontexteSelected: toSelectOptionRecord(PlanungskontextOptions),
    contentCommunitiesSelected: toSelectOptionRecord(ContentCommunityOptions),
    kanaeleSelected: toSelectOptionRecord(KanalOptions),
  } satisfies Partial<Record<keyof RechercheSearchFormData, Record<string, { text: string }>>>;

  onRemove(filter: SearchFormFilterObject) {
    this.facade.removeFilter(filter.key, filter.value);
  }

  private getFiltersFromSearchForm(
    searchValue: Partial<RechercheSearchFormData>,
  ): SearchFormFilterObject[] {
    const propertiesToInclude = [
      "contentCommunitiesSelected",
      "genreSelected",
      "kanaeleSelected",
      "planungskontexteSelected",
      "redaktionenSelected",
    ] satisfies (keyof RechercheSearchFormData)[];

    const filters: SearchFormFilterObject[] = propertiesToInclude.flatMap(
      (key: (typeof propertiesToInclude)[number]) => {
        const currentValues = searchValue[key]?.value ?? [];
        const translations = this.translations[key];

        return currentValues.map(
          (value): SearchFormFilterObject => ({
            key,
            value,
            displayValue: translations[value].text,
          }),
        );
      },
    );

    return filters;
  }
}
