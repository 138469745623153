import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  ContextMenuSelectEvent,
  ContextMenuTargetDirective,
  MenuItemComponent,
} from "@progress/kendo-angular-menu";
import { NGXLogger } from "ngx-logger";
import { Observable, switchMap } from "rxjs";
import ansichtSelectors from "src/app/core/stores/ansicht/ansicht.selectors";
import { ekWindowActions } from "src/app/core/stores/ek-window/ek-window.actions";
import eventSelectors from "src/app/core/stores/event/event.selectors";
import konkurrenzprogrammSelectors from "src/app/core/stores/konkurrenzprogramm/konkurrenzprogramm.selectors";
import { ContextMenuEvent } from "src/app/models/context-menu";
import { Aktion, AktionEnum, AktionResult } from "src/app/models/enums/aktion";
import { Icons } from "src/app/models/icons";
import { CopyPattern } from "src/app/models/openapi/model/copy-pattern";
import { KonkurrenzprogrammDto } from "src/app/models/openapi/model/konkurrenzprogramm-dto";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { CustomWindowService } from "src/app/services/custom-window.service";
import { SendeplatzUtils } from "src/app/utils/sendeplatz.utils";

@Component({
  selector: "app-konkurrenzprogramm-list",
  templateUrl: "./konkurrenzprogramm-list.component.html",
  styleUrls: ["./konkurrenzprogramm-list.component.scss"],
})
export class KonkurrenzprogrammListComponent implements OnInit {
  @Input() zeitraum: { start: Date; end: Date };
  @Input() sendeplaetze: SendeplatzDto[];

  CopyPattern = CopyPattern;
  AktionEnum = AktionEnum;
  Aktion = Aktion;
  Icons = Icons;

  konkurrenzprogramme$ = this.store.select(ansichtSelectors.selectIsBlockansichtOrEkAnsicht).pipe(
    switchMap((isBlockOrEkAnsicht) => {
      if (isBlockOrEkAnsicht) {
        return this.store.select(
          konkurrenzprogrammSelectors.selectKonkurrenzprogrammeForInterval(this.zeitraum),
        );
      } else {
        return this.store.select(
          konkurrenzprogrammSelectors.selectKonkurrenzprogrammForSendeplatz(
            SendeplatzUtils.getSendeplatzKeys(this.sendeplaetze),
          ),
        );
      }
    }),
  );
  hasPrecedingEvents$: Observable<boolean>;

  constructor(
    protected customWindowService: CustomWindowService,
    private store: Store,
    private logger: NGXLogger,
  ) {}

  ngOnInit(): void {
    this.hasPrecedingEvents$ = this.store.select(
      eventSelectors.selectHasEventForSendeplaetze(this.sendeplaetze),
    );
  }

  openEventKonkurrenzprogrammWindow(value: {
    event: MouseEvent;
    konkurrenzEvent: KonkurrenzprogrammDto;
  }) {
    // Doppelklick wird nicht an weiter unten liegenede Elemente weitergegeben,
    // und somit wird nicht der Erstellen-Dialog für E&K geöffnet
    value.event.stopPropagation();

    this.store.dispatch(
      ekWindowActions.openWindow({
        input: {
          type: "Konkurrenzprogramm",
          konkurrenzEvent: value.konkurrenzEvent,
          asNewCopy: false,
        },
      }),
    );
  }

  /**
   * Verarbeitet das Context Menü für Konkurrenzprogramme und Events und öffnet ggf. den gemeinsamen Dialog
   * @param event
   * @param isEvent
   */
  onKonkurrenzContextMenuSelect(event: ContextMenuSelectEvent): void {
    if (!(event.item instanceof MenuItemComponent)) {
      this.logger.error(`Unexpected type for context menu selection`);
      return;
    }

    const { action, copyPattern, systemAction } = event.item.data as ContextMenuEvent;
    const viewModel = (event.target as ContextMenuTargetDirective).data as KonkurrenzprogrammDto;

    let aktion;

    switch (action) {
      case AktionEnum.DETAILS:
        aktion = ekWindowActions.openWindow({
          input: {
            type: "Konkurrenzprogramm",
            konkurrenzEvent: viewModel,
            asNewCopy: false,
          },
        });
        break;
      case AktionEnum.KOPIE_HIER:
      case AktionEnum.KOPIE_NAECHSTE_WOCHE:
        aktion = ekWindowActions.handleEkWindowResult({
          result: {
            result: AktionResult.COPY,
            returnValue: {
              action: systemAction,
              value: viewModel,
              isEvent: false,
              copyPattern,
            },
          },
        });
        break;
      case AktionEnum.KOPIE_BENUTZERDEFINIERT: {
        aktion = ekWindowActions.openWindow({
          input: {
            type: "Konkurrenzprogramm",
            konkurrenzEvent: viewModel,
            asNewCopy: true,
          },
        });
      }
    }

    if (aktion) {
      this.store.dispatch(aktion);
    }
  }
}
