import { Component, inject } from "@angular/core";
import { AusstrahlungOptions } from "src/app/models/enums/ausstrahlung";
import { GenreOptionsFiltered } from "src/app/models/enums/genre";
import { RedaktionOptions } from "src/app/models/enums/redaktion";
import { Genre } from "src/app/models/openapi/model/genre";
import { Redaktion } from "src/app/models/openapi/model/redaktion";
import { MengengeruesteintragWindowFacade } from "./mengengeruesteintrag-window.facade";

@Component({
  selector: "app-mengengeruesteintrag-window",
  templateUrl: "./mengengeruesteintrag-window.component.html",
  styleUrls: ["./mengengeruesteintrag-window.component.scss"],
  providers: [MengengeruesteintragWindowFacade],
})
export class MengengeruestEintragWindowComponent {
  public readonly facade = inject(MengengeruesteintragWindowFacade);

  Genre = Genre;
  RedaktionOptions = RedaktionOptions.filter(
    (redaktion) => redaktion.value !== Redaktion.KEINE_REDAKTION,
  );
  GenreOptions = GenreOptionsFiltered;
  AusstrahlungOptions = AusstrahlungOptions;
}
