import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { catchError, EMPTY, filter, map, of, switchMap, take, tap } from "rxjs";
import { PharosService } from "src/app/api/pharos/pharos.service";
import { HttpRequestError } from "src/app/errors/http-request-error";
import { PharosWindowService } from "src/app/services/pharos-window.service";
import { CustomNotificationService } from "src/app/shared/notifications/custom-notification.service";
import { allValuesDefined } from "src/app/utils/array-utils";
import { createConfirmClosingEffect, createTrySavingEffect } from "src/app/utils/ngrx-utils";
import { sendeplatzActions } from "../sendeplatz/sendeplatz.actions";
import { pharosExportWindowActions } from "./pharos-export-window.actions";
import {
  calculateInitialFormStateFromInput,
  pharosExportWindowFormActions,
} from "./pharos-export-window.form";
import { pharosExportWindowSelectors } from "./pharos-export-window.selectors";
import { notificationActions } from "../notification/notification.actions";

@Injectable()
export class PharosExportWindowEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly pharosApi = inject(PharosService);
  private readonly pharosWindowService = inject(PharosWindowService);
  private readonly notificationService = inject(CustomNotificationService);

  tryOpenNachPharosUebertragenWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(pharosExportWindowActions.tryOpenWindow),
      switchMap(({ input: sendeplatzKey }) =>
        this.pharosApi
          .getPharosTransferTable$({
            sendeplatzKey,
          })
          .pipe(
            map((pharosContent) =>
              pharosExportWindowActions.openWindow({
                windowInput: {
                  prepareTransferToPharosQuery: { sendeplatzKey },
                  pharosContent,
                },
              }),
            ),
            catchError((error: HttpRequestError) => {
              return of(notificationActions.showNotification({
                notificationType: "Error",
                message: error.message,
              }));
            }),
          ),
      ),
    );
  });

  openNachPharosUebertragenWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(pharosExportWindowActions.openWindow),
      switchMap(() => {
        const windowRef = this.pharosWindowService.onOpenNachPharosUebertragenWindow();

        return this.actions$.pipe(
          ofType(pharosExportWindowActions.closeWindow),
          take(1),
          tap(() => {
            windowRef.close();
          }),
          map(() => pharosExportWindowActions.windowClosed()),
        );
      }),
    );
  });

  trySaving$ = createTrySavingEffect({
    trySavingAction: pharosExportWindowFormActions.trySaving,
    formIsValid$: this.store.select(pharosExportWindowSelectors.selectFormIsValid),
    saveAction: pharosExportWindowFormActions.save,
  });

  tryClosingWithoutSaving$ = createConfirmClosingEffect({
    tryClosingAction: pharosExportWindowActions.tryClosingWithoutSaving,
    preventClosingWhen$: this.store.select(pharosExportWindowSelectors.selectFormIsDirty),
    closingAction: pharosExportWindowActions.closeWindow,
  });

  setInitialFormValueAfterTransferTableLoaded$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(pharosExportWindowActions.openWindow),
      map(({ windowInput: pharosWindowInput }) =>
        pharosExportWindowFormActions.setValue({
          value: calculateInitialFormStateFromInput(pharosWindowInput),
        }),
      ),
    );
  });

  onSave$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(pharosExportWindowFormActions.save),
      concatLatestFrom(() => [this.store.select(pharosExportWindowSelectors.selectTransferTable)]),
      filter(allValuesDefined),
      switchMap(([_, transferTable]) => {
        return this.pharosApi.updatePharosTransferTable$(transferTable).pipe(
          // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
          switchMap(() => [
            sendeplatzActions.updateSendeplatzWithSendeplatzKey({
              sendeplatzKey: transferTable.sendeplatzKey,
            }),
            pharosExportWindowActions.closeWindow(),
          ]),
          catchError((error: HttpRequestError) => {
            this.notificationService.showErrorNotification(error.message);
            return EMPTY;
          }),
        );
      }),
    );
  });
}
