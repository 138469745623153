import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CreateFormGroupArgs, GridComponent } from "@progress/kendo-angular-grid";
import { PlanungshinweiseWindowInput } from "src/app/core/stores/planungshinweise-window/planungshinweise-window.model";
import {
  PlanungshinweisStatusOptions,
  PlanungshinweisStatusRecord,
} from "src/app/models/enums/planungshinweis-status";
import { DeveloperError } from "src/app/models/errors/technical.error";
import { Icons } from "src/app/models/icons";
import { PlanungshinweisDto } from "src/app/models/openapi/model/planungshinweis-dto";
import { PlanungshinweisStatus } from "src/app/models/openapi/model/planungshinweis-status";
import { DebugService } from "src/app/services/debug.service";
import { PlanungshinweisEditService } from "src/app/services/planungshinweis-edit.service";
import { CustomValidators } from "src/app/shared/form-inputs/custom-validators-angular";
import { PlanungshinweiseWindowFacade } from "./planungshinweise-window.facade";

@Component({
  selector: "app-planungshinweise-window",
  templateUrl: "./planungshinweise-window.component.html",
  styleUrls: ["./planungshinweise-window.component.scss"],
  providers: [PlanungshinweiseWindowFacade],
})
export class PlanungshinweiseWindowComponent implements OnInit {
  public readonly facade = inject(PlanungshinweiseWindowFacade);
  private readonly destroyRef$ = inject(DestroyRef);
  private readonly formBuilder = inject(FormBuilder);
  protected readonly planungshinweisEditService = inject(PlanungshinweisEditService);
  protected readonly debugService = inject(DebugService);

  Icons = Icons;
  PlanungshinweisStatusOptions = PlanungshinweisStatusOptions;
  PlanungshinweisStatusRecord = PlanungshinweisStatusRecord;

  // Weil wir hier mit Kendo Grid arbeiten, das alles Sync haben will, kein Observable
  protected input: PlanungshinweiseWindowInput | null;
  formGroup: ReturnType<typeof this.createFormGroup>;

  ngOnInit(): void {
    this.facade.windowInput$.pipe(takeUntilDestroyed(this.destroyRef$)).subscribe((input) => {
      this.input = input;
    });

    // Kendo Grid benötigt eine gesetzte FormGroup
    this.formGroup = this.createFormGroup({
      isNew: true,
      dataItem: null,
      rowIndex: -1,
      sender: null as unknown as GridComponent,
    });
  }

  public setDirty(isDirty: boolean) {
    this.facade.setDirtyState(isDirty);
  }

  /**
   * Die Signatur der args ist von Kendo vorgegeben.
   */
  public createFormGroup = (args: CreateFormGroupArgs): FormGroup => {
    if (!this.input) {
      throw new DeveloperError("No input set");
    }

    const item = args.isNew
      ? ({
          von: this.input.von,
          bis: this.input.bis,
          kanal: this.input.kanal,
          status: PlanungshinweisStatus.PROBLEMATISCH,
          notiz: "",
        } as PlanungshinweisDto)
      : (args.dataItem as PlanungshinweisDto);

    this.formGroup = this.formBuilder.group({
      von: [item.von, [Validators.required, CustomValidators.dateBefore("bis", "Bis")]],
      bis: [item.bis, [Validators.required, CustomValidators.dateAfter("von", "Von")]],
      kanal: item.kanal,
      status: item.status,
      notiz: item.notiz,
    });

    return this.formGroup;
  };
}
