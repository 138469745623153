import { Component, ElementRef, inject, ViewChild } from "@angular/core";
import { FormControlState, FormControlValueTypes } from "ngrx-forms";
import { BehaviorSubject, combineLatest, map } from "rxjs";
import { PharosExportWindowFormData } from "src/app/core/stores/pharos-export-window/pharos-export-window.model";
import { Icons } from "src/app/models/icons";
import { PharosExportWindowFacade } from "./pharos-export-window.facade";

const fieldsToDisable = [
  "sendetitel",
  "folgenNrInfo",
  "dachzeile",
] satisfies (keyof PharosExportWindowFormData)[];
type FieldsToDisable = (typeof fieldsToDisable)[number];

const setControlDisabledStateButKeepErrors = <T extends FormControlValueTypes>(
  control: FormControlState<T>,
  disabledState: boolean,
): FormControlState<T> => {
  return {
    ...control,
    isDisabled: disabledState,
    isEnabled: !disabledState,
  };
};

@Component({
  selector: "app-pharos-export-dialog",
  templateUrl: "./pharos-export-window.component.html",
  styleUrls: ["./pharos-export-window.component.scss"],
  providers: [PharosExportWindowFacade],
})
export class PharosExportWindowComponent {
  public readonly Icons = Icons;
  public readonly facade = inject(PharosExportWindowFacade);

  private disabledControls = new BehaviorSubject<Record<FieldsToDisable, boolean>>({
    sendetitel: true,
    folgenNrInfo: true,
    dachzeile: true,
  });

  // Wir müssen das Form hier patchen, weil disabled Controls keine Fehler haben können.
  // Daher ist das Form valid, obwohl es fachlich nicht valid ist.
  public formStateWithCustomDisablement$ = combineLatest([
    this.facade.formState$,
    this.disabledControls,
  ]).pipe(
    map(([formState, disabledControls]) => {
      return {
        ...formState,
        controls: {
          ...formState.controls,
          sendetitel: setControlDisabledStateButKeepErrors(
            formState.controls.sendetitel,
            disabledControls.sendetitel,
          ),
          folgenNrInfo: setControlDisabledStateButKeepErrors(
            formState.controls.folgenNrInfo,
            disabledControls.folgenNrInfo,
          ),
          dachzeile: setControlDisabledStateButKeepErrors(
            formState.controls.dachzeile,
            disabledControls.dachzeile,
          ),
        },
      };
    }),
  );


  @ViewChild("sendetitelPharosNeu") sendetitel: HTMLElement;
  disableSendetitel(isDisabled: boolean): void {
    this.disabledControls.next({ ...this.disabledControls.value, sendetitel: isDisabled });
    if(!isDisabled) {
      setTimeout(() => {
        this.sendetitel.focus();
      });
    }
  }

  @ViewChild("folgenNrInfoPharosNeu") folgenNrInfo: HTMLElement;
  disableFolgenNrInfo(isDisabled: boolean): void {
    this.disabledControls.next({ ...this.disabledControls.value, folgenNrInfo: isDisabled });
    if(!isDisabled) {
      setTimeout(() => {
        this.folgenNrInfo.focus();
      });
    }
  }

  @ViewChild("dachzeilePharosNeu") dachzeile: HTMLElement;
  disableDachzeile(isDisabled: boolean): void {
    this.disabledControls.next({ ...this.disabledControls.value, dachzeile: isDisabled });
    if(!isDisabled) {
      setTimeout(() => {
        this.dachzeile.focus();
      });
    }
  }

  set(field: keyof PharosExportWindowFormData, value: string | undefined): void {
    if (value !== undefined) {
      this.facade.setFormFieldValue(field, value);
    }
  }

  onDachzeileClick(event: MouseEvent, pharosDachzeile: string | undefined): void {
    const isSelected = (event.target as HTMLInputElement).checked;
    const value = isSelected ? pharosDachzeile : "";
    this.set("dachzeile", value);
  }
}
