import { GUID } from "src/app/models";
import { DeveloperError } from "src/app/models/errors/technical.error";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { PlanungsobjektFarbgebung } from "src/app/models/openapi/model/planungsobjekt-farbgebung";
import { PlanungsobjektLinearVorgeplantBlockAktualisierenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeplant-block-aktualisieren-command";
import { PlanungsobjektLinearVorgeplantBlockErstellenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeplant-block-erstellen-command";
import { PlanungsobjektLinearVorgeplantSendeplatzAktualisierenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeplant-sendeplatz-aktualisieren-command";
import { PlanungsobjektLinearVorgeschlagenAktualisierenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeschlagen-aktualisieren-command";
import { PlanungsobjektLinearVorgeschlagenErstellenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeschlagen-erstellen-command";
import { PlanungsobjektOnDemandVorgemerktAktualisierenCommand } from "src/app/models/openapi/model/planungsobjekt-on-demand-vorgemerkt-aktualisieren-command";
import { PlanungsobjektOnDemandVorgeplantErstellenCommand } from "src/app/models/openapi/model/planungsobjekt-on-demand-vorgeplant-erstellen-command";
import { Redaktion } from "src/app/models/openapi/model/redaktion";
import {
  PlanungsobjektWindowInputWithPlanungsobjekt,
  PlanungsobjektWindowUseCase,
} from "src/app/shared/windows/planungsobjekt-window/planungsobjekt-window.model";
import { assertUnreachable } from "src/app/utils/function-utils";
import { KanalOffsetUtils } from "src/app/utils/kanal-offset-utils";
import { onDemandActions } from "../on-demand/on-demand.actions";
import { planungsobjektActions } from "../planungsobjekt/planungsobjekt.actions";
import {
  PlanungsobjektWindowPlanungFormData,
  SLIDER_LEFT_POSITION,
  SLIDER_RIGHT_POSITION,
} from "./planungsobjekt-window.model";

export function extractPlanungsobjektCommandAction(
  formData: PlanungsobjektWindowPlanungFormData,
  windowInput: PlanungsobjektWindowInputWithPlanungsobjekt,
  shouldCloseWindow: boolean,
) {
  const kanal =
    windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ ||
    windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT ||
    windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_ONDEMAND
      ? windowInput.kanal
      : Kanal.UNBEKANNT;

  switch (windowInput.usecase) {
    case PlanungsobjektWindowUseCase.CREATE_ONDEMAND: {
      switch (windowInput.planungskontext) {
        case Planungskontext.VORGEPLANT:
          return extractCreateOnDemandVorgeplantCommandAction(formData, kanal, shouldCloseWindow);
        case Planungskontext.VORGESCHLAGEN:
          return extractCreateOnDemandVorgeschlagenCommandAction(
            formData,
            kanal,
            shouldCloseWindow,
          );
        default:
          throw new DeveloperError(
            `Unbekannter Planungskontext für CREATE_ONDEMAND ${windowInput.planungskontext}
            Wurde case in extractPlanungsobjektCommandAction vergessen?`,
          );
      }
    }
    case PlanungsobjektWindowUseCase.EDIT_ONDEMAND: {
      const id = windowInput.planungsobjektId;
      if (!id) {
        throw new DeveloperError(
          "extractPlanungsobjektCommandAction fehlgeschlagen - Planungsobjekt-ID fehlt",
        );
      }
      switch (windowInput.planungskontext) {
        case Planungskontext.VORGEPLANT:
          return extractUpdateOnDemandVorgeplantCommandAction(formData, id, shouldCloseWindow);
        case Planungskontext.VORGESCHLAGEN:
          return extractUpdateOnDemandVorgeschlagenCommandAction(formData, id, shouldCloseWindow);
        case Planungskontext.VORGEMERKT:
          return extractUpdateOnDemandVorgemerktCommandAction(formData, id, shouldCloseWindow);
        default:
          throw new DeveloperError(
            `Unbekannter Planungskontext für EDIT_ONDEMAND ${windowInput.planungskontext}
            Wurde case in extractPlanungsobjektCommandAction vergessen?`,
          );
      }
    }

    case PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ: {
      switch (windowInput.planungskontext) {
        // "Vorgeplant" case sollte  weiterhin über die "+" Logik abgehandelt, also ist das hier nicht geprüft/abgedeckt
        case Planungskontext.VORGEPLANT:
          return extractCreateLinearSendeplatzVorgeplantCommandAction(
            formData,
            kanal,
            windowInput.wannPreset.planlaenge,
            shouldCloseWindow,
          );
        case Planungskontext.VORGESCHLAGEN:
          return extractCreateLinearSendeplatzVorgeschlagenCommandAction(
            formData,
            kanal,
            windowInput.wannPreset.planlaenge,
            shouldCloseWindow,
          );
        // "Vorgemerkt" case wird weiterhin über die "+" Logik abgehandelt
        default:
          throw new DeveloperError(
            `Unbekannter Planungskontext für CREATE_LINEAR ${windowInput.planungskontext}
            Wurde case in extractPlanungsobjektCommandAction vergessen?`,
          );
      }
    }
    case PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT: {
      switch (windowInput.planungskontext) {
        case Planungskontext.VORGEPLANT:
          return extractCreateLinearBlockansichtVorgeplantCommandAction(
            formData,
            kanal,
            shouldCloseWindow,
          );
        // "Vorgeschlagen" existiert nicht auf Blockansichten
        // "Vorgemerkt" case wird weiterhin über die "+" Logik abgehandelt
        default:
          throw new DeveloperError(
            `Unbekannter Planungskontext für CREATE_LINEAR_BLOCKANSICHT ${windowInput.planungskontext}
            Wurde case in extractPlanungsobjektCommandAction vergessen?`,
          );
      }
    }
    case PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ: {
      const id = windowInput.planungsobjektId;
      if (!id) {
        throw new DeveloperError(
          "extractPlanungsobjektCommandAction fehlgeschlagen - Planungsobjekt-ID fehlt",
        );
      }
      switch (windowInput.planungskontext) {
        case Planungskontext.VORGEPLANT:
          return extractUpdateLinearSendeplatzVorgeplantCommandAction(
            formData,
            id,
            windowInput.planungsobjekt.kanal,
            shouldCloseWindow,
          );
        case Planungskontext.VORGESCHLAGEN: {
          const schemaplatzLaengeAusPlanungsobjekt =
            windowInput.planungsobjekt.publikationsplanung?.sendeplatzLaenge;
          if (!schemaplatzLaengeAusPlanungsobjekt)
            throw new DeveloperError("Schemaplatz-Länge fehlt");
          return extractUpdateLinearSendeplatzVorgeschlagenCommandAction(
            formData,
            schemaplatzLaengeAusPlanungsobjekt, // Quasi die Schemaplatz-Länge. Müssen wir ansonsten durchschleifen
            id,
            windowInput.planungsobjekt.kanal,
            shouldCloseWindow,
          );
        }
        case Planungskontext.VORGEMERKT:
          return extractUpdateLinearVorgemerktCommandAction(formData, id, shouldCloseWindow);
        default:
          throw new DeveloperError(
            `Unbekannter Planungskontext für EDIT_LINEAR ${windowInput.planungskontext}
            Wurde case in extractPlanungsobjektCommandAction vergessen?`,
          );
      }
    }

    case PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT: {
      const id = windowInput.planungsobjektId;
      if (!id) {
        throw new DeveloperError(
          "extractPlanungsobjektCommandAction fehlgeschlagen - Planungsobjekt-ID fehlt",
        );
      }
      switch (windowInput.planungskontext) {
        case Planungskontext.VORGEPLANT:
          return extractUpdateLinearBlockansichtVorgeplantCommandAction(
            formData,
            id,
            windowInput.planungsobjekt.kanal,
            shouldCloseWindow,
          );
        // "Vorgeschlagen" existiert nicht auf Blockansichten
        case Planungskontext.VORGEMERKT:
          return extractUpdateLinearVorgemerktCommandAction(formData, id, shouldCloseWindow);
        default:
          throw new DeveloperError(
            `Unbekannter Planungskontext für EDIT_LINEAR_BLOCKANSICHT ${windowInput.planungskontext}
            Wurde case in extractPlanungsobjektCommandAction vergessen?`,
          );
      }
    }

    case PlanungsobjektWindowUseCase.READONLY_LINEAR:
    case PlanungsobjektWindowUseCase.READONLY_ONDEMAND:
      throw new DeveloperError(
        `READONLY WindowInput sollte nicht in extractPlanungsobjektCommandAction landen.
          Wurde case in extractPlanungsobjektCommandAction vergessen?`,
      );

    default:
      assertUnreachable(windowInput, "Unbekanntes WindowInput: %1");
  }
}

function extractCreateOnDemandVorgeplantCommandAction(
  formValue: PlanungsobjektWindowPlanungFormData,
  kanal: Kanal,
  shouldCloseWindow: boolean,
) {
  const {
    onlineAb,
    onlineAbZeit,
    onlineBis,
    verweildauerInTagen,
    wunschOnlineBisVsVerweildauerToggle,
    planlaenge,
    contentCommunities,
    fruehesteVeroeffentlichung,
    genre,
    highlight,
    titel,
    stofffuehrendeRedaktion,
    fsk,
    staffelnummer,
    folgennummer,
    gesamtfolgennummer,
    inhaltsbeschreibung,
    mitwirkende,
  } = formValue;
  const notiz = formValue.notiz ?? "";
  const farbgebung = formValue.farbgebung ?? PlanungsobjektFarbgebung.KEINE;
  // Redaktion ist bei OnDemand Vorgeplant optional und wird daher per Default auf KEINE gesetzt
  const redaktion = formValue.redaktion ?? Redaktion.KEINE_REDAKTION;
  if (
    !onlineAb ||
    (!onlineBis && verweildauerInTagen) ||
    !titel ||
    titel.length < 2 ||
    !genre ||
    highlight === null ||
    !contentCommunities ||
    kanal === Kanal.UNBEKANNT
  ) {
    throw new DeveloperError(
      `extractCreateOnDemandVorgeplantCommandAction fehlgeschlagen - nicht alle Felder haben den erwarteten Wert.
      Stimmt Prüfung in extract Methode mit Formular Validierung überein?`,
    );
  }
  return onDemandActions.createOnDemandVorgeplant({
    shouldCloseWindow,
    value: {
      onlineAb,
      onlineAbZeit,
      onlineBis: wunschOnlineBisVsVerweildauerToggle === SLIDER_RIGHT_POSITION ? null : onlineBis,
      verweildauerInTagen:
        wunschOnlineBisVsVerweildauerToggle === SLIDER_LEFT_POSITION ? null : verweildauerInTagen,
      kanal,
      titel,
      planlaenge,
      redaktion,
      genre,
      notiz,
      highlight,
      farbgebung,
      contentCommunities,
      fruehesteVeroeffentlichung,
      stofffuehrendeRedaktion,
      fsk,
      staffelnummer,
      folgennummer,
      gesamtfolgennummer,
      inhaltsbeschreibung,
      mitwirkende,
    } satisfies PlanungsobjektOnDemandVorgeplantErstellenCommand,
  });
}

function extractCreateOnDemandVorgeschlagenCommandAction(
  formValue: PlanungsobjektWindowPlanungFormData,
  kanal: Kanal,
  shouldCloseWindow: boolean,
) {
  const {
    onlineAb,
    onlineAbZeit,
    onlineBis,
    verweildauerInTagen,
    wunschOnlineBisVsVerweildauerToggle,
    planlaenge,
    contentCommunities,
    fruehesteVeroeffentlichung,
    genre,
    redaktion,
    highlight,
    titel,
    stofffuehrendeRedaktion,
    fsk,
    staffelnummer,
    folgennummer,
    gesamtfolgennummer,
    inhaltsbeschreibung,
    mitwirkende,
  } = formValue;
  const notiz = formValue.notiz ?? "";
  const farbgebung = formValue.farbgebung ?? PlanungsobjektFarbgebung.KEINE;
  if (
    !onlineAb ||
    (!onlineBis && !verweildauerInTagen) ||
    !titel ||
    titel.length < 2 ||
    !genre ||
    !redaktion ||
    redaktion === Redaktion.KEINE_REDAKTION ||
    highlight === null ||
    !contentCommunities ||
    kanal === Kanal.UNBEKANNT
  ) {
    throw new DeveloperError(
      `extractCreateOnDemandVorgeschlagenCommandAction fehlgeschlagen - nicht alle Felder haben den erwarteten Wert
      Stimmt Prüfung in extract Methode mit Formular Validierung überein?`,
    );
  }
  return onDemandActions.createOnDemandVorgeschlagen({
    shouldCloseWindow,
    value: {
      onlineAb,
      onlineAbZeit,
      onlineBis: wunschOnlineBisVsVerweildauerToggle === SLIDER_RIGHT_POSITION ? null : onlineBis,
      verweildauerInTagen:
        wunschOnlineBisVsVerweildauerToggle === SLIDER_LEFT_POSITION ? null : verweildauerInTagen,
      kanal,
      titel,
      planlaenge,
      redaktion,
      genre,
      notiz,
      highlight,
      farbgebung,
      contentCommunities,
      fruehesteVeroeffentlichung,
      stofffuehrendeRedaktion,
      fsk,
      staffelnummer,
      folgennummer,
      gesamtfolgennummer,
      inhaltsbeschreibung,
      mitwirkende,
    },
  });
}

function extractUpdateOnDemandVorgeplantCommandAction(
  formValue: PlanungsobjektWindowPlanungFormData,
  id: GUID,
  shouldCloseWindow: boolean,
) {
  const {
    onlineAb,
    onlineAbZeit,
    onlineBis,
    verweildauerInTagen,
    wunschOnlineBisVsVerweildauerToggle,
    planlaenge,
    contentCommunities,
    fruehesteVeroeffentlichung,
    genre,
    highlight,
    titel,
    stofffuehrendeRedaktion,
    fsk,
    staffelnummer,
    folgennummer,
    gesamtfolgennummer,
    inhaltsbeschreibung,
    mitwirkende,
  } = formValue;
  const notiz = formValue.notiz ?? "";
  const farbgebung = formValue.farbgebung ?? PlanungsobjektFarbgebung.KEINE;
  const redaktion = formValue.redaktion ?? Redaktion.KEINE_REDAKTION;
  if (
    !onlineAb ||
    (!onlineBis && !verweildauerInTagen) ||
    !titel ||
    titel.length < 2 ||
    !genre ||
    highlight === null ||
    !contentCommunities
  ) {
    throw new DeveloperError(
      `extractUpdateOnDemandVorgeplantCommandAction fehlgeschlagen - nicht alle Felder haben den erwarteten Wert
      Stimmt Prüfung in extract Methode mit Formular Validierung überein?`,
    );
  }
  return onDemandActions.updateOnDemandVorgeplant({
    shouldCloseWindow,
    value: {
      id,
      onlineAb,
      onlineAbZeit,
      onlineBis: wunschOnlineBisVsVerweildauerToggle === SLIDER_RIGHT_POSITION ? null : onlineBis,
      verweildauerInTagen:
        wunschOnlineBisVsVerweildauerToggle === SLIDER_LEFT_POSITION ? null : verweildauerInTagen,
      titel,
      planlaenge,
      redaktion,
      genre,
      notiz,
      highlight,
      farbgebung,
      contentCommunities,
      fruehesteVeroeffentlichung,
      stofffuehrendeRedaktion,
      fsk,
      staffelnummer,
      folgennummer,
      gesamtfolgennummer,
      inhaltsbeschreibung,
      mitwirkende,
    },
  });
}

function extractUpdateOnDemandVorgeschlagenCommandAction(
  formValue: PlanungsobjektWindowPlanungFormData,
  id: GUID,
  shouldCloseWindow: boolean,
) {
  const {
    onlineAb,
    onlineAbZeit,
    onlineBis,
    verweildauerInTagen,
    wunschOnlineBisVsVerweildauerToggle,
    planlaenge,
    contentCommunities,
    fruehesteVeroeffentlichung,
    genre,
    highlight,
    titel,
    stofffuehrendeRedaktion,
    fsk,
    staffelnummer,
    folgennummer,
    gesamtfolgennummer,
    inhaltsbeschreibung,
    mitwirkende,
  } = formValue;
  const notiz = formValue.notiz ?? "";
  const farbgebung = formValue.farbgebung ?? PlanungsobjektFarbgebung.KEINE;
  const redaktion = formValue.redaktion ?? Redaktion.KEINE_REDAKTION;
  if (
    !onlineAb ||
    (!onlineBis && !verweildauerInTagen) ||
    !titel ||
    titel.length < 2 ||
    !genre ||
    highlight === null ||
    !contentCommunities
  ) {
    throw new DeveloperError(
      `extractUpdateOnDemandVorgeschlagenCommandAction fehlgeschlagen - nicht alle Felder haben den erwarteten Wert
      Stimmt Prüfung in extract Methode mit Formular Validierung überein?`,
    );
  }
  return onDemandActions.updateOnDemandVorgeschlagen({
    shouldCloseWindow,
    value: {
      id,
      onlineAb,
      onlineAbZeit,
      onlineBis: wunschOnlineBisVsVerweildauerToggle === SLIDER_RIGHT_POSITION ? null : onlineBis,
      verweildauerInTagen:
        wunschOnlineBisVsVerweildauerToggle === SLIDER_LEFT_POSITION ? null : verweildauerInTagen,
      titel,
      planlaenge,
      redaktion,
      genre,
      notiz,
      highlight,
      farbgebung,
      contentCommunities,
      fruehesteVeroeffentlichung,
      stofffuehrendeRedaktion,
      fsk,
      staffelnummer,
      folgennummer,
      gesamtfolgennummer,
      inhaltsbeschreibung,
      mitwirkende,
    },
  });
}

function extractUpdateOnDemandVorgemerktCommandAction(
  formValue: PlanungsobjektWindowPlanungFormData,
  id: GUID,
  shouldCloseWindow: boolean,
) {
  const {
    onlineAb,
    onlineBis,
    verweildauerInTagen,
    wunschOnlineBisVsVerweildauerToggle,
    planlaenge,
    contentCommunities,
    fruehesteVeroeffentlichung,
    genre,
    highlight,
    titel,
    stofffuehrendeRedaktion,
    fsk,
    staffelnummer,
    folgennummer,
    gesamtfolgennummer,
    inhaltsbeschreibung,
    mitwirkende,
  } = formValue;
  const notiz = formValue.notiz ?? "";
  const farbgebung = formValue.farbgebung ?? PlanungsobjektFarbgebung.KEINE;
  const redaktion = formValue.redaktion ?? Redaktion.KEINE_REDAKTION;
  // onlineAbZeit kommt teilweise als leerer String aus dem Form, was kein gültiges TimeOnly ist
  const onlineAbZeit = formValue.onlineAbZeit || null;
  if (!titel || titel.length < 2 || !genre || highlight === null || !contentCommunities) {
    throw new DeveloperError(
      `extractUpdateOnDemandVorgemerktCommandAction fehlgeschlagen - nicht alle Felder haben den erwarteten Wert
      Stimmt Prüfung in extract Methode mit Formular Validierung überein?`,
    );
  }
  return onDemandActions.updateOnDemandVorgemerkt({
    shouldCloseWindow,
    value: {
      id,
      onlineAb,
      onlineAbZeit,
      onlineBis: wunschOnlineBisVsVerweildauerToggle === SLIDER_RIGHT_POSITION ? null : onlineBis,
      verweildauerInTagen:
        wunschOnlineBisVsVerweildauerToggle === SLIDER_LEFT_POSITION ? null : verweildauerInTagen,
      titel,
      planlaenge,
      redaktion,
      genre,
      notiz,
      highlight,
      farbgebung,
      contentCommunities,
      fruehesteVeroeffentlichung,
      stofffuehrendeRedaktion,
      fsk,
      staffelnummer,
      folgennummer,
      gesamtfolgennummer,
      inhaltsbeschreibung,
      mitwirkende,
    } satisfies PlanungsobjektOnDemandVorgemerktAktualisierenCommand,
  });
}

/**
 * Wird aktuell nicht wirklich benötigt, weil das Erstellen auf Sendeplatz immernoch der "+" Logik folgt
 * @param formValue
 * @param kanal
 * @returns
 * @throws DeveloperError
 **/

function extractCreateLinearSendeplatzVorgeplantCommandAction(
  formValue: PlanungsobjektWindowPlanungFormData,
  kanal: Kanal,
  schemaplatzLaenge: number, // Vordefinierter Planlänge-Wert des Schemaplatzes im Gegensatz zur Planlänge des Planungsobjekts
  shouldCloseWindow: boolean,
) {
  const { sendetag, beginnzeit, planlaenge, contentCommunities, genre, highlight, titel } =
    formValue;

  const redaktion = formValue.redaktion ?? Redaktion.KEINE_REDAKTION;

  if (
    !sendetag ||
    !beginnzeit ||
    !planlaenge ||
    !redaktion ||
    !titel ||
    titel.length < 2 ||
    !genre ||
    highlight === null ||
    !contentCommunities ||
    kanal === Kanal.UNBEKANNT
  ) {
    throw new DeveloperError(
      `extractCreateLinearSendeplatzVorgeplantCommandAction fehlgeschlagen - nicht alle Felder haben den erwarteten Wert.
      Stimmt Prüfung in extract Methode mit Formular Validierung überein?`,
    );
  }

  return planungsobjektActions.createPlanungsobjektOnSendeplatz({
    shouldCloseWindow,
    titel,
    sendeplatzKey: {
      beginnzeit,
      sendetag,
      kanal,
      laenge: schemaplatzLaenge,
      kalendertag: KanalOffsetUtils.calculateKalendertagAsString(
        new Date(sendetag),
        beginnzeit,
        kanal,
      ),
    },
  });
}

function extractCreateLinearBlockansichtVorgeplantCommandAction(
  formValue: PlanungsobjektWindowPlanungFormData,
  kanal: Kanal,
  shouldCloseWindow: boolean,
) {
  const {
    sendetag,
    beginnzeit,
    endzeit,
    variante,
    planlaenge,
    contentCommunities,
    genre,
    highlight,
    titel,
    stofffuehrendeRedaktion,
    fsk,
    staffelnummer,
    folgennummer,
    gesamtfolgennummer,
    inhaltsbeschreibung,
    mitwirkende,
    fruehesteVeroeffentlichung,
  } = formValue;
  const produkttitel = "";
  const produkttitelMultipart = "";
  const notiz = formValue.notiz ?? "";
  const farbgebung = formValue.farbgebung ?? PlanungsobjektFarbgebung.KEINE;
  const redaktion = formValue.redaktion ?? Redaktion.KEINE_REDAKTION;

  if (
    variante === null ||
    !sendetag ||
    !beginnzeit ||
    !endzeit ||
    !planlaenge ||
    !redaktion ||
    !titel ||
    titel.length < 2 ||
    !genre ||
    highlight === null ||
    !contentCommunities ||
    kanal === Kanal.UNBEKANNT
  ) {
    throw new DeveloperError(
      `extractCreateLinearBlockansichtVorgeplantCommandAction fehlgeschlagen - nicht alle Felder haben den erwarteten Wert.
      Stimmt Prüfung in extract Methode mit Formular Validierung überein?`,
    );
  }

  return planungsobjektActions.createPlanungsobjektOnBlockansichtNewWindow({
    shouldCloseWindow,
    command: {
      kanal,
      sendetag,
      beginnzeit,
      laenge: planlaenge,
      variante,
      titel,
      contentCommunities,
      farbgebung,
      genre,
      highlight,
      notiz,
      redaktion,
      stofffuehrendeRedaktion,
      fsk,
      staffelnummer,
      folgennummer,
      gesamtfolgennummer,
      inhaltsbeschreibung,
      mitwirkende,
      fruehesteVeroeffentlichung,
    } satisfies PlanungsobjektLinearVorgeplantBlockErstellenCommand,
  });
}

function extractUpdateLinearSendeplatzVorgeplantCommandAction(
  formValue: PlanungsobjektWindowPlanungFormData,
  id: GUID,
  kanal: Kanal,

  shouldCloseWindow: boolean,
) {
  const {
    planlaenge,
    redaktion,
    contentCommunities,
    genre,
    highlight,
    titel,
    stofffuehrendeRedaktion,
    fsk,
    staffelnummer,
    folgennummer,
    gesamtfolgennummer,
    inhaltsbeschreibung,
    mitwirkende,
    fruehesteVeroeffentlichung,
  } = formValue;
  const notiz = formValue.notiz ?? "";
  const farbgebung = formValue.farbgebung ?? PlanungsobjektFarbgebung.KEINE;
  const veroeffentlichung = formValue.fruehesteVeroeffentlichung;
  if (
    !planlaenge ||
    !redaktion ||
    !titel ||
    titel.length < 2 ||
    !genre ||
    highlight === null ||
    !contentCommunities ||
    kanal === Kanal.UNBEKANNT
  ) {
    throw new DeveloperError(
      `extractUpdateLinearVorgeplantSendeplatzCommandAction fehlgeschlagen - nicht alle Felder haben den erwarteten Wert.
      Stimmt Prüfung in extract Methode mit Formular Validierung überein?`,
    );
  }

  return planungsobjektActions.updatePlanungsobjektOnSendeplatzNewWindow({
    shouldCloseWindow,
    command: {
      id,
      titel,
      contentCommunities,
      farbgebung,
      genre,
      highlight,
      notiz,
      planlaenge,
      redaktion,
      stofffuehrendeRedaktion,
      fsk,
      staffelnummer,
      folgennummer,
      gesamtfolgennummer,
      inhaltsbeschreibung,
      mitwirkende,
      fruehesteVeroeffentlichung,
    } satisfies PlanungsobjektLinearVorgeplantSendeplatzAktualisierenCommand,
  });
}

function extractUpdateLinearBlockansichtVorgeplantCommandAction(
  formValue: PlanungsobjektWindowPlanungFormData,
  id: GUID,
  kanal: Kanal,
  shouldCloseWindow: boolean,
) {
  const {
    sendetag,
    beginnzeit,
    endzeit,
    variante,
    planlaenge,
    redaktion,
    contentCommunities,
    genre,
    highlight,
    titel,
    stofffuehrendeRedaktion,
    fsk,
    staffelnummer,
    folgennummer,
    gesamtfolgennummer,
    inhaltsbeschreibung,
    mitwirkende,
    fruehesteVeroeffentlichung,
  } = formValue;
  const notiz = formValue.notiz ?? "";
  const farbgebung = formValue.farbgebung ?? PlanungsobjektFarbgebung.KEINE;
  if (
    variante === null ||
    !sendetag ||
    !beginnzeit ||
    !endzeit ||
    !planlaenge ||
    !redaktion ||
    !titel ||
    titel.length < 2 ||
    !genre ||
    highlight === null ||
    !contentCommunities ||
    kanal === Kanal.UNBEKANNT
  ) {
    throw new DeveloperError(
      `extractUpdateLinearVorgeplantBlockansichtCommandAction fehlgeschlagen - nicht alle Felder haben den erwarteten Wert.
      Stimmt Prüfung in extract Methode mit Formular Validierung überein?`,
    );
  }

  return planungsobjektActions.updatePlanungsobjektOnBlockansichtNewWindow({
    shouldCloseWindow,
    command: {
      kanal,
      sendetag,
      beginnzeit,
      laenge: planlaenge,
      variante,
      titel,
      contentCommunities,
      farbgebung,
      genre,
      highlight,
      notiz,
      redaktion,
      id,
      stofffuehrendeRedaktion,
      fsk,
      staffelnummer,
      folgennummer,
      gesamtfolgennummer,
      inhaltsbeschreibung,
      mitwirkende,
      fruehesteVeroeffentlichung,
    } satisfies PlanungsobjektLinearVorgeplantBlockAktualisierenCommand,
  });
}

/**
 * Workaround, den wir für EditVorgeschlagen brauchen, wird hier nicht benötigt, weil der Effect für EditVorgemerkt
 * sich das aktuelles Planungsobjekt bereits holt.
 */
function extractUpdateLinearVorgemerktCommandAction(
  formValue: PlanungsobjektWindowPlanungFormData,
  id: GUID,
  shouldCloseWindow: boolean,
) {
  const {
    planlaenge,
    redaktion,
    contentCommunities,
    fruehesteVeroeffentlichung,
    genre,
    highlight,
    titel,
    stofffuehrendeRedaktion,
    fsk,
    staffelnummer,
    folgennummer,
    gesamtfolgennummer,
    inhaltsbeschreibung,
    mitwirkende,
  } = formValue;
  const notiz = formValue.notiz ?? "";
  const farbgebung = formValue.farbgebung ?? PlanungsobjektFarbgebung.KEINE;
  if (
    !redaktion ||
    !titel ||
    titel.length < 2 ||
    !genre ||
    highlight === null ||
    !contentCommunities
  ) {
    throw new DeveloperError(
      `extractUpdateLinearVorgemerktCommandAction fehlgeschlagen - nicht alle Felder haben den erwarteten Wert.
      Stimmt Prüfung in extract Methode mit Formular Validierung überein?`,
    );
  }

  return planungsobjektActions.updatePlanungsobjektOnMerkliste({
    shouldCloseWindow,
    command: {
      planungsobjektLinearId: id,
      planlaenge,
      titel,
      contentCommunities,
      farbgebung,
      genre,
      highlight,
      notiz,
      redaktion,
      stofffuehrendeRedaktion,
      fsk,
      staffelnummer,
      folgennummer,
      gesamtfolgennummer,
      inhaltsbeschreibung,
      mitwirkende,
      fruehesteVeroeffentlichung,
    },
  });
}

function extractCreateLinearSendeplatzVorgeschlagenCommandAction(
  formValue: PlanungsobjektWindowPlanungFormData,
  kanal: Kanal,
  schemaplatzLaenge: number, // Vordefinierter Planlänge-Wert des Schemaplatzes im Gegensatz zur Planlänge des Planungsobjekts
  shouldCloseWindow: boolean,
) {
  const {
    sendetag,
    beginnzeit,
    planlaenge,
    redaktion,
    contentCommunities,
    genre,
    highlight,
    fruehesteVeroeffentlichung,
    titel,
    stofffuehrendeRedaktion,
    fsk,
    staffelnummer,
    folgennummer,
    gesamtfolgennummer,
    inhaltsbeschreibung,
    mitwirkende,
  } = formValue;
  const notiz = formValue.notiz ?? "";
  const farbgebung = formValue.farbgebung ?? PlanungsobjektFarbgebung.KEINE;
  if (
    !sendetag ||
    !beginnzeit ||
    !planlaenge ||
    !redaktion || // Eigentlich nur hier Pflicht?
    !titel ||
    titel.length < 2 ||
    !genre ||
    highlight === null ||
    !contentCommunities ||
    kanal === Kanal.UNBEKANNT
  ) {
    throw new DeveloperError(
      `extractCreateLinearSendeplatzVorgeschlagenCommandAction fehlgeschlagen - nicht alle Felder haben den erwarteten Wert.
      Stimmt Prüfung in extract Methode mit Formular Validierung überein?`,
    );
  }

  return planungsobjektActions.createPlanungsobjektLinearVorgeschlagen({
    shouldCloseWindow,
    command: {
      sendeplatzKey: {
        beginnzeit,
        sendetag,
        kanal,
        laenge: schemaplatzLaenge,
        kalendertag: KanalOffsetUtils.calculateKalendertagAsString(
          new Date(sendetag),
          beginnzeit,
          kanal,
        ),
      },
      planlaenge, // Planlänge des Planungsobjekts, nicht die Schemaplatz-Länge
      titel,
      contentCommunities,
      farbgebung,
      genre,
      highlight,
      notiz,
      redaktion,
      stofffuehrendeRedaktion,
      fsk,
      staffelnummer,
      folgennummer,
      gesamtfolgennummer,
      inhaltsbeschreibung,
      mitwirkende,
      fruehesteVeroeffentlichung,
    } satisfies PlanungsobjektLinearVorgeschlagenErstellenCommand,
  });
}

/**
 * Update für Vorgeschlagen auf Sendeplatz
 */
function extractUpdateLinearSendeplatzVorgeschlagenCommandAction(
  formValue: PlanungsobjektWindowPlanungFormData,
  schemaplatzLaenge: number, // Vordefinierter Planlänge-Wert des Schemaplatzes im Gegensatz zur Planlänge des Planungsobjekts
  id: GUID,
  kanal: Kanal,
  shouldCloseWindow: boolean,
) {
  const {
    sendetag,
    beginnzeit,
    planlaenge,
    redaktion,
    contentCommunities,
    genre,
    highlight,
    fruehesteVeroeffentlichung,
    titel,
    stofffuehrendeRedaktion,
    fsk,
    staffelnummer,
    folgennummer,
    gesamtfolgennummer,
    inhaltsbeschreibung,
    mitwirkende,
  } = formValue;
  const notiz = formValue.notiz ?? "";
  const farbgebung = formValue.farbgebung ?? PlanungsobjektFarbgebung.KEINE;
  if (
    !sendetag ||
    !beginnzeit ||
    !planlaenge ||
    !redaktion ||
    !titel ||
    titel.length < 2 ||
    !genre ||
    highlight === null ||
    !contentCommunities ||
    kanal === Kanal.UNBEKANNT
  ) {
    throw new DeveloperError(
      `extractUpdateLinearSendeplatzVorgeschlagenCommandAction fehlgeschlagen - nicht alle Felder haben den erwarteten Wert.
      Stimmt Prüfung in extract Methode mit Formular Validierung überein?`,
    );
  }

  return planungsobjektActions.updatePlanungsobjektLinearVorgeschlagen({
    shouldCloseWindow,
    command: {
      planungsobjektLinearId: id,
      planlaenge,
      titel,
      contentCommunities,
      farbgebung,
      genre,
      highlight,
      notiz,
      redaktion,
      stofffuehrendeRedaktion,
      fsk,
      staffelnummer,
      folgennummer,
      gesamtfolgennummer,
      inhaltsbeschreibung,
      mitwirkende,
      fruehesteVeroeffentlichung,
    } satisfies PlanungsobjektLinearVorgeschlagenAktualisierenCommand,
  });
}
