import { Component, inject } from "@angular/core";
import { RechercheFacade } from "./recherche.facade";

@Component({
  selector: "app-recherche",
  templateUrl: "./recherche.component.html",
  styleUrls: ["./recherche.component.scss"],
  providers: [RechercheFacade],
})
export class RechercheComponent {
  public readonly facade = inject(RechercheFacade);
}
