import { box, wrapReducerWithFormStateUpdate } from "ngrx-forms";
import { greaterThan, required } from "ngrx-forms/validation";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { Redaktion } from "src/app/models/openapi/model/redaktion";
import * as CustomValidators from "src/app/shared/form-inputs/custom-validators-ngrx";
import { maxSecondsForMaskedInput } from "src/app/shared/form-inputs/masked-input/masked-input.model";
import {
  PlanungsobjektWindowInputWithPlanungsobjekt,
  PlanungsobjektWindowUseCase,
} from "src/app/shared/windows/planungsobjekt-window/planungsobjekt-window.model";
import { assertUnreachable } from "src/app/utils/function-utils";
import {
  ValidationMap,
  createReducerAndCustomFormActions,
  createValidationRules,
} from "src/app/utils/ngrx-forms";
import { calculateEndzeitValue } from "../planungsobjekt-window-wann-wo.utils";
import {
  PlanungsobjektWindowPlanungFormData,
  PlanungsobjektWindowState,
  initialPlanungsobjektWindowState,
} from "../planungsobjekt-window.model";
import {
  isBlockansichtWindow,
  isCreateWindow,
  isEditWindow,
  isLinearWindow,
  isOnDemandWindow,
} from "../planungsobjekt-window.utils";
import {
  PLANUNGSOBJEKT_FORM_ID,
  PlanungsobjektWindowPlanungFormNgRx,
  planungsobjektEmptyForm,
} from "./planungsobjekt-window.form.model";
import { DateFnsService } from "src/app/services/date-fns.service";

export const planungsobjektFormValidationRules = (
  form: PlanungsobjektWindowPlanungFormNgRx,
  feature: PlanungsobjektWindowState,
): ValidationMap<PlanungsobjektWindowPlanungFormNgRx> => {
  const windowInput = feature.input;

  const isVorschlag = windowInput?.planungskontext === Planungskontext.VORGESCHLAGEN;
  const isVorgeplant = windowInput?.planungskontext === Planungskontext.VORGEPLANT;

  const requiredWhenIsVorgeschlagen = isVorschlag ? [required] : [];
  const requiredWhenIsVorgeplant = isVorgeplant ? [required] : [];
  const requiredWhenIsVorgeschlagenOrVorgeplant = isVorschlag || isVorgeplant ? [required] : [];
  const requiredWhenIsOnDemandAndVorschlagOrVorgeplant = isOnDemandWindow(windowInput)
    ? requiredWhenIsVorgeschlagenOrVorgeplant
    : [];
  const requiredWhenIsBlockansichtAndVorgeschlagenOrVorgeplant = isBlockansichtWindow(windowInput)
    ? requiredWhenIsVorgeschlagenOrVorgeplant
    : [];
  const requiredWhenIsBlockansichtAndVorgeplant = isBlockansichtWindow(windowInput)
    ? requiredWhenIsVorgeplant
    : [];

  return {
    titel: [required, CustomValidators.minLengthTrimmed(2)],
    gesamtfolgennummer: [
      CustomValidators.patternWithCustomError(
        /^(?:\d+(?:\.\d+)?)?$/,
        "Gültiges Eingabeformat: X oder X.X (X=Ziffer)",
      ),
    ],
    planlaenge: [CustomValidators.numberBetween(1, maxSecondsForMaskedInput)],
    beginnzeit: [...requiredWhenIsBlockansichtAndVorgeschlagenOrVorgeplant],
    endzeit: [...requiredWhenIsBlockansichtAndVorgeschlagenOrVorgeplant],
    onlineAb: [
      CustomValidators.valueBefore(form, "onlineBis", "Online bis"),
      ...requiredWhenIsOnDemandAndVorschlagOrVorgeplant,
    ],
    onlineBis: [
      CustomValidators.valueAfter(form, "onlineAb", "Online ab"),
      ...requiredWhenIsOnDemandAndVorschlagOrVorgeplant,
    ],
    verweildauerInTagen: [
      greaterThan(0),
      ...requiredWhenIsOnDemandAndVorschlagOrVorgeplant,
    ],
    redaktion: isVorschlag
      ? [required, CustomValidators.notEqualTo(Redaktion.KEINE_REDAKTION)]
      : [],
    sendetag: [
      ...requiredWhenIsBlockansichtAndVorgeschlagenOrVorgeplant,
      ...(isBlockansichtWindow(windowInput)
        ? [CustomValidators.wochentag(windowInput.blockansichtDefinition.wochentagSendetag)]
        : []),
    ],
    variante: [...requiredWhenIsBlockansichtAndVorgeplant],
    // Das hier ist ein Sonderfall. Wir validieren zusätzlich das ganze Objekt.
    __form: [
      ...(isBlockansichtWindow(windowInput)
        ? [
            CustomValidators.planungsobjektBlockansichtInTimeRange(
              windowInput.blockansichtDefinition.timeRange,
              "beginnzeit",
              "planlaenge",
            ),
            CustomValidators.endzeitVorBeginnzeitMitSendetagsgrenze(
              "beginnzeit",
              "endzeit",
              windowInput.blockansichtDefinition.ansichtViewModel.kanal,
            ),
          ]
        : []),
    ],
  };
};

const { actions, reducer, selectors } = createReducerAndCustomFormActions<
  PlanungsobjektWindowState,
  "formState"
>(initialPlanungsobjektWindowState, PLANUNGSOBJEKT_FORM_ID, "formState");

export const planungsobjektFormCustomActionReducer = wrapReducerWithFormStateUpdate(
  reducer,
  (state) => state.formState,
  createValidationRules(planungsobjektFormValidationRules),
);

export const planungsobjektFormSelectors = selectors;
export const planungsobjektFormActions = actions;

/**
 * Uhrzeit, die als Default für das Feld "Online ab Zeit" gesetzt wird, sofern das Planungsobjekt neu erstellt wird.
 */
export const DEFAULT_ONLINE_AB_ZEIT = "10:00";

/**
 * Belegt die Werte der Formularfelder vor, abhängig vom UseCase.
 */
export const getInitalPlanungsobjektFormValue = (
  windowInput: PlanungsobjektWindowInputWithPlanungsobjekt,
) => {
  const newFormValue: Partial<PlanungsobjektWindowPlanungFormData> = {
    ...windowInput.planungsobjekt,
  };
  // Befüllen oder Vorbelegen von Feldern
  // ...wenn das Planungsobjekt editiert wird
  if (
    isEditWindow(windowInput) ||
    windowInput.usecase === PlanungsobjektWindowUseCase.READONLY_ONDEMAND ||
    windowInput.usecase === PlanungsobjektWindowUseCase.READONLY_LINEAR
  ) {
    // ...wenn das Planungsobjekt über die + Logik auf dem Sendeplatz erstellt wurde ist die Planlänge null,
    // daher wird die Planlänge aus der SendeplatzLänge der Publikationsplanung übernommen
    if (
      windowInput.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ &&
      !newFormValue.planlaenge
    ) {
      newFormValue.planlaenge = windowInput.planungsobjekt.sendeplatzLaenge ?? null;
    }
    // ...wenn Planlänge und Beginnzeit existiert, wird die Endzeit automatisch berechnet
    const planlaenge = newFormValue.planlaenge;
    const beginnzeit = newFormValue.beginnzeit;
    if (planlaenge && beginnzeit)
      newFormValue.endzeit = calculateEndzeitValue(planlaenge, beginnzeit) ?? null;
    // ...wenn das Planungsobjekt ein OnDemand PO ist, steht die Verweildauer in Tagen zur Verfügung
    newFormValue.wunschOnlineBisVsVerweildauerToggle = !!newFormValue.verweildauerInTagen;
    const disabledVerweildauer = newFormValue.onlineBis && newFormValue.onlineAb ? DateFnsService.daysBetweenDates(
      newFormValue.onlineBis,
      newFormValue.onlineAb,
    ) : 0;
    newFormValue.verweildauerInTagen = newFormValue.verweildauerInTagen ?? disabledVerweildauer;
    // ...oder die default OnlineAb Zeit für Create_OnDemand
  } else if (windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_ONDEMAND) {
    newFormValue.onlineAbZeit = DEFAULT_ONLINE_AB_ZEIT;
    // ... oder Vorbelegung beim Erstellen auf Blockansichten -> Zukünftig auch für Listenansichten
  } else if (
    isCreateWindow(windowInput) &&
    (isLinearWindow(windowInput) || isBlockansichtWindow(windowInput))
  ) {
    Object.assign(newFormValue, windowInput.wannPreset);
  } else {
    assertUnreachable(windowInput);
  }

  return newFormValue;
};

export const planungsobjektToFormValue = (
  planungsobjekt: ReturnType<typeof getInitalPlanungsobjektFormValue>,
) => ({
  ...planungsobjektEmptyForm,
  ...planungsobjekt,
  contentCommunities: box(planungsobjekt?.contentCommunities ?? []),
});
