<ng-container
  *ngIf="{
    variants: variants$ | async,
    loadingVariants: loadingVariants$ | async,
    validationErrors: facade.validationErrors$ | async,
    formState: facade.formState$ | async,
  } as vm"
>
  <div class="k-d-flex k-align-items-end k-gap-2">
    <app-form-field
      *ngIf="planungskontext !== Planungskontext.VORGEMERKT"
      label="Sendetag"
      [hint]="Sendetagdefinitionen(kanal)"
    >
      <app-iso-dateonly
        data-testid="sendetag"
        [ngrxFormControlState]="vm.formState?.controls?.sendetag"
      ></app-iso-dateonly>
    </app-form-field>

    <app-form-field *ngIf="planungskontext !== Planungskontext.VORGEMERKT" label="Beginnzeit">
      <app-iso-timeonly
        clearable
        data-testid="beginnzeit"
        [ngrxFormControlState]="vm.formState?.controls?.beginnzeit"
      ></app-iso-timeonly>
    </app-form-field>

    <app-form-field *ngIf="planungskontext !== Planungskontext.VORGEMERKT" label="Endzeit">
      <app-iso-timeonly
        clearable
        data-testid="endzeit"
        [ngrxFormControlState]="vm.formState?.controls?.endzeit"
      ></app-iso-timeonly>
    </app-form-field>

    <app-form-field label="Planlänge (min:sek)">
      <app-masked-input
        dataTestId="planlaenge"
        [ngrxFormControlState]="vm.formState?.controls?.planlaenge"
      ></app-masked-input>
    </app-form-field>

    <app-form-field
      *ngIf="
        planungskontext !== Planungskontext.VORGEMERKT &&
        (usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT ||
          usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT)
      "
      class="variante-field"
      label="Variante"
      [loading]="vm.loadingVariants"
    >
      <app-select
        data-testid="variante"
        [loading]="vm.loadingVariants"
        [ngrxFormControlState]="vm.formState?.controls?.variante"
        [noDataText]="'Noch keine verfügbaren Varianten gefunden'"
        [options]="vm.variants"
      ></app-select>
    </app-form-field>
  </div>
  @if (vm.validationErrors?.planungsobjektBlockansichtInTimeRange) {
    <kendo-formerror data-testid="formerror-timerange">
      Das einzuplanende Programm muss mindestens eine Minute im Zeitrahmen der Blockansicht liegen
    </kendo-formerror>
  }
  @if (
    vm.validationErrors?.endzeitVorBeginnzeitMitSendetagsgrenze;
    as endzeitVorBeginnzeitMitSendetagsgrenze
  ) {
    <kendo-formerror data-testid="formerror-endzeit-vor-beginnzeit">
      Die Endzeit muss nach der Beginnzeit oder der Tagesgrenze um
      {{ endzeitVorBeginnzeitMitSendetagsgrenze }} Uhr liegen
    </kendo-formerror>
  }
</ng-container>
