import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs";
import { CustomNotificationService } from "src/app/shared/notifications/custom-notification.service";
import { notificationActions } from "./notification.actions";

@Injectable()
export class NotificationEffects {
  private readonly actions$ = inject(Actions);
  private readonly customNotificationService = inject(CustomNotificationService);

  showNotification$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(notificationActions.showNotification),
        tap((action) => {
          this.customNotificationService.showNotification(action.message, action.notificationType);
        }),
      );
    },
    { dispatch: false },
  );

  showNotifications$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(notificationActions.showNotifications),
        tap(({ sideEffectNotificationDto }) => {
          this.customNotificationService.showNotifications(sideEffectNotificationDto);
        }),
      );
    },
    { dispatch: false },
  );
}
