<ng-container
  *ngIf="{
    formState: formStateWithCustomDisablement$ | async,
    data: facade.pharosWindowViewModel$ | async,
  } as vm"
>
  <h4>
    {{ vm.data?.kalendertag | date: "EEEE, dd.MM.yyyy" }}
  </h4>
  <form [ngrxFormState]="vm.formState">
    <table class="pharos-table">
      <thead>
        <tr class="header">
          <th class="m-col">Attribut</th>
          <th class="l-col">Pharos aktuell</th>
          <th class="l-col">publish.it</th>
          <th class="xl-col">Pharos neu</th>
        </tr>
      </thead>
      <tbody>
        <!-- 1. Zeile: Planzeit -->
        <tr>
          <td>Planzeit*</td>
          <td>
            <input
              class="space-right"
              id="planzeit-pharos"
              kendoCheckBox
              type="radio"
              [checked]="vm.data?.planzeit?.isPharos"
              (click)="set('planzeit', vm.data?.planzeit?.pharos)"
            />
            <label
              *ngIf="vm.data?.planzeit?.pharos"
              class="label-for label-rearange-text"
              for="planzeit-pharos"
              >{{ vm.data?.planzeit?.pharos }}</label
            >
          </td>
          <td>
            <input
              class="space-right"
              id="planzeit-publit"
              kendoCheckBox
              type="radio"
              [checked]="vm.data?.planzeit?.isPublit"
              [disabled]="vm.data?.planzeit?.areEqual"
              (click)="set('planzeit', vm.data?.planzeit?.publit)"
            />
            <label
              *ngIf="vm.data?.planzeit?.publit"
              class="label-for label-rearange-text"
              for="planzeit-publit"
              >{{ vm.data?.planzeit?.publit }}</label
            >
          </td>
          <td>
            <kendo-textbox
              class="input-in-table"
              id="planzeit-pharosNeu"
              [disabled]="true"
              [ngrxFormControlState]="vm.formState?.controls?.planzeit"
              [readonly]="true"
            ></kendo-textbox>
          </td>
        </tr>
        <!-- 2. Zeile: Länge -->
        <tr>
          <td>Länge*</td>
          <td>
            <input
              class="space-right"
              id="laenge-pharos"
              kendoCheckBox
              type="radio"
              [checked]="vm.data?.laenge?.isPharos"
              (click)="set('laenge', vm.data?.laenge?.pharos)"
            />
            <label
              *ngIf="vm.data?.laenge?.pharos"
              class="label-for label-rearange-text"
              for="laenge-pharos"
              >{{ vm.data?.laenge?.pharos }}
            </label>
          </td>
          <td>
            <input
              class="space-right"
              id="laenge-publit"
              kendoCheckBox
              type="radio"
              [checked]="vm.data?.laenge?.isPublit"
              [disabled]="vm.data?.laenge?.areEqual"
              (click)="set('laenge', vm.data?.laenge?.publit)"
            />
            <label
              *ngIf="vm.data?.laenge?.publit"
              class="label-for label-rearange-text"
              for="laenge-publit"
              >{{ vm.data?.laenge?.publit }}
            </label>
          </td>
          <td>
            <kendo-textbox
              class="input-in-table"
              id="laenge-pharosNeu"
              [disabled]="true"
              [ngrxFormControlState]="vm.formState?.controls?.laenge"
              [readonly]="true"
            ></kendo-textbox>
          </td>
        </tr>
        <!-- 3. Zeile: Sendetitel -->
        <tr>
          <td>Sendetitel*</td>
          <td>
            <input
              class="space-right"
              id="sendetitel-pharos"
              kendoCheckBox
              type="radio"
              [checked]="vm.data?.sendetitel?.isPharos"
              (click)="set('sendetitel', vm.data?.sendetitel?.pharos)"
            />
            <label
              *ngIf="vm.data?.sendetitel?.pharos"
              class="label-rearange-text"
              for="sendetitel-pharos"
              label-for="sendetitel-pharos"
              >{{ vm.data?.sendetitel?.pharos }}</label
            >
          </td>
          <td>
            <input
              class="space-right"
              id="sendetitel-publit"
              kendoCheckBox
              type="radio"
              [checked]="vm.data?.sendetitel?.isPublit"
              [disabled]="vm.data?.sendetitel?.areEqual"
              (click)="set('sendetitel', vm.data?.sendetitel?.publit)"
            />
            <label
              *ngIf="vm.data?.sendetitel?.publit"
              class="label-rearange-text"
              for="sendetitel-publit"
              label-for="sendetitel-publit"
              >{{ vm.data?.sendetitel?.publit }}</label
            >
          </td>
          <td>
            <div class="edit-layout">
              <kendo-textbox
                class="input-in-table"
                data-testid="sendetitel-field"
                #sendetitelPharosNeu
                id="sendetitel-pharosNeu"
                showErrorIcon="initial"
                [ngrxFormControlState]="vm.formState?.controls?.sendetitel"
                (blur)="disableSendetitel(true)"
              ></kendo-textbox>
              <kendo-formerror *ngIf="vm.formState?.controls?.sendetitel?.errors?.required"
                >Sendetitel darf nicht leer sein</kendo-formerror
              >
              <kendo-formerror *ngIf="vm.formState?.controls?.sendetitel?.errors?.minLength"
                >Sendetitel muss aus mindestens 2 Zeichen bestehen</kendo-formerror
              >
              <kendo-formerror *ngIf="vm.formState?.controls?.sendetitel?.errors?.maxLength"
                >Maximal 60 Zeichen</kendo-formerror
              >
              <kendo-formerror *ngIf="vm.formState?.controls?.sendetitel?.errors?.pattern"
                >Sendetitel darf nicht nur aus Leerzeichen bestehen</kendo-formerror
              >
              <button
                class="space-between-buttons right"
                data-testid="edit-sendetitel-button"
                fillMode="flat"
                kendoButton
                title="Bearbeiten"
                [svgIcon]="Icons.editIcon"
                (click)="disableSendetitel(false)"
              ></button>
            </div>
          </td>
        </tr>
        <!-- 4. Zeile: Redaktion -->
        <tr>
          <td>Redaktion*</td>
          <td>
            <input
              class="space-right"
              id="redaktion-pharos"
              kendoCheckBox
              type="radio"
              [checked]="vm.data?.redaktion?.isPharos"
              (click)="set('redaktion', vm.data?.redaktion?.pharos)"
            />
            <label
              *ngIf="vm.data?.redaktion?.pharos"
              class="label-for label-rearange-text"
              for="redaktion-pharos"
              >{{ vm.data?.redaktion?.pharos }}</label
            >
          </td>
          <td>
            <input
              class="space-right"
              id="redaktion-publit"
              kendoCheckBox
              type="radio"
              [checked]="vm.data?.redaktion?.isPublit"
              [disabled]="vm.data?.redaktion?.areEqual"
              (click)="set('redaktion', vm.data?.redaktion?.publit)"
            />
            <label
              *ngIf="vm.data?.redaktion?.publit"
              class="label-for label-rearange-text"
              for="redaktion-publit"
              >{{ vm.data?.redaktion?.publit }}</label
            >
          </td>
          <td>
            <kendo-textbox
              class="input-in-table"
              id="redaktion-pharosNeu"
              showErrorIcon="initial"
              [disabled]="true"
              [ngrxFormControlState]="vm.formState?.controls?.redaktion"
              [readonly]="true"
            ></kendo-textbox>
            <kendo-formerror *ngIf="vm.formState?.controls?.redaktion?.errors?.length > 0">Bitte Redaktion prüfen!</kendo-formerror>
          </td>
        </tr>
        <!-- 5. Zeile: FolgenNr/Info -->
        <tr>
          <td>FolgenNr/Info</td>
          <td>
            <input
              class="space-right"
              id="folgenNrInfo-pharos"
              kendoCheckBox
              type="radio"
              [checked]="vm.data?.folgenNrInfo?.isPharos"
              (click)="set('folgenNrInfo', vm.data?.folgenNrInfo?.pharos)"
            />
            <label
              *ngIf="vm.data?.folgenNrInfo?.pharos"
              class="label-for label-rearange-text"
              for="folgenNrInfo-pharos"
              >{{ vm.data?.folgenNrInfo?.pharos }}</label
            >
          </td>
          <td>
            <input
              class="space-right"
              id="folgenNrInfo-publit"
              kendoCheckBox
              type="radio"
              [checked]="vm.data?.folgenNrInfo?.isPublit"
              [disabled]="vm.data?.folgenNrInfo?.areEqual"
              (click)="set('folgenNrInfo', vm.data?.folgenNrInfo?.publit)"
            />
            <label
              *ngIf="vm.data?.folgenNrInfo?.publit"
              class="label-for label-rearange-text"
              for="folgenNrInfo-publit"
              >{{ vm.data?.folgenNrInfo?.publit }}</label
            >
          </td>
          <td>
            <div class="edit-layout">
              <kendo-textbox
                class="input-in-table"
                data-testid="folgennummer-field"
                #folgenNrInfoPharosNeu
                id="folgenNrInfo-pharosNeu"
                showErrorIcon="initial"
                [ngrxFormControlState]="vm.formState?.controls?.folgenNrInfo"
                (blur)="disableFolgenNrInfo(true)"
              ></kendo-textbox>
              <kendo-formerror *ngIf="vm.formState?.controls?.folgenNrInfo?.errors?.maxLength"
                >Maximal 40 Zeichen</kendo-formerror
              >

              <button
                class="space-between-buttons right"
                data-testid="edit-folgennummer-button"
                fillMode="flat"
                kendoButton
                title="Bearbeiten"
                [svgIcon]="Icons.editIcon"
                (click)="disableFolgenNrInfo(false)"
              ></button>
            </div>
          </td>
        </tr>
        <!-- 6. Zeile: Dachzeile -->
        <tr>
          <td>Dachzeile</td>
          <td>
            <!-- Hier wird eine CheckBox verwendet,
            da nur ein Feld zum checken da ist -->
            <input
              class="space-right"
              data-testid="dachzeile-pharos"
              id="dachzeile-pharos"
              kendoCheckBox
              type="checkbox"
              [checked]="vm.data?.dachzeile?.isPharos"
              (click)="onDachzeileClick($event, vm.data?.dachzeile?.pharos)"
            />
            <label
              *ngIf="vm.data?.dachzeile?.pharos"
              class="label-for label-rearange-text"
              for="dachzeile-pharos"
              >{{ vm.data?.dachzeile?.pharos }}</label
            >
          </td>
          <!-- Keine Dachzeile bei Publish.it -->
          <td></td>
          <td>
            <div class="edit-layout">
              <kendo-textbox
                class="input-in-table"
                data-testid="dachzeile-pharosNeu"
                id="dachzeile-pharosNeu"
                #dachzeilePharosNeu
                [ngrxFormControlState]="vm.formState?.controls?.dachzeile"
                (blur)="disableDachzeile(true)"
              ></kendo-textbox>
              <kendo-formerror *ngIf="vm.formState?.controls?.dachzeile?.errors?.maxLength"
                >Maximal 60 Zeichen</kendo-formerror
              >
              <button
                class="space-between-buttons right"
                fillMode="flat"
                kendoButton
                title="Bearbeiten"
                [svgIcon]="Icons.editIcon"
                (click)="disableDachzeile(false)"
              ></button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </form>

  <div class="window-action-buttons">
    <button data-testid="export-button" kendoButton themeColor="primary" (click)="facade.save()">
      Nach Pharos übertragen
    </button>
    <button kendoButton (click)="facade.close()">Abbrechen</button>
  </div>
</ng-container>
