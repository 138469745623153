import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";

export const sendeplatzWindowActions = createActionGroup({
  source: "Sendeplatz Window",
  events: {
    openWindow: props<{
      input: SendeplatzDto;
    }>(),
    closeWindow: emptyProps(),
    tryClosingWithoutSaving: emptyProps(),
    windowClosed: emptyProps(),
  },
});
