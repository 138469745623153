import { wrapReducerWithFormStateUpdate } from "ngrx-forms";
import { greaterThanOrEqualTo, minLength, required } from "ngrx-forms/validation";
import { AktionEnum } from "src/app/models/enums/aktion";
import { assertUnreachable } from "src/app/utils/function-utils";
import {
  ValidationMap,
  createReducerAndCustomFormActions,
  createValidationRules,
} from "src/app/utils/ngrx-forms";
import {
  MengengeruestEintragWindowInput,
  MengengeruesteintragWindowFeatureState,
  MengengeruesteintragWindowFormData,
  emptyMengengeruesteintragWindowForm,
  initialMengengeruesteintragWindowFeatureState,
  mengengeruesteintragFormId,
} from "./mengengeruesteintrag-window.model";

const validationRules = (
  form: MengengeruesteintragWindowFormData,
): ValidationMap<MengengeruesteintragWindowFormData> => {
  return {
    titel: [required, minLength(2)],
    redaktion: [required],
    ausstrahlung: [required],
    sendesoll: [required, greaterThanOrEqualTo(1)],
  };
};

const { actions, reducer, selectors } = createReducerAndCustomFormActions<
  MengengeruesteintragWindowFeatureState,
  "formState"
>(initialMengengeruesteintragWindowFeatureState, mengengeruesteintragFormId, "formState");

export const mengengeruesteintragWindowFormSelectors = selectors;
export const mengengeruesteintragWindowFormActions = actions;
export const mengengeruesteintragWindowFormReducer = wrapReducerWithFormStateUpdate(
  reducer,
  (s) => s.formState,
  createValidationRules(validationRules),
);

export const calculateInitialFormStateFromInput = (
  input: MengengeruestEintragWindowInput,
): MengengeruesteintragWindowFormData => {
  const emptyForm = emptyMengengeruesteintragWindowForm;

  switch (input.action) {
    case AktionEnum.ERSTELLE_MENGENGERUESTEINTRAG:
      return {
        ...emptyForm,
        beginnzeit: input.presetBeginnzeit ?? "",
      };
    case AktionEnum.BEARBEITE_MENGENGERUESTEINTRAG:
      return {
        ...emptyForm,
        titel: input.mengengeruesteintrag.titel,
        redaktion: input.mengengeruesteintrag.redaktion,
        genre: input.mengengeruesteintrag.genre,
        beginnzeit: input.mengengeruesteintrag.beginnzeit ?? "",
        ausstrahlung: input.mengengeruesteintrag.ausstrahlung,
        sendesoll: input.mengengeruesteintrag.sendesoll,
        laenge: input.mengengeruesteintrag.laenge ?? 0,
        kommentar: input.mengengeruesteintrag.kommentar,
      };
    default:
      assertUnreachable(input);
  }
};
