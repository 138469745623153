import { createSelector } from "@ngrx/store";
import { TransferToPharosViewModel } from "src/app/models/viewmodels/pharos-viewmodel";
import { pharosExportWindowFormSelectors } from "./pharos-export-window.form";
import { pharosExportWindowFeature } from "./pharos-export-window.reducer";

const selectWindowInput = pharosExportWindowFeature.selectWindowInput;
const selectFormState = pharosExportWindowFeature.selectFormState;
const formSelectors = pharosExportWindowFormSelectors(selectFormState);

type PharosWindowZeile = {
  pharos: string | undefined;
  publit: string | undefined;
  isPharos: boolean;
  isPublit: boolean;
  areEqual: boolean;
};

const selectPharosWindowViewModel = createSelector(
  selectWindowInput,
  formSelectors.selectFormData,
  (input, formValue) => {
    const keys = [
      "planzeit", 
      "laenge", 
      "sendetitel", 
      "redaktion", 
      "folgenNrInfo"
    ] as const;

    const zeilen = keys.reduce((accu, key) => {
      const pharos = input?.pharosContent.fromPharos[key];
      const publit = input?.pharosContent.fromPublit[key];
      const isPharos = pharos === formValue[key] && formValue[key] !== "";
      const isPublit = publit === formValue[key] && formValue[key] !== "";

      accu[key] = {
        pharos,
        publit,
        isPharos,
        isPublit: isPublit && !isPharos,
        areEqual: isPharos && isPublit,
      };

      return accu;
    }, {} as Record<typeof keys[number], PharosWindowZeile>);

    const pharosDachzeile = input?.pharosContent.fromPharos.dachzeile;
    const isDachzeileFromPharos = pharosDachzeile === formValue.dachzeile;

    return {
      kalendertag: input?.prepareTransferToPharosQuery.sendeplatzKey.kalendertag,

      ...zeilen,
      dachzeile: {
        pharos: pharosDachzeile,
        isPharos: isDachzeileFromPharos,
      }
    };
  },
);

const selectTransferTable = createSelector(
  selectWindowInput,
  formSelectors.selectFormData,
  (input, formValue): TransferToPharosViewModel | undefined => {
    if (!input) return undefined;

    const { planzeit, laenge, sendetitel, redaktion, folgenNrInfo, dachzeile } = formValue;

    const sendeplatzKey = input.prepareTransferToPharosQuery.sendeplatzKey;
    const primaereVorplanugsfassungId = input.pharosContent.fromPublit.primaereVorplanugsfassungId;
    const sendungKey = input.pharosContent.fromPharos.sendungKey;

    return {
      planzeit,
      laenge,
      sendetitel,
      redaktion,
      folgenNrInfo,
      dachzeile,
      sendeplatzKey,
      primaereVorplanugsfassungId,
      sendungKey,
    };
  },
);

export const pharosExportWindowSelectors = {
  // Window Selectors
  selectWindowInput,
  selectPharosWindowViewModel,
  selectTransferTable,

  // Form Selectors
  ...formSelectors,
};
