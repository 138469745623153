import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { filter, map, of, switchMap, tap } from "rxjs";
import { AnsichtenApiService } from "src/app/api/ansichten/ansichten.api.service";
import { AnsichtenMapper } from "src/app/models/mapper/ansichten.mapper";
import { ansichtActions } from "./ansicht.actions";
import { ansichtFeature } from "./ansicht.reducer";
import ansichtSelectors from "./ansicht.selectors";

@Injectable()
export class AnsichtEffects {
  loadAnsichten$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ansichtActions.loadAnsichten),
      concatLatestFrom(() => this.store.select(ansichtFeature.selectAll)),
      filter(([_, ansichten]) => ansichten.length === 0),
      switchMap(() =>
        this.apiService.getAllAnsichten$().pipe(
          map((ansichten) =>
            ansichtActions.loadAnsichtenSuccess({
              ansichten: AnsichtenMapper.mapAnsichtenDtosToAnsichtViewModel(ansichten),
            }),
          ),
        ),
      ),
    );
  });

  navigateAnsichtToYear$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ansichtActions.navigateCurrentAnsichtToYear),
        concatLatestFrom(() => [
          this.store.select(ansichtFeature.selectAll),
          this.store.select(ansichtSelectors.selectAnsichtViewModelForAnsichtIdFromQueryParam),
        ]),
        switchMap(([{ year: yearToNavigateTo }, ansichten, currentAnsicht]) => {
          if (!currentAnsicht) {
            throw new Error("No current ansicht found");
          }

          const neueAnsicht = ansichten.find(
            (ansicht) =>
              ansicht.ansichtsdefinition.id === currentAnsicht?.ansichtsdefinition.id &&
              ansicht.year === yearToNavigateTo,
          );

          if (!neueAnsicht) {
            throw new Error(
              `Die Ansicht ${
                currentAnsicht.titel
              } existiert im Jahr ${yearToNavigateTo.toString()} nicht und kann daher nicht angezeigt werden.`,
            );
          }

          return of({
            ansichtId: neueAnsicht.id,
            year: yearToNavigateTo,
            kanal: neueAnsicht.kanal,
          });
        }),
        tap((queryParams) => {
          void this.router.navigate(["ansichten"], { queryParams });
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private apiService: AnsichtenApiService,
    private router: Router,
  ) {}
}
