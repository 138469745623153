import { createFormGroupState, FormGroupState } from "ngrx-forms";
import { EventDto } from "src/app/models/openapi/model/event-dto";
import { KonkurrenzprogrammDto } from "src/app/models/openapi/model/konkurrenzprogramm-dto";
import { KonkurrenzprogrammKategorie } from "src/app/models/openapi/model/konkurrenzprogramm-kategorie";
import { SenderPlattform } from "src/app/models/openapi/model/sender-plattform";
import { EKWindowInput } from "src/app/shared/windows/ek-window/ek-window.model";
import { EventKategorie } from "tests/common/generated/api";

// @todo Das hier ist eigentlich eine Choice zwischen EventDto und KonkurrenzprogrammDto...
export type EkWindowFormData = Pick<
  EventDto & KonkurrenzprogrammDto,
  | "titel"
  | "vonDatum"
  | "vonZeit"
  | "bisDatum"
  | "bisZeit"
  | "notiz"
  | "isAbgesetzt"
  | "pressetextLink"
  | "senderPlattformSonstiges"
> & {
  id: string | null;
  isEvent: boolean;
  kategorie: EventKategorie | KonkurrenzprogrammKategorie | null;
  senderPlattform: SenderPlattform | null;
};

export type EkWindowFormState = FormGroupState<EkWindowFormData>;

export const EKWINDOW_FORM_ID = "EkWindowForm";
export const ekWindowFormStateKey = "EkWindowForm";

const ekWindowEmptyForm: EkWindowFormData = {
  isEvent: false,
  id: null,
  titel: "",
  vonDatum: "",
  vonZeit: "",
  bisDatum: "",
  bisZeit: "",
  notiz: "",
  kategorie: null,
  isAbgesetzt: false,
  pressetextLink: "",
  senderPlattform: null,
  senderPlattformSonstiges: "",
};

export const initialFormState = createFormGroupState<EkWindowFormData>(
  EKWINDOW_FORM_ID,
  ekWindowEmptyForm,
);

export type EkWindowFeatureState = {
  input: EKWindowInput | null;
  formState: EkWindowFormState;
};

export const initalEkWindowFeatureState: EkWindowFeatureState = {
  input: null,
  formState: initialFormState,
};
