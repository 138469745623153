import { wrapReducerWithFormStateUpdate } from "ngrx-forms";
import { required } from "ngrx-forms/validation";
import {
  createReducerAndCustomFormActions,
  createValidationRules,
  ValidationMap,
} from "src/app/utils/ngrx-forms";
import {
  initialRechercheState,
  RechercheSearchFormData,
  rechercheSearchFormId,
  RechercheState,
} from "./recherche.model";

const validationRules = (form: RechercheSearchFormData): ValidationMap<RechercheSearchFormData> => {
  return {
    sendetagVon: [required],
    sendetagBis: [required],
    kanaeleSelected: [required],
  };
};

const { actions, reducer } = createReducerAndCustomFormActions<RechercheState, "searchForm">(
  initialRechercheState,
  rechercheSearchFormId,
  "searchForm",
);

export const rechercheFormActions = actions;

export const rechercheFormReducer = wrapReducerWithFormStateUpdate(
  reducer,
  (state) => state.searchForm,
  createValidationRules(validationRules),
);
