import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { DialogCloseResult, WindowCloseResult } from "@progress/kendo-angular-dialog";
import { filter, map, merge, switchMap, take, tap } from "rxjs";
import { Aktion, AktionEnum } from "src/app/models/enums/aktion";
import { CustomDialogService } from "src/app/services/custom-dialog.service";
import { MengengeruesteintragWindowService } from "src/app/services/mengengeruesteintrag-window.service";
import { allValuesDefined } from "src/app/utils/array-utils";
import { assertUnreachable } from "src/app/utils/function-utils";
import { createConfirmClosingEffect, createTrySavingEffect } from "src/app/utils/ngrx-utils";
import { mengengeruestWindowActions } from "../mengengeruest-window/mengengeruest-window.actions";
import { mengengeruestActions } from "../mengengeruest/mengengeruest.actions";
import { mengengeruesteintragWindowActions } from "./mengengeruesteintrag-window.actions";
import {
  calculateInitialFormStateFromInput,
  mengengeruesteintragWindowFormActions,
} from "./mengengeruesteintrag-window.form";
import { mengengeruesteintragWindowSelectors } from "./mengengeruesteintrag-window.selectors";
import { formToCreateCommand, formToUpdateCommand } from "./mengengeruesteintrag-window.utils";

@Injectable()
export class MengengeruestEintragWindowEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly mengengeruesteintragWindowService = inject(MengengeruesteintragWindowService);
  private readonly customDialogService = inject(CustomDialogService);

  openMengengeruestEintragWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mengengeruesteintragWindowActions.openWindow),
      switchMap((action) => {
        const titel =
          action.input.action === AktionEnum.ERSTELLE_MENGENGERUESTEINTRAG
            ? "Mengengerüsteintrag erstellen"
            : "Mengengerüsteintrag bearbeiten";

        const windowRef =
          this.mengengeruesteintragWindowService.openCreateMengengeruesteintragWindow(titel);

        return merge(
          this.actions$.pipe(
            ofType(mengengeruesteintragWindowActions.closeWindow),
            take(1),
            tap(() => void windowRef.close()),
          ),
          windowRef.result.pipe(filter((result) => result instanceof WindowCloseResult)),
        ).pipe(
          take(1),
          map(() => mengengeruesteintragWindowActions.windowClosed()),
        );
      }),
    );
  });

  tryClosingWithoutSaving$ = createConfirmClosingEffect({
    tryClosingAction: mengengeruesteintragWindowActions.tryClosingWithoutSaving,
    closingAction: mengengeruesteintragWindowActions.closeWindow,
    preventClosingWhen$: this.store.select(mengengeruesteintragWindowSelectors.selectFormIsDirty),
  });

  presetFormValues$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mengengeruesteintragWindowActions.openWindow),
      map(({ input }) =>
        mengengeruesteintragWindowFormActions.setValue({
          value: calculateInitialFormStateFromInput(input),
        }),
      ),
    );
  });

  trySaving$ = createTrySavingEffect({
    trySavingAction: mengengeruesteintragWindowFormActions.trySaving,
    saveAction: mengengeruesteintragWindowFormActions.save,
    formIsValid$: this.store.select(mengengeruesteintragWindowSelectors.selectFormIsValid),
  });

  save$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mengengeruesteintragWindowFormActions.save),
      concatLatestFrom(() => [
        this.store.select(mengengeruesteintragWindowSelectors.selectInput),
        this.store.select(mengengeruesteintragWindowSelectors.selectFormData),
      ]),
      filter(allValuesDefined),
      map(([_, input, formData]) => {
        switch (input.action) {
          case AktionEnum.ERSTELLE_MENGENGERUESTEINTRAG:
            return mengengeruestActions.createMengengeruestEintrag({
              createMengengeruestEintragCommand: formToCreateCommand(input, formData),
            });
          case AktionEnum.BEARBEITE_MENGENGERUESTEINTRAG:
            return mengengeruestActions.updateMengengeruestEintrag({
              updateMengengeruestEintragCommand: formToUpdateCommand(
                input.mengengeruesteintrag.id,
                formData,
              ),
            });
          default:
            assertUnreachable(input);
        }
      }),
    );
  });

  closeOnSave$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mengengeruesteintragWindowFormActions.save),
      map(() => mengengeruesteintragWindowActions.closeWindow()),
    );
  });

  openDeleteMengengeruesteintragDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mengengeruestWindowActions.openDeleteMengengeruesteintragDialog),
      switchMap(({ mengengeruesteintrag }) =>
        this.customDialogService
          .openDeleteDialog(
            mengengeruesteintrag.titel,
            Aktion.ENTFERNE_MENGENGERUESTEINTRAG,
            undefined,
            `Du bist dabei den Mengengerüsteintrag "${mengengeruesteintrag.titel}" zu löschen.`,
          )
          .result.pipe(
            map((dialogResult) => {
              if (
                !(dialogResult instanceof DialogCloseResult) &&
                dialogResult.action === Aktion.ENTFERNE_MENGENGERUESTEINTRAG
              ) {
                return mengengeruestActions.deleteMengengeruestEintrag({
                  mengengeruestEintragId: mengengeruesteintrag.id,
                });
              }

              return mengengeruesteintragWindowActions.closeWindow();
            }),
          ),
      ),
    );
  });
}
