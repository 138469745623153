import { Injectable } from "@angular/core";
import { DialogCloseResult } from "@progress/kendo-angular-dialog";
import { SendeplatzDto } from "../models/openapi/model/sendeplatz-dto";
import { CustomDialogAction } from "../shared/dialogs/standard-action-dialog/standard-action-dialog.component";
import { UnifyDurationDialogInput } from "../utils/duration-utils";
import { CustomDialogService } from "./custom-dialog.service";

/**
 * Das Ergebnis des Dialogs zur Abfrage, ob Längen angeglichen werden sollen.
 */
export type UnifyDurationDialogResult = CustomDialogAction<SendeplatzDto[]> | DialogCloseResult;

@Injectable({
  providedIn: "root",
})
export class DurationService {
  constructor(private readonly customdialogService: CustomDialogService) {}

  /**
   * Öffnet einen Dialog zur Abfrage, ob Längen angeglichen werden sollen.
   */
  openUnifyDurationDialog({
    groupsMitMengengeruest,
    sendeplatzLaengeInSeconds,
    planungsobjektLaengeInSeconds,
  }: {
    groupsMitMengengeruest: UnifyDurationDialogInput[];
    sendeplatzLaengeInSeconds: number;
    planungsobjektLaengeInSeconds: number;
  }) {
    const moreThanOneEntry = groupsMitMengengeruest.length > 1;

    const title = "Unterschiedliche Länge für Sendeplatz und Programm";
    const content = moreThanOneEntry
      ? `Die Längen der Sendeplätze und der Programme unterscheiden sich. Sollen sie überschrieben werden?`
      : `Soll die Länge des Sendeplatzes (${
          sendeplatzLaengeInSeconds / 60
        } min.) mit der Länge des Programms (${
          planungsobjektLaengeInSeconds / 60
        } min.) überschrieben werden?`;
    const actions: CustomDialogAction<SendeplatzDto[]>[] = [
      {
        text: `Sendeplatzlänge${moreThanOneEntry ? "n" : ""} überschreiben`,
        fillMode: "solid",
        themeColor: "primary",
        data: groupsMitMengengeruest.map((group) => ({
          ...group.sendeplatz,
          abweichendeLaenge: group.planlaengeInSeconds ?? group.planungsobjekt.planlaenge,
        })),
      },
      {
        text: `Länge${moreThanOneEntry ? "n" : ""} nicht überschreiben`,
        fillMode: "outline",
        isCancelAction: true,
      },
    ];

    const dialogRef = this.customdialogService.openStandardActionDialog({
      title,
      content,
      actions,
    });

    return dialogRef;
  }
}
