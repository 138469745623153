import { inject, Injectable } from "@angular/core";
import { PureAbility } from "@casl/ability";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { WindowCloseResult } from "@progress/kendo-angular-dialog";
import { filter, map, switchMap, take, tap } from "rxjs";
import { Aktion, AktionResult } from "src/app/models/enums/aktion";
import { CopyPattern } from "src/app/models/openapi/model/copy-pattern";
import { EventDto } from "src/app/models/openapi/model/event-dto";
import { KonkurrenzprogrammDto } from "src/app/models/openapi/model/konkurrenzprogramm-dto";
import { CustomWindowService } from "src/app/services/custom-window.service";
import { DateFnsService } from "src/app/services/date-fns.service";
import { EKWindowResult } from "src/app/shared/windows/ek-window/ek-window.model";
import { allValuesDefined } from "src/app/utils/array-utils";
import { createConfirmClosingEffect, createTrySavingEffect } from "src/app/utils/ngrx-utils";
import { ekWindowActions } from "./ek-window.actions";
import { calculateInitialFormStateFromInput, ekWindowFormActions } from "./ek-window.form";
import { ekWindowSelectors } from "./ek-window.selectors";

@Injectable()
export class EkWindowEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly customWindowService = inject(CustomWindowService);
  private readonly ability = inject(PureAbility);

  openEkWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ekWindowActions.openWindow),
      switchMap(({ input }) => {
        const windowRef = this.customWindowService.openEkWindow();

        return this.actions$.pipe(
          ofType(ekWindowActions.closeWindow),
          take(1),
          tap(() => {
            windowRef.close();
          }),
          map(() => ekWindowActions.windowClosed()),
        );
      }),
    );
  });

  setInitalFormValue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ekWindowActions.openWindow),
      map(({ input }) => {
        return ekWindowFormActions.setValue({
          value: calculateInitialFormStateFromInput(input),
        });
      }),
    );
  });

  tryClosingWithSaving$ = createConfirmClosingEffect({
    tryClosingAction: ekWindowActions.tryClosingWithoutSaving,
    preventClosingWhen$: this.store.select(ekWindowSelectors.selectFormIsDirty),
    closingAction: ekWindowActions.closeWindow,
  });

  trySaving$ = createTrySavingEffect({
    trySavingAction: ekWindowFormActions.trySaving,
    formIsValid$: this.store.select(ekWindowSelectors.selectFormIsValid),
    saveAction: ekWindowFormActions.save,
  });

  save$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ekWindowFormActions.save),
      concatLatestFrom(() => [
        this.store.select(ekWindowSelectors.selectFormData),
        this.store.select(ekWindowSelectors.selectInput),
      ]),
      filter(allValuesDefined),
      map(([_, formValue, input]) => {
        let action: Aktion;
        let convertedGUID: undefined | string = undefined;

        const vonDatum = formValue.vonDatum
          ? DateFnsService.formatDateAsString(new Date(formValue.vonDatum))
          : null;
        const vonZeit = formValue.vonZeit ? formValue.vonZeit : null;
        const bisDatum = formValue.bisDatum
          ? DateFnsService.formatDateAsString(new Date(formValue.bisDatum))
          : null;
        const bisZeit = formValue.bisZeit ? formValue.bisZeit : null;

        let shouldConvert: boolean = false;
        if (input.type === "Create EK") {
          shouldConvert = false;
        } else {
          const wasEvent = input.type === "Event";
          shouldConvert = formValue.isEvent !== wasEvent;
        }

        switch (true) {
          case input.type === "Create EK" || input.asNewCopy:
            action = formValue.isEvent ? Aktion.ERSTELLE_EVENT : Aktion.ERSTELLE_KONKURRENZPROGRAMM;
            break;
          case shouldConvert:
            action = formValue.isEvent
              ? Aktion.KONVERTIERE_EVENT
              : Aktion.KONVERTIERE_KONKURRENZPROGRAMM;
            convertedGUID = input.konkurrenzEvent.id;
            break;
          default:
            action = formValue.isEvent
              ? Aktion.BEARBEITE_EVENT
              : Aktion.BEARBEITE_KONKURRENZPROGRAMM;
            break;
        }

        const output: EKWindowResult = {
          result: AktionResult.SAVE,
          returnValue: {
            action: action,
            value: { ...formValue, vonDatum, vonZeit, bisDatum, bisZeit } as unknown as
              | EventDto
              | KonkurrenzprogrammDto,
            isEvent: formValue.isEvent,
            // CopyPattern muss übergeben werden, da required wird aber an der Stelle eigentlich nicht verwendet
            copyPattern: CopyPattern.NOW,
            convertedGUID: convertedGUID,
          },
        };
        return output;
      }),
      map((output) => {
        return ekWindowActions.handleEkWindowResult({ result: output });
      }),
    );
  });

  closeAfterSave$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ekWindowFormActions.save),
      map(() => ekWindowActions.closeWindow()),
    );
  });

  setDisabledState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ekWindowActions.openWindow),
      filter(() => !this.ability.can("editieren", "EK")),
      map(() => ekWindowFormActions.disable()),
    );
  });

  handleEkWindowResult$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ekWindowActions.handleEkWindowResult),
      map(({ result }) => {
        if (result instanceof WindowCloseResult) return ekWindowActions.closeWindow();

        return result.returnValue.isEvent
          ? ekWindowActions.handleEventWindowResult({ result })
          : ekWindowActions.handleKonkurrenzprogrammWindowResult({ result });
      }),
    );
  });
}
