import { Component, inject, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Icons } from "src/app/models/icons";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";
import { DashboardAnsichtFacade } from "./dashboard-ansicht.facade";

@Component({
  selector: "app-dashboard-ansicht",
  templateUrl: "./dashboard-ansicht.component.html",
  styleUrls: ["./dashboard-ansicht.component.scss"],
  providers: [DashboardAnsichtFacade],
})
export class DashboardAnsichtComponent {
  private readonly facade = inject(DashboardAnsichtFacade);

  @Input() title: string;
  @Input() position: TilePosition;
  @Input() ansichtViewModel?: AnsichtViewModel;
  @Input() schemaplaetze?: string[];

  Icons = Icons;

  constructor(private router: Router) {}

  onContextMenuSelect(event: any): void {
    this.facade.openMengengeruestWindow(this.ansichtViewModel!);
  }

  gotoAnsicht(): void {
    if (this.ansichtViewModel) {
      void this.router.navigate(["ansichten"], {
        queryParams: {
          ansichtId: this.ansichtViewModel.id,
          year: this.ansichtViewModel.year,
          kanal: this.ansichtViewModel.kanal,
        },
      });
    }
  }
}

export interface TilePosition {
  row: number;
  col: number;
  rowSpan: number;
  colSpan: number;
}
