import { DEFAULT_ROUTER_FEATURENAME, routerReducer, RouterState } from "@ngrx/router-store";
import { ActionReducerMap, MetaReducer } from "@ngrx/store";
import { aktivitaetenFeature, AktivitaetenState } from "./aktivitaeten/aktivitaeten.reducer";
import { ansichtFeature, AnsichtState } from "./ansicht/ansicht.reducer";
import {
  benachrichtigungFeature,
  BenachrichtigungState,
} from "./benachrichtigung/benachrichtigung.reducer";
import { beziehungFeature, BeziehungState } from "./beziehung/beziehung.reducer";
import { blockansichtFeature, BlockansichtState } from "./blockansicht/blockansicht.reducer";
import { dragDropFeature, DragDropState } from "./dragdrop/dragdrop.reducer";
import { EkWindowFeatureState } from "./ek-window/ek-window.model";
import { ekWindowFeature } from "./ek-window/ek-window.reducer";
import { eventFeature, EventState } from "./event/event.reducer";
import {
  konkurrenzprogrammFeature,
  KonkurrenzprogrammState,
} from "./konkurrenzprogramm/konkurrenzprogramm.reducer";
import { listenansichtFeature, ListenansichtState } from "./listenansicht/listenansicht.reducer";
import {
  mehrfachauswahlFeature,
  MehrfachauswahlState,
} from "./mehrfachauswahl/mehrfachauswahl.reducer";
import { MengengeruestWindowFeatureState } from "./mengengeruest-window/mengengeruest-window.model";
import { mengengeruestWindowFeature } from "./mengengeruest-window/mengengeruest-window.reducer";
import {
  mengengeruestEintragFeature,
  MengengeruestEintragState,
} from "./mengengeruest/mengengeruest.reducer";
import { MengengeruesteintragWindowFeatureState } from "./mengengeruesteintrag-window/mengengeruesteintrag-window.model";
import { mengengeruesteintragWindowFeature } from "./mengengeruesteintrag-window/mengengeruesteintrag-window.reducer";
import { merklisteFeature, MerklisteState } from "./merkliste/merkliste.reducer";
import { metadatenFeature, VersionInformationState } from "./metadaten/metadaten.reducer";
import { multiAnsichtFeature, MultiAnsichtState } from "./multiansicht/multiansicht.reducer";
import { onDemandFeature, OnDemandState } from "./on-demand/on-demand.reducer";
import { permissionFeature, PermissionState } from "./permissions/permissions.reducer";
import { PharosExportWindowFeatureState } from "./pharos-export-window/pharos-export-window.model";
import { pharosExportWindowFeature } from "./pharos-export-window/pharos-export-window.reducer";
import {
  planungshinweisFeature,
  PlanungshinweisState,
} from "./planungshinweis/planungshinweis.reducer";
import { PlanungshinweiseWindowFeatureState } from "./planungshinweise-window/planungshinweise-window.model";
import { planungshinweiseWindowFeature } from "./planungshinweise-window/planungshinweise-window.reducer";
import { PlanungsobjektWindowState } from "./planungsobjekt-window/planungsobjekt-window.model";
import { planungsobjektWindowFeature } from "./planungsobjekt-window/planungsobjekt-window.reducer";
import {
  planungsobjektFeature,
  PlanungsobjektState,
} from "./planungsobjekt/planungsobjekt.reducer";
import { RechercheState } from "./recherche/recherche.model";
import { rechercheFeature } from "./recherche/recherche.reducer";
import { SendePlatzWindowFeatureState } from "./sendeplatz-window/sendeplatz-window.model";
import { sendeplatzWindowFeature } from "./sendeplatz-window/sendeplatz-window.reducer";
import { sendeplatzFeature, SendeplatzState } from "./sendeplatz/sendeplatz.reducer";
import { SerienWindowFeatureState } from "./serien-window/serien-window.model";
import { serienWindowFeauture } from "./serien-window/serien-window.reducer";
import { shellFeature, ShellState } from "./shell/shell.reducer";
import { sidebarFeature, SidebarState } from "./sidebar/sidebar.reducer";

export interface CoreState {
  [metadatenFeature.name]: VersionInformationState;
  [rechercheFeature.name]: RechercheState;
  [ansichtFeature.name]: AnsichtState;
  [multiAnsichtFeature.name]: MultiAnsichtState;
  [mengengeruestEintragFeature.name]: MengengeruestEintragState;
  [sendeplatzFeature.name]: SendeplatzState;
  [beziehungFeature.name]: BeziehungState;
  [planungsobjektFeature.name]: PlanungsobjektState;
  [planungsobjektWindowFeature.name]: PlanungsobjektWindowState;
  [eventFeature.name]: EventState;
  [konkurrenzprogrammFeature.name]: KonkurrenzprogrammState;
  [merklisteFeature.name]: MerklisteState;
  [mehrfachauswahlFeature.name]: MehrfachauswahlState;
  [permissionFeature.name]: PermissionState;
  [blockansichtFeature.name]: BlockansichtState;
  [dragDropFeature.name]: DragDropState;
  [benachrichtigungFeature.name]: BenachrichtigungState;
  [sidebarFeature.name]: SidebarState;
  [planungshinweisFeature.name]: PlanungshinweisState;
  [shellFeature.name]: ShellState;
  [listenansichtFeature.name]: ListenansichtState;
  [onDemandFeature.name]: OnDemandState;
  [aktivitaetenFeature.name]: AktivitaetenState;
  [DEFAULT_ROUTER_FEATURENAME]: RouterState;
  [ekWindowFeature.name]: EkWindowFeatureState;
  [pharosExportWindowFeature.name]: PharosExportWindowFeatureState;
  [sendeplatzWindowFeature.name]: SendePlatzWindowFeatureState;
  [serienWindowFeauture.name]: SerienWindowFeatureState;
  [mengengeruestWindowFeature.name]: MengengeruestWindowFeatureState;
  [mengengeruesteintragWindowFeature.name]: MengengeruesteintragWindowFeatureState;
  [planungshinweiseWindowFeature.name]: PlanungshinweiseWindowFeatureState;
}

export const reducers: ActionReducerMap<CoreState> = {
  [metadatenFeature.name]: metadatenFeature.reducer,
  [rechercheFeature.name]: rechercheFeature.reducer,
  [ansichtFeature.name]: ansichtFeature.reducer,
  [multiAnsichtFeature.name]: multiAnsichtFeature.reducer,
  [mengengeruestEintragFeature.name]: mengengeruestEintragFeature.reducer,
  [sendeplatzFeature.name]: sendeplatzFeature.reducer,
  [beziehungFeature.name]: beziehungFeature.reducer,
  [planungsobjektFeature.name]: planungsobjektFeature.reducer,
  [planungsobjektWindowFeature.name]: planungsobjektWindowFeature.reducer,
  [eventFeature.name]: eventFeature.reducer,
  [konkurrenzprogrammFeature.name]: konkurrenzprogrammFeature.reducer,
  [merklisteFeature.name]: merklisteFeature.reducer,
  [mehrfachauswahlFeature.name]: mehrfachauswahlFeature.reducer,
  [permissionFeature.name]: permissionFeature.reducer,
  [blockansichtFeature.name]: blockansichtFeature.reducer,
  [dragDropFeature.name]: dragDropFeature.reducer,
  [benachrichtigungFeature.name]: benachrichtigungFeature.reducer,
  [planungshinweisFeature.name]: planungshinweisFeature.reducer,
  [shellFeature.name]: shellFeature.reducer,
  [sidebarFeature.name]: sidebarFeature.reducer,
  [listenansichtFeature.name]: listenansichtFeature.reducer,
  [onDemandFeature.name]: onDemandFeature.reducer,
  [aktivitaetenFeature.name]: aktivitaetenFeature.reducer,
  [DEFAULT_ROUTER_FEATURENAME]: routerReducer,
  [ekWindowFeature.name]: ekWindowFeature.reducer,
  [pharosExportWindowFeature.name]: pharosExportWindowFeature.reducer,
  [sendeplatzWindowFeature.name]: sendeplatzWindowFeature.reducer,
  [serienWindowFeauture.name]: serienWindowFeauture.reducer,
  [mengengeruestWindowFeature.name]: mengengeruestWindowFeature.reducer,
  [mengengeruesteintragWindowFeature.name]: mengengeruesteintragWindowFeature.reducer,
  [planungshinweiseWindowFeature.name]: planungshinweiseWindowFeature.reducer,
};

export const metaReducers: MetaReducer<CoreState>[] = [];
