<div *ngIf="facade.formState$ | async as formState" class="u-grid-container">
  <app-form-field class="u-col-12" label="Planungstitel">
    <!-- TODO formcontrol zu planungstitel ändern nachdem DTO und Commands angepasst wurden -->
    <div class="k-d-flex k-align-items-center">
      <kendo-textbox
        data-testid="planungstitel"
        [ngrxFormControlState]="formState.controls.titel"
      ></kendo-textbox>
      <app-icon-toggle
        data-testid="highlight"
        icon="star"
        title="Highlight"
        [ngrxFormControlState]="formState.controls.highlight"
        [tooltips]="{ active: 'Highlight: ja', inactive: 'Highlight: nein' }"
      ></app-icon-toggle>
    </div>
  </app-form-field>

  <app-input-label class="u-col-12" text="Notiz">
    <kendo-textarea
      data-testid="notiz"
      resizable="auto"
      [ngrxFormControlState]="formState.controls.notiz"
    ></kendo-textarea>
  </app-input-label>

  <!-- TODO formcontrol redaktion -> planendeRedaktion nachdem DTO und Commands angepasst wurden -->
  <app-form-field class="u-col-4" label="Planende Redaktion">
    <app-select
      data-testid="planende-redaktion"
      [ngrxFormControlState]="formState.controls.redaktion"
      [options]="RedaktionOptionsFiltered"
    >
    </app-select>
  </app-form-field>

  <app-form-field class="u-col-4" label="Farbe">
    <app-select
      data-testid="farbgebung"
      [farbgebungEnumStyleMap]="planungsobjektFarbgebungMap"
      [ngrxFormControlState]="formState.controls.farbgebung"
      [options]="PlanungsobjektFarbenOptions"
    >
    </app-select>
  </app-form-field>

  <app-form-field
    class="u-col-4"
    hint="voraussichtlich frühestmöglicher Termin nach Endabnahme eines sendefähigen Materials"
    label="früheste Veröffentlichung"
  >
    <app-iso-dateonly
      data-testid="frueheste-veroeffentlichung"
      [ngrxFormControlState]="formState.controls.fruehesteVeroeffentlichung"
    ></app-iso-dateonly>
  </app-form-field>
</div>
