import { inject, Injectable } from "@angular/core";
import { PureAbility } from "@casl/ability";
import { Store } from "@ngrx/store";
import { map, take } from "rxjs";
import { ekWindowActions } from "src/app/core/stores/ek-window/ek-window.actions";
import { ekWindowFormActions } from "src/app/core/stores/ek-window/ek-window.form";
import { EkWindowFormData } from "src/app/core/stores/ek-window/ek-window.model";
import { ekWindowSelectors } from "src/app/core/stores/ek-window/ek-window.selectors";
import { Aktion, AktionResult } from "src/app/models/enums/aktion";
import { CopyPattern } from "src/app/models/openapi/model/copy-pattern";
import { EventDto } from "src/app/models/openapi/model/event-dto";
import { KonkurrenzprogrammDto } from "src/app/models/openapi/model/konkurrenzprogramm-dto";

@Injectable()
export class EkWindowFacade {
  private readonly store = inject(Store);
  private readonly appAbility = inject(PureAbility);

  public readonly formState$ = this.store.select(ekWindowSelectors.selectFormState);
  public readonly input$ = this.store.select(ekWindowSelectors.selectInput);

  public canEdit = this.appAbility.can("editieren", "EK");

  public isSonstiges$ = this.formState$.pipe(
    map((state) => state.value.senderPlattform === "Sonstiges" && !state.value.isEvent),
  );

  public setFormValue(value: EkWindowFormData) {
    this.store.dispatch(ekWindowFormActions.setValue({ value }));
  }

  public updateFormValue(value: Partial<EkWindowFormData>) {
    this.store.dispatch(ekWindowFormActions.patchValue({ value }));
  }

  public resetForm() {
    this.store.dispatch(ekWindowFormActions.reset());
  }

  public closeWindow() {
    this.store.dispatch(ekWindowActions.tryClosingWithoutSaving());
  }

  public remove() {
    this.formState$
      .pipe(
        take(1),
        map((state) => state.value),
        map((formState) => ({
          result: AktionResult.DELETE,
          returnValue: {
            action: formState.isEvent ? Aktion.ENTFERNE_EVENT : Aktion.ENTFERNE_KONKURRENZPROGRAMM,
            isEvent: formState.isEvent,
            value: { ...formState } as unknown as EventDto | KonkurrenzprogrammDto,
            // CopyPattern muss übergeben werden da required, wird aber an der Stelle eigentlich nicht verwendet
            copyPattern: CopyPattern.NOW,
          },
        })),
      )
      .subscribe((payload) => {
        this.store.dispatch(ekWindowActions.handleEkWindowResult({ result: payload }));
        this.store.dispatch(ekWindowActions.closeWindow());
      });
  }

  save() {
    this.store.dispatch(ekWindowFormActions.trySaving());
  }
}
