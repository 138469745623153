import { inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { mengengeruesteintragWindowActions } from "../core/stores/mengengeruesteintrag-window/mengengeruesteintrag-window.actions";
import { MengengeruestEintragWindowComponent } from "../shared/windows/mengengeruesteintrag-window/mengengeruesteintrag-window.component";
import { MediumWindow } from "../shared/windows/window.templates";
import { CustomWindowService } from "./custom-window.service";

@Injectable({ providedIn: "root" })
export class MengengeruesteintragWindowService {
  private readonly store = inject(Store);
  private readonly customWindowService = inject(CustomWindowService);

  openCreateMengengeruesteintragWindow(title: string) {
    const windowRef = this.customWindowService.open<MengengeruestEintragWindowComponent>({
      content: MengengeruestEintragWindowComponent,
      title,
      ...MediumWindow(),
      htmlAttributes: {
        "data-testid": "mengengeruesteintrag-window",
      },
      preventClose: () => {
        this.store.dispatch(mengengeruesteintragWindowActions.tryClosingWithoutSaving());
        return true;
      },
    });

    return windowRef;
  }
}
