import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { WindowCloseResult } from "@progress/kendo-angular-dialog";
import { MengengeruesteintragDto } from "src/app/models/openapi/model/mengengeruesteintrag-dto";
import {
  MengengeruestWindowComponentResult,
  MengengeruestWindowInput,
} from "./mengengeruest-window.model";

export const mengengeruestWindowActions = createActionGroup({
  source: "Mengengeruest Window",
  events: {
    openDeleteMengengeruesteintragDialog: props<{
      mengengeruesteintrag: MengengeruesteintragDto;
    }>(),
    openMengengeruestWindow: props<{
      input: MengengeruestWindowInput;
    }>(),
    handleMengengeruestWindowResult: props<{
      result: MengengeruestWindowComponentResult | WindowCloseResult;
    }>(),

    setActiveMengengeruesteintragId: props<{
      mengengeruesteintragId: string | null;
    }>(),

    closeWindow: emptyProps(),
    tryClosingWithoutSaving: emptyProps(),
    windowClosed: emptyProps(),
  },
});
