import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, map, of, switchMap, tap } from "rxjs";
import { PlanungsobjektOnDemandApiService } from "src/app/api/planungsobjekt/planungsobjekt-on-demand.api.service";
import { planungsobjektActions } from "src/app/core/stores/planungsobjekt/planungsobjekt.actions";
import { Aktion } from "src/app/models/enums/aktion";
import { NotificationStyle } from "src/app/models/openapi/model/notification-style";
import onDemandPageSelectors from "src/app/on-demand/state/on-demand-page.selectors";
import { OnDemandService } from "src/app/services/on-demand.service";
import { CustomNotificationService } from "src/app/shared/notifications/custom-notification.service";
import { beziehungActions } from "../beziehung/beziehung.actions";
import { onDemandBeziehungFormActions } from "../planungsobjekt-window/on-demand-form/on-demand-beziehung-form.actions";
import { onDemandActions } from "./on-demand.actions";

@Injectable()
export class OnDemandEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly onDemandService = inject(OnDemandService);
  private readonly planungsobjektOnDemandApi = inject(PlanungsobjektOnDemandApiService);
  private readonly notificationService = inject(CustomNotificationService);

  getPlanungsobjektOnDemandById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandActions.getPlanungsobjektOnDemandById),
      switchMap(({ planungsobjektOnDemandId }) => {
        return this.planungsobjektOnDemandApi.getOnDemandById$(planungsobjektOnDemandId).pipe(
          map((planungsobjektOnDemand) =>
            onDemandActions.getPlanungsobjektOnDemandByIdSuccess({ planungsobjektOnDemand }),
          ),
          catchError((error: unknown) =>
            of(onDemandActions.getPlanungsobjektOnDemandByIdFailure({ error })),
          ),
        );
      }),
    );
  });

  loadOnDemandPlanung$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandActions.loadOnDemand),
      concatLatestFrom(() => [this.store.select(onDemandPageSelectors.selectZeitraum)]),
      exhaustMap(([_, zeitraum]) =>
        this.onDemandService.getOnDemandInZeitraumMitVerknuepftenLinearen$(zeitraum).pipe(
          map(({ linear, onDemand }) => {
            return onDemandActions.loadOnDemandSuccess({
              planungsobjekteOnDemand: onDemand,
              planungsobjekteLinear: linear,
            });
          }),
          catchError((error: unknown) => of(onDemandActions.loadOnDemandFailure({ error }))),
        ),
      ),
    );
  });

  /**
   * Übersetzt die OnDemand Action, die OnDemand + Linear enthält in eine Action, die nur Linear enthält
   */
  loadOnDemandSuccessToSetAllPlanungsobjekte$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandActions.loadOnDemandSuccess),
      map(({ planungsobjekteLinear }) => {
        return planungsobjektActions.upsertPlanungsobjekteByAnsichtenSuccess({
          planungsobjekte: planungsobjekteLinear,
        });
      }),
    );
  });

  createOnDemandVorgeplant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandActions.createOnDemandVorgeplant),
      switchMap(({ value, shouldCloseWindow }) =>
        this.onDemandService.planungsobjektOnDemandVorgeplantErstellen$(value).pipe(
          map((planungsobjekt) =>
            onDemandActions.createOnDemandSuccess({
              planungsobjekt,
              shouldCloseWindow,
            }),
          ),
          catchError((error: unknown) => of(onDemandActions.createOnDemandFailure({ error }))),
        ),
      ),
    );
  });

  updateOnDemandVorgeplant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandActions.updateOnDemandVorgeplant),
      switchMap(({ value, shouldCloseWindow }) =>
        this.onDemandService.planungsobjektOnDemandVorgeplantAktualisieren$(value).pipe(
          map((response) =>
            onDemandActions.updateOnDemandSuccess({
              planungsobjekt: response.result,
              shouldCloseWindow,
            }),
          ),
          catchError((error: unknown) => of(onDemandActions.createOnDemandFailure({ error }))),
        ),
      ),
    );
  });

  createOnDemandVorgeschlagen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandActions.createOnDemandVorgeschlagen),
      switchMap(({ value, shouldCloseWindow }) =>
        this.onDemandService.planungsobjektOnDemandVorgeschlagenErstellen$(value).pipe(
          map((planungsobjekt) =>
            onDemandActions.createOnDemandSuccess({
              planungsobjekt,
              shouldCloseWindow,
            }),
          ),
          catchError((error: unknown) => of(onDemandActions.createOnDemandFailure({ error }))),
        ),
      ),
    );
  });

  updateOnDemandVorgeschlagen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandActions.updateOnDemandVorgeschlagen),
      switchMap(({ value, shouldCloseWindow }) =>
        this.onDemandService.planungsobjektOnDemandVorgeschlagenAktualisieren$(value).pipe(
          map((response) =>
            onDemandActions.updateOnDemandSuccess({
              planungsobjekt: response.result,
              shouldCloseWindow,
            }),
          ),
          catchError((error: unknown) => of(onDemandActions.createOnDemandFailure({ error }))),
        ),
      ),
    );
  });

  updateOnDemandVorgemerkt$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandActions.updateOnDemandVorgemerkt),
      switchMap(({ value, shouldCloseWindow }) =>
        this.onDemandService.planungsobjektOnDemandVorgemerktAktualisieren$(value).pipe(
          tap((planungsobjektOnDemand) => {
            this.notificationService.showActionNotification(
              Aktion.BEARBEITE_PLANUNGSOBJEKT,
              NotificationStyle.SUCCESS,
              planungsobjektOnDemand.titel,
            );
          }),
          map((planungsobjektOnDemand) =>
            onDemandActions.updateOnDemandSuccess({
              planungsobjekt: planungsobjektOnDemand,
              shouldCloseWindow,
            }),
          ),
          catchError((error: unknown) => of(onDemandActions.updateOnDemandFailure({ error }))),
        ),
      ),
    );
  });

  updateOnDemandPlanungskontext$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandActions.updateOnDemandPlanungskontext),
      switchMap(({ command }) =>
        this.onDemandService.aktualisierenPlanungsobjekteOnDemandPlanungskontext$(command).pipe(
          map((onDemandPlanungsobjekte) =>
            onDemandActions.updateOnDemandPlanungskontextSuccess({
              onDemandPlanungsobjekte,
            }),
          ),
        ),
      ),
    );
  });

  verschiebePlanungsobjektOnDemandZuVorgeschlagen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandActions.verschiebePlanungsobjektOnDemandZuVorgeschlagen),
      switchMap(({ command }) => {
        return this.planungsobjektOnDemandApi
          .planungsobjektOnDemandVerschiebenZuVorgeschlagen$(command)
          .pipe(
            map((response) => {
              return onDemandActions.verschiebePlanungsobjektOnDemandZuVorgeschlagenSuccess({
                planungsobjektOnDemand: response.result,
              });
            }),
          );
      }),
    );
  });

  verschiebePlanungsobjektOnDemandZuVorgeplant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandActions.verschiebePlanungsobjektOnDemandZuVorgeplant),
      switchMap(({ command }) => {
        return this.planungsobjektOnDemandApi
          .planungsobjektOnDemandVerschiebenZuVorgeplant$(command)
          .pipe(
            map((response) => {
              return onDemandActions.verschiebePlanungsobjektOnDemandZuVorgeplantSuccess({
                planungsobjektOnDemand: response.result,
              });
            }),
          );
      }),
    );
  });

  mapPlanungsobjekteToPlanungsobjektLinearId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenErstellenSuccess,
      ),
      map(({ planungsobjekte }) => {
        // Hier können wir nur den ersten Eintrag nehmen, da wir aus
        // LinearOnDemandService.linearOnDemandBeziehungVorgeschlagenVorgeplantErstellen$
        // immer nur ein Planungsobjekte Paar mit jeweils einem Element zurückbekommen.
        return beziehungActions.getBeziehungenForPlanungsobjekt({
          planungsobjektId: planungsobjekte.linear[0].id,
        });
      }),
    );
  });
}
