import { Prettify } from "@ngrx/store/src/models";
import { DeveloperError } from "src/app/models/errors/technical.error";
import { DateFnsService } from "src/app/services/date-fns.service";
import { maxSecondsForMaskedInput } from "src/app/shared/form-inputs/masked-input/masked-input.model";
import {
  PlanungsobjektWindowInputWithPlanungsobjekt,
  PlanungsobjektWindowUseCase,
} from "src/app/shared/windows/planungsobjekt-window/planungsobjekt-window.model";
import { NullableValues, getChangedKeys } from "src/app/utils/object-utils";
import { PlanungsobjektUtils } from "src/app/utils/planungsobjekt.utils";
import { calculateOnlineBisOrVerweildauer } from "./on-demand-form/on-demand-beziehung-form.utils";
import {
  PlanungsobjektWindowPlanungFormData,
  WannBezugLinearFormData,
  WannBezugOnDemandFormData,
} from "./planungsobjekt-window.model";
import { isCreateWindow, isEditWindow, isLinearWindow } from "./planungsobjekt-window.utils";

export function calculatePlanlaengeValue(
  sendetag: string,
  beginnzeit: string,
  endzeit: string,
  previousPlanlaenge: number | null,
) {
  const calculatedLaenge = PlanungsobjektUtils.updatePlanlaengeFromZeiten({
    sendetag,
    beginnzeit,
    endzeit,
    prevPlanlaenge: previousPlanlaenge,
  });

  const planlaengeSeconds = previousPlanlaenge ? previousPlanlaenge % 60 : 0;
  return calculatedLaenge + planlaengeSeconds;
}

export function calculateEndzeitValue(laengeInSeconds: number | null, beginnzeit: string) {
  if (laengeInSeconds === null) return null;
  const beginnzeitDate = DateFnsService.parseDateAndTimeToDateObject(beginnzeit);
  if (laengeInSeconds > 0 && beginnzeitDate) {
    const shouldRoundUp = laengeInSeconds % 60 > 0;
    let computedEndTime = DateFnsService.addSeconds(beginnzeitDate, laengeInSeconds);
    if (shouldRoundUp) {
      computedEndTime = DateFnsService.addMinutes(computedEndTime, 1);
    }
    return DateFnsService.formatDateAsTimeString(computedEndTime);
  }
  return null;
}

/**
 * Extrahiert die Werte für die PlanlaengenProperties aus dem WindowInput.
 * Beim Erstellen werden die Werte aus `WannPreset` übernommen.
 * Beim Bearbeiten werden die Werte aus dem Planungsobjekt übernommen.
 */
export function extractPlanlaengenPropertiesFromWindowInput(
  windowInput: PlanungsobjektWindowInputWithPlanungsobjekt,
): PlanlaengenProperties | null {
  if (!isLinearWindow(windowInput)) {
    return null;
  }

  if (isEditWindow(windowInput)) {
    return {
      beginnzeit: windowInput.planungsobjekt.beginnzeit,
      endzeit: calculateEndzeitValue(
        windowInput.planungsobjekt.planlaenge,
        windowInput.planungsobjekt.beginnzeit,
      ),
      sendetag: windowInput.planungsobjekt.sendetag,
      planlaenge:
        windowInput.planungsobjekt.planlaenge ??
        windowInput.planungsobjekt.publikationsplanung?.sendeplatzLaenge ?? // Falls wir mit der Plus-Logik erstellt haben
        null,
    };
  }

  if (isCreateWindow(windowInput)) {
    return {
      beginnzeit: windowInput.wannPreset.beginnzeit,
      endzeit: null,
      sendetag: windowInput.wannPreset.sendetag,
      planlaenge:
        windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ
          ? windowInput.wannPreset.planlaenge
          : null,
    };
  }

  return null;
}

export type PlanlaengenProperties = Prettify<
  NullableValues<Omit<WannBezugLinearFormData, "variante">>
>;

export function berechneSynchronisiertePlanlaengenWerte(opts: {
  prevFormValue: PlanlaengenProperties;
  nextFormValue: PlanlaengenProperties;
}): Partial<PlanlaengenProperties> | null {
  const foundDiff: (keyof PlanungsobjektWindowPlanungFormData)[] = [];
  const throwIfMoreThanOneChange = (property: keyof PlanungsobjektWindowPlanungFormData) => {
    if (foundDiff.length)
      throw new DeveloperError("Mehr als ein Feld wurde geändert", {
        foundDiff,
        property,
        prevFormValue,
        nextFormValue,
      });
    foundDiff.push(property);
  };
  const { prevFormValue, nextFormValue } = opts;
  const {
    sendetag: nextSendetag,
    beginnzeit: nextBeginnzeit,
    endzeit: nextEndzeit,
    planlaenge: nextPlanlaenge,
  } = nextFormValue;
  const {
    sendetag: prevSendetag,
    beginnzeit: prevBeginnzeit,
    endzeit: prevEndzeit,
    planlaenge: prevPlanlaenge,
  } = prevFormValue;

  let result: Partial<PlanlaengenProperties> = {};

  let endzeit = nextEndzeit;
  let planlaenge = nextPlanlaenge;

  // Sendetag geändert
  if (nextSendetag !== prevSendetag) {
    throwIfMoreThanOneChange("sendetag");
    if (!nextSendetag || !prevBeginnzeit) return null;
    if (prevPlanlaenge) {
      endzeit = calculateEndzeitValue(prevPlanlaenge, prevBeginnzeit);
    } else if (prevEndzeit) {
      planlaenge = calculatePlanlaengeValue(
        nextSendetag,
        prevBeginnzeit,
        prevEndzeit,
        prevPlanlaenge,
      );
    }
  }
  // Beginnzeit geändert
  if (nextBeginnzeit !== prevBeginnzeit) {
    throwIfMoreThanOneChange("beginnzeit");
    if (nextBeginnzeit) {
      if (prevSendetag && prevPlanlaenge) {
        result.endzeit = calculateEndzeitValue(prevPlanlaenge, nextBeginnzeit);
      }
    } else {
      result.endzeit = null;
    }
  }
  // Endzeit geändert
  if (nextEndzeit !== prevEndzeit) {
    throwIfMoreThanOneChange("endzeit");
    if (nextEndzeit && prevSendetag && prevBeginnzeit) {
      const planlaenge = calculatePlanlaengeValue(
        prevSendetag,
        prevBeginnzeit,
        nextEndzeit,
        prevPlanlaenge,
      );
      if (planlaenge <= maxSecondsForMaskedInput) {
        result.planlaenge = planlaenge;
      }
    }
  }
  // Planlänge geändert
  if (nextPlanlaenge !== prevPlanlaenge) {
    throwIfMoreThanOneChange("planlaenge");
    if (nextPlanlaenge && prevSendetag && prevBeginnzeit) {
      result.endzeit = calculateEndzeitValue(nextPlanlaenge, prevBeginnzeit);
    }
  }

  return foundDiff.length ? result : null;
}

export function berechneSynchronisierteOnDemandFormValues(opts: {
  nextFormValue: WannBezugOnDemandFormData;
  prevFormValue: WannBezugOnDemandFormData;
}) {
  const { nextFormValue, prevFormValue } = opts;

  const changedKeys = getChangedKeys(nextFormValue, prevFormValue);

  if (changedKeys.length === 0) {
    return null;
  }

  const changedKey = changedKeys[0];
  const { verweildauerInTagen, onlineAb, onlineBis } =
    nextFormValue;

  let calculatedValues: Partial<WannBezugOnDemandFormData> = {};

  switch (changedKey) {
    case "onlineBis":
      if (onlineAb === null || onlineBis === null) {
        return null;
      }
      calculatedValues = {
        ...calculateOnlineBisOrVerweildauer(nextFormValue),
      };
      break;

    case "verweildauerInTagen":
      if (verweildauerInTagen === null || onlineAb === null) {
        return null;
      }
      calculatedValues = {
        ...calculateOnlineBisOrVerweildauer(nextFormValue),
      };
      break;
  }

  const changedValues = getChangedKeys(calculatedValues, nextFormValue).reduce(
    (accu, key) => {
      accu[key] = calculatedValues[key];
      return accu;
    },
    {} as Record<string, unknown>,
  ) as Partial<WannBezugOnDemandFormData>;

  return changedValues;
}
