<ng-container *ngIf="facade.formState$ | async as formState">
  <form autocomplete="off" [ngrxFormState]="formState">
    <div class="flex-container">
      <app-form-field class="flex-item" label="Titel">
        <kendo-textbox data-testid="titel" [ngrxFormControlState]="formState.controls.titel">
        </kendo-textbox>
      </app-form-field>
    </div>

    <div class="flex-container">
      <app-form-field class="three-quarter-width" label="Redaktion">
        <app-select
          data-testid="redaktion"
          [ngrxFormControlState]="formState.controls.redaktion"
          [options]="RedaktionOptions"
        >
        </app-select>
      </app-form-field>

      <app-form-field class="quarter-width" label="Länge (min:sek)">
        <app-masked-input
          dataTestId="laenge"
          [ngrxFormControlState]="formState.controls.laenge"
        ></app-masked-input>
      </app-form-field>
    </div>

    <div class="flex-container">
      <app-form-field class="three-quarter-width" label="Genre">
        <app-select
          data-testid="genre"
          [ngrxFormControlState]="formState.controls.genre"
          [options]="GenreOptions"
        ></app-select>
      </app-form-field>

      <app-form-field class="quarter-width" label="Uhrzeit">
        <app-iso-timeonly
          data-testid="beginnzeit"
          [ngrxFormControlState]="formState.controls.beginnzeit"
        ></app-iso-timeonly>
      </app-form-field>
    </div>

    <div class="flex-container">
      <app-form-field class="three-quarter-width" label="Ausstrahlung">
        <app-select
          data-testid="ausstrahlung"
          [ngrxFormControlState]="formState.controls.ausstrahlung"
          [options]="AusstrahlungOptions"
        ></app-select>
      </app-form-field>

      <app-form-field class="quarter-width" data-testid="sendesoll" label="Sendesoll">
        <kendo-numerictextbox
          format="n0"
          [min]="1"
          [ngrxFormControlState]="formState.controls.sendesoll"
          [step]="1"
        >
        </kendo-numerictextbox>
      </app-form-field>
    </div>
    <app-form-field label="Kommentar">
      <kendo-textarea
        data-testid="kommentar"
        resizable="auto"
        [ngrxFormControlState]="formState.controls.kommentar"
      >
      </kendo-textarea>
    </app-form-field>
  </form>
  <div class="window-action-buttons">
    <button
      class="primary-button"
      kendoButton
      themeColor="primary"
      type="button"
      [disabled]="formState.isPristine"
      (click)="facade.save()"
    >
      Speichern
    </button>
    <button fillMode="outline" kendoButton type="button" (click)="facade.closeWindow()">
      Abbrechen
    </button>
  </div>
</ng-container>
