import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { forkJoin, map, switchMap } from "rxjs";
import { PlanungsobjektBeziehungTyp } from "src/app/models/openapi/model/planungsobjekt-beziehung-typ";
import { PlanungsobjektBeziehungService } from "src/app/services/planungsobjekt-beziehung.service";
import { PlanungsobjektService } from "src/app/services/planungsobjekt.service";
import { CustomNotificationService } from "src/app/shared/notifications/custom-notification.service";
import { onDemandBeziehungFormActions } from "../planungsobjekt-window/on-demand-form/on-demand-beziehung-form.actions";
import { planungsobjektActions } from "../planungsobjekt/planungsobjekt.actions";
import { beziehungActions } from "./beziehung.actions";

@Injectable()
export class BeziehungEffects {
  /**
   * Verlinke mehrere Planungsobjekte als Reihenfolge.
   */
  createReihenfolgeForSelectedPlanungsobjekten$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.createReihenfolgeForSelectedPlanungsobjekte),
      switchMap(({ command }) =>
        this.planungsobjektBeziehungService
          .createReihenfolgeForMarkedPlanungsobjekte$(command)
          .pipe(
            map(() => {
              return planungsobjektActions.getPlanungsobjekteByIds({
                planungsobjekteIds: command.planungsobjekte,
              });
            }),
          ),
      ),
    );
  });

  /**
   * Verlinke genau zwei Planungsobjekten als Abhängigkeit.
   */
  createAbhaengigkeitForSelectedPlanungsobjekte$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.createAbhaengigkeitForSelectedPlanungsobjekte),
      switchMap(({ command }) =>
        this.planungsobjektBeziehungService
          .createAbhaengigkeitForMarkedPlanungsobjekte$(command)
          .pipe(
            map(() => {
              return planungsobjektActions.getPlanungsobjekteByIds({
                planungsobjekteIds: [command.planungsobjektVon, command.planungsobjektZu],
              });
            }),
          ),
      ),
    );
  });

  /**
   * Helper Effect um nach einem Verlinken die entsprechenden Planungsobjekte neu aus dem Backend zu laden.
   * Stellt sicher, dass bei einem Klick auf eine Planungsobjekt die verlinkten Chips/Pillen korrekt gestylt werden.
   */
  updatePlanungsobjektenWithBeziehung$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.getPlanungsobjekteByIds),
      switchMap(({ planungsobjekteIds }) => {
        const all = planungsobjekteIds.map((planungsobjektId) =>
          this.service.getPlanungsobjektById$(planungsobjektId),
        );
        return forkJoin(all);
      }),
      map((planungsobjekteToUpdate) =>
        planungsobjektActions.getPlanungsobjekteByIdsSuccess({
          planungsobjekte: planungsobjekteToUpdate,
        }),
      ),
    );
  });

  /**
   * Lade die Beziehungen zu Planungsobjekt aus dem Backend.
   * Wird benötigt, um die Beziehungen in der Detailansicht anzuzeigen.
   * Wird auch benötigt, um die Beziehungen nach dem Entfernen einer Beziehung neu zu laden.
   */
  getBeziehungenForPlanungsobjekt$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        beziehungActions.getBeziehungenForPlanungsobjekt,
        beziehungActions.removeBeziehungSuccess,
        onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenErstellenSuccess,
        onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenAktualisierenSuccess,
        onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktErstellenSuccess,
        onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktAktualisierenSuccess,
      ),
      map((action) => {
        switch (action.type) {
          case "[Beziehung API] getBeziehungenForPlanungsobjekt":
            return action.planungsobjektId;
          case "[OnDemand Beziehung Form] linearOnDemandBeziehungVorgeplantVorgeschlagenErstellenSuccess":
          case "[OnDemand Beziehung Form] linearOnDemandBeziehungVorgeplantVorgeschlagenAktualisierenSuccess":
          case "[OnDemand Beziehung Form] linearOnDemandBeziehungVorgemerktErstellenSuccess":
          case "[OnDemand Beziehung Form] linearOnDemandBeziehungVorgemerktAktualisierenSuccess":
            return action.currentPlanungsobjektId; // Für das Backend ist es relevant, ob die aktuell geöffnete PO Linear oder OnDemand ist
          default:
            return action.planungsobjekt.id;
        }
      }),
      switchMap((planungsobjektId) =>
        this.planungsobjektBeziehungService.getBeziehungenForPlanungsobjekt$(planungsobjektId).pipe(
          map((beziehungen) =>
            beziehungActions.getBeziehungenForPlanungsobjektSuccess({
              beziehungen,
            }),
          ),
        ),
      ),
    );
  });

  /**
   * Entferne die Beziehung und lade die betroffenen Planungsobjekte aus dem Backend.
   */
  removeBeziehung$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(beziehungActions.removeBeziehung),
      switchMap(({ planungsobjekt, beziehung }) => {
        return this.planungsobjektBeziehungService
          .deletePlanungsobjektBeziehung$(beziehung.planungsobjektBeziehungId)
          .pipe(
            map(() =>
              beziehungActions.removeBeziehungSuccess({
                planungsobjekt,
                beziehung: beziehung,
              }),
            ),
          );
      }),
    );
  });

  loadPlanungsobjekteAfterRemoveBeziehung$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(beziehungActions.removeBeziehungSuccess),
      map(({ planungsobjekt, beziehung }) => {
        const planungsobjekteInBeziehung: string[] = [];
        switch (beziehung.beziehungTyp) {
          case PlanungsobjektBeziehungTyp.REIHENFOLGE:
            planungsobjekteInBeziehung.push(
              ...planungsobjekt.vorgaenger,
              ...planungsobjekt.nachfolger,
            );
            break;
          case PlanungsobjektBeziehungTyp.ABHAENGIGKEIT:
            planungsobjekteInBeziehung.push(...planungsobjekt.abhaengigkeiten);
        }
        return planungsobjektActions.getPlanungsobjekteByIds({
          planungsobjekteIds: [planungsobjekt.id, ...planungsobjekteInBeziehung],
        });
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private service: PlanungsobjektService,
    private notificationService: CustomNotificationService,
    private planungsobjektBeziehungService: PlanungsobjektBeziehungService,
  ) {}
}
