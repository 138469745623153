<ng-container
  *ngIf="{
    formState: facade.formState$ | async,
    input: facade.input$ | async,
  } as vm"
>
  <ng-container *ngIf="vm.formState && vm.input">
    <form autocomplete="off" data-testid="ek-window" [ngrxFormState]="vm.formState">
      <div *ngIf="facade.canEdit" class="ek-window-switch">
        Konkurrenzprogramm
        <kendo-switch
          data-testid="ek-window-switch"
          [ngrxFormControlState]="vm.formState.controls.isEvent"
          (valueChange)="onChangeSwitch()"
        ></kendo-switch>
        Event
      </div>

      <div class="flex-container">
        <app-form-field class="u-w-full" label="Titel">
          <kendo-textbox
            data-testid="ek-window-titel"
            minlength="2"
            [ngrxFormControlState]="vm.formState.controls.titel"
          ></kendo-textbox>
        </app-form-field>
      </div>

      <div class="flex-container">
        <app-form-field
          *ngIf="!vm.formState.controls.isEvent.value"
          class="u-w-full max-width"
          label="Kategorie"
        >
          <app-select
            data-testid="ek-window-kategorie"
            [ngrxFormControlState]="vm.formState.controls.kategorie"
            [options]="KonkurrenzKategorie"
          ></app-select>
        </app-form-field>

        <app-form-field
          *ngIf="vm.formState.controls.isEvent?.value"
          class="u-w-full"
          label="Kategorie"
        >
          <app-select
            data-testid="ek-window-kategorie"
            [ngrxFormControlState]="vm.formState.controls.kategorie"
            [options]="EventKategorie"
          ></app-select>
        </app-form-field>

        <app-form-field
          *ngIf="!vm.formState.controls.isEvent.value"
          class="u-w-full max-width"
          label="Sender/Plattform"
        >
          <app-select
            data-testid="ek-window-senderPlattform"
            [isSenderPlattform]="true"
            [ngrxFormControlState]="vm.formState.controls.senderPlattform"
            [options]="SenderPlattform"
            (selectionChange)="onSenderPlattformChange($event)"
          ></app-select>
        </app-form-field>
      </div>
      <div *ngIf="facade.isSonstiges$ | async" class="flex-container">
        <app-form-field class="u-w-full" label="Sonstiges">
          <kendo-textbox
            data-testid="ek-window-senderPlattformSonstiges"
            [ngrxFormControlState]="vm.formState.controls.senderPlattformSonstiges"
          ></kendo-textbox>
        </app-form-field>
      </div>
      <div class="flex-container">
        <!-- Datum von -->
        <app-form-field class="u-w-full max-width" hint="Kalendertag" label="Datum von">
          <app-iso-dateonly
            data-testid="ek-window-vonDatum"
            [ngrxFormControlState]="vm.formState.controls.vonDatum"
          >
          </app-iso-dateonly>
        </app-form-field>

        <!-- Uhrzeit von -->
        <app-form-field class="u-w-full max-width" label="Uhrzeit von">
          <app-iso-timeonly
            data-testid="ek-window-vonZeit"
            [ngrxFormControlState]="vm.formState.controls.vonZeit"
          ></app-iso-timeonly>
        </app-form-field>
      </div>
      <div class="flex-container">
        <!-- Datum bis -->
        <app-form-field class="u-w-full max-width" hint="Kalendertag" label="Datum bis">
          <app-iso-dateonly
            data-testid="ek-window-bisDatum"
            placeholder=""
            [focusedDate]="vm.formState.value.bisDatum || vm.formState.value.vonDatum"
            [minDate]="vm.formState.value.vonDatum"
            [ngrxFormControlState]="vm.formState.controls.bisDatum"
          >
          </app-iso-dateonly>
        </app-form-field>

        <!-- Uhrzeit bis -->
        <app-form-field class="u-w-full max-width" label="Uhrzeit bis">
          <app-iso-timeonly
            data-testid="ek-window-bisZeit"
            [ngrxFormControlState]="vm.formState.controls.bisZeit"
          ></app-iso-timeonly>
        </app-form-field>
      </div>
      <div *ngIf="!vm.formState.value.isEvent" class="publit-checkbox">
        <input
          #abgesetzt
          data-testid="ek-window-isAbgesetzt"
          kendoCheckBox
          type="checkbox"
          [ngrxFormControlState]="vm.formState.controls.isAbgesetzt"
        />
        <kendo-label text="als abgesetzt markieren" [for]="abgesetzt"></kendo-label>
      </div>

      <app-form-field class="u-w-full" label="Notiz">
        <kendo-textarea
          data-testid="ek-window-notiz"
          resizable="auto"
          [ngrxFormControlState]="vm.formState.controls.notiz"
        >
        </kendo-textarea>
      </app-form-field>
    </form>

    <div class="window-action-buttons">
      <button
        class="primary-button"
        data-testid="ek-window-speichern-button"
        kendoButton
        themeColor="primary"
        type="button"
        [disabled]="!facade.canEdit || vm.formState.isPristine"
        (click)="facade.save()"
      >
        Speichern
      </button>
      <button
        *ngIf="vm.input.type !== 'Create EK'"
        data-testid="ek-window-loeschen-button"
        fillMode="outline"
        kendoButton
        type="button"
        [disabled]="!facade.canEdit"
        (click)="facade.remove()"
      >
        Löschen
      </button>
      <button
        data-testid="ek-window-abbrechen-button"
        fillMode="outline"
        kendoButton
        type="button"
        (click)="facade.closeWindow()"
      >
        Abbrechen
      </button>
    </div>
  </ng-container>
</ng-container>
