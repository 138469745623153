import { createSelector } from "@ngrx/store";
import { PlanungsobjektWindowMapper } from "src/app/models/mapper/planungsobjekt-window.mapper";
import { Kanal } from "src/app/models/openapi/model/kanal";
import {
  PlanungsobjektWindowInputWithPlanungsobjekt,
  PlanungsobjektWindowUseCase,
  useCasesWithPlanungsobjekt,
} from "src/app/shared/windows/planungsobjekt-window/planungsobjekt-window.model";
import { assertUnreachable } from "src/app/utils/function-utils";
import { PlanungsobjektUtils } from "src/app/utils/planungsobjekt.utils";
import { onDemandFeature } from "../on-demand/on-demand.reducer";
import { planungsobjektFeature } from "../planungsobjekt/planungsobjekt.reducer";
import { onDemandFormSelectors as onDemandFormSelectorsCreator } from "./on-demand-form/on-demand.form";
import { planungsobjektFormSelectors } from "./planungsobjekt-form/planungsobjekt-window.form";
import {
  PlanungsobjektWindowTabEnum,
  WannBezugLinearFormData,
} from "./planungsobjekt-window.model";
import { planungsobjektWindowFeature } from "./planungsobjekt-window.reducer";
import { extractKanalFromWindowInput } from "./planungsobjekt-window.utils";

const planungsFormSelectors = planungsobjektFormSelectors(
  planungsobjektWindowFeature.selectFormState,
);

const onDemandFormSelectors = onDemandFormSelectorsCreator(
  planungsobjektWindowFeature.selectOnDemandFormState,
);

/**
 * Wird verwendet um für die EDIT Use Cases das Planungsobjekt zu selektieren und damit die Beziehungen zu .
 * An dieser Stelle wird je nach Use Case das Planungsobjekt aus dem Planungsobjekt- oder OnDemand-Store selektiert.
 * @Bader und @Philip eventuell hier eine bessere Lösung finden, um nicht auf beide Stores zuzugreifen.
 * -> Haben nichts besseres fürs Erste gefunden *shrug*
 */
const selectPlanungsobjektForWindow = createSelector(
  planungsobjektWindowFeature.selectInput,
  planungsobjektFeature.selectEntities,
  onDemandFeature.selectEntities,
  (input, linearEntities, onDemandEntities) => {
    if (!input) return undefined;
    switch (input.usecase) {
      case PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ:
      case PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT:
      case PlanungsobjektWindowUseCase.CREATE_ONDEMAND:
        return undefined;
      case PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ:
      case PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT:
      case PlanungsobjektWindowUseCase.READONLY_LINEAR:
        return linearEntities[input.planungsobjektId];
      case PlanungsobjektWindowUseCase.EDIT_ONDEMAND:
      case PlanungsobjektWindowUseCase.READONLY_ONDEMAND:
        return onDemandEntities[input.planungsobjektId];
      default:
        assertUnreachable(input);
    }
  },
);

const selectInput = createSelector(
  planungsobjektWindowFeature.selectInput,
  selectPlanungsobjektForWindow,
  (input, planungsobjekt): PlanungsobjektWindowInputWithPlanungsobjekt | null => {
    if (input === null) {
      return null;
    }

    if ((<ReadonlyArray<string>>useCasesWithPlanungsobjekt).includes(input.usecase)) {
      if (!planungsobjekt) {
        // @todo logging? should propably not happen...
        return null;
      }

      return <PlanungsobjektWindowInputWithPlanungsobjekt>{
        ...input,
        planungsobjekt,
      };
    }

    return <PlanungsobjektWindowInputWithPlanungsobjekt>{
      ...input,
      planungsobjekt: undefined,
    };
  },
);

const selectPlanungsobjektBeziehungenIds = createSelector(
  selectPlanungsobjektForWindow,
  (planungsobjekt) => planungsobjekt?.beziehungenIds ?? [],
);

const selectHasPlanungsobjektOnDemandId = createSelector(
  selectPlanungsobjektForWindow,
  (planungsobjekt): boolean => !!planungsobjekt?.planungsobjektOnDemandId,
);

const selectKanal = createSelector(selectInput, (input) =>
  input ? extractKanalFromWindowInput(input) : Kanal.UNBEKANNT,
);

const selectPlanungsobjektId = createSelector(
  planungsobjektWindowFeature.selectInput,
  (input) => input?.planungsobjektId,
);

/**
 * Gibt für das aktuelle PlanungsobjektWindow immer den OnDemand-Part einer
 * möglicherweise existierenden LinearOnDemand-Beziehung zurück.
 */
const selectLinearOnDemandBeziehungOnDemandObjekt = createSelector(
  selectPlanungsobjektForWindow,
  onDemandFeature.selectEntities,
  (planungsobjekt, onDemandEntities) => {
    if (!planungsobjekt) return undefined;

    if (PlanungsobjektUtils.isOnDemand(planungsobjekt)) {
      return planungsobjekt;
    }

    const beziehungen = [
      ...planungsobjekt.beziehungenAusgehend,
      ...planungsobjekt.beziehungenEingehend,
    ].filter((beziehung) => beziehung.typ === "LinearOnDemand");

    if (beziehungen.length > 1) {
      throw new Error(
        `Planungsobjekt mit der ID "${planungsobjekt.id}" hat mehr als eine Beziehung vom Typ LinearOnDemand.`,
      );
    }

    const linearOnDemandBeziehung = beziehungen[0];

    if (!linearOnDemandBeziehung) return undefined;

    const otherId =
      linearOnDemandBeziehung.vonId === planungsobjekt.id
        ? linearOnDemandBeziehung.zuId
        : linearOnDemandBeziehung.vonId;

    return onDemandEntities[otherId];
  },
);

const selectOnDemandFormToggleState = createSelector(
  onDemandFormSelectors.selectFormData,
  (form) => {
    return {
      wunschOnlineAbVsRelationalZuLinearToggle: form.wunschOnlineAbVsRelationalZuLinearToggle,
      wunschOnlineBisVsVerweildauerToggle: form.wunschOnlineBisVsVerweildauerToggle,
    };
  },
);

const selectPlanungsbezugFormValue = createSelector(
  planungsFormSelectors.selectFormData,
  (form) =>
    ({
      beginnzeit: form.beginnzeit,
      endzeit: form.endzeit,
      planlaenge: form.planlaenge,
      sendetag: form.sendetag,
      variante: form.variante,
    }) as WannBezugLinearFormData,
);

const selectWannOnDemandFormValue = createSelector(planungsFormSelectors.selectFormData, (form) =>
  PlanungsobjektWindowMapper.mapPlanungFormToWannBezugOnDemandFormData(form),
);

const selectIsVerlinkungTab = createSelector(
  planungsobjektWindowFeature.selectSelectedTab,
  (tab) => tab === PlanungsobjektWindowTabEnum.VERLINKUNG,
);

export default {
  selectInput,
  selectPlanungsobjektForEditWindow: selectPlanungsobjektForWindow,
  selectHasPlanungsobjektOnDemandId,
  selectKanal,
  selectPlanungsobjektId,
  selectPlanungsobjektBeziehungenIds,
  selectLinearOnDemandBeziehungOnDemandObjekt,
  selectIsVerlinkungTab,
  selectWannOnDemandFormValue,
  selectOnDemandFormToggleState,
  planungsForm: {
    selectPlanungsbezugFormValue,
    ...planungsFormSelectors,
  },
  onDemandForm: {
    ...onDemandFormSelectors,
  },
};
