import { createFormGroupState, FormGroupState } from "ngrx-forms";
import { AktionEnum } from "src/app/models/enums/aktion";
import { Ausstrahlung } from "src/app/models/openapi/model/ausstrahlung";
import { Genre } from "src/app/models/openapi/model/genre";
import { MengengeruesteintragDto } from "src/app/models/openapi/model/mengengeruesteintrag-dto";
import { Redaktion } from "tests/common/generated/api";

export type MengengeruestEintragWindowInput =
  | {
      action: AktionEnum.ERSTELLE_MENGENGERUESTEINTRAG;
      ansichtId: string;
      schemaplatzId: string;
      presetBeginnzeit: string | null;
    }
  | {
      action: AktionEnum.BEARBEITE_MENGENGERUESTEINTRAG;
      mengengeruesteintrag: MengengeruesteintragDto;
    };

export type MengengeruesteintragWindowFormData = {
  titel: string;
  redaktion: Redaktion | null;
  genre: Genre;
  beginnzeit: string;
  ausstrahlung: Ausstrahlung | null;
  sendesoll: number;
  laenge: number;
  kommentar: string;
};
export const emptyMengengeruesteintragWindowForm: MengengeruesteintragWindowFormData = {
  titel: "",
  redaktion: null,
  genre: Genre.KEIN_GENRE,
  beginnzeit: "",
  ausstrahlung: null,
  sendesoll: 0,
  laenge: 0,
  kommentar: "",
};

export type MengengeruesteintragWindowFormNgRx = FormGroupState<MengengeruesteintragWindowFormData>;
export const mengengeruesteintragFormId = "MengengeruesteintragWindowForm";
const initialMengengeruesteintragWindowFormState: MengengeruesteintragWindowFormNgRx =
  createFormGroupState(mengengeruesteintragFormId, emptyMengengeruesteintragWindowForm);

export type MengengeruesteintragWindowFeatureState = {
  formState: MengengeruesteintragWindowFormNgRx;
  input: MengengeruestEintragWindowInput | null;
  isOpen: boolean;
};

export const initialMengengeruesteintragWindowFeatureState: MengengeruesteintragWindowFeatureState =
  {
    formState: initialMengengeruesteintragWindowFormState,
    input: null,
    isOpen: false,
  };
