import { CreateMengengeruesteintragCommand } from "src/app/models/openapi/model/create-mengengeruesteintrag-command";
import { UpdateMengengeruesteintragCommand } from "src/app/models/openapi/model/update-mengengeruesteintrag-command";
import { MengengeruesteintragWindowFormData } from "./mengengeruesteintrag-window.model";

const fieldsFromForm = (formValue: MengengeruesteintragWindowFormData) => {
  const { titel, redaktion, genre, ausstrahlung, sendesoll, kommentar, beginnzeit, laenge } =
    formValue;
  return {
    titel,
    redaktion,
    genre,
    ausstrahlung,
    sendesoll,
    kommentar,
    beginnzeit: beginnzeit || null,
    laenge: laenge || null,
  };
};

export const formToCreateCommand = (
  valuesFromInput: {
    ansichtId: string;
    schemaplatzId: string;
  },
  formValue: MengengeruesteintragWindowFormData,
): CreateMengengeruesteintragCommand => {
  return {
    ansichtId: valuesFromInput.ansichtId,
    schemaplatzId: valuesFromInput.schemaplatzId,
    ...fieldsFromForm(formValue),
  };
};

export const formToUpdateCommand = (
  id: string,
  formValue: MengengeruesteintragWindowFormData,
): UpdateMengengeruesteintragCommand => {
  return {
    id,
    ...fieldsFromForm(formValue),
  };
};
