import { inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { mengengeruestWindowActions } from "../core/stores/mengengeruest-window/mengengeruest-window.actions";
import { MengengeruestWindowInput } from "../core/stores/mengengeruest-window/mengengeruest-window.model";
import { CustomWindowRef } from "../shared/dialogs/dialog.model";
import { MengengeruestWindowComponent } from "../shared/windows/mengengeruest-window/mengengeruest-window.component";
import { BigWindow } from "../shared/windows/window.templates";
import { CustomDialogService } from "./custom-dialog.service";
import { CustomWindowService } from "./custom-window.service";

@Injectable({ providedIn: "root" })
export class MengengeruestWindowService {
  private readonly store = inject(Store);
  private customWindowService = inject(CustomWindowService);
  private customDialogService = inject(CustomDialogService);

  openMengengeruesteWindow(
    input: MengengeruestWindowInput,
  ): CustomWindowRef<MengengeruestWindowComponent> {
    const title = `Mengengerüst - ${input.ansichtViewModel.titel} - ${input.ansichtViewModel.year}`;

    const windowRef = this.customWindowService.open<MengengeruestWindowComponent>({
      content: MengengeruestWindowComponent,
      title,
      ...BigWindow(),
      // Verhindert das Schließen der Mengengerüsttabelle, falls der MG-Eintrag Dialog geöffnet ist
      // @todo JS hier mit ngrx ersetzen?
      preventClose: () => {
        this.store.dispatch(mengengeruestWindowActions.tryClosingWithoutSaving());
        return true;
      },
    });

    return windowRef;
  }

  openMengeneruestZuweisungValidationWindow() {
    return this.customDialogService.openMengeneruestZuweisungValidationDialog();
  }
}
