import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { PlanungsobjekteDto } from "src/app/models/openapi/model/planungsobjekte-dto";
import {
  FilterEnum,
  RechercheGridResultColumn,
  RechercheSearchQueryVM,
  ResizedColumn,
} from "./recherche.model";

export const rechercheActions = createActionGroup({
  source: "Recherche",
  events: {
    search: emptyProps(),
    /**
     * Sucht die Kinder eines Planungsobjekts und expanded das Parent-Objekt
     */
    searchChildren: props<{ childrenIds: string[]; parentIds: string[] }>(),
    searchChildrenSuccess: props<{
      results: PlanungsobjekteDto;
      idsWithVarianten: string[];
      isChild: string[];
    }>(),
    collapseParents: props<{ parentIds: string[] }>(),
    searchByBrowserNavigation: props<{
      query: RechercheSearchQueryVM;
      shownColumns: RechercheGridResultColumn[];
    }>(),
    searchReset: emptyProps(),
    searchSuccess: props<{
      results: PlanungsobjekteDto;
      idsWithVarianten: string[];
      isChild: string[];
      isParent: string[];
      query: RechercheSearchQueryVM;
    }>(),
    searchFailure: emptyProps(),
    updateQuery: props<{ query: RechercheSearchQueryVM }>(),
    toggleGridColumns: props<{ columns: RechercheGridResultColumn[] }>(),
    setGridColumns: props<{ columns: RechercheGridResultColumn[] }>(),
    setAdditionalFilters: props<{ filters: FilterEnum[]; previousFilters: FilterEnum[] }>(),
    removeAdditionalFilter: props<{ filter: FilterEnum }>(),
    openRechercheResultDetails: props<{ resultId: string }>(),
    reorderRechercheColumns: props<{ oldIndex: number; newIndex: number }>(),
    resizeGridColumns: props<{ resizedColumns: ResizedColumn[] }>(),
  },
});
