import { Component, inject } from "@angular/core";
import { Observable, map } from "rxjs";
import { FilterEnum } from "src/app/core/stores/recherche/recherche.model";
import { ContentCommunityOptions } from "src/app/models/enums/content-community";
import { GenreOptions } from "src/app/models/enums/genre";
import { KanalOptions } from "src/app/models/enums/kanal";
import { PlanungskontextOptions } from "src/app/models/enums/planungskontext";
import { RedaktionShortOptions } from "src/app/models/enums/redaktion";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { Redaktion } from "src/app/models/openapi/model/redaktion";
import { dateValueConverter } from "src/app/utils/ngrx-forms";
import { RechercheFacade } from "../recherche.facade";

@Component({
  selector: "app-search-form",
  templateUrl: "./search-form.component.html",
  styleUrls: ["./search-form.component.scss"],
})
export class SearchFormComponent {
  public readonly facade = inject(RechercheFacade);

  public readonly dateValueConverter = dateValueConverter;

  activeAdditionalFiltersRecord$: Observable<Record<FilterEnum, boolean>> =
    this.facade.activeAdditionalFilters$.pipe(
      map((activeAdditionalFilters) => {
        return activeAdditionalFilters.reduce(
          (acc, filter) => {
            acc[filter] = true;
            return acc;
          },
          {} as Record<FilterEnum, boolean>,
        );
      }),
    );

  FilterEnum = FilterEnum;

  RedaktionOptions = RedaktionShortOptions.filter((redaktion) => redaktion.value !== Redaktion.NN);
  KanalOptions = KanalOptions.filter(
    (kanal) =>
      kanal.value === Kanal.ZDF ||
      kanal.value === Kanal.ZDF_MEDIATHEK ||
      kanal.value == Kanal.ZDF_NEO,
  );
  GenreOptions = GenreOptions;
  PlanungskontextOptions = PlanungskontextOptions.filter(
    (planungskontext) =>
      planungskontext.value === Planungskontext.VORGEPLANT ||
      planungskontext.value === Planungskontext.VORGESCHLAGEN,
  );
  ContentCommunityOptions = ContentCommunityOptions;

  onClickSearch() {
    this.facade.search();
  }

  onFilterSelectionChange(selectedFilters: FilterEnum[], previousFilters: FilterEnum[]) {
    this.facade.updateAdditionalFilters(selectedFilters, previousFilters);
  }

  onRemoveFilter(value: FilterEnum) {
    this.facade.removeAdditionalFilter(value);
  }
}

// http://localhost:5040/recherche?sendetagBis=2023-02-11&sendetagVon=2023-01-01&kanaele=ZDF&kanaele=ZDFMediathek&redaktionen=CS&highlightsOnly=true&genres=Magazin&planungskontext=Eingeplant&titel=spannend&shownColumns=redaktion&shownColumns=genre&shownColumns=planungskontext&shownColumns=onlineAb&shownColumns=onlineBis
// http://localhost:5040/recherche?sendetagBis=2023-02-11&sendetagVon=2023-01-01&kanaele=ZDF&kanaele=ZDFMediathek&redaktionen=CS&highlightsOnly=true&genres=Magazin&planungskontext=Eingeplant&titel=spannend&shownColumns=redaktion&shownColumns=genre&shownColumns=planungskontext&shownColumns=onlineAb&shownColumns=onlineBis
// http://localhost:5040/recherche?sendetagBis=2023-02-11&sendetagVon=2023-01-01&kanaele=ZDF&kanaele=ZDFMediathek&redaktionen=CS&highlightsOnly=true&genres=Magazin&planungskontext=Eingeplant&titel=spannend&shownColumns=redaktion&shownColumns=genre&shownColumns=planungskontext&shownColumns=onlineAb&shownColumns=onlineBis
