<form [formGroup]="form">
  <app-input-label class="u-w-full" text="Produkt-ID">
    <kendo-textbox data-testid="produkt-id-textbox" minlength="2" [formControl]="form.controls.id">
    </kendo-textbox>
  </app-input-label>
</form>

<button fillMode="outline" kendoButton style="margin: 1rem 0" (click)="recherche()">
  Produkt-DB Recherche
</button>

<div class="window-action-buttons">
  <button fillMode="outline" kendoButton (click)="close()">Abbrechen</button>
  <button
    class="primary-button"
    kendoButton
    themeColor="primary"
    [disabled]="!form.valid"
    (click)="onSave()"
  >
    Bestätigen
  </button>
</div>
