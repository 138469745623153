import { Kanal } from "src/app/models/openapi/model/kanal";

export type PlanungshinweiseWindowInput = {
  von: string;
  bis: string;
  kanal: Kanal;
};

export type PlanungshinweiseWindowFeatureState = {
  input: PlanungshinweiseWindowInput | null;
  isDirty: boolean;
};

export const initialPlanungshinweiseWindowFeatureState: PlanungshinweiseWindowFeatureState = {
  input: null,
  isDirty: false,
};
