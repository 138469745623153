<ng-container
  *ngIf="{
    planenOnSendeplatz: 'planen' | able: (sendeplatz | asSubject: 'Sendeplatz'),
  } as can"
>
  <kendo-chip
    cdkDrag
    class="planungsobjekt-chip"
    rounded="full"
    [attr.data-testid]="'planungsobjekt-' + planungsobjekt.titel"
    [cdkDragData]="planungsobjekt"
    [cdkDragDisabled]="
      ('planen' | able: (sendeplatz | asSubject: 'Sendeplatz')) === false ||
      (planungsobjekt.id | cdkDragDisabled | async)
    "
    [dtsSelectItem]="planungsobjekt"
    [kendoContextMenuTarget]="{ planungsobjekt }"
    [ngClass]="{
      'u-font-style-italic': !istMitProduktdatenbankVerknuepft(planungsobjekt),
    }"
    [ngStyle]="planungsobjekt | planungsobjektStyle | async"
    [title]="planungsobjekt.notiz"
    (click.double)="openPlanungsobjektLinearWithPlanungWindow(planungsobjekt)"
    (click.single)="onClick(planungsobjekt)"
  >
    <!-- Chip beim Verschieben (ggf. mit Badge) -->
    <div *cdkDragPreview class="item-drag-preview float-left">
      <kendo-badge-container>
        <app-planungsobjekt-vorgeplant
          [isMengengeruestErlaubt]="isMengengeruestErlaubt"
          [planungsobjekt]="planungsobjekt"
          [sendeplatz]="sendeplatz"
        ></app-planungsobjekt-vorgeplant>
        <kendo-badge
          *ngIf="planungsobjekteInMehrfachauswahlCount > 1"
          rounded="full"
          size="small"
          >{{ planungsobjekteInMehrfachauswahlCount }}</kendo-badge
        >
      </kendo-badge-container>
    </div>

    <kendo-svg-icon
      *ngIf="!planungsobjekt.mengengeruesteintragId && isMengengeruestErlaubt"
      class="chip-icon icon-xxs"
      title="Mengengerüst-Zuordnung fehlt"
      [icon]="Icons.balance"
    ></kendo-svg-icon>

    <app-abweichende-laenge-badge
      [planungsobjekt]="planungsobjekt"
      [sendeplatz]="sendeplatz"
    ></app-abweichende-laenge-badge>

    {{ planungsobjekt.titel }}

    {{
      planungsobjekt.redaktion && planungsobjekt.redaktion !== Redaktion.KEINE_REDAKTION
        ? " (" + planungsobjekt.redaktion + ")"
        : ""
    }}

    <kendo-svg-icon
      *ngIf="planungsobjekt.highlight"
      class="chip-icon icon-xxs"
      [icon]="Icons.star"
    ></kendo-svg-icon>

    <kendo-svg-icon
      *ngIf="planungsobjekt.planungsobjektOnDemandId"
      class="chip-icon icon-xs"
      title="Online-Publikation verlinkt"
      [attr.data-testid]="planungsobjekt.titel + '-onDemand-verlinkt'"
      [icon]="Icons.onDemandBeziehung"
    ></kendo-svg-icon>
  </kendo-chip>

  <kendo-contextmenu
    showOn="contextmenu"
    [target]="target"
    (popupOpen)="onPlanungsobjektContextMenuOpen($event)"
    (select)="onPlanungsobjektContextMenuSelect($event)"
  >
    <kendo-menu-item
      *ngIf="settings.features.onDemandPlanung"
      text="Details"
      [data]="{ action: AktionEnum.BEARBEITE_PLANUNGSOBJEKT }"
      [svgIcon]="Icons.information"
    ></kendo-menu-item>
    <kendo-menu-item
      *ngIf="isMengengeruestErlaubt"
      text="Mengengerüst zuordnen"
      [data]="{ action: AktionEnum.PLANUNGSOBJEKT_ZU_MENGENGERUESTEINTRAG }"
      [svgIcon]="Icons.balance"
    ></kendo-menu-item>
    <kendo-menu-item
      text="in Serie umwandeln"
      [data]="{ action: AktionEnum.PLANUNGSOBJEKT_IN_SERIE_UMWANDELN }"
      [disabled]="!can.planenOnSendeplatz"
      [svgIcon]="Icons.serie"
    ></kendo-menu-item>
    <kendo-menu-item
      text="Kopie anlegen"
      [data]="{
        action: AktionEnum.KOPIE_HIER,
        copyPattern: CopyPattern.NOW,
      }"
      [disabled]="!can.planenOnSendeplatz"
      [svgIcon]="Icons.copy"
    >
      <kendo-menu-item
        text="diese Woche"
        [data]="{
          action: AktionEnum.KOPIE_HIER,
          copyPattern: CopyPattern.NOW,
        }"
        [svgIcon]="Icons.copyThisWeek"
      ></kendo-menu-item>
      <kendo-menu-item
        text="nächste Woche"
        [data]="{
          action: AktionEnum.KOPIE_NAECHSTE_WOCHE,
          copyPattern: CopyPattern.NEXT_WEEK,
        }"
        [svgIcon]="Icons.copyNextWeek"
      ></kendo-menu-item>
    </kendo-menu-item>
    <kendo-menu-item
      text="Auf Merkliste setzen"
      [data]="{ action: AktionEnum.PLANUNGSOBJEKT_AUF_MERKLISTE }"
      [disabled]="!can.planenOnSendeplatz"
      [svgIcon]="Icons.merklisteIcon"
    ></kendo-menu-item>
  </kendo-contextmenu>
</ng-container>
