import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { filter, map, switchMap, take, tap } from "rxjs";
import { CustomDialogService } from "src/app/services/custom-dialog.service";
import { PlanungsobjektService } from "src/app/services/planungsobjekt.service";
import { allValuesDefined } from "src/app/utils/array-utils";
import { createConfirmClosingEffect, createTrySavingEffect } from "src/app/utils/ngrx-utils";
import { serienWindowActions } from "./serien-window.actions";
import { extractToSerieCommandAction } from "./serien-window.effects.utils";
import { serienWindowFormActions } from "./serien-window.form";
import { serienWindowSelectors } from "./serien-window.selectors";

@Injectable()
export class SerienWindowEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly customDialogService = inject(CustomDialogService);
  private readonly planungsobjektService = inject(PlanungsobjektService);

  openSerienWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(serienWindowActions.openWindow),
      switchMap(() => {
        const windowRef = this.planungsobjektService.openZuSerieUmwandelnWindow();

        return this.actions$.pipe(
          ofType(serienWindowActions.closeWindow),
          take(1),
          tap(() => void windowRef.close()),
          map(() => serienWindowActions.windowClosed()),
        );
      }),
    );
  });

  trySaving$ = createTrySavingEffect({
    trySavingAction: serienWindowFormActions.trySaving,
    formIsValid$: this.store.select(serienWindowSelectors.selectFormIsValid),
    saveAction: serienWindowFormActions.save,
  });

  tryClosingWithoutSaving$ = createConfirmClosingEffect({
    tryClosingAction: serienWindowActions.tryClosingWithoutSaving,
    preventClosingWhen$: this.store.select(serienWindowSelectors.selectFormIsDirty),
    closingAction: serienWindowActions.closeWindow,
  });

  zuSerieUmwandeln$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(serienWindowFormActions.save),
      concatLatestFrom(() => [this.store.select(serienWindowSelectors.selectSerienWindowResult)]),
      filter(allValuesDefined),
      // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
      switchMap(([_, result]) => [
        extractToSerieCommandAction(result.context, result),
        serienWindowActions.closeWindow(),
      ]),
    );
  });
}
