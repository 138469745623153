import { Component, OnInit, computed, input } from "@angular/core";
import { Store } from "@ngrx/store";
import { onDemandBeziehungFormActions } from "src/app/core/stores/planungsobjekt-window/on-demand-form/on-demand-beziehung-form.actions";
import { planungsobjektWindowFeature } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.reducer";
import { Icons } from "src/app/models/icons";
import {
  PlanungsobjektWindowInputWithPlanungsobjekt,
  PlanungsobjektWindowUseCase,
} from "../../planungsobjekt-window.model";

/**
 * Komponente zum Erstellen von OnDemand PlanungsobjektBeziehungen.
 */
@Component({
  selector: "app-on-demand-beziehung-form",
  templateUrl: "./on-demand-beziehung-form.component.html",
  styleUrls: ["./on-demand-beziehung-form.component.scss"],
})
export class OnDemandBeziehungFormComponent implements OnInit {
  input = input.required<PlanungsobjektWindowInputWithPlanungsobjekt>();

  linear = computed(() => {
    const input = this.input();
    return input.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ
      ? input.planungsobjekt
      : undefined;
  });

  onDemandFormState$ = this.store.select(planungsobjektWindowFeature.selectOnDemandFormState);

  Icons = Icons;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(
      onDemandBeziehungFormActions.initialize({
        planungsobjektLinearId: this.input().planungsobjektId,
      }),
    );
  }

  onSave() {
    this.store.dispatch(onDemandBeziehungFormActions.saveLinearOnDemandBeziehung());
  }
}
