import { inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { box, unbox } from "ngrx-forms";
import { take, tap } from "rxjs";
import { rechercheActions } from "src/app/core/stores/recherche/recherche.actions";
import { rechercheFormActions } from "src/app/core/stores/recherche/recherche.form";
import {
  FilterEnum,
  RechercheGridResultColumn,
  RechercheSearchFormData,
  RechercheSearchQueryVM,
  ResizedColumn,
} from "src/app/core/stores/recherche/recherche.model";
import { rechercheFeature } from "src/app/core/stores/recherche/recherche.reducer";
import rechercheSelectors from "src/app/core/stores/recherche/recherche.selectors";
import { BoxedProperties } from "src/app/utils/ngrx-forms";

@Injectable()
export class RechercheFacade {
  private readonly store = inject(Store);

  readonly formState$ = this.store.select(rechercheSelectors.selectSearchFormState);
  readonly formValue$ = this.store.select(rechercheSelectors.selectSearchFormValue);

  readonly isFirstSearch$ = this.store.select(rechercheFeature.selectIsFirstSearch);
  readonly isSearchFormDirty$ = this.store.select(rechercheFeature.selectIsSearchFormDirty);
  readonly searchResults$ = this.store.select(rechercheSelectors.selectSearchResults);
  readonly expandedIds$ = this.store.select(rechercheFeature.selectExpandedIds);
  readonly kanaeleSelected$ = this.store.select(rechercheSelectors.selectKanaeleSelected);
  readonly query$ = this.store.select(rechercheSelectors.selectSearchQueryVM);
  readonly activeAdditionalFilters$ = this.store.select(
    rechercheFeature.selectActiveAdditionalFilters,
  );
  readonly rechercheResultColumns$ = this.store
    .select(rechercheSelectors.selectRechercheResultColumns);

  readonly rechercheShownGridColumnsInOrder$ = this.store.select(
    rechercheSelectors.selectShownGridColumnsSorted,
  );

  public search() {
    this.store.dispatch(rechercheActions.search());
  }

  public patchForm(formValue: Partial<RechercheSearchFormData>) {
    this.store.dispatch(
      rechercheFormActions.patchValue({
        value: formValue,
      }),
    );
  }

  public expandResult(id: string) {
    this.store.dispatch(
      rechercheActions.openRechercheResultDetails({
        resultId: id,
      }),
    );
  }

  public loadChildren(childrenIds: string[], parentId: string | string[]) {
    const parentIds = Array.isArray(parentId) ? parentId : [parentId];

    this.store.dispatch(
      rechercheActions.searchChildren({
        childrenIds,
        parentIds,
      }),
    );
  }

  public collapseResults(id: string | string[]) {
    const parentIds = Array.isArray(id) ? id : [id];
    this.store.dispatch(rechercheActions.collapseParents({ parentIds }));
  }

  public updateQuery(query: RechercheSearchQueryVM) {
    this.store.dispatch(rechercheActions.updateQuery({ query }));
  }

  public updateAdditionalFilters(filters: FilterEnum[], previousFilters: FilterEnum[]) {
    this.store.dispatch(
      rechercheActions.setAdditionalFilters({
        filters,
        previousFilters,
      }),
    );
  }

  public removeFilter(key: BoxedProperties<RechercheSearchFormData>, value: string) {
    this.formValue$.pipe(take(1)).subscribe((formValue) => {
      const filterValues = unbox(formValue[key]);

      if (Array.isArray(filterValues)) {
        const newValue = filterValues.filter((item) => item !== value);
        this.patchForm({
          [key]: box(newValue),
        });
      }
    });
  }

  public removeAdditionalFilter(filter: FilterEnum) {
    this.store.dispatch(rechercheActions.removeAdditionalFilter({ filter }));
  }

  public toggleGridColumns(columns: RechercheGridResultColumn[]) {
    this.store.dispatch(
      rechercheActions.toggleGridColumns({
        columns,
      }),
    );
  }

  public resizeColumn(resizedColumns: ResizedColumn[]) {
    this.store.dispatch(
      rechercheActions.resizeGridColumns({
        resizedColumns,
      }),
    );
  }

  public reoderColumns(oldIndex: number, newIndex: number) {
    this.store.dispatch(
      rechercheActions.reorderRechercheColumns({
        oldIndex,
        newIndex,
      }),
    );
  }
}
