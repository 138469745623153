import { inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { DialogCloseResult } from "@progress/kendo-angular-dialog";
import { combineLatest, filter, map, Observable, switchMap, take, tap, withLatestFrom } from "rxjs";
import { AppAbility } from "src/app/casl/app-ability";
import { asSubject } from "src/app/casl/casl-utils";
import { mengengeruestWindowActions } from "src/app/core/stores/mengengeruest-window/mengengeruest-window.actions";
import {
  MengengeruestWindowComponentResult,
  MengengeruestWindowUseCase,
} from "src/app/core/stores/mengengeruest-window/mengengeruest-window.model";
import { mengengeruestWindowSelectors } from "src/app/core/stores/mengengeruest-window/mengengeruest-window.selectors";
import { mengengeruestActions } from "src/app/core/stores/mengengeruest/mengengeruest.actions";
import { mengengeruestEintragFeature } from "src/app/core/stores/mengengeruest/mengengeruest.reducer";
import mengengeruestSelectors from "src/app/core/stores/mengengeruest/mengengeruest.selectors";
import { mengengeruesteintragWindowActions } from "src/app/core/stores/mengengeruesteintrag-window/mengengeruesteintrag-window.actions";
import { mengengeruesteintragWindowSelectors } from "src/app/core/stores/mengengeruesteintrag-window/mengengeruesteintrag-window.selectors";
import { AktionEnum } from "src/app/models/enums/aktion";
import { MengengeruesteintragDto } from "src/app/models/openapi/model/mengengeruesteintrag-dto";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { MengengeruestViewModel } from "src/app/models/viewmodels/mengengeruest-viewmodel";
import { MengengeruestWindowService } from "src/app/services/mengengeruest-window.service";
import { allValuesDefined } from "src/app/utils/array-utils";

@Injectable()
export class MengengeruestWindowFacade {
  private readonly store = inject(Store);
  private readonly mengengeruestWindowService = inject(MengengeruestWindowService);

  public readonly windowInput$ = this.store.select(mengengeruestWindowSelectors.selectWindowInput);
  public readonly isBlockansicht$ = this.store.select(
    mengengeruestWindowSelectors.selectIsBlockansicht,
  );
  public readonly mengengeruestTables$: Observable<MengengeruestViewModel[]> =
    this.isBlockansicht$.pipe(
      withLatestFrom(this.windowInput$),
      filter(allValuesDefined),
      switchMap(([isBlockansicht, input]) =>
        isBlockansicht
          ? this.store.select(
              mengengeruestSelectors.selectMengengeruestViewModelsByBeginnzeit(
                input.ansichtViewModel,
              ),
            )
          : this.store.select(
              mengengeruestSelectors.selectMengengeruestViewModels(input.ansichtViewModel),
            ),
      ),
    );
  public readonly isMengengeruestZuweisung$ = this.windowInput$.pipe(
    map((input) => input?.type !== MengengeruestWindowUseCase.MAIN_WINDOW),
  );
  public readonly initialMengengeruesteintragId$ = this.store.select(
    mengengeruestWindowSelectors.selectInitialMengengeruesteintragId,
  );
  public readonly activeMengengeruesteintragId$ = this.store.select(
    mengengeruestWindowSelectors.selectActiveMengengeruesteintragId,
  );
  public readonly mengengeruestChanged$ = this.store.select(
    mengengeruestWindowSelectors.selectMengengeruestChanged,
  );

  public readonly mengengeruesteintraege$ = this.store.select(
    mengengeruestEintragFeature.selectAll,
  );
  public readonly mengengeruesteintragWindowOpen$ = this.store.select(
    mengengeruesteintragWindowSelectors.selectIsOpen,
  );
  public readonly can$ = this.windowInput$.pipe(
    map((input) => {
      if (!input) {
        return {
          mengengeruestZuweisen: false,
          mengengeruestEditieren: false,
        };
      }

      const sendeplatz =
        input.type === MengengeruestWindowUseCase.ZUWEISUNG_SENDEPLATZ
          ? input.sendeplatz
          : undefined;

      const schemaplatz =
        input.type !== MengengeruestWindowUseCase.MAIN_WINDOW ? input.schemaplatz : undefined;

      const mengengeruest = { kanal: input.ansichtViewModel.kanal };

      return {
        mengengeruestZuweisen:
          this.ability.can("planen", asSubject("Sendeplatz", sendeplatz)) ||
          this.ability.can("planen", asSubject("Schemaplatz", schemaplatz)),
        mengengeruestEditieren: this.ability.can(
          "editieren",
          asSubject("Mengengeruest", mengengeruest),
        ),
      };
    }),
  );

  public mengengeruesteintragErstellen(mengengeruestViewModel: MengengeruestViewModel) {
    if (!mengengeruestViewModel.schemaplatz) {
      throw Error(
        "Es kann kein Mengengerüsteintrag erstellt werden, wenn kein Schemaplatz existiert.",
      );
    }

    // Nur wenn alle einzigartigen Beginnzeiten identisch sind setzen wir einen Preset.
    const commonBeginnzeit = [
      ...new Set(
        mengengeruestViewModel.mengengeruesteintraege.map((eintrag) => eintrag.beginnzeit ?? null),
      ),
    ];
    const presetBeginnzeit = commonBeginnzeit.length <= 1 ? commonBeginnzeit[0] : null;

    this.windowInput$.pipe(take(1)).subscribe((input) => {
      if (!input) return;
      this.store.dispatch(
        mengengeruesteintragWindowActions.openWindow({
          input: {
            action: AktionEnum.ERSTELLE_MENGENGERUESTEINTRAG,
            ansichtId: input.ansichtViewModel.id,
            schemaplatzId: mengengeruestViewModel.schemaplatz.id,
            presetBeginnzeit,
          },
        }),
      );
    });
  }

  public setActiveMengengeruesteintragId(mengengeruesteintragId: string | null) {
    this.store.dispatch(
      mengengeruestWindowActions.setActiveMengengeruesteintragId({ mengengeruesteintragId }),
    );
  }

  public mengengeruesteintragBearbeiten(mengengeruesteintrag: MengengeruesteintragDto) {
    this.store.dispatch(
      mengengeruesteintragWindowActions.openWindow({
        input: {
          action: AktionEnum.BEARBEITE_MENGENGERUESTEINTRAG,
          mengengeruesteintrag,
        },
      }),
    );
  }

  public mengengeruesteintragLoeschen(mengengeruesteintrag: MengengeruesteintragDto) {
    this.store.dispatch(
      mengengeruestWindowActions.openDeleteMengengeruesteintragDialog({
        mengengeruesteintrag,
      }),
    );
  }

  public changeSendeplan(mengengeruestEintrag: MengengeruesteintragDto, sendeplan: number) {
    this.store.dispatch(
      mengengeruestActions.updateMengengeruestEintragSuccess({
        mengengeruestEintrag: { ...mengengeruestEintrag, sendeplan },
      }),
    );
  }

  public save(mengengeruesteintragId: string | null) {
    combineLatest([
      this.windowInput$,
      this.mengengeruesteintraege$,
      this.initialMengengeruesteintragId$,
    ])
      .pipe(take(1))
      .subscribe(([input, mengengeruesteintraege, initialMengengeruesteintragId]) => {
        if (!input) return;

        if (input.type === MengengeruestWindowUseCase.MAIN_WINDOW) {
          return;
        }

        const newPlanungsobjekt: PlanungsobjektDto = {
          ...input.planungsobjekt,
          mengengeruesteintragId,
        };

        const mengengeruestWindowResult: MengengeruestWindowComponentResult =
          input.type === MengengeruestWindowUseCase.ZUWEISUNG_SENDEPLATZ
            ? {
                zugewiesenePlanungsobjekt: newPlanungsobjekt,
                previousMengengeruestId: initialMengengeruesteintragId,
                type: input.type,
                sendeplatz: input.sendeplatz,
              }
            : {
                zugewiesenePlanungsobjekt: newPlanungsobjekt,
                previousMengengeruestId: initialMengengeruesteintragId,
                type: input.type,
              };

        if (
          isDifferentSchemaplatz(
            mengengeruesteintraege,
            mengengeruesteintragId,
            input.schemaplatz.id,
          )
        ) {
          this.validateBeforeCommitZuweisung(mengengeruestWindowResult);
        } else {
          this.commitZuweisung(mengengeruestWindowResult);
        }
      });
  }

  private validateBeforeCommitZuweisung(
    mengengeruestWindowResult: MengengeruestWindowComponentResult,
  ) {
    // Wenn der Schemaplatz des Sendeplatzes/Planungsobjekt nicht dem Schemaplatz des Mengengerüstes entspricht, dann öffne das Validierungsfenster
    this.mengengeruestWindowService
      .openMengeneruestZuweisungValidationWindow()
      .result.pipe(
        tap((windowResult) => {
          if (windowResult instanceof DialogCloseResult) return;

          this.commitZuweisung(mengengeruestWindowResult);
        }),
        take(1),
      )
      .subscribe();
  }

  private commitZuweisung(mengengeruestWindowResult: MengengeruestWindowComponentResult) {
    this.store.dispatch(
      mengengeruestWindowActions.handleMengengeruestWindowResult({
        result: mengengeruestWindowResult,
      }),
    );
    this.store.dispatch(mengengeruestWindowActions.closeWindow());
  }

  public closeWindow() {
    this.store.dispatch(mengengeruestWindowActions.tryClosingWithoutSaving());
  }

  constructor(private readonly ability: AppAbility) {}
}

const isDifferentSchemaplatz = (
  mengengeruesteintraege: MengengeruesteintragDto[],
  mengengeruesteintragId: string | null,
  schemaplatzId: string,
) => {
  return (
    mengengeruesteintragId &&
    mengengeruesteintraege.find((eintrag) => eintrag.id === mengengeruesteintragId)
      ?.schemaplatzId !== schemaplatzId
  );
};
