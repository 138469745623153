<ng-container
  *ngIf="{
    can: {
      planen: facade.canPlanen$ | async,
    },
    planungsobjekt: planungsobjekt$ | async,
    formState: facade.formState$ | async,
    publikationsart: publikationsart$ | async,
    kanal: kanal$ | async,
    selectedTab: selectedTab$ | async,
    isPlanungFormDirty: isPlanungFormDirty$ | async,
    input: input$ | async,
  } as vm"
>
  <app-loading-overlay [showAfterMs]="500"></app-loading-overlay>
  <form *ngIf="vm.formState" class="container k-flex-1" [ngrxFormState]="vm.formState">
    <kendo-tabstrip
      class="k-flex-1"
      [keepTabContent]="true"
      (tabSelect)="onTabSelect($event, vm.formState)"
    >
      <kendo-tabstrip-tab
        [selected]="vm.selectedTab === PlanungsobjektWindowTabEnum.PLANUNG"
        [title]="PlanungsobjektWindowTabEnum.PLANUNG"
      >
        <ng-template kendoTabTitle>
          <div class="kendo-tab-title" data-testid="planung-tab" title="Planung">Planung</div>
        </ng-template>
        <ng-template kendoTabContent>
          <app-planung-tab
            [kanal]="vm.kanal"
            [planungskontext]="vm.input?.planungskontext"
            [planungsobjekt]="vm.planungsobjekt"
            [publikationsart]="vm.publikationsart"
            [usecase]="vm.input?.usecase"
          ></app-planung-tab>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab
        [selected]="vm.selectedTab === PlanungsobjektWindowTabEnum.VERLINKUNG"
        [title]="PlanungsobjektWindowTabEnum.VERLINKUNG"
      >
        <ng-template kendoTabTitle>
          <div class="kendo-tab-title" data-testid="verlinkung-tab" title="Verlinkung">
            Verlinkung
          </div>
        </ng-template>
        <ng-template
          kendoTabContent
          *ngIf="vm.selectedTab === PlanungsobjektWindowTabEnum.VERLINKUNG"
        >
          <app-verlinkung-tab
            [abhaengigkeiten]="abhaengigkeiten$ | async"
            [currentPlanungsobjekt]="currentPlanungsobjekt$ | async"
            [hasPlanungsobjektOnDemandId]="hasPlanungsobjektOnDemandId$ | async"
            [input]="vm.input"
            [linearOnDemandBeziehungOnDemandObjekt]="linearOnDemandBeziehungOnDemandObjekt$ | async"
            [onDemandBeziehungen]="onDemandBeziehungen$ | async"
            [readOnly]="
              vm.input?.usecase === PlanungsobjektWindowUseCase.READONLY_LINEAR ||
              vm.input?.usecase === PlanungsobjektWindowUseCase.READONLY_ONDEMAND
            "
            [reihenfolgen]="reihenfolgen$ | async"
            (deleteBeziehung)="onDeleteBeziehung($event)"
          >
          </app-verlinkung-tab>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab
        title="Aktivitäten"
        [selected]="vm.selectedTab === PlanungsobjektWindowTabEnum.AKTIVITAETEN"
      >
        <ng-template kendoTabTitle>
          <div class="kendo-tab-title" data-testid="aktivitaeten-tab" title="Aktivitäten">
            Aktivitäten
          </div>
        </ng-template>
        <ng-template
          kendoTabContent
          *ngIf="vm.selectedTab === PlanungsobjektWindowTabEnum.AKTIVITAETEN"
        >
          <app-aktivitaeten-tab
            [aktivitaeten]="aktivitaeten$ | async"
            (loadAktivitaetenForPlanungsobjekt)="onLoadAktivitaetenForPlanungsobjekt()"
          >
          </app-aktivitaeten-tab>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </form>

  <div class="window-action-buttons">
    <button
      data-testid="planungsobjekt-abbrechen"
      fillMode="outline"
      kendoButton
      type="button"
      (click)="onClose()"
    >
      Abbrechen
    </button>
    <button
      *ngIf="vm.selectedTab === PlanungsobjektWindowTabEnum.PLANUNG"
      data-testid="planungsobjekt-löschen"
      fillMode="outline"
      kendoButton
      type="button"
      [disabled]="!vm.can.planen"
      (click)="onDelete()"
    >
      Löschen
    </button>
    <button
      *ngIf="vm.selectedTab === PlanungsobjektWindowTabEnum.PLANUNG"
      class="primary-button"
      data-testid="planungsobjekt-speichern-und-schließen"
      fillMode="outline"
      kendoButton
      type="button"
      [disabled]="!vm.isPlanungFormDirty || !vm.can.planen"
      (click)="onSaveAndClose()"
    >
      Speichern und Schließen
    </button>
    <button
      *ngIf="vm.selectedTab === PlanungsobjektWindowTabEnum.PLANUNG"
      class="primary-button"
      data-testid="planungsobjekt-speichern"
      kendoButton
      themeColor="primary"
      type="button"
      [disabled]="!vm.isPlanungFormDirty || !vm.can.planen"
      (click)="onSave()"
    >
      Speichern
    </button>
  </div>
</ng-container>
