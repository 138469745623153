import { createFeature, createReducer, on } from "@ngrx/store";
import { onNgrxForms } from "ngrx-forms";
import { mergeReducers } from "src/app/utils/ngrx-utils";
import { onDemandBeziehungFormActions } from "./on-demand-form/on-demand-beziehung-form.actions";
import { onDemandFormCustomActionReducer } from "./on-demand-form/on-demand.form";
import { planungsobjektFormCustomActionReducer } from "./planungsobjekt-form/planungsobjekt-window.form";
import { planungsobjektWindowWannWoActions } from "./planungsobjekt-window-wann-wo.actions";
import { planungsobjektWindowActions } from "./planungsobjekt-window.actions";
import {
  PlanungsobjektWindowState,
  initialPlanungsobjektWindowState,
} from "./planungsobjekt-window.model";

const planungsobjektWindowFeatureReducer = createReducer(
  initialPlanungsobjektWindowState,
  on(
    planungsobjektWindowActions.openPlanungsobjektWindow,
    (state, { input }): PlanungsobjektWindowState => ({
      ...initialPlanungsobjektWindowState,
      input,
      varianten: [],
    }),
  ),
  on(
    planungsobjektWindowActions.transitionToEditWindow,
    (state, { input }): PlanungsobjektWindowState => ({
      ...state,
      input,
    }),
  ),
  on(
    planungsobjektWindowActions.changePlanungsobjektWindowTab,
    (state, { newTab }): PlanungsobjektWindowState => ({ ...state, selectedTab: newTab }),
  ),
  on(
    onDemandBeziehungFormActions.resetOnDemandBeziehungFormValues,
    (state): PlanungsobjektWindowState => ({
      ...state,
      onDemandFormState: initialPlanungsobjektWindowState.onDemandFormState,
    }),
  ),
  on(
    planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilenDebounced,
    (state): PlanungsobjektWindowState => ({
      ...state,
      loadingVariants: true,
    }),
  ),
  on(
    planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilenSuccess,
    (state, { variantenzeilen }): PlanungsobjektWindowState => ({
      ...state,
      varianten: variantenzeilen,
      loadingVariants: false,
    }),
  ),
  on(
    planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilenFailure,
    (state): PlanungsobjektWindowState => ({
      ...state,
      loadingVariants: false,
    }),
  ),
  // Sollte nicht vorkommen
  on(
    onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenErstellenFailure,
    onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenAktualisierenFailure,
    onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktErstellenFailure,
    onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktAktualisierenFailure,
    (state): PlanungsobjektWindowState => {
      throw new Error(
        "LinearOnDemand-Beziehung konnte nicht erstellt/aktualisiert werden. Wurde versucht beim Anlegen des Planungsobjekts die Beziehung zu erstellen/anzupassen? ",
      );
    },
  ),
  onNgrxForms(),
);

const planungsobjektWindowReducer = mergeReducers(
  planungsobjektWindowFeatureReducer,
  planungsobjektFormCustomActionReducer,
  onDemandFormCustomActionReducer,
);

export const planungsobjektWindowFeature = createFeature({
  name: "Planungsobjekt Window",
  reducer: planungsobjektWindowReducer,
});
