import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PlanungsobjektOnDemandApiService } from "../api/planungsobjekt/planungsobjekt-on-demand.api.service";
import { AktualisierenPlanungsobjekteOnDemandPlanungskontextCommand } from "../models/openapi/model/aktualisieren-planungsobjekte-on-demand-planungskontext-command";
import { GetPlanungsobjekteOnDemandInZeitraumMitVerknuepftenLinearenQuery } from "../models/openapi/model/get-planungsobjekte-on-demand-in-zeitraum-mit-verknuepften-linearen-query";
import { PlanungsobjektOnDemandVorgemerktAktualisierenCommand } from "../models/openapi/model/planungsobjekt-on-demand-vorgemerkt-aktualisieren-command";
import { PlanungsobjektOnDemandVorgeplantAktualisierenCommand } from "../models/openapi/model/planungsobjekt-on-demand-vorgeplant-aktualisieren-command";
import { PlanungsobjektOnDemandVorgeplantErstellenCommand } from "../models/openapi/model/planungsobjekt-on-demand-vorgeplant-erstellen-command";
import { PlanungsobjektOnDemandVorgeschlagenAktualisierenCommand } from "../models/openapi/model/planungsobjekt-on-demand-vorgeschlagen-aktualisieren-command";
import { PlanungsobjektOnDemandVorgeschlagenErstellenCommand } from "../models/openapi/model/planungsobjekt-on-demand-vorgeschlagen-erstellen-command";
import { PlanungsobjekteDto } from "../models/openapi/model/planungsobjekte-dto";

@Injectable({
  providedIn: "root",
})
export class OnDemandService {
  constructor(private onDemandApiService: PlanungsobjektOnDemandApiService) {}

  getOnDemandById$(onDemandId: string) {
    return this.onDemandApiService.getOnDemandById$(onDemandId);
  }

  getOnDemandInZeitraumMitVerknuepftenLinearen$(query: {
    von: string;
    bis: string;
  }): Observable<PlanungsobjekteDto> {
    const von = query.von.split("T")[0];
    const bis = query.bis.split("T")[0];
    const _query: GetPlanungsobjekteOnDemandInZeitraumMitVerknuepftenLinearenQuery = {
      von,
      bis,
    };
    return this.onDemandApiService.getOnDemandInZeitraumMitVerknuepftenLinearen$(_query);
  }

  aktualisierenPlanungsobjekteOnDemandPlanungskontext$(
    command: AktualisierenPlanungsobjekteOnDemandPlanungskontextCommand,
  ) {
    return this.onDemandApiService.aktualisierenPlanungsobjekteOnDemandPlanungskontext$(command);
  }

  planungsobjektOnDemandVorgeplantErstellen$(
    command: PlanungsobjektOnDemandVorgeplantErstellenCommand,
  ) {
    return this.onDemandApiService.planungsobjektOnDemandVorgeplantErstellen$(command);
  }

  planungsobjektOnDemandVorgeplantAktualisieren$(
    command: PlanungsobjektOnDemandVorgeplantAktualisierenCommand,
  ) {
    return this.onDemandApiService.planungsobjektOnDemandVorgeplantAktualisieren$(command);
  }

  planungsobjektOnDemandVorgeschlagenErstellen$(
    command: PlanungsobjektOnDemandVorgeschlagenErstellenCommand,
  ) {
    return this.onDemandApiService.planungsobjektOnDemandVorgeschlagenErstellen$(command);
  }

  planungsobjektOnDemandVorgeschlagenAktualisieren$(
    command: PlanungsobjektOnDemandVorgeschlagenAktualisierenCommand,
  ) {
    return this.onDemandApiService.planungsobjektOnDemandVorgeschlagenAktualisieren$(command);
  }

  planungsobjektOnDemandVorgemerktAktualisieren$(
    command: PlanungsobjektOnDemandVorgemerktAktualisierenCommand,
  ) {
    return this.onDemandApiService.planungsobjektOnDemandVorgemerktAktualisieren$(command);
  }
}
