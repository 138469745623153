import { wrapReducerWithFormStateUpdate } from "ngrx-forms";
import { maxLength, minLength, pattern, required } from "ngrx-forms/validation";
import { DurationUtils } from "src/app/utils/duration-utils";
import {
  ValidationMap,
  createReducerAndCustomFormActions,
  createValidationRules,
} from "src/app/utils/ngrx-forms";
import {
  PharosExportWindowFeatureState,
  PharosExportWindowFormData,
  PharosWindowInput,
  initialPharosExportWindowFeatureState,
  pharosExportFormId,
} from "./pharos-export-window.model";

const pharosExportWindowFormValidationRules = (
  form: PharosExportWindowFormData,
): ValidationMap<PharosExportWindowFormData> => {
  return {
    planzeit: [required],
    laenge: [required],
    sendetitel: [required, minLength(2), maxLength(60), pattern(/[\S]/)],
    redaktion: [required, pattern(/[^NN]/)],
    folgenNrInfo: [maxLength(40)],
    dachzeile: [maxLength(60)],
  };
};

const { reducer, actions, selectors } = createReducerAndCustomFormActions<
  PharosExportWindowFeatureState,
  "formState"
>(initialPharosExportWindowFeatureState, pharosExportFormId, "formState");

export const pharosExportWindowFormSelectors = selectors;
export const pharosExportWindowFormActions = actions;

export const pharosExportWindowFormReducer = wrapReducerWithFormStateUpdate(
  reducer,
  (s) => s.formState,
  createValidationRules(pharosExportWindowFormValidationRules),
);

export const calculateInitialFormStateFromInput = ({
  pharosContent: { fromPublit, fromPharos },
}: PharosWindowInput): PharosExportWindowFormData => {
  // Inhalte aus Publit
  const { planzeit, sendetitel, redaktion } = fromPublit;

  // Inhalte aus Pharos
  const { dachzeile } = fromPharos;

  // Inhalte aus Publit oder Pharos
  const folgenNrInfo = fromPublit.folgenNrInfo ?? fromPharos.folgenNrInfo;

  const pharosLaenge = fromPharos.laenge;
  const publitLaenge = fromPublit.laenge;
  const laenge = DurationUtils.isOtherDurationStringLessOrEqualSecondsAwayFromDurationString(
    pharosLaenge,
    publitLaenge,
    5 * 60,
  )
    ? pharosLaenge
    : publitLaenge;

  return {
    planzeit,
    laenge,
    sendetitel,
    redaktion,
    folgenNrInfo,
    dachzeile,
  };
};
