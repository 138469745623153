<div class="k-d-flex-col">
  <div
    *ngIf="currentPlanungsobjekt"
    class="k-p-3 planungsobjekt-informationen-zeile"
    data-testid="verlinkung-details"
  >
    "{{ currentPlanungsobjekt.titel }}"
    {{ currentPlanungsobjekt.publikationsplanung?.sendetag }}
    {{ currentPlanungsobjekt.publikationsplanung?.beginnzeit }} {{ currentPlanungsobjekt.kanal }}
    {{ currentPlanungsobjekt.planungskontext }}
  </div>

  <div
    *ngIf="!currentPlanungsobjekt"
    class="k-p-3 planungsobjekt-informationen-zeile"
    data-testid="no-planungsobjekt-for-verlinkung"
  >
    Kein Planungsobjekt vorhanden
  </div>

  <!-- Verlinkungstabelle -->
  <app-planungsobjekt-beziehungen
    *ngIf="currentPlanungsobjekt"
    [abhaengigkeiten]="abhaengigkeiten"
    [hasPlanungsobjektOnDemandId]="hasPlanungsobjektOnDemandId"
    [linearOnDemandBeziehungOnDemandObjekt]="linearOnDemandBeziehungOnDemandObjekt"
    [onDemandBeziehungen]="onDemandBeziehungen"
    [readOnly]="readOnly"
    [reihenfolgen]="reihenfolgen"
    [showOnDemandBeziehungForm]="showOnDemandBeziehung"
    [usecase]="input.usecase"
    (deleteBeziehung)="deleteBeziehung.emit($event)"
    (toggleOnDemandBeziehungForm)="onToggleOnDemandBeziehungForm()"
    (updateOnDemandBeziehung)="onToggleOnDemandBeziehungForm()"
  ></app-planungsobjekt-beziehungen>

  <!-- On Demand Formular -->

  <app-on-demand-beziehung-form
    *ngIf="
      showOnDemandBeziehung &&
      (input.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ ||
        input.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT ||
        input.usecase === PlanungsobjektWindowUseCase.EDIT_ONDEMAND)
    "
    [input]="input"
  >
  </app-on-demand-beziehung-form>
</div>
