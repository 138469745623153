import { createFeature, createReducer, on } from "@ngrx/store";
import { onNgrxForms } from "ngrx-forms";
import { mergeReducers } from "src/app/utils/ngrx-utils";
import { sendeplatzWindowActions } from "./sendeplatz-window.actions";
import { sendeplatzWindowFormReducer } from "./sendeplatz-window.form";
import {
  SendePlatzWindowFeatureState,
  initialSendePlatzWindowFeatureState,
} from "./sendeplatz-window.model";

const sendePlatzWindowReducer = createReducer(
  initialSendePlatzWindowFeatureState,
  on(
    sendeplatzWindowActions.openWindow,
    (state, { input: sendeplatz }): SendePlatzWindowFeatureState => ({
      ...initialSendePlatzWindowFeatureState,
      windowInput: { sendeplatz },
    }),
  ),
  onNgrxForms(),
);

const reducer = mergeReducers(sendePlatzWindowReducer, sendeplatzWindowFormReducer);

export const sendeplatzWindowFeature = createFeature({
  name: "sendeplatzWindow",
  reducer,
});
