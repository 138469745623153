import { Component, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { SelectEvent } from "@progress/kendo-angular-layout";
import { FormGroupState } from "ngrx-forms";
import aktivitaetenSelectors from "src/app/core/stores/aktivitaeten/aktivitaeten.selectors";
import beziehungSelectors from "src/app/core/stores/beziehung/beziehung.selectors";
import { PlanungsobjektWindowPlanungFormNgRx } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-form/planungsobjekt-window.form.model";
import planungsobjektWindowWannWoSelectors from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window-wann-wo.selectors";
import { PlanungsobjektWindowTabEnum } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.model";
import { planungsobjektWindowFeature } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.reducer";
import planungsobjektWindowSelectors from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.selectors";
import { BeziehungDto } from "src/app/models/openapi/model/beziehung-dto";
import { Role } from "src/app/models/openapi/model/role";
import { stringToEnum } from "src/app/utils/enum-utils";
import { PublitFrontendSettings } from "src/environments/environment";
import { PlanungsobjektWindowFacade } from "./planungsobjekt-window.facade";
import { PlanungsobjektWindowUseCase } from "./planungsobjekt-window.model";

@Component({
  selector: "app-planungsobjekt-window",
  templateUrl: "./planungsobjekt-window.component.html",
  styleUrls: ["./planungsobjekt-window.component.scss"],
  providers: [PlanungsobjektWindowFacade],
})
export class PlanungsobjektWindowComponent {
  public readonly facade = inject(PlanungsobjektWindowFacade);

  readonly input$ = this.facade.input$;

  readonly planungsobjekt$ = this.facade.planungsobjekt$;

  readonly Role = Role;
  readonly PlanungsobjektWindowTabEnum = PlanungsobjektWindowTabEnum;
  readonly PlanungsobjektWindowUseCase = PlanungsobjektWindowUseCase;

  readonly kanal$ = this.store.select(planungsobjektWindowSelectors.selectKanal);

  readonly publikationsart$ = this.store.select(
    planungsobjektWindowWannWoSelectors.selectPublikationsart,
  );

  readonly currentPlanungsobjekt$ = this.store.select(
    planungsobjektWindowSelectors.selectPlanungsobjektForEditWindow,
  );

  protected readonly selectedTab$ = this.store.select(
    planungsobjektWindowFeature.selectSelectedTab,
  );
  protected readonly hasPlanungsobjektOnDemandId$ = this.store.select(
    planungsobjektWindowSelectors.selectHasPlanungsobjektOnDemandId,
  );
  protected readonly reihenfolgen$ = this.store.select(
    beziehungSelectors.selectReihenfolgeByPlanungsobjekt,
  );
  protected readonly abhaengigkeiten$ = this.store.select(
    beziehungSelectors.selectAbhaengigkeitenByPlanungsobjekt,
  );
  protected readonly onDemandBeziehungen$ = this.store.select(
    beziehungSelectors.selectLinearOnDemandBeziehungByPlanungsobjekt,
  );
  protected readonly linearOnDemandBeziehungOnDemandObjekt$ = this.store.select(
    planungsobjektWindowSelectors.selectLinearOnDemandBeziehungOnDemandObjekt,
  );
  protected readonly aktivitaeten$ = this.store.select(
    aktivitaetenSelectors.selectAktivitaetenByPlanungsobjektInEditDialog,
  );
  protected readonly isPlanungFormDirty$ = this.store.select(
    planungsobjektWindowSelectors.planungsForm.selectFormIsDirty,
  );

  constructor(
    private store: Store,
    protected settings: PublitFrontendSettings,
  ) {}

  onSaveAndClose() {
    this.facade.saveAndClose();
  }

  onClose() {
    this.facade.tryCloseWindow();
  }

  onDelete() {
    this.facade.deletePlanungsobjekt();
  }

  onSave() {
    this.facade.save();
  }

  onTabSelect(event: SelectEvent, form: FormGroupState<PlanungsobjektWindowPlanungFormNgRx>) {
    const clickedTab = stringToEnum(event.title, PlanungsobjektWindowTabEnum);
    if (!clickedTab) {
      throw new Error(`${event.title} existiert nicht im Enum PlanungsobjektWindowTabEnum`);
    }

    const isFormValid = form.isValid;

    // wechseln des Tabs immer verhindern und stattdessen durch Effect steuern,
    // der als Resultat `selectedTab` im Store aktualisiert oder auch nicht
    event.preventDefault();
    this.facade.tryChangeTab(clickedTab, isFormValid);
  }

  onDeleteBeziehung(beziehung: BeziehungDto) {
    this.facade.deleteBeziehung(beziehung);
  }

  onLoadAktivitaetenForPlanungsobjekt() {
    this.facade.loadAktivitaetenForPlanungsobjekt();
  }
}
