import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { combineLatest, filter, take } from "rxjs";
import {
  MengengeruestWindowInput,
  MengengeruestWindowUseCase,
} from "src/app/core/stores/mengengeruest-window/mengengeruest-window.model";
import { GenreRecord } from "src/app/models/enums/genre";
import { Icons } from "src/app/models/icons";
import { MengengeruesteintragDto } from "src/app/models/openapi/model/mengengeruesteintrag-dto";
import { MengengeruestWindowFacade } from "./mengengeruest-window.facade";

@Component({
  selector: "app-mengengeruest-window",
  templateUrl: "./mengengeruest-window.component.html",
  styleUrls: ["./mengengeruest-window.component.scss"],
  providers: [MengengeruestWindowFacade],
})
export class MengengeruestWindowComponent implements OnInit, AfterViewInit {
  public readonly facade = inject(MengengeruestWindowFacade);
  private readonly destroyRef$ = inject(DestroyRef);
  mengengeruestTables$ = this.facade.mengengeruestTables$;
  mengengeruesteintraege$ = this.facade.mengengeruesteintraege$;

  GenreRecord = GenreRecord;
  Icons = Icons;
  MengengeruestWindowUseCase = MengengeruestWindowUseCase;

  @ViewChildren("mengengeruest") mengengerueste: QueryList<ElementRef<HTMLDivElement>>;

  ngOnInit(): void {
    this.facade.windowInput$.pipe(take(1)).subscribe((input) => {
      if (!input) return;

      if (input.type !== MengengeruestWindowUseCase.MAIN_WINDOW) {
        this.facade.setActiveMengengeruesteintragId(input.planungsobjekt.mengengeruesteintragId);
      }
    });
  }

  ngAfterViewInit(): void {
    // Scrollt zum Mengengerüst des Schemaplatzes
    // mit Hilfe des Sendeplatzes
    this.facade.windowInput$
      .pipe(
        take(1),
        filter(
          (
            input,
          ): input is MengengeruestWindowInput & {
            type: MengengeruestWindowUseCase.ZUWEISUNG_SENDEPLATZ;
          } => !!input && input.type === MengengeruestWindowUseCase.ZUWEISUNG_SENDEPLATZ,
        ),
      )
      .subscribe((input) => {
        const schemaPlatzId = input.schemaplatz.id;
        const mengengeruestElement = this.mengengerueste.find(
          (el) => el.nativeElement.id === schemaPlatzId,
        );

        if (mengengeruestElement) {
          mengengeruestElement.nativeElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      });
  }

  onClose(): void {
    this.facade.closeWindow();
  }

  onSaveZuweisung() {
    combineLatest([this.facade.mengengeruestChanged$, this.facade.activeMengengeruesteintragId$])
      .pipe(take(1))
      .subscribe(([mengengeruestChanged, activeMengengeruesteintragId]) => {
        if (!mengengeruestChanged) {
          this.facade.closeWindow();
          return;
        }

        this.facade.save(activeMengengeruesteintragId);
      });
  }

  updateSendeplanCounter(
    eintrag: MengengeruesteintragDto,
    allMengengeruestEintraege: MengengeruesteintragDto[],
  ) {
    this.facade.activeMengengeruesteintragId$
      .pipe(take(1))
      .subscribe((activeMengengeruesteintragId) => {
        // Nutzer klickt auf ein bereits selektieren MG-Eintrag
        if (activeMengengeruesteintragId === eintrag.id) {
          this.facade.setActiveMengengeruesteintragId(null);
          this.facade.changeSendeplan(eintrag, eintrag.sendeplan - 1);
        }

        // Es existiert kein selektierter MG-Eintrag oder ein anderer MG-Eintrag wurde selektiert
        else if (!activeMengengeruesteintragId || activeMengengeruesteintragId !== eintrag.id) {
          this.facade.changeSendeplan(eintrag, eintrag.sendeplan + 1);
          this.facade.setActiveMengengeruesteintragId(eintrag.id);
        }

        // Es existierte ein selektierter MG-Eintrag, dieser wird wieder dekrementiert (ein anderer wurde selektiert)
        else if (activeMengengeruesteintragId) {
          const lastSelectedMengengeruesteintrag = allMengengeruestEintraege.find(
            (eintrag) => eintrag.id === activeMengengeruesteintragId,
          );
          if (lastSelectedMengengeruesteintrag) {
            this.facade.changeSendeplan(
              lastSelectedMengengeruesteintrag,
              lastSelectedMengengeruesteintrag.sendeplan - 1,
            );
          }
          this.facade.setActiveMengengeruesteintragId(eintrag.id);
        }
      });
  }
}
