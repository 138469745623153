/* eslint-disable @ngrx/no-multiple-actions-in-effects */
import { inject, Injectable } from "@angular/core";
import { PureAbility } from "@casl/ability";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Action, Store } from "@ngrx/store";
import { EMPTY, filter, map, switchMap, tap } from "rxjs";
import planungsobjektWindowSelectors from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.selectors";
import { planungsobjektFeature } from "src/app/core/stores/planungsobjekt/planungsobjekt.reducer";
import { NotificationStyle } from "src/app/models/openapi/model/notification-style";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { PlanungsobjektOnDemandDto } from "src/app/models/openapi/model/planungsobjekt-on-demand-dto";
import { LinearOnDemandService } from "src/app/services/linear-on-demand.service";
import { PlanungsobjektService } from "src/app/services/planungsobjekt.service";
import { CustomNotificationService } from "src/app/shared/notifications/custom-notification.service";
import {
  CrossUpdateUseCase,
  PlanungsobjektWindowUseCase,
} from "src/app/shared/windows/planungsobjekt-window/planungsobjekt-window.model";
import { allValuesDefined } from "src/app/utils/array-utils";
import { PlanungsobjektUtils } from "src/app/utils/planungsobjekt.utils";
import { pairwiseWhen } from "src/app/utils/rxjs-utils";
import planungsobjektWindowWannWoSelectors from "../planungsobjekt-window-wann-wo.selectors";
import { SLIDER_LEFT_POSITION } from "../planungsobjekt-window.model";
import { planungsobjektWindowFeature } from "../planungsobjekt-window.reducer";
import { isReadOnlyWindow } from "../planungsobjekt-window.utils";
import { onDemandBeziehungFormActions } from "./on-demand-beziehung-form.actions";
import onDemandBeziehungFormSelectors from "./on-demand-beziehung-form.selectors";
import {
  berechneSynchronisierteOnDemandFormValues,
  calculateReihenfolgeHerstellen,
  calculateRelationalZuLinear,
  extractLinearOnDemandBeziehungCommandAction,
} from "./on-demand-beziehung-form.utils";
import { onDemandFormActions } from "./on-demand.form";
import { OnDemandFormNgRx } from "./on-demand.form.model";
import { DEFAULT_ONLINE_AB_ZEIT } from "../planungsobjekt-form/planungsobjekt-window.form";

@Injectable()
export class OnDemandBeziehungFormEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly linearOnDemandService = inject(LinearOnDemandService);
  private readonly planungsobjektService = inject(PlanungsobjektService);
  private readonly notificationService = inject(CustomNotificationService);
  private readonly appAbility = inject(PureAbility);

  // Form Disablement
  initalFormDisablement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandBeziehungFormActions.initialize),
      concatLatestFrom(() => [this.store.select(planungsobjektWindowSelectors.selectInput)]),
      filter(allValuesDefined),
      switchMap(([action, input]) => {
        const resultingActions: Action[] = [
          onDemandBeziehungFormActions.getInitialOnDemandBeziehungFormData({
            planungsobjektLinearId: action.planungsobjektLinearId,
          }),
        ];

        if (
          !this.appAbility.can("PlanungsobjektBeitragen", "Permission") ||
          isReadOnlyWindow(input)
        ) {
          resultingActions.push(onDemandFormActions.disable());
        }

        if (input.planungskontext === Planungskontext.VORGEMERKT) {
          // Hier nur Relative Werte erlauben.
          resultingActions.push(
            onDemandFormActions.disableControls({
              controls: [
                "wunschOnlineAbVsRelationalZuLinearToggle",
                "wunschOnlineBisVsVerweildauerToggle",
              ],
            }),
          );
        }

        return resultingActions;
      }),
    );
  });

  updateFormDisablement$ = createEffect(() => {
    return this.store.select(planungsobjektWindowSelectors.selectOnDemandFormToggleState).pipe(
      filter(() => this.appAbility.can("PlanungsobjektBeitragen", "Permission")),
      map(({ wunschOnlineAbVsRelationalZuLinearToggle, wunschOnlineBisVsVerweildauerToggle }) => {
        const fieldsToEnable: (keyof OnDemandFormNgRx)[] = [];
        const fieldsToDisable: (keyof OnDemandFormNgRx)[] = [];

        if (wunschOnlineAbVsRelationalZuLinearToggle === SLIDER_LEFT_POSITION) {
          fieldsToEnable.push("wunschOnlineAb");
          fieldsToDisable.push("relationZuLinearInTagen");
          fieldsToDisable.push("minDistanz");
          fieldsToDisable.push("maxDistanz");
        } else {
          fieldsToEnable.push("relationZuLinearInTagen");
          fieldsToEnable.push("minDistanz");
          fieldsToEnable.push("maxDistanz");
          fieldsToDisable.push("wunschOnlineAb");
        }

        if (wunschOnlineBisVsVerweildauerToggle === SLIDER_LEFT_POSITION) {
          fieldsToEnable.push("wunschOnlineBis");
          fieldsToDisable.push("verweildauerInTagen");
        } else {
          fieldsToEnable.push("verweildauerInTagen");
          fieldsToDisable.push("wunschOnlineBis");
        }

        return {
          fieldsToEnable,
          fieldsToDisable,
        };
      }),
      switchMap(({ fieldsToEnable, fieldsToDisable }) => {
        if (!fieldsToEnable.length && !fieldsToDisable.length) {
          return EMPTY;
        }
        return [
          onDemandFormActions.enableControls({ controls: fieldsToEnable }),
          onDemandFormActions.disableControls({ controls: fieldsToDisable }),
          onDemandFormActions.resetControls({ controls: fieldsToDisable }),
        ];
      }),
    );
  });

  onDemandBeziehungFormHelper$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandBeziehungFormActions.initialize),
      switchMap(() =>
        this.store.select(planungsobjektWindowSelectors.onDemandForm.selectFormData).pipe(
          pairwiseWhen(
            this.store.select(
              planungsobjektWindowWannWoSelectors.selectCrossUpdateCondition(
                CrossUpdateUseCase.ONDEMAND_BEZIEHUNG,
              ),
            ),
          ),
          concatLatestFrom(() => [
            this.store.select(planungsobjektWindowSelectors.selectInput),
            this.store.select(planungsobjektFeature.selectEntities),
            this.store.select(planungsobjektWindowFeature.selectFormState),
          ]),
          filter(allValuesDefined),
        ),
      ),
      map(([[prev, next], input, planungsobjekte]) => {
        const isEditOnDemand = input.usecase === PlanungsobjektWindowUseCase.EDIT_ONDEMAND;

        // Wenn wir im UseCase EDIT_ONDEMAND sind, dann holen wir uns den Sendetag aus dem linearen Planungsobjekt
        const linearKalendertag: string | undefined =
          isEditOnDemand && input.planungsobjekt?.linearId
            ? planungsobjekte[input.planungsobjekt.linearId]?.publikationsplanung?.kalendertag
            : input.planungsobjekt?.publikationsplanung?.kalendertag;

        return {
          nextFormValue: next,
          prevFormValue: prev,
          linearKalendertag,
        };
      }),
      map(({ linearKalendertag, nextFormValue, prevFormValue }) =>
        berechneSynchronisierteOnDemandFormValues({
          linearKalendertag,
          nextFormValue,
          prevFormValue,
        }),
      ),
      map((onDemandFormValue) =>
        onDemandFormValue
          ? onDemandFormActions.patchValue({
              value: onDemandFormValue,
            })
          : onDemandBeziehungFormActions.noChangesNecessary(),
      ),
    );
  });

  initializeOnDemandBeziehungFormData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandBeziehungFormActions.getInitialOnDemandBeziehungFormData),
      switchMap(({ planungsobjektLinearId }) => {
        if (!planungsobjektLinearId) return EMPTY;
        return this.planungsobjektService.getPlanungsobjekteById$(planungsobjektLinearId);
      }),
      filter(({ onDemand, linear }) =>
        // Wir wissen noch nicht, wie wir mit n:n Beziehungen umgehen sollen
        //new Error("Mehrere OnDemand Planungen sind einer Linearplanung zugeordnet")
        //new Error("Mehrere Linearplanungen sind einer OnDemand-Planung zugeordnet")
        onDemand.length > 1 || linear.length > 1 ? false : true,
      ),
      map(({ onDemand, linear }) => {
        // Wir wissen noch nicht, wie wir mit n:n Beziehungen umgehen sollen
        const singleOnDemandOrEmpty = onDemand.length < 1 ? null : onDemand[0];
        const singleLinearOrEmpty = linear.length < 1 ? null : linear[0];

        const isOnMerkliste = singleLinearOrEmpty?.planungskontext === Planungskontext.VORGEMERKT;

        const relationZuLinearInTagen =
          !!singleLinearOrEmpty && !!singleOnDemandOrEmpty
            ? calculateRelationalZuLinear(singleLinearOrEmpty, singleOnDemandOrEmpty, "distanz")
            : null;

        const minDistanz =
          !!singleLinearOrEmpty && !!singleOnDemandOrEmpty
            ? calculateRelationalZuLinear(singleLinearOrEmpty, singleOnDemandOrEmpty, "minDistanz")
            : null;

        const maxDistanz =
          !!singleLinearOrEmpty && !!singleOnDemandOrEmpty
            ? calculateRelationalZuLinear(singleLinearOrEmpty, singleOnDemandOrEmpty, "maxDistanz")
            : null;

        return onDemandFormActions.patchValue({
          value: {
            onlineAbZeit: singleOnDemandOrEmpty?.onlineAbZeit ?? DEFAULT_ONLINE_AB_ZEIT,
            wunschOnlineAb: singleOnDemandOrEmpty?.onlineAb ?? null,
            wunschOnlineBis: singleOnDemandOrEmpty?.onlineBis ?? null,
            reihenfolgeHerstellen:
              !!singleLinearOrEmpty && !!singleOnDemandOrEmpty
                ? calculateReihenfolgeHerstellen(singleLinearOrEmpty, singleOnDemandOrEmpty)
                : false,
            relationZuLinearInTagen,
            minDistanz,
            maxDistanz,

            verweildauerInTagen: singleOnDemandOrEmpty?.verweildauerInTagen ?? 0,

            wunschOnlineAbVsRelationalZuLinearToggle: !!(
              relationZuLinearInTagen ||
              relationZuLinearInTagen === 0 ||
              isOnMerkliste
            ),
            wunschOnlineBisVsVerweildauerToggle: !!(
              singleOnDemandOrEmpty?.verweildauerInTagen || isOnMerkliste
            ),
          },
        });
      }),
    );
  });

  saveLinearOnDemandBeziehung$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandBeziehungFormActions.saveLinearOnDemandBeziehung),
      concatLatestFrom(() => [
        this.store.select(planungsobjektWindowSelectors.selectInput),
        this.store.select(
          onDemandBeziehungFormSelectors.selectOnDemandFormMitRelationalOderAbsolutWerten,
        ),
        this.store.select(planungsobjektWindowSelectors.selectHasPlanungsobjektOnDemandId),
      ]),
      filter(allValuesDefined),
      map(([_, windowInput, onDemandFormValue, hasPlanungsobjektOnDemandId]) => {
        const shouldCreate = !hasPlanungsobjektOnDemandId;
        const action = extractLinearOnDemandBeziehungCommandAction({
          windowInput,
          onDemandFormValue,
          shouldCreate,
        });
        return action;
      }),
    );
  });

  linearOnDemandBeziehungVorgemerktErstellen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktErstellen),
      switchMap(({ command }) =>
        this.linearOnDemandService.linearOnDemandBeziehungVorgemerktErstellen$(command),
      ),
      tap(() => {
        this.notificationService.showNotification(
          "OnDemand Beziehung wurde erstellt.",
          NotificationStyle.SUCCESS,
        );
      }),
      concatLatestFrom(() =>
        this.store.select(planungsobjektWindowSelectors.selectPlanungsobjektId),
      ),
      map(([planungsobjekte, currentPlanungsobjektId]) => {
        if (!currentPlanungsobjektId)
          return onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktErstellenFailure();
        return onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktErstellenSuccess({
          planungsobjekte,
          currentPlanungsobjektId,
        });
      }),
    );
  });

  linearOnDemandBeziehungVorgemerktAktualisieren$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktAktualisieren),
      switchMap(({ command }) =>
        this.linearOnDemandService.linearOnDemandBeziehungVorgemerktAktualisieren$(command),
      ),
      tap(() => {
        this.notificationService.showNotification(
          "OnDemand Beziehung wurde aktualisiert.",
          NotificationStyle.SUCCESS,
        );
      }),
      concatLatestFrom(() =>
        this.store.select(planungsobjektWindowSelectors.selectPlanungsobjektId),
      ),
      map(([planungsobjekte, currentPlanungsobjektId]) => {
        if (!currentPlanungsobjektId)
          return onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktAktualisierenFailure();
        return onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktAktualisierenSuccess({
          planungsobjekte,
          currentPlanungsobjektId,
        });
      }),
    );
  });

  /**
   * Erstellt ein Planungsobjekt OnDemand über den Verlinkungs-Tab mit entsprechender Beziehung zu Linear und fügt es dem Store hinzu.
   */
  linearOnDemandBeziehungVorgeplantVorgeschlagenErstellen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenErstellen),
      switchMap(({ command }) =>
        this.linearOnDemandService
          .linearOnDemandBeziehungVorgeschlagenVorgeplantErstellen$(command)
          .pipe(
            concatLatestFrom(() =>
              this.store.select(planungsobjektWindowSelectors.selectPlanungsobjektId),
            ),
            map(([planungsobjekte, currentPlanungsobjektId]) => {
              if (!currentPlanungsobjektId)
                return onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenErstellenFailure();
              return onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenErstellenSuccess(
                {
                  planungsobjekte,
                  currentPlanungsobjektId,
                },
              );
            }),
          ),
      ),
      tap(() =>
        this.notificationService.showNotification(
          "OnDemand Beziehung wurde hergestellt.",
          NotificationStyle.SUCCESS,
        ),
      ),
    );
  });

  /**
   * Aktualisiert die Beziehung LinearOnDemand (ggf. mit Update auf der OnDemand-Planung) über den Verlinkungs-Tab.
   */
  linearOnDemandBeziehungVorgeplantVorgeschlagenAktualisieren$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenAktualisieren,
      ),
      concatLatestFrom(() => [
        this.store.select(planungsobjektWindowSelectors.selectInput),
        this.store.select(planungsobjektWindowSelectors.selectPlanungsobjektForEditWindow),
      ]),
      filter(allValuesDefined),
      switchMap(([{ enabeledFormGroupValues }, input, currentPlanungsobjekt]) => {
        // linear ID bestimmen
        const planungsobjektLinearId =
          input.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ ||
          input.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT
            ? currentPlanungsobjekt.id
            : input.usecase === PlanungsobjektWindowUseCase.EDIT_ONDEMAND
              ? PlanungsobjektUtils.findLinearIdInOnDemandBeziehungen(
                  currentPlanungsobjekt as PlanungsobjektOnDemandDto,
                )
              : undefined;
        // Soll auch nicht möglich sein bei einem CREATE_LINEAR mit Tab-Switch zwischenspeichern
        if (
          planungsobjektLinearId &&
          (input.planungskontext === "Vorgeplant" || input.planungskontext === "Vorgeschlagen")
        ) {
          {
            return this.linearOnDemandService
              .linearOnDemandBeziehungVorgeschlagenVorgeplantAktualisieren$({
                planungsobjektLinearId,
                // Entweder wunschOnlineAb bzw. wunschOnlineBis oder wir übergeben relationalZuLinear bzw. verweildauer. Nicht beides
                onlineAb: enabeledFormGroupValues.wunschOnlineAb,
                onlineAbZeit: enabeledFormGroupValues.onlineAbZeit,
                onlineBis: enabeledFormGroupValues.wunschOnlineBis,
                reihenfolgeHerstellen: enabeledFormGroupValues.reihenfolgeHerstellen ?? false,
                relationZuLinearInTagen: enabeledFormGroupValues.relationZuLinearInTagen,
                verweildauerInTagen: enabeledFormGroupValues.verweildauerInTagen,
                minDistanz: enabeledFormGroupValues.minDistanz,
                maxDistanz: enabeledFormGroupValues.maxDistanz,
              })
              .pipe(
                tap(() => {
                  this.notificationService.showNotification(
                    "OnDemand Beziehung wurde aktualisiert.",
                    NotificationStyle.SUCCESS,
                  );
                }),
                concatLatestFrom(() =>
                  this.store.select(planungsobjektWindowSelectors.selectPlanungsobjektId),
                ),
                map(([planungsobjekte, currentPlanungsobjektId]) => {
                  if (!currentPlanungsobjektId)
                    return onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenAktualisierenFailure();
                  return onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenAktualisierenSuccess(
                    {
                      planungsobjekte,
                      currentPlanungsobjektId,
                    },
                  );
                }),
              );
          }
        } else {
          return EMPTY;
        }
      }),
    );
  });
}
