import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType, rootEffectsInit } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { routerNavigatedAction } from "@ngrx/router-store";
import { Store } from "@ngrx/store";
import { format } from "date-fns";
import { map, switchMap, tap } from "rxjs";
import { BenachrichtigungApiService } from "src/app/api/benachrichtigung/benachrichtigung.api.service";
import { BenachrichtigungPushService } from "src/app/api/benachrichtigung/benachrichtigung.push.service";
import { AppPage } from "src/app/models/enums/app-page";
import { ReloadNotificationService } from "src/app/shared/notifications/reload-notification.service";
import { ansichtActions } from "../ansicht/ansicht.actions";
import ansichtSelectors from "../ansicht/ansicht.selectors";
import { multiansichtActions } from "../multiansicht/multiansicht.actions";
import { multiAnsichtFeature } from "../multiansicht/multiansicht.reducer";
import { benachrichtigungActions } from "./benachrichtigung.actions";
import { isBenachrichtigungInAnsichtLinear } from "./benachrichtigung.filters";

/**
 * Von einem Benutzer verworfene Benachrichtigungen werden im Backend gespeichert
 * und automatisch gefiltert, wenn Benachrichtigungen geladen werden.
 */
@Injectable()
export class BenachrichtigungEffects {
  /**
   * SignalR Verbindung starten, sobald der Store initialisiert wurde.
   */
  startSocketConnection$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(rootEffectsInit),
        tap(() => this.benachrichtigungPushService.start()),
      );
    },
    { dispatch: false },
  );

  /**
   * Lädt alle existierenden Benachrichtigungen.
   * Da die Menge extrem große werden kann, sollte diese Action nur
   * verwendet werden wenn das wirklich notwendig ist (aktuell niemals).
   */
  loadBenachrichtigungen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(benachrichtigungActions.loadBenachrichtigungen),
      switchMap(() => this.service.getBenachrichtigungen$()),
      map((benachrichtigungen) =>
        benachrichtigungActions.loadBenachrichtigungenSuccess({ benachrichtigungen }),
      ),
    );
  });

  /**
   * Lädt alle Benachrichtigungen für einen gegebenen Zeitraum.
   */
  loadBenachrichtigungenByZeitraum$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(benachrichtigungActions.loadBenachrichtigungenByZeitraum),
      switchMap(({ von, bis }) => this.service.getBenachrichtigungenByZeitraum$(von, bis)),
      map((benachrichtigungen) =>
        benachrichtigungActions.loadBenachrichtigungenSuccess({ benachrichtigungen }),
      ),
    );
  });

  /**
   * Lädt alle Benachrichtigung für den Zeitraum einer Ansicht.
   * Dabei wird der gesamte Zeitraum Von/Bis zur Eingrenzung der Benachrichtigungen.
   * Einzelne Sendeplätze o.ä. müssen dann im Frontend gefiltert werden.
   * z.B. wird für die Ansicht "Mittwoch 20.15 2023" der 01.01.2023 bis 31.12.2023 geladen.
   */
  loadBenachrichtigungenByAnsicht$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(benachrichtigungActions.loadBenachrichtigungenByAnsicht),
      map(({ multiAnsichtViewModel }) => {
        // Es wäre ebenfalls möglich die Benachrichtigungen für den kompletten Bereich
        // aller aktuell angezeigten Ansicht zu laden, z.B. Mittwoch 20.15 2022 - 2024
        const primaryAnsichtPublikationViewModel = multiAnsichtViewModel.ansichtViewModels.find(
          (ansichtPublikationViewModel) => {
            return ansichtPublikationViewModel.primary;
          },
        );

        if (!primaryAnsichtPublikationViewModel) {
          throw new Error("AnsichtPublikationViewModel has no primary Ansicht set.");
        }

        return benachrichtigungActions.loadBenachrichtigungenByZeitraum({
          von: format(
            primaryAnsichtPublikationViewModel.ansichtViewModel.zeitraum.start,
            "yyyy-MM-dd",
          ),
          bis: format(
            primaryAnsichtPublikationViewModel.ansichtViewModel.zeitraum.end,
            "yyyy-MM-dd",
          ),
        });
      }),
    );
  });

  /**
   * Wird die Ansicht aktualisiert oder erweitert, werden alle Benachrichtigungen
   * erneut geladen. Dabei werden alle Benachrichtigungen als bestätigt / confirmed / gelesen markiert.
   */
  loadBenachrichtigungenOnReload$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        // Aktualisierung durch den Anwender nach einem Klick auf
        // "Diese Ansicht wurde durch einen anderen Nutzer verändert (Ansicht aktualisieren)"
        ansichtActions.updateAllEntitiesInAnsicht,
        // Erweiterung der aktuellen Ansicht um ein neues Jahr
        multiansichtActions.updateAnsichtInMultiansichtSuccess,
      ),
      concatLatestFrom(() => this.store.select(multiAnsichtFeature.selectMultiAnsichtViewModel)),
      map(([, multiAnsichtViewModel]) => {
        if (!multiAnsichtViewModel) {
          return benachrichtigungActions.loadBenachrichtigungenSuccess({ benachrichtigungen: [] });
        }
        return benachrichtigungActions.loadBenachrichtigungenByAnsicht({ multiAnsichtViewModel });
      }),
    );
  });

  /**
   * Hierbei gehen wir hauptsächlich davon aus, dass wir bei einem Navigate Event von einer Ansicht
   * weg navigieren (z.B. auf das Dashboard). Sollte daher noch eine "Es haben sich Daten geändert, bitte neu laden"
   * Benachrichtigung vorhanden sein hat diese auf der neuen Seite keine Gültigkeit mehr.
   *
   * Die Action wird jedoch auch getriggert wenn wir unsere (OnDemand) Suche verändern und dabei
   * die Parameter in die URL schreiben. In diesem Fall ist es nicht schlimm, den unten stehenden
   * Seiteneffekt auszuführen, aber so richtig richtig ist es auch nicht.
   */
  destroyReloadAnsichtNotificationOnRouterNavigated$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(routerNavigatedAction),
        tap(() => {
          // console.log("destroyReloadAnsichtNotificationOnRouterNavigated$");
          this.notificationService.destroyBroadcastNotificationIfExists();
        }),
      );
    },
    { dispatch: false },
  );

  /**
   * Wenn eine neue Ansicht geladen wird, werden die Benachrichtigungen für diese Ansicht geladen.
   */
  loadBenachrichtigungenOnAnsichtChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(multiansichtActions.setMultiansichtSuccess),
      tap(() => {
        this.notificationService.destroyBroadcastNotificationIfExists();
      }),
      map(({ multiAnsichtViewModel }) => {
        return benachrichtigungActions.loadBenachrichtigungenByAnsicht({ multiAnsichtViewModel });
      }),
    );
  });

  /**
   * Wenn eine geladene Ansicht erweitert wird (vorheriges / nächstes Jahr),
   * werden die Benachrichtigungen für diese Ansicht geladen.
   */
  loadBenachrichtigungenOnAnsichtExpand$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(multiansichtActions.updateAnsichtInMultiansichtSuccess),
      tap(() => {
        // console.log("loadBenachrichtigungenOnAnsichtExpand$");
        this.notificationService.destroyBroadcastNotificationIfExists();
      }),
      concatLatestFrom(() => this.store.select(multiAnsichtFeature.selectMultiAnsichtViewModel)),
      map(([, multiAnsichtViewModel]) => {
        if (!multiAnsichtViewModel) {
          throw new Error("Ansicht was expanded, but AnsichtPublikationViewModel is not set.");
        }
        return benachrichtigungActions.loadBenachrichtigungenByAnsicht({ multiAnsichtViewModel });
      }),
    );
  });

  verwerfenMultiple$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(benachrichtigungActions.verwerfenMultiple),
      switchMap(({ benachrichtigungIds }) =>
        this.service
          .verwerfenMultiple$(benachrichtigungIds)
          .pipe(
            map(() => benachrichtigungActions.verwerfenMultipleSuccess({ benachrichtigungIds })),
          ),
      ),
    );
  });

  showReloadAnsichtNotificationForAnsichtLinear$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(benachrichtigungActions.benachrichtigungFromSocket),
        concatLatestFrom(() => [
          this.store.select(multiAnsichtFeature.selectMultiAnsichtViewModel),
          // Reload Benachrichtigungen werden nun für alle expandierten Ansichten angezeigt,
          // da der Selector das MultiAnsichtVm für die Berechnung der Sendeplatz Intervalle verwendet.
          this.store.select(ansichtSelectors.selectSendeplatzIntervalsForCurrentAnsichten),
        ]),
        tap(([{ benachrichtigung, requiresReload }, multiAnsichtVm, intervals]) => {
          // Die Benachrichtigung bezieht sich auf eine Aktion die der Benutzer selbst ausgeführt hat,
          // es besteht daher keine Notwendigkeit die Daten der Ansicht neu zu laden
          if (!requiresReload) {
            return;
          }

          // Wir befinden uns auf keiner Ansicht und müssen daher auch keine Reload Benachrichtigung anzeigen
          if (!multiAnsichtVm) {
            return;
          }

          const ausspielweg = multiAnsichtVm?.ansichtViewModels
            .map((viewModel) => viewModel.ansichtViewModel.kanal)
            .shift();
          // Wir können den Ausspielweg der linearen Ansicht nicht feststellen
          if (!ausspielweg) {
            return;
          }

          if (!isBenachrichtigungInAnsichtLinear(benachrichtigung, ausspielweg, intervals)) {
            return;
          }

          this.notificationService.showReloadNotification(benachrichtigung, AppPage.Ansicht);
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private service: BenachrichtigungApiService,
    private notificationService: ReloadNotificationService,
    private benachrichtigungPushService: BenachrichtigungPushService,
  ) {}
}
