import { Injectable, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { sendeplatzWindowActions } from "../core/stores/sendeplatz-window/sendeplatz-window.actions";
import { SendeplatzWindowComponent } from "../shared/windows/sendeplatz-window/sendeplatz-window.component";
import { SmallWindow } from "../shared/windows/window.templates";
import { CustomWindowService } from "./custom-window.service";

@Injectable({ providedIn: "root" })
export class SendeplatzWindowService {
  private readonly store = inject(Store);
  private readonly customWindowService = inject(CustomWindowService);

  openSendeplatzWindow() {
    const windowRef = this.customWindowService.open<SendeplatzWindowComponent>({
      content: SendeplatzWindowComponent,
      title: "Sendeplatz",
      ...SmallWindow({ dataTestId: "sendeplatz-window" }),
      preventClose: () => {
        this.store.dispatch(sendeplatzWindowActions.tryClosingWithoutSaving());
        return true;
      },
    });

    return windowRef;
  }
}
