import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import planungshinweisSelectors from "src/app/core/stores/planungshinweis/planungshinweis.selectors";
import planungsobjektSelectors from "src/app/core/stores/planungsobjekt/planungsobjekt.selectors";
import { PlanungshinweisDto } from "src/app/models/openapi/model/planungshinweis-dto";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { SendeplatzUtils } from "src/app/utils/sendeplatz.utils";

@Component({
  selector: "app-listenansicht-sendeplatz-notizen, [app-listenansicht-sendeplatz-notizen]",
  templateUrl: "./listenansicht-sendeplatz-notizen.component.html",
  styleUrls: ["./listenansicht-sendeplatz-notizen.component.scss"],
})
export class ListenansichtSendeplatzNotizenComponent implements OnInit {
  @Input({ required: true }) sendeplatz: SendeplatzDto | null;
  @Input({ required: true }) planungskontext: Planungskontext;
  @Input({ required: true }) showSendeplatzNotizen: boolean;

  planungsobjekte$: Observable<PlanungsobjektDto[]>;
  planungshinweise$: Observable<PlanungshinweisDto[]>;

  Planungskontext = Planungskontext;

  constructor(private store: Store) {}

  ngOnInit(): void {
    if (!this.sendeplatz) {
      return;
    }

    this.planungsobjekte$ = this.store.select(
      planungsobjektSelectors.selectPlanungsobjekteForSendeplatzKeyPlanungskontext(
        SendeplatzUtils.getSendeplatzKey(this.sendeplatz),
        this.planungskontext,
      ),
    );

    this.planungshinweise$ = this.store.select(
      planungshinweisSelectors.selectPlanungshinweiseByTimeRange(
        this.sendeplatz.effektivVon,
        this.sendeplatz.effektivBis,
        this.sendeplatz.kanal,
      ),
    );
  }
}
