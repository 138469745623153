<div class="sub-header">
  <form autocomplete="off">
    <app-input-label class="u-w-full" text="Kategorie">
      <kendo-dropdownlist
        textField="text"
        valueField="value"
        [attr.data-testid]="'merkliste-ondemand-kategorie'"
        [data]="onDemandFilterOptions"
        [valuePrimitive]="true"
        [(value)]="onDemandFilter"
        (valueChange)="onFilterChange()"
      >
      </kendo-dropdownlist>
    </app-input-label>
  </form>
</div>
<div class="dialog-footer">
  <div
    *ngIf="{
      merklisten: merklisten$ | async
    } as vm"
    class="window-action-buttons k-flex-wrap"
    style="gap: 0.5rem"
  >
    <button
      *ngFor="let merkliste of vm.merklisten"
      fillMode="outline"
      kendoButton
      type="button"
      (click)="onVerschieben(merkliste)"
    >
      {{ merkliste.name }}
    </button>
  </div>
  <button
    class="k-button-group-stretched"
    fillMode="outline"
    kendoButton
    type="button"
    (click)="onClose()"
  >
    Abbrechen
  </button>
</div>
