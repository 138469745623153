import { Component, inject } from "@angular/core";
import { EventKategorieOptions } from "src/app/models/enums/event-kategorie";
import { KonkurrenzKategorieOptions } from "src/app/models/enums/konkurrenz-kategorie";
import { SenderPlattformOptions } from "src/app/models/enums/sender-plattform";
import { EventKategorie } from "src/app/models/openapi/model/event-kategorie";
import { KonkurrenzprogrammKategorie } from "src/app/models/openapi/model/konkurrenzprogramm-kategorie";
import { SenderPlattform } from "src/app/models/openapi/model/sender-plattform";
import { DateFnsService } from "src/app/services/date-fns.service";
import { EkWindowFacade } from "./ek-window.facade";

@Component({
  templateUrl: "./ek-window.component.html",
  styleUrls: ["./ek-window.component.scss"],
  providers: [EkWindowFacade],
})
export class EKWindowComponent {
  public readonly facade = inject(EkWindowFacade);

  KonkurrenzKategorie = KonkurrenzKategorieOptions;
  EventKategorie = EventKategorieOptions;
  SenderPlattform = SenderPlattformOptions;

  kategorieValue: KonkurrenzprogrammKategorie | EventKategorie | null;
  senderPlattformValue: SenderPlattform | null;

  // Wir das Event beim Speichern in ein Konkurrenzprogramm konvertiert (oder umgekehrt)
  shouldConvert = false;

  strToDate(date: string): Date {
    return DateFnsService.parseStringToDate(date);
  }

  onChangeSwitch(): void {
    this.facade.updateFormValue({ kategorie: "KeineKategorie" });
  }

  onSenderPlattformChange(value: string | null): void {
    if (value !== "Sonstiges") {
      this.facade.updateFormValue({ senderPlattformSonstiges: "" });
    }
  }
}
