import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { MengengeruestEintragWindowInput } from "./mengengeruesteintrag-window.model";

export const mengengeruesteintragWindowActions = createActionGroup({
  source: "Mengengeruesteintrag Window",
  events: {
    openWindow: props<{
      input: MengengeruestEintragWindowInput;
    }>(),
    closeWindow: emptyProps(),
    tryClosingWithoutSaving: emptyProps(),
    windowClosed: emptyProps(),
  },
});
