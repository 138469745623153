import { createFeature, createReducer, on } from "@ngrx/store";
import { onNgrxForms } from "ngrx-forms";
import { mergeReducers } from "src/app/utils/ngrx-utils";
import { pharosExportWindowActions } from "./pharos-export-window.actions";
import { pharosExportWindowFormReducer } from "./pharos-export-window.form";
import {
  PharosExportWindowFeatureState,
  initialPharosExportWindowFeatureState,
} from "./pharos-export-window.model";

const pharosExportWindowFeatureReducer = createReducer(
  initialPharosExportWindowFeatureState,
  on(
    pharosExportWindowActions.openWindow,
    (state, { windowInput }): PharosExportWindowFeatureState => ({
      ...initialPharosExportWindowFeatureState,
      windowInput,
    }),
  ),
  onNgrxForms(),
);

const reducer = mergeReducers(pharosExportWindowFeatureReducer, pharosExportWindowFormReducer);

export const pharosExportWindowFeature = createFeature({
  name: "pharosExportWindow",
  reducer,
});
