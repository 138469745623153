import { Component, inject } from "@angular/core";
import { SendeplatzWindowFacade } from "./sendeplatz-window.facade";

@Component({
  selector: "app-sendeplatz-window",
  templateUrl: "./sendeplatz-window.component.html",
  styleUrls: ["./sendeplatz-window.component.scss"],
  providers: [SendeplatzWindowFacade],
})
export class SendeplatzWindowComponent {
  public readonly facade = inject(SendeplatzWindowFacade);
}
