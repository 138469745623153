<ng-container *ngIf="facade.formState$ | async as formState">
  <div class="u-grid-container">
    <app-produktinformationen-getit-link
      class="u-col-12"
      [planungsobjekt]="planungsobjekt"
      [usecase]="usecase"
    >
    </app-produktinformationen-getit-link>

    <app-form-field class="u-col-4" label="Stoffführende Redaktion">
      <app-select
        data-testid="stofffuehrendeRedaktion"
        [ngrxFormControlState]="formState.controls.stofffuehrendeRedaktion"
        [options]="StofffuehrendeRedaktionOptionsFiltered"
      >
      </app-select>
    </app-form-field>

    <app-form-field class="u-col-4" label="PLP Genre">
      <app-select
        data-testid="genre"
        [defaultItem]="KeinGenreOption"
        [ngrxFormControlState]="formState.controls.genre"
        [options]="GenreOptionsFiltered"
      >
      </app-select>
    </app-form-field>

    <app-input-label class="u-col-4" text="AGF v4 Genre">
      <kendo-dropdownlist data-testid="agf-genre" [disabled]="true"></kendo-dropdownlist>
    </app-input-label>

    <app-form-field class="u-col-4" label="Zielgruppe">
      <app-multiselect
        data-testid="contentCommunities"
        [ngrxFormControlState]="formState.controls.contentCommunities"
        [options]="ContentCommunityOptions"
      >
      </app-multiselect>
    </app-form-field>

    <app-form-field class="u-col-4" label="FSK">
      <app-select
        data-testid="fsk"
        [ngrxFormControlState]="formState.controls.fsk"
        [options]="FSKEinstufungOptions"
      ></app-select>
    </app-form-field>

    <app-input-label class="u-col-4" text="Barrierefreiheit">
      <kendo-multiselect data-testid="barrierefreiheit" [disabled]="true"></kendo-multiselect>
    </app-input-label>

    <div class="u-col-12 u-grid-container">
      <app-input-label class="u-col-4" text="Staffelnr.">
        <kendo-numerictextbox
          data-testid="staffelnummer"
          format="n"
          [min]="1"
          [ngrxFormControlState]="formState.controls.staffelnummer"
          [step]="1"
        ></kendo-numerictextbox>
      </app-input-label>

      <app-input-label class="u-col-4" text="Folgennr.">
        <kendo-numerictextbox
          data-testid="folgennummer"
          format="n"
          [min]="1"
          [ngrxFormControlState]="formState.controls.folgennummer"
          [step]="1"
        ></kendo-numerictextbox>
      </app-input-label>

      <app-form-field class="u-col-4" label="Gesamtfolgennr.">
        <kendo-textbox
          data-testid="gesamtfolgennummer"
          [ngrxFormControlState]="formState.controls.gesamtfolgennummer"
        ></kendo-textbox>
      </app-form-field>
    </div>

    <app-input-label class="u-col-12" text="Inhaltsbeschreibung">
      <kendo-textarea
        data-testid="inhaltsbeschreibung"
        resizable="auto"
        [ngrxFormControlState]="formState.controls.inhaltsbeschreibung"
      ></kendo-textarea>
    </app-input-label>

    <app-input-label class="u-col-12" text="Mitwirkende">
      <kendo-textarea
        data-testid="mitwirkende"
        resizable="auto"
        [ngrxFormControlState]="formState.controls.mitwirkende"
      ></kendo-textarea>
    </app-input-label>
  </div>
</ng-container>
