import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SendeplatzApiService } from "../api/sendeplatz/sendeplatz.api.service";
import { GetSendeplaetzeByAnsichtenQuery } from "../models/openapi/model/get-sendeplaetze-by-ansichten-query";
import { SendeplatzDto } from "../models/openapi/model/sendeplatz-dto";
import { SendeplatzKeyDto } from "../models/openapi/model/sendeplatz-key-dto";
import { UpsertSendeplatzCommand } from "../models/openapi/model/upsert-sendeplatz-command";

@Injectable({
  providedIn: "root",
})
export class SendeplatzService {
  constructor(private sendeplatzApiService: SendeplatzApiService) {}

  getSendeplatzById$(sendeplatzId: string): Observable<SendeplatzDto> {
    return this.sendeplatzApiService.getSendeplatzById$(sendeplatzId);
  }

  getSendeplatzBySendeplatzKey$(sendeplatzKey: SendeplatzKeyDto): Observable<SendeplatzDto> {
    return this.sendeplatzApiService.getSendeplatzBySendeplatzKey$(sendeplatzKey);
  }

  updateSendeplatz$(sendeplatz: SendeplatzDto): Observable<SendeplatzDto> {
    const sendeplatzDto = this.mapSendeplatzToUpsertCommand(sendeplatz);
    return this.sendeplatzApiService.updateSendeplatz$(sendeplatzDto);
  }

  getSendeplaetzeByAnsichten$(query: GetSendeplaetzeByAnsichtenQuery) {
    return this.sendeplatzApiService.getSendeplaetzeByAnsichten$(query);
  }

  private mapSendeplatzToUpsertCommand(sendeplatz: SendeplatzDto): UpsertSendeplatzCommand {
    const command: UpsertSendeplatzCommand = {
      abweichendeBeginnzeit: sendeplatz.abweichendeBeginnzeit,
      abweichendeLaenge: sendeplatz.abweichendeLaenge,
      notiz: sendeplatz.notiz,
      sendeplatzKey: {
        beginnzeit: sendeplatz.beginnzeit,
        kanal: sendeplatz.kanal,
        laenge: sendeplatz.laenge,
        kalendertag: sendeplatz.kalendertag,
        sendetag: sendeplatz.sendetag,
      },
    };
    return command;
  }
}
