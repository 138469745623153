import { ekWindowFormSelectors } from "./ek-window.form";
import { ekWindowFeature } from "./ek-window.reducer";

const selectInput = ekWindowFeature.selectInput;
const selectEkWindowForm = ekWindowFeature.selectFormState;
const formSelectors = ekWindowFormSelectors(selectEkWindowForm);

export const ekWindowSelectors = {
  // Window Selectors
  selectInput,

  // Form Selectors
  ...formSelectors,
};
