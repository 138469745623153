import { Prettify } from "@ngrx/store/src/models";
import { LinearOnDemandDto } from "src/app/models/openapi/model/linear-on-demand-dto";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { PlanungsobjektLinearDto } from "src/app/models/openapi/model/planungsobjekt-linear-dto";
import { PlanungsobjektOnDemandDto } from "src/app/models/openapi/model/planungsobjekt-on-demand-dto";
import { VariantenzeileDto } from "src/app/models/openapi/model/variantenzeile-dto";
import { PlanungsobjektWindowInput } from "src/app/shared/windows/planungsobjekt-window/planungsobjekt-window.model";
import { ControlEnablement, EnablementForKeysOf } from "src/app/utils/form-utils";
import { NullableValues } from "src/app/utils/object-utils";
import { OnDemandFormState, initialOnDemandFormState } from "./on-demand-form/on-demand.form.model";
import {
  PlanungsobjektFormState,
  initialPlanungsobjektFormState,
} from "./planungsobjekt-form/planungsobjekt-window.form.model";

export const SLIDER_LEFT_POSITION = false;
export const SLIDER_RIGHT_POSITION = true;

export type PlanungsinhalteFormData = Pick<
  PlanungsobjektDto,
  | "titel"
  | "redaktion"
  | "genre"
  | "notiz"
  | "highlight"
  | "contentCommunities"
  | "farbgebung"
  | "fruehesteVeroeffentlichung"
  | "stofffuehrendeRedaktion"
  | "fsk"
  | "staffelnummer"
  | "folgennummer"
  | "gesamtfolgennummer"
  | "inhaltsbeschreibung"
  | "mitwirkende"
>;

export type WannBezugOnDemandFormData = Pick<
  PlanungsobjektOnDemandDto,
  "onlineAb" | "onlineAbZeit" | "onlineBis" | "planlaenge" | "verweildauerInTagen"
> & { wunschOnlineBisVsVerweildauerToggle: boolean };

export type WannBezugLinearFormData = Pick<
  PlanungsobjektLinearDto,
  "sendetag" | "beginnzeit" | "planlaenge" | "variante"
> & { endzeit: string | null };

export type PlanungsobjektProduktBezugData = Pick<
  PlanungsobjektDto,
  "getitId" | "letzterGetitSync" | "produktstatus" | "produkttitel" | "produkttitelMultipart"
>;

export type PlanungsobjektWindowPlanungFormData = Prettify<
  NullableValues<
    PlanungsinhalteFormData &
      WannBezugOnDemandFormData &
      WannBezugLinearFormData &
      PlanungsobjektProduktBezugData
  >
>;

/**
 * Enablement-Map für das Planung-Formular.
 */
export type PlanungsobjektWindowPlanungFormEnablement =
  | ControlEnablement
  | EnablementForKeysOf<PlanungsobjektWindowPlanungFormData>;

type OnDemandFormData = Pick<
  LinearOnDemandDto,
  | "wunschOnlineAb"
  | "onlineAbZeit"
  | "wunschOnlineBis"
  | "reihenfolgeHerstellen"
  | "relationZuLinearInTagen"
  | "verweildauerInTagen"
  | "minDistanz"
  | "maxDistanz"
>;

export type PlanungsobjektWindowOnDemandFormData = Prettify<NullableValues<OnDemandFormData>>;

export enum PlanungsobjektWindowTabEnum {
  PLANUNG = "Planung",
  VERLINKUNG = "Verlinkung",
  AKTIVITAETEN = "Aktivitäten",
}

export interface PlanungsobjektWindowState {
  input: PlanungsobjektWindowInput | null;

  formState: PlanungsobjektFormState;

  onDemandFormState: OnDemandFormState;

  selectedTab: PlanungsobjektWindowTabEnum;
  varianten: VariantenzeileDto[];
  loadingVariants: boolean;
}

export const initialPlanungsobjektWindowState: PlanungsobjektWindowState = {
  input: null,
  formState: initialPlanungsobjektFormState,
  onDemandFormState: initialOnDemandFormState,
  selectedTab: PlanungsobjektWindowTabEnum.PLANUNG,
  varianten: [],
  loadingVariants: false,
};

export type AffectedByFixVsRelationalSwitch = Pick<
  WannBezugOnDemandFormData,
  "verweildauerInTagen" | "onlineBis"
>;
