import { mengengeruesteintragWindowFormSelectors } from "./mengengeruesteintrag-window.form";
import { mengengeruesteintragWindowFeature } from "./mengengeruesteintrag-window.reducer";

const selectFormState = mengengeruesteintragWindowFeature.selectFormState;
const formSelectors = mengengeruesteintragWindowFormSelectors(selectFormState);
const selectInput = mengengeruesteintragWindowFeature.selectInput;
const selectIsOpen = mengengeruesteintragWindowFeature.selectIsOpen;

export const mengengeruesteintragWindowSelectors = {
  // Window Selectors
  selectInput,
  selectIsOpen,

  // Form Selectors
  ...formSelectors,
};
