import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { Component, Input, OnInit, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { ContextMenuEvent } from "@progress/kendo-angular-menu/context-menu/context-menu-event";
import { Observable } from "rxjs";
import planungsobjektSelectors from "src/app/core/stores/planungsobjekt/planungsobjekt.selectors";
import { AktionEnum } from "src/app/models/enums/aktion";
import { Icons } from "src/app/models/icons";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";
import { SendeplatzDroplistData } from "src/app/models/viewmodels/sendeplatz-viewmodel";
import { droppedInNewContainer } from "src/app/utils/planungsobjekt-interaktion-utils";
import { PlanungsobjektUtils } from "src/app/utils/planungsobjekt.utils";
import { SendeplatzUtils } from "src/app/utils/sendeplatz.utils";
import ListenansichtFacade from "../../listenansicht.facade";

@Component({
  selector: "app-listenansicht-sendeplatz-vorgeplant, [app-listenansicht-sendeplatz-vorgeplant]",
  templateUrl: "./listenansicht-sendeplatz-vorgeplant.component.html",
  styleUrls: ["./listenansicht-sendeplatz-vorgeplant.component.scss"],
})
export class ListenansichtSendeplatzVorgeplantComponent implements OnInit {
  public readonly facade = inject(ListenansichtFacade);

  @Input() ansicht: AnsichtViewModel;
  @Input() sendeplatz: SendeplatzDto;
  @Input() sendeplatzGroupZeileSendeplaetze: Array<SendeplatzDto | null>;
  @Input() sendeplatzIndex: number;

  AktionEnum = AktionEnum;
  Icons = Icons;
  planungsobjekte$: Observable<PlanungsobjektDto[]>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.planungsobjekte$ = this.store.select(
      planungsobjektSelectors.selectPlanungsobjekteVorgeplantForSendeplatz(
        SendeplatzUtils.getSendeplatzKey(this.sendeplatz),
      ),
    );
  }

  planungsobjektEinplanen(
    event: CdkDragDrop<SendeplatzDroplistData, SendeplatzDroplistData, PlanungsobjektDto>,
    newSendeplatz: SendeplatzDto,
    eingeplant: PlanungsobjektDto[],
  ) {
    this.facade.endDragging();

    const planungsobjekt = event.item.data;
    if (!planungsobjekt) {
      throw new Error("Planungsobjekt from CdkDragDrop event is undefined.");
    }

    if (eingeplant === undefined) {
      throw new Error("Planungsobjekte on Sendeplatz are undefined.");
    }

    // Vorschlag ergänzen/ersetzen Dialog nur anzeigen, wenn bereits Planungsobjekte
    // eingeplant sind, andernfalls wird die Planungsobjekt einfach in die Eingeplant Spalte verschoben
    if (PlanungsobjektUtils.isOnVorschlag(planungsobjekt) && eingeplant.length > 0) {
      this.facade.openPlanungsobjektAcceptWindow({
        sendeplatz: newSendeplatz,
        linearId: planungsobjekt.id,
        linearTitel: planungsobjekt.titel,
        vorgeplantePlanungsobjekte: eingeplant,
      });
      return;
    }

    // Sicherstellen, dass sich der Sendeplatz verändert hat
    const isNewDropList = droppedInNewContainer(event);
    if (isNewDropList) {
      const oldSendeplatz = SendeplatzUtils.isDraggedFromSendeplatzNotMerkliste(
        event.previousContainer.data,
      );
      this.facade.movePlanungsobjektInAnsicht({
        newSendeplatz,
        oldSendeplatz: oldSendeplatz,
        planungsobjekt,
      });
    }
  }

  onOpenPlanungshinweiseWindow() {
    const kanal = this.sendeplatz.kanal;
    this.facade.openPlanungshinweiseWindow({
      kanal,
      start: this.sendeplatz.effektivVon,
      end: this.sendeplatz.effektivBis,
    });
  }

  openSendePlatzKontextmenu(e: ContextMenuEvent, sendeplatz: SendeplatzDto) {
    const payload = e.item as {
      data: {
        action: AktionEnum;
      };
    };

    switch (payload.data.action) {
      case AktionEnum.NACH_PHAROS_UEBERTRAGEN:
        this.facade.openNachPharosUebertragenWindow(sendeplatz);
        break;
      case AktionEnum.SENDEPLATZ_DETAILS:
        this.facade.openSendeplatzWindow(sendeplatz);
        break;
    }
  }
}
