import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GetPlanungsobjekteLinearByAnsichtenBlockQuery } from "src/app/models/openapi/model/get-planungsobjekte-linear-by-ansichten-block-query";
import { GetPlanungsobjekteLinearByAnsichtenSendeplatzQuery } from "src/app/models/openapi/model/get-planungsobjekte-linear-by-ansichten-sendeplatz-query";
import { MovePlanungsobjektLinearToVorgemerktCommand } from "src/app/models/openapi/model/move-planungsobjekt-linear-to-vorgemerkt-command";
import { MovePlanungsobjektLinearToVorgeplantBlockCommand } from "src/app/models/openapi/model/move-planungsobjekt-linear-to-vorgeplant-block-command";
import { MovePlanungsobjektLinearToVorgeplantSendeplatzCommand } from "src/app/models/openapi/model/move-planungsobjekt-linear-to-vorgeplant-sendeplatz-command";
import { MovePlanungsobjektLinearToVorgeschlagenCommand } from "src/app/models/openapi/model/move-planungsobjekt-linear-to-vorgeschlagen-command";
import { MovePlanungsobjekteLinearToVorgeplantBlockCommand } from "src/app/models/openapi/model/move-planungsobjekte-linear-to-vorgeplant-block-command";
import { MovePlanungsobjekteLinearToVorgeplantSendeplatzCommand } from "src/app/models/openapi/model/move-planungsobjekte-linear-to-vorgeplant-sendeplatz-command";
import { PlanungsobjektLinearDto } from "src/app/models/openapi/model/planungsobjekt-linear-dto";
import { PlanungsobjektLinearDtoIEnumerableResultDto } from "src/app/models/openapi/model/planungsobjekt-linear-dto-i-enumerable-result-dto";
import { PlanungsobjektLinearDtoResultDto } from "src/app/models/openapi/model/planungsobjekt-linear-dto-result-dto";
import { PlanungsobjektLinearVorgemerktAktualisierenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgemerkt-aktualisieren-command";
import { PlanungsobjektLinearVorgemerktErstellenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgemerkt-erstellen-command";
import { PlanungsobjektLinearVorgemerktKopierenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgemerkt-kopieren-command";
import { PlanungsobjektLinearVorgeplantBlockAktualisierenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeplant-block-aktualisieren-command";
import { PlanungsobjektLinearVorgeplantBlockErstellenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeplant-block-erstellen-command";
import { PlanungsobjektLinearVorgeplantBlockKopierenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeplant-block-kopieren-command";
import { PlanungsobjektLinearVorgeplantBlockZuSerieUmwandelnCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeplant-block-zu-serie-umwandeln-command";
import { PlanungsobjektLinearVorgeplantErstellenMitGetitCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeplant-erstellen-mit-getit-command";
import { PlanungsobjektLinearVorgeplantSendeplatzAktualisierenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeplant-sendeplatz-aktualisieren-command";
import { PlanungsobjektLinearVorgeplantSendeplatzErstellenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeplant-sendeplatz-erstellen-command";
import { PlanungsobjektLinearVorgeplantSendeplatzKopierenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeplant-sendeplatz-kopieren-command";
import { PlanungsobjektLinearVorgeplantSendeplatzZuSerieUmwandelnCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeplant-sendeplatz-zu-serie-umwandeln-command";
import { PlanungsobjektLinearVorgeplantZuweisenZuMengengeruestCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeplant-zuweisen-zu-mengengeruest-command";
import { PlanungsobjektLinearVorgeschlagenAktualisierenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeschlagen-aktualisieren-command";
import { PlanungsobjektLinearVorgeschlagenErsetzenZuVorgeplantSendeplatzCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeschlagen-ersetzen-zu-vorgeplant-sendeplatz-command";
import { PlanungsobjektLinearVorgeschlagenErstellenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeschlagen-erstellen-command";
import { PlanungsobjektVerknuepfenMitGetitCommand } from "src/app/models/openapi/model/planungsobjekt-verknuepfen-mit-getit-command";
import { PlanungsobjekteDtoResultDto } from "src/app/models/openapi/model/planungsobjekte-dto-result-dto";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { SendeplatzDtoIEnumerableResultDto } from "src/app/models/openapi/model/sendeplatz-dto-i-enumerable-result-dto";
import { SendeplatzDtoIReadOnlyCollectionResultDto } from "src/app/models/openapi/model/sendeplatz-dto-i-read-only-collection-result-dto";
import { SendeplatzDtoResultDto } from "src/app/models/openapi/model/sendeplatz-dto-result-dto";
import { PublitFrontendSettings } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PlanungsobjektLinearApiService {
  private readonly apiUrl = this.settings.apiUrl;
  private readonly basePath = "PlanungsobjektLinear";

  constructor(
    private http: HttpClient,
    private settings: PublitFrontendSettings,
  ) {}

  getPlanungsobjektLinearById$(planungsobjektId: string) {
    return this.http.get<PlanungsobjektLinearDto>(
      `${this.apiUrl}${this.basePath}/${planungsobjektId}`,
    );
  }

  getPlanungsobjekteLinearByAnsichtBlock$(ansichtId: string) {
    return this.http.get<PlanungsobjektLinearDto[]>(
      `${this.apiUrl}${this.basePath}/Vorgeplant/Block/ByAnsicht/${ansichtId}`,
    );
  }

  getPlanungsobjekteLinearByAnsichtenBlock$(query: GetPlanungsobjekteLinearByAnsichtenBlockQuery) {
    return this.http.post<PlanungsobjektLinearDto[]>(
      `${this.apiUrl}${this.basePath}/Vorgeplant/Block/ByAnsichten`,
      query,
    );
  }

  getPlanungsobjekteLinearByAnsichtSendeplatz$(ansichtId: string) {
    return this.http.get<PlanungsobjektLinearDto[]>(
      `${this.apiUrl}${this.basePath}/Vorgeplant/Sendeplatz/ByAnsicht/${ansichtId}`,
    );
  }

  getPlanungsobjekteLinearByAnsichtenSendeplatz$(
    query: GetPlanungsobjekteLinearByAnsichtenSendeplatzQuery,
  ) {
    return this.http.post<PlanungsobjektLinearDto[]>(
      `${this.apiUrl}${this.basePath}/Vorgeplant/Sendeplatz/ByAnsichten`,
      query,
    );
  }

  planungsobjektLinearVorgeschlagenErsetzenZuVorgeplantSendeplatz$(
    command: PlanungsobjektLinearVorgeschlagenErsetzenZuVorgeplantSendeplatzCommand,
  ) {
    return this.http.put<PlanungsobjektLinearDtoResultDto>(
      `${this.apiUrl}${this.basePath}/Vorgeschlagen/${command.linearId}/Ersetzen/Vorgeplant`,
      command,
    );
  }

  planungsobjektLinearVorgeplantZuweisenZuMengengeruest$(
    command: PlanungsobjektLinearVorgeplantZuweisenZuMengengeruestCommand,
  ) {
    return this.http.put<void>(
      `${this.apiUrl}${this.basePath}/Vorgeplant/${command.planungsobjektId}/Mengengeruest`,
      command,
    );
  }

  planungsobjektLinearVorgeplantSendeplatzKopieren$(
    command: PlanungsobjektLinearVorgeplantSendeplatzKopierenCommand,
  ) {
    return this.http.post<SendeplatzDtoResultDto>(
      `${this.apiUrl}${this.basePath}/Vorgeplant/Sendeplatz/${command.planungsobjektId}/Kopieren`,
      command,
    );
  }

  planungsobjektLinearVorgemerktAktualisieren$(
    command: PlanungsobjektLinearVorgemerktAktualisierenCommand,
  ) {
    return this.http.put<PlanungsobjektLinearDto>(
      `${this.apiUrl}${this.basePath}/Vorgemerkt/${command.planungsobjektLinearId}`,
      command,
    );
  }

  planungsobjektLinearVorgeplantSendeplatzZuSerieUmwandeln$(
    command: PlanungsobjektLinearVorgeplantSendeplatzZuSerieUmwandelnCommand,
  ) {
    return this.http.post<SendeplatzDtoIEnumerableResultDto>(
      `${this.apiUrl}${this.basePath}/Vorgeplant/Sendeplatz/${command.planungsobjektId}/Serie`,
      command,
    );
  }

  planungsobjektLinearVorgeplantBlockZuSerieUmwandeln$(
    command: PlanungsobjektLinearVorgeplantBlockZuSerieUmwandelnCommand,
  ) {
    return this.http.post<PlanungsobjektLinearDtoIEnumerableResultDto>(
      `${this.apiUrl}${this.basePath}/Vorgeplant/Block/${command.planungsobjektId}/Serie`,
      command,
    );
  }

  planungsobjektLinearVorgeplantErstellenMitGetit$(
    command: PlanungsobjektLinearVorgeplantErstellenMitGetitCommand,
  ) {
    return this.http.post<PlanungsobjektLinearDto>(
      `${this.apiUrl}${this.basePath}/Vorgeplant/Getit`,
      command,
    );
  }

  verknuepfePlanungsobjektMitGetit$(command: PlanungsobjektVerknuepfenMitGetitCommand) {
    return this.http.put<PlanungsobjekteDtoResultDto>(
      `${this.apiUrl}${this.basePath}/Update/Getit`,
      command,
    );
  }

  planungsobjektLinearVorgeschlagenErstellen$(
    command: PlanungsobjektLinearVorgeschlagenErstellenCommand,
  ) {
    return this.http.post<PlanungsobjektLinearDto>(
      `${this.apiUrl}${this.basePath}/Vorgeschlagen`,
      command,
    );
  }

  planungsobjektLinearVorgeschlagenAktualisieren$(
    command: PlanungsobjektLinearVorgeschlagenAktualisierenCommand,
  ) {
    return this.http.put<PlanungsobjektLinearDto>(
      `${this.apiUrl}${this.basePath}/Vorgeschlagen/${command.planungsobjektLinearId}`,
      command,
    );
  }

  movePlanungsobjektLinearToVorgeschlagen$(
    command: MovePlanungsobjektLinearToVorgeschlagenCommand,
  ) {
    return this.http.put<SendeplatzDtoResultDto>(
      `${this.apiUrl}${this.basePath}/${command.id}/Verschieben/Vorgeschlagen`,
      command,
    );
  }

  movePlanungsobjektLinearToVorgeplantSendeplatz$(
    command: MovePlanungsobjektLinearToVorgeplantSendeplatzCommand,
  ) {
    return this.http.put<SendeplatzDtoResultDto>(
      `${this.apiUrl}${this.basePath}/${command.id}/Verschieben/Vorgeplant/Sendeplatz`,
      command,
    );
  }

  movePlanungsobjekteLinearToVorgeplantSendeplatz$(
    command: MovePlanungsobjekteLinearToVorgeplantSendeplatzCommand,
  ) {
    return this.http.put<SendeplatzDtoIReadOnlyCollectionResultDto>(
      `${this.apiUrl}${this.basePath}/Verschieben/Vorgeplant/Sendeplatz`,
      command,
    );
  }

  planungsobjektLinearVorgeplantSendeplatzAktualisieren$(
    command: PlanungsobjektLinearVorgeplantSendeplatzAktualisierenCommand,
  ) {
    return this.http.put<PlanungsobjektLinearDto>(
      `${this.apiUrl}${this.basePath}/Vorgeplant/Sendeplatz/${command.id}`,
      command,
    );
  }

  planungsobjektLinearVorgeplantSendeplatzErstellen$(
    command: PlanungsobjektLinearVorgeplantSendeplatzErstellenCommand,
  ) {
    return this.http.post<SendeplatzDto>(
      `${this.apiUrl}${this.basePath}/Vorgeplant/Sendeplatz`,
      command,
    );
  }

  planungsobjektLinearVorgeplantBlockKopieren$(
    command: PlanungsobjektLinearVorgeplantBlockKopierenCommand,
  ) {
    return this.http.post<PlanungsobjektLinearDtoResultDto>(
      `${this.apiUrl}${this.basePath}/Vorgeplant/Block/${command.planungsobjektId}/Kopieren`,
      command,
    );
  }

  planungsobjektLinearVorgemerktKopieren$(command: PlanungsobjektLinearVorgemerktKopierenCommand) {
    return this.http.post<PlanungsobjektLinearDto>(
      `${this.apiUrl}${this.basePath}/Vorgemerkt/${command.planungsobjektId}/Kopieren`,
      command,
    );
  }

  movePlanungsobjektLinearToVorgemerkt$(command: MovePlanungsobjektLinearToVorgemerktCommand) {
    return this.http.put<PlanungsobjektLinearDtoResultDto>(
      `${this.apiUrl}${this.basePath}/${command.planungsobjektId}/Verschieben/Vorgemerkt`,
      command,
    );
  }

  planungsobjektLinearVorgemerktErstellen$(
    command: PlanungsobjektLinearVorgemerktErstellenCommand,
  ) {
    return this.http.post<PlanungsobjektLinearDto>(
      `${this.apiUrl}${this.basePath}/Vorgemerkt`,
      command,
    );
  }

  planungsobjektLinearVorgeplantBlockErstellen$(
    command: PlanungsobjektLinearVorgeplantBlockErstellenCommand,
  ) {
    return this.http.post<PlanungsobjektLinearDto>(
      `${this.apiUrl}${this.basePath}/Vorgeplant/Block`,
      command,
    );
  }

  planungsobjektLinearVorgeplantBlockAktualisieren$(
    command: PlanungsobjektLinearVorgeplantBlockAktualisierenCommand,
  ) {
    return this.http.put<PlanungsobjektLinearDtoResultDto>(
      `${this.apiUrl}${this.basePath}/Vorgeplant/Block/${command.id}`,
      command,
    );
  }

  movePlanungsobjektLinearToVorgeplantBlock$(
    command: MovePlanungsobjektLinearToVorgeplantBlockCommand,
  ) {
    return this.http.put<PlanungsobjektLinearDtoResultDto>(
      `${this.apiUrl}${this.basePath}/${command.id}/Verschieben/Vorgeplant/Block`,
      command,
    );
  }

  movePlanungsobjekteLinearToVorgeplantBlock$(
    command: MovePlanungsobjekteLinearToVorgeplantBlockCommand,
  ) {
    return this.http.put<PlanungsobjektLinearDtoIEnumerableResultDto>(
      `${this.apiUrl}${this.basePath}/Verschieben/Vorgeplant/Block`,
      command,
    );
  }
}
