import { Component, inject } from "@angular/core";
import { RedaktionOptionsFiltered } from "src/app/models/enums/redaktion";
import { Icons } from "src/app/models/icons";
import {
  PlanungsobjektFarbenOptions,
  planungsobjektFarbgebungMap,
} from "src/app/models/viewmodels/planungsobjekt-viewmodel";
import { PlanungsobjektWindowFacade } from "../../planungsobjekt-window.facade";

@Component({
  selector: "app-planungsinhalte",
  templateUrl: "./planungsinhalte.component.html",
  styleUrls: ["./planungsinhalte.component.scss"],
})
export class PlanungsinhalteComponent {
  readonly facade = inject(PlanungsobjektWindowFacade);

  readonly RedaktionOptionsFiltered = RedaktionOptionsFiltered;
  readonly planungsobjektFarbgebungMap = planungsobjektFarbgebungMap;
  readonly PlanungsobjektFarbenOptions = PlanungsobjektFarbenOptions;
  readonly Icons = Icons;
}
