import { createSelector } from "@ngrx/store";
import { PlanungsobjektWindowOnDemandFormData } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.model";
import planungsobjektWindowSelectors from "../planungsobjekt-window.selectors";
import { OnDemandFormNgRx } from "./on-demand.form.model";

/**
 * Selektor, der abhängig von den Toggles im OnDemand Beziehung Formular die relationalen
 * oder die absoluten Werte zurückgibt und die anderen Werte auf `null` setzt.
 */
const selectOnDemandFormMitRelationalOderAbsolutWerten = createSelector(
  planungsobjektWindowSelectors.onDemandForm.selectFormData,
  (onDemandFormValue): PlanungsobjektWindowOnDemandFormData | undefined => {
    const {
      onlineAbZeit,
      reihenfolgeHerstellen,
    } = onDemandFormValue;

    return {
      onlineAbZeit,
      reihenfolgeHerstellen,
      ...resolveOnlineAbRelativVsAbsolut(onDemandFormValue),
      ...resolveOnlineBisRelativVsAbsolut(onDemandFormValue),
    };
  },
);


const resolveOnlineAbRelativVsAbsolut = (form: OnDemandFormNgRx) => {
  const modus = form.wunschOnlineAbVsRelationalZuLinearToggle
    ? "relativ"
    : "absolut";

  switch(modus) {
    case "absolut":
      return {
        relationZuLinearInTagen: null,
        minDistanz: null,
        maxDistanz: null,
        wunschOnlineAb: form.wunschOnlineAb,
      };
    case "relativ":
      const relationZuLinearInTagen = form.relationZuLinearInTagen;
      const minDistanz = form.minDistanz;
      const maxDistanz = form.maxDistanz;
      return {
        relationZuLinearInTagen,
        minDistanz: minDistanz === null ? relationZuLinearInTagen : minDistanz,
        maxDistanz: maxDistanz === null ? relationZuLinearInTagen : maxDistanz,
        wunschOnlineAb: null,
      };
  }
};

const resolveOnlineBisRelativVsAbsolut = (form: OnDemandFormNgRx) => {
  const modus = form.wunschOnlineBisVsVerweildauerToggle
    ? "relativ"
    : "absolut";	

  switch(modus) {
    case "absolut":
      return {
        verweildauerInTagen: null,
        wunschOnlineBis: form.wunschOnlineBis,
      };
    case "relativ":
      const verweildauerInTagen = form.verweildauerInTagen;
      return {
        verweildauerInTagen,
        wunschOnlineBis: null,
      };
  }
};



export default {
  selectOnDemandFormMitRelationalOderAbsolutWerten,
};
