import { createFeature, createReducer, on } from "@ngrx/store";
import { mengengeruestWindowActions } from "./mengengeruest-window.actions";
import {
  MengengeruestWindowFeatureState,
  initialMengengeruestWindowFeatureState,
} from "./mengengeruest-window.model";

const reducer = createReducer(
  initialMengengeruestWindowFeatureState,
  on(
    mengengeruestWindowActions.openMengengeruestWindow,
    (_, { input }): MengengeruestWindowFeatureState => ({
      ...initialMengengeruestWindowFeatureState,
      input,
    }),
  ),
  on(
    mengengeruestWindowActions.setActiveMengengeruesteintragId,
    (state, { mengengeruesteintragId }): MengengeruestWindowFeatureState => ({
      ...state,
      activeMengengeruesteintragId: mengengeruesteintragId,
    }),
  ),
);

export const mengengeruestWindowFeature = createFeature({
  name: "mengengeruestWindow",
  reducer,
});
