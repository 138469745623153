import { createFeature, createReducer, on } from "@ngrx/store";
import { onNgrxForms } from "ngrx-forms";
import { mergeReducers } from "src/app/utils/ngrx-utils";
import { mengengeruesteintragWindowActions } from "./mengengeruesteintrag-window.actions";
import { mengengeruesteintragWindowFormReducer } from "./mengengeruesteintrag-window.form";
import {
  MengengeruesteintragWindowFeatureState,
  initialMengengeruesteintragWindowFeatureState,
} from "./mengengeruesteintrag-window.model";

const mengengeruesteintragWindowFeatureReducer = createReducer(
  initialMengengeruesteintragWindowFeatureState,
  on(
    mengengeruesteintragWindowActions.openWindow,
    (_, { input }): MengengeruesteintragWindowFeatureState => ({
      ...initialMengengeruesteintragWindowFeatureState,
      input,
      isOpen: true,
    }),
  ),
  on(
    mengengeruesteintragWindowActions.windowClosed,
    (_): MengengeruesteintragWindowFeatureState => ({
      ...initialMengengeruesteintragWindowFeatureState,
      isOpen: false,
    }),
  ),
  onNgrxForms(),
);

const reducer = mergeReducers(
  mengengeruesteintragWindowFormReducer,
  mengengeruesteintragWindowFeatureReducer,
);

export const mengengeruesteintragWindowFeature = createFeature({
  name: "mengengeruesteintragWindow",
  reducer,
});
