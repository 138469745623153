import { inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { pharosExportWindowActions } from "src/app/core/stores/pharos-export-window/pharos-export-window.actions";
import { pharosExportWindowFormActions } from "src/app/core/stores/pharos-export-window/pharos-export-window.form";
import { PharosExportWindowFormData } from "src/app/core/stores/pharos-export-window/pharos-export-window.model";
import { pharosExportWindowSelectors } from "src/app/core/stores/pharos-export-window/pharos-export-window.selectors";

@Injectable()
export class PharosExportWindowFacade {
  private readonly store = inject(Store);

  public readonly windowInput$ = this.store.select(pharosExportWindowSelectors.selectWindowInput);
  public readonly pharosWindowViewModel$ = this.store.select(
    pharosExportWindowSelectors.selectPharosWindowViewModel,
  );
  public readonly formState$ = this.store.select(pharosExportWindowSelectors.selectFormState);

  public save() {
    this.store.dispatch(pharosExportWindowFormActions.trySaving());
  }

  public close() {
    this.store.dispatch(pharosExportWindowActions.tryClosingWithoutSaving());
  }

  public setFormFieldValue(field: keyof PharosExportWindowFormData, value: string) {
    this.store.dispatch(
      pharosExportWindowFormActions.patchValue({
        value: {
          [field]: value,
        },
      }),
    );
    this.store.dispatch(pharosExportWindowFormActions.markAllAsDirty());
  }

  public setFieldsDisabledState(fields: (keyof PharosExportWindowFormData)[], disabled: boolean) {
    if (disabled) {
      this.disableControls(fields);
    } else {
      this.enableControls(fields);
    }
  }

  public disableControls(controls: (keyof PharosExportWindowFormData)[]) {
    this.store.dispatch(pharosExportWindowFormActions.disableControls({ controls }));
  }

  public enableControls(controls: (keyof PharosExportWindowFormData)[]) {
    this.store.dispatch(pharosExportWindowFormActions.enableControls({ controls }));
  }
}
