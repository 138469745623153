import { createFormGroupState, FormGroupState } from "ngrx-forms";
import { PlanungsobjektWindowOnDemandFormData } from "../planungsobjekt-window.model";

export const ON_DEMAND_FORM_ID = "OnDemandForm";
export type OnDemandFormState = FormGroupState<OnDemandFormNgRx>;

export type OnDemandFormNgRx = PlanungsobjektWindowOnDemandFormData & {
  wunschOnlineAbVsRelationalZuLinearToggle: boolean;
  wunschOnlineBisVsVerweildauerToggle: boolean;
};

const emptyOnDemandForm: OnDemandFormNgRx = {
  wunschOnlineAb: null,
  onlineAbZeit: null,
  wunschOnlineBis: null,
  reihenfolgeHerstellen: false,
  relationZuLinearInTagen: 0,
  minDistanz: 0,
  maxDistanz: 0,
  verweildauerInTagen: 0,
  // Diese Dinge sind nicht Teil des DTOs, sondern nur für die Formularsteuerung
  wunschOnlineAbVsRelationalZuLinearToggle: false,
  wunschOnlineBisVsVerweildauerToggle: false,
};

export const initialOnDemandFormState = createFormGroupState(ON_DEMAND_FORM_ID, emptyOnDemandForm);
