import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { filter, map } from "rxjs";
import { DurationService } from "src/app/services/duration.service";
import { DurationUtils, UnifyDurationDialogInput } from "src/app/utils/duration-utils";
import { planungsobjektActions } from "../planungsobjekt/planungsobjekt.actions";
import planungsobjektSelectors from "../planungsobjekt/planungsobjekt.selectors";
import { durationActions } from "./duration.actions";

@Injectable()
export class DurationEffects {
  openUnifyDurationDialogForUpdatePlanungsobjekt$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.updatePlanungsobjektOnSendeplatz),
      filter(
        ({ command, oldPlanungsobjekt }) =>
          !!command.planlaenge && command.planlaenge !== oldPlanungsobjekt.planlaenge,
      ),
      map(({ command, oldPlanungsobjekt, sendeplatz }) =>
        this._openUnifyDurationDialogIfNecessary([
          {
            sendeplatz,
            planungsobjekt: oldPlanungsobjekt,
            planlaengeInSeconds: command.planlaenge,
          },
        ]),
      ),
    );
  });

  openUnifyDurationDialogForMengengeruest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.assignPlanungsobjektToMengengeruestSuccess),
      filter(({ sendeplatz }) => !!sendeplatz),
      map(({ sendeplatz, planungsobjekt, mengengeruesteintragId }) =>
        this._openUnifyDurationDialogIfNecessary([
          {
            sendeplatz,
            planungsobjekt,
            overrideHasMengengeruest: !!mengengeruesteintragId,
          },
        ]),
      ),
    );
  });

  openUnifyDurationDialogForZuSerieUmwandeln$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.planungsobjektZuSerieUmwandelnSendeplatzSuccess),
      concatLatestFrom(({ planungsobjektId }) =>
        this.store.select(planungsobjektSelectors.selectPlanungsobjektById(planungsobjektId)),
      ),
      map(([{ sendeplaetze }, planungsobjekt]) =>
        planungsobjekt
          ? this._openUnifyDurationDialogIfNecessary(
              sendeplaetze.map((sendeplatz) => ({ sendeplatz, planungsobjekt })),
            )
          : durationActions.noUnificationNeeded(),
      ),
    );
  });

  comparePlanungsobjektAndSendeplatzDurationForMultiplePlanungsobjekte$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.moveMultiplePlanungsobjekteSuccess),
      map((action) =>
        action.sendeplaetze.result.map((sendeplatz, i) => ({
          planungsobjekt: action.planungsobjekte[i],
          sendeplatz: sendeplatz,
        })),
      ),
      map((planungsobjekte) => this._openUnifyDurationDialogIfNecessary(planungsobjekte)),
    );
  });

  comparePlanungsobjektAndSendeplatzDuration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        planungsobjektActions.movePlanungsobjektSuccess,
        planungsobjektActions.copyPlanungsobjektSuccess,
      ),
      map((action) => ({
        planungsobjekt: action.planungsobjekt,
        sendeplatz: action.sendeplatz.result,
      })),
      map((planungsobjektAndSendeplatz) => {
        // Sonderfall bei genau einer Planungsobjekt,
        // denn sie kann auf einen anderen Schemaplatz verschoben werden.
        // Bei mehreren Planungsobjekte ist das nicht der Fall.
        const oldPlanungsobjekt = planungsobjektAndSendeplatz.planungsobjekt;
        const sendeplatzDto = planungsobjektAndSendeplatz.sendeplatz;

        const foundPlanungsobjekt = sendeplatzDto.planungsobjekte.find(
          (s) => s.id === oldPlanungsobjekt.id,
        );
        if (
          oldPlanungsobjekt.mengengeruesteintragId &&
          foundPlanungsobjekt &&
          oldPlanungsobjekt.mengengeruesteintragId === foundPlanungsobjekt.mengengeruesteintragId
        ) {
          // Längenvergleichdialog nur aufrufen wenn
          // die VPF nicht an einen anderen Sendeplatz verschoben wird
          // und somit das Mengengerüst entfernt werden würde
          return this._openUnifyDurationDialogIfNecessary([
            {
              sendeplatz: sendeplatzDto,
              planungsobjekt: oldPlanungsobjekt,
            },
          ]);
        }
        return durationActions.noUnificationNeeded();
      }),
    );
  });

  openUnifyDurationDialogForVorschlagVerschiebung$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        planungsobjektActions.movePlanungsobjektVorgeschlagenToVorgeplantSuccess,
        planungsobjektActions.replacePlanungsobjektWithVorschlagSuccess,
      ),
      filter(
        ({ planungsobjekt }) =>
          !!planungsobjekt.mengengeruesteintragId && !!planungsobjekt.planlaenge,
      ),
      map(({ sendeplatz, planungsobjekt }) =>
        this._openUnifyDurationDialogIfNecessary([{ sendeplatz, planungsobjekt }]),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private durationService: DurationService,
    private store: Store,
  ) {}

  private _openUnifyDurationDialogIfNecessary(groups: UnifyDurationDialogInput[]) {
    const needsUnification = DurationUtils.checkUnifyDuration(groups);
    return needsUnification
      ? durationActions.openUnifyDurationDialog({
          ...needsUnification,
        })
      : durationActions.noUnificationNeeded();
  }
}
