import { inject, Injectable } from "@angular/core";
import { PlanungshinweiseWindowComponent } from "../shared/windows/planunshinweise-window/planungshinweise-window.component";
import { BigWindow } from "../shared/windows/window.templates";
import { CustomWindowService } from "./custom-window.service";
import { Store } from "@ngrx/store";
import { planungshinweiseWindowActions } from "../core/stores/planungshinweise-window/planungshinweise-window.actions";

@Injectable({
  providedIn: "root",
})
export class PlanungshinweiseService {
  private readonly customWindowService = inject(CustomWindowService);
  private readonly store = inject(Store);

  openPlanungshinweiseWindow() {
    const windowRef = this.customWindowService.open<PlanungshinweiseWindowComponent>({
      content: PlanungshinweiseWindowComponent,
      title: "Planungshinweise",
      ...BigWindow(),
      preventClose: () => {
        this.store.dispatch(planungshinweiseWindowActions.tryCloseWindow());
        return true;
      }
    });

    return windowRef;
  }
}
