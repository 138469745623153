import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { from, map, mergeMap, switchMap } from "rxjs";
import { PlanungshinweisApiService } from "src/app/api/planungshinweis/planungshinweis.api.service";
import { ansichtActions } from "../ansicht/ansicht.actions";
import { multiansichtActions } from "../multiansicht/multiansicht.actions";
import { multiAnsichtFeature } from "../multiansicht/multiansicht.reducer";
import { planungshinweisActions } from "./planungshinweis.actions";

@Injectable()
export class PlanungshinweisEffects {
  loadPlanungshinweiseForAnsicht$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungshinweisActions.loadPlanungshinweiseByAnsicht),
      switchMap(({ ansichtId }) => this.api.getPlanungshinweiseByAnsicht$(ansichtId)),
      map((planungshinweise) =>
        planungshinweisActions.loadPlanungshinweiseByAnsichtSuccess({ planungshinweise }),
      ),
    );
  });

  loadPlanungshinweiseForAnsichten$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungshinweisActions.loadPlanungshinweiseByAnsichten),
      switchMap(({ ansichtenIds }) => this.api.getPlanungshinweiseByAnsichten$(ansichtenIds)),
      map((planungshinweise) =>
        planungshinweisActions.loadPlanungshinweiseByAnsichtenSuccess({ planungshinweise }),
      ),
    );
  });

  /**
   * Wird bei einem Wechsel der kompletten Ansicht(definition) aufgerufen.
   */
  ansichtChanged$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(multiansichtActions.setMultiansichtSuccess),
      mergeMap(({ multiAnsichtViewModel }) => {
        const actions = multiAnsichtViewModel.ansichtViewModels
          .filter((ansichtViewModel) => ansichtViewModel.visible)
          .map((ansichtViewModel) =>
            planungshinweisActions.loadPlanungshinweiseByAnsicht({
              ansichtId: ansichtViewModel.ansichtViewModel.id,
            }),
          );
        return from(actions);
      }),
    );
  });

  /**
   * Wird bei einer Erweiterung der aktuellen Ansicht um ein neues Jahr aufgerufen.
   */
  ansichtExpanded$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(multiansichtActions.updateAnsichtInMultiansichtSuccess),
      map(({ additionalAnsichtViewModelId }) =>
        planungshinweisActions.loadPlanungshinweiseByAnsicht({
          ansichtId: additionalAnsichtViewModelId,
        }),
      ),
    );
  });

  /**
   * Wird ausgelöst, wenn der Anwender auf die Benachrichtigung "Ansicht aktualisieren" klickt.
   */
  ansichtReload$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ansichtActions.updateAllEntitiesInAnsicht),
      concatLatestFrom(() => this.store.select(multiAnsichtFeature.selectMultiAnsichtViewModel)),
      map(([, multiAnsichtViewModel]) => {
        const visibleAnsichten = multiAnsichtViewModel.ansichtViewModels
          .filter((ansichtViewModel) => ansichtViewModel.visible)
          .map((ansichtViewModel) => ansichtViewModel.ansichtViewModel.id);
        return planungshinweisActions.loadPlanungshinweiseByAnsichten({
          ansichtenIds: visibleAnsichten,
        });
      }),
    );
  });

  createPlanungshinweis$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungshinweisActions.createPlanungshinweis),
      switchMap(({ command }) => this.api.createPlanungshinweis$(command)),
      map((response) =>
        planungshinweisActions.createPlanungshinweisSuccess({
          planungshinweis: response.result,
        }),
      ),
    );
  });

  updatePlanungshinweis$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungshinweisActions.updatePlanungshinweis),
      switchMap(({ command }) => this.api.updatePlanungshinweis$(command)),
      map((response) =>
        planungshinweisActions.updatePlanungshinweisSuccess({
          planungshinweis: response.result,
        }),
      ),
    );
  });

  deletePlanungshinweis$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungshinweisActions.deletePlanungshinweis),
      switchMap(({ id }) => this.api.deletePlanungshinweis$(id)),
      map((response) =>
        planungshinweisActions.deletePlanungshinweisSuccess({
          planungshinweis: response.result,
        }),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store,
    private api: PlanungshinweisApiService,
  ) {}
}
