import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { pharosExportWindowActions } from "../core/stores/pharos-export-window/pharos-export-window.actions";
import { PharosExportWindowComponent } from "../shared/windows/pharos-export-window/pharos-export-window.component";
import { BigWindow } from "../shared/windows/window.templates";
import { CustomWindowService } from "./custom-window.service";

@Injectable({
  providedIn: "root",
})
export class PharosWindowService {
  constructor(
    private windowService: CustomWindowService,
    private store: Store,
  ) {}

  onOpenNachPharosUebertragenWindow() {
    return this.windowService.open<PharosExportWindowComponent>({
      content: PharosExportWindowComponent,
      title: "Nach Pharos übertragen",
      ...BigWindow({ dataTestId: "pharos-export-window" }),
      preventClose: () => {
        this.store.dispatch(pharosExportWindowActions.tryClosingWithoutSaving());
        return true;
      },
    });
  }
}
