import { Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  ContextMenuEvent,
  ContextMenuPopupEvent,
  ContextMenuTargetContainerDirective,
} from "@progress/kendo-angular-menu";
import { mengengeruestWindowActions } from "src/app/core/stores/mengengeruest-window/mengengeruest-window.actions";
import { MengengeruestWindowUseCase } from "src/app/core/stores/mengengeruest-window/mengengeruest-window.model";
import { merklisteWindowActions } from "src/app/core/stores/merkliste/merkliste.window.actions";
import { planungsobjektWindowActions } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.actions";
import { planungsobjektActions } from "src/app/core/stores/planungsobjekt/planungsobjekt.actions";
import { serienWindowActions } from "src/app/core/stores/serien-window/serien-window.actions";
import { AktionEnum } from "src/app/models/enums/aktion";
import { AnsichtType } from "src/app/models/enums/ansicht-type";
import { Icons } from "src/app/models/icons";
import { CopyPattern } from "src/app/models/openapi/model/copy-pattern";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { PlanungsobjektLinearDto } from "src/app/models/openapi/model/planungsobjekt-linear-dto";
import { Redaktion } from "src/app/models/openapi/model/redaktion";
import { SchemaplatzDto } from "src/app/models/openapi/model/schemaplatz-dto";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";
import { PlanungsobjektInteraktionService } from "src/app/services/planungsobjekt-interaktion.service";
import { PlanungsobjektUtils } from "src/app/utils/planungsobjekt.utils";
import { PublitFrontendSettings } from "src/environments/environment";

@Component({
  selector: "app-planungsobjekt-vorgeplant",
  templateUrl: "./planungsobjekt-vorgeplant.component.html",
  styleUrls: ["./planungsobjekt-vorgeplant.component.scss"],
})
export class PlanungsobjektVorgeplantComponent {
  @Input() planungsobjekt: PlanungsobjektDto;
  @Input() planungsobjekteInMehrfachauswahlCount: number;
  @Input() isMengengeruestErlaubt: boolean;
  @Input() isMehrfachauswahlEnabled: boolean;
  @Input() sendeplatz: SendeplatzDto;
  @Input() schemaplatz: SchemaplatzDto;
  @Input() ansichtViewModel: AnsichtViewModel;
  @Input() target: ContextMenuTargetContainerDirective;

  CopyPattern = CopyPattern;
  AktionEnum = AktionEnum;
  Icons = Icons;

  Redaktion = Redaktion;

  constructor(
    protected planungsobjektInteraktionService: PlanungsobjektInteraktionService,
    protected settings: PublitFrontendSettings,
    private store: Store,
  ) {}

  onPlanungsobjektContextMenuSelect(e: ContextMenuEvent) {
    const {
      data: { action: actionEnum, copyPattern },
    } = e.item as {
      data: {
        action: AktionEnum;
        copyPattern: CopyPattern;
      };
    };
    const {
      data: { planungsobjekt },
    } = e.target as {
      data: {
        planungsobjekt: PlanungsobjektLinearDto;
      };
    };
    switch (actionEnum) {
      case AktionEnum.BEARBEITE_PLANUNGSOBJEKT:
        this.openPlanungsobjektLinearWithPlanungWindow(planungsobjekt);
        break;
      case AktionEnum.ONLINE_DETAILS:
        throw new Error("Funktion nicht implementiert. Kommt in Zukunft.");
      case AktionEnum.PLANUNGSOBJEKT_ZU_MENGENGERUESTEINTRAG:
        this.openMengengeruestForPlanungsobjektWindow(planungsobjekt, this.sendeplatz);
        break;
      case AktionEnum.PLANUNGSOBJEKT_IN_SERIE_UMWANDELN:
        this.openZuSerieUmwandelnWindow(planungsobjekt);
        break;
      case AktionEnum.KOPIE_HIER:
      case AktionEnum.KOPIE_NAECHSTE_WOCHE:
        this.copyPlanungsobjekt(planungsobjekt, copyPattern);
        e.sender.hide();
        break;
      case AktionEnum.PLANUNGSOBJEKT_AUF_MERKLISTE:
        this.store.dispatch(
          merklisteWindowActions.openMovePlanungsobjektLinearToMerklisteDialog({
            planungsobjektLinearId: planungsobjekt.id,
            planungsobjektLinearTitel: planungsobjekt.titel,
          }),
        );
        break;
    }
  }

  onPlanungsobjektContextMenuOpen(event: ContextMenuPopupEvent) {
    if (event.originalEvent instanceof PointerEvent) {
      event.originalEvent.stopPropagation();
    }
  }

  openPlanungsobjektLinearWithPlanungWindow(planungsobjekt: PlanungsobjektDto) {
    this.store.dispatch(
      planungsobjektWindowActions.openPlanungsobjektLinearWithPlanungOnSendeplatzWindow({
        linearId: planungsobjekt.id,
      }),
    );
  }

  onClick(planungsobjekt: PlanungsobjektDto) {
    this.planungsobjektInteraktionService.onPlanungsobjektClick(
      planungsobjekt,
      this.isMehrfachauswahlEnabled,
    );
  }

  openZuSerieUmwandelnWindow(planungsobjekt: PlanungsobjektDto) {
    this.store.dispatch(
      serienWindowActions.openWindow({ context: AnsichtType.Liste, planungsobjekt }),
    );
  }

  openMengengeruestForPlanungsobjektWindow(
    planungsobjekt: PlanungsobjektDto,
    sendeplatz: SendeplatzDto,
  ) {
    this.store.dispatch(
      mengengeruestWindowActions.openMengengeruestWindow({
        input: {
          type: MengengeruestWindowUseCase.ZUWEISUNG_SENDEPLATZ,
          ansichtViewModel: this.ansichtViewModel,
          planungsobjekt,
          sendeplatz,
          schemaplatz: this.schemaplatz,
        },
      }),
    );
  }

  copyPlanungsobjekt(planungsobjekt: PlanungsobjektDto, copyPattern: CopyPattern) {
    this.store.dispatch(planungsobjektActions.copyPlanungsobjekt({ planungsobjekt, copyPattern }));
  }

  istMitProduktdatenbankVerknuepft = PlanungsobjektUtils.isConnectedWithProductDb;
}
