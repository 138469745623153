import { Component, Input, inject } from "@angular/core";
import { ContentCommunityOptions } from "src/app/models/enums/content-community";
import { FSKEinstufungOptions } from "src/app/models/enums/fsk-einstufung";
import { GenreOptionsFiltered, KeinGenreOption } from "src/app/models/enums/genre";
import { StofffuehrendeRedaktionOptionsFiltered } from "src/app/models/enums/stofffuehrende-redaktion";
import { Icons } from "src/app/models/icons";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import {
  PlanungsobjektFarbenOptions,
  planungsobjektFarbgebungMap,
} from "src/app/models/viewmodels/planungsobjekt-viewmodel";
import { PlanungsobjektWindowFacade } from "../../planungsobjekt-window.facade";
import { PlanungsobjektWindowUseCase } from "../../planungsobjekt-window.model";

@Component({
  selector: "app-produktinformationen",
  templateUrl: "./produktinformationen.component.html",
  styleUrls: ["./produktinformationen.component.scss"],
})
export class ProduktinformationenComponent {
  public readonly facade = inject(PlanungsobjektWindowFacade);

  readonly FSKEinstufungOptions = FSKEinstufungOptions;
  readonly StofffuehrendeRedaktionOptionsFiltered = StofffuehrendeRedaktionOptionsFiltered;
  readonly GenreOptionsFiltered = GenreOptionsFiltered;
  readonly KeinGenreOption = KeinGenreOption;
  readonly ContentCommunityOptions = ContentCommunityOptions;
  readonly planungsobjektFarbgebungMap = planungsobjektFarbgebungMap;
  readonly PlanungsobjektFarbenOptions = PlanungsobjektFarbenOptions;
  readonly Icons = Icons;

  @Input({ required: true }) planungsobjekt: PlanungsobjektDto | null;
  @Input({ required: true }) usecase: PlanungsobjektWindowUseCase;
}
