import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import {
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  filter,
  map,
  merge,
  of,
  switchMap,
} from "rxjs";
import { GetVariantenzeilenByPublikationKeyQuery } from "src/app/models/openapi/model/get-variantenzeilen-by-publikation-key-query";
import { BlockansichtService } from "src/app/pages/ansichten/blockansicht/blockansicht.service";
import { CrossUpdateUseCase, PlanungsobjektWindowUseCase } from "src/app/shared/windows/planungsobjekt-window/planungsobjekt-window.model";
import { allValuesDefined } from "src/app/utils/array-utils";
import { pairwiseWhen } from "src/app/utils/rxjs-utils";
import { planungsobjektFormActions } from "./planungsobjekt-form/planungsobjekt-window.form";
import { planungsobjektWindowWannWoActions } from "./planungsobjekt-window-wann-wo.actions";
import planungsobjektWindowWannWoSelectors from "./planungsobjekt-window-wann-wo.selectors";
import {
  berechneSynchronisierteOnDemandFormValues,
  berechneSynchronisiertePlanlaengenWerte,
  extractPlanlaengenPropertiesFromWindowInput,
  PlanlaengenProperties,
} from "./planungsobjekt-window-wann-wo.utils";
import { planungsobjektWindowActions } from "./planungsobjekt-window.actions";
import { SLIDER_LEFT_POSITION, SLIDER_RIGHT_POSITION, WannBezugOnDemandFormData } from "./planungsobjekt-window.model";
import planungsobjektWindowSelectors from "./planungsobjekt-window.selectors";

@Injectable()
// Provider sollte PlanungsobjektWindowFormService enthalten
export class PlanungsobjektWindowWannWoEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly blockansichtService = inject(BlockansichtService);

  /**
   * Effekt, der die verfügbaren Variantenzeilen für das aktuell geöffnete Planungsobjekt
   * anhand der übergebenen Query lädt.
   */
  fetchAvailableVariantenzeilen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilen),
      switchMap(({ query }) =>
        this.blockansichtService.loadVariantenzeilen$(query).pipe(
          map((variantenzeilen) =>
            planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilenSuccess({
              variantenzeilen,
            }),
          ),
          catchError((error: unknown) =>
            of(planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilenFailure({ error })),
          ),
        ),
      ),
    );
  });

  planlaengenHelperForPlanungsobjektWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openPlanungsobjektWindow),
      switchMap(() =>
        this.store.select(planungsobjektWindowSelectors.planungsForm.selectFormData).pipe(
          map(
            (formValue): PlanlaengenProperties => ({
              beginnzeit: formValue.beginnzeit,
              endzeit: formValue.endzeit,
              planlaenge: formValue.planlaenge,
              sendetag: formValue.sendetag,
            }),
          ),
          pairwiseWhen(
            this.store.select(
              planungsobjektWindowWannWoSelectors.selectCrossUpdateCondition(
                CrossUpdateUseCase.PLANLAENGENHELPER,
              ),
            ),
          ),
        ),
      ),
      map(([prevFormValue, nextFormValue]) => {
        return berechneSynchronisiertePlanlaengenWerte({
          nextFormValue,
          prevFormValue,
        });
      }),
      map((value) =>
        value
          ? planungsobjektFormActions.patchValue({ value })
          : planungsobjektWindowWannWoActions.noWannBezugChangesNecessary(),
      ),
    );
  });

  /**
   * Effekt, der die Aktualisierung der verfügbaren Varianten anstößt, wenn sich
   * die Werte für die Wann-Bezug Werte geändert haben.
   *
   * @see planungsobjektWindowWannWoActions.updateWannBezugValueSuccess
   * @see planlaengenHelperForPlanungsobjektWindow$
   */
  fetchAvailableVariantsAfterUpdateWannBezug$ = createEffect(() => {
    return this.store
      .select(planungsobjektWindowSelectors.planungsForm.selectPlanungsbezugFormValue)
      .pipe(
        distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next)),
        concatLatestFrom(() => [
          this.store.select(planungsobjektWindowSelectors.selectInput),
          this.store.select(planungsobjektWindowSelectors.selectKanal),
        ]),
        filter(([_, windowInput]) => windowInput?.usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT || 
          windowInput?.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT ||
          windowInput?.usecase === PlanungsobjektWindowUseCase.READONLY_LINEAR),
        map(([wannBezugForm, windowInput, kanal]) => {
          const { sendetag, beginnzeit, planlaenge } = wannBezugForm;

          if (!sendetag || !beginnzeit || planlaenge === null || !kanal) {
            return planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilenNotPossible();
          }

          const query: GetVariantenzeilenByPublikationKeyQuery = {
            planungsobjektId: windowInput?.planungsobjekt?.id ?? null,
            kanal,
            beginnzeit,
            sendetag,
            laenge: planlaenge,
          };

          return planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilenDebounced({
            query,
          });
        }),
      );
  });

  /**
   * Effekt, der die Aktualisierung der verfügbaren Varianten anstößt, wenn das Planungsobjekt
   * Fenster geöffnet wurde. Der Request für die verfügbaren Varianten wird anhand der Werte
   * aus dem Planungsobjekt-Fenster Input berechnet.
   */
  fetchAvailableVariantsAfterOpenPlanungsobjektWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openPlanungsobjektWindow),
      concatLatestFrom(() => this.store.select(planungsobjektWindowSelectors.selectKanal)),
      map(([{ input }, kanal]) => {
        const planlaengenProperties = extractPlanlaengenPropertiesFromWindowInput(input);
        if (!planlaengenProperties)
          return planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilenNotPossible();

        const { sendetag, beginnzeit, planlaenge } = planlaengenProperties;

        if (!sendetag || !beginnzeit || planlaenge === null) {
          return planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilenNotPossible();
        }

        const query: GetVariantenzeilenByPublikationKeyQuery = {
          planungsobjektId: input?.planungsobjektId ?? null,
          kanal,
          beginnzeit,
          sendetag,
          laenge: planlaenge,
        };

        return planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilen({ query });
      }),
    );
  });

  fetchAvailableVariantenzeilenDebounced$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilenDebounced),
      debounceTime(500),
      map(({ query }) =>
        planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilen({ query }),
      ),
    );
  });

  updateFormDisablement$ = createEffect(() => {
    return combineLatest(
      this.actions$.pipe(
        ofType(planungsobjektWindowWannWoActions.setInitialFormEnablements)
      ), 
      this.store
      .select(planungsobjektWindowWannWoSelectors.selectWunschOnlineBisVsVerweildauerToggle)
    )
      .pipe(
        map(([_, wunschOnlineBisVsVerweildauerToggle]) => {
          const fieldsToEnable: (keyof WannBezugOnDemandFormData)[] = [];
          const fieldsToDisable: (keyof WannBezugOnDemandFormData)[] = [];

          if (wunschOnlineBisVsVerweildauerToggle === SLIDER_RIGHT_POSITION) {
            fieldsToEnable.push("verweildauerInTagen");
            fieldsToDisable.push("onlineBis");
          } else {
            fieldsToDisable.push("verweildauerInTagen");
            fieldsToEnable.push("onlineBis");
          }

          return {
            fieldsToEnable,
            fieldsToDisable,
          };
        }),
        switchMap(({ fieldsToEnable, fieldsToDisable }) => {
          if (!fieldsToEnable.length && !fieldsToDisable.length) {
            return EMPTY;
          }
          return of(
            planungsobjektFormActions.enableControls({ controls: fieldsToEnable }),
            planungsobjektFormActions.disableControls({ controls: fieldsToDisable }),
          );
        }),
      );
  });

  calculateOnlineBisOrVerweildauer$ = createEffect(() => {
    return this.store.select(planungsobjektWindowSelectors.selectWannOnDemandFormValue).pipe(
      debounceTime(0),
      pairwiseWhen(
        this.store.select(
          planungsobjektWindowWannWoSelectors.selectCrossUpdateCondition(
            CrossUpdateUseCase.WANNBEZUG_ONDEMAND,
          ),
        ),
      ),
      filter(allValuesDefined),
      map(([prevFormValue, nextFormValue]) => {
        const formChanges = berechneSynchronisierteOnDemandFormValues({
          nextFormValue,
          prevFormValue,
        });
        return formChanges
          ? planungsobjektFormActions.patchValue({
              value: formChanges,
            })
          : planungsobjektWindowWannWoActions.noWannBezugChangesNecessary();
      }),
    );
  });
}
