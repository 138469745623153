import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";

export const planungsobjektFieldsFromGetit = [
  "getitId",
  "letzterGetitSync",
  "produktstatus",
  "fsk",
  "produkttitel",
  "produkttitelMultipart",
  "contentCommunities",
  "staffelnummer",
  "folgennummer",
  "gesamtfolgennummer",
  "inhaltsbeschreibung",
  "mitwirkende",
  "stofffuehrendeRedaktion",
  "genre",
] satisfies (keyof PlanungsobjektDto)[];

export type PlanungsobjektFieldFromGetit = (typeof planungsobjektFieldsFromGetit)[number];

export const extractGetitFieldsFromPlanungsobjektDto = (
  planungsobjekt: PlanungsobjektDto,
): Pick<PlanungsobjektDto, PlanungsobjektFieldFromGetit> => {
  return {
    getitId: planungsobjekt.getitId,
    letzterGetitSync: planungsobjekt.letzterGetitSync,
    produktstatus: planungsobjekt.produktstatus,
    fsk: planungsobjekt.fsk,
    produkttitel: planungsobjekt.produkttitel,
    produkttitelMultipart: planungsobjekt.produkttitelMultipart,
    contentCommunities: planungsobjekt.contentCommunities,
    staffelnummer: planungsobjekt.staffelnummer,
    folgennummer: planungsobjekt.folgennummer,
    gesamtfolgennummer: planungsobjekt.gesamtfolgennummer,
    inhaltsbeschreibung: planungsobjekt.inhaltsbeschreibung,
    mitwirkende: planungsobjekt.mitwirkende,
    stofffuehrendeRedaktion: planungsobjekt.stofffuehrendeRedaktion,
    genre: planungsobjekt.genre,
  };
};
