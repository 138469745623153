import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { PlanungshinweiseWindowInput } from "./planungshinweise-window.model";

export const planungshinweiseWindowActions = createActionGroup({
  source: "planungsHinweisWindow",
  events: {
    openPlanungshinweiseWindow: props<{ input: PlanungshinweiseWindowInput }>(),
    tryCloseWindow: emptyProps(),
    closeWindow: emptyProps(),
    windowClosed: emptyProps(),

    setIsDirty: props<{ isDirty: boolean }>(),
  },
});
