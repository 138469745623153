import { box, Boxed, createFormGroupState, FormGroupState } from "ngrx-forms";
import { ContentCommunity } from "src/app/models/openapi/model/content-community";
import { FSKEinstufung } from "src/app/models/openapi/model/fsk-einstufung";
import { Genre } from "src/app/models/openapi/model/genre";
import { GetitProduktStatus } from "src/app/models/openapi/model/getit-produkt-status";
import { PlanungsobjektFarbgebung } from "src/app/models/openapi/model/planungsobjekt-farbgebung";
import { Redaktion } from "src/app/models/openapi/model/redaktion";
import { StofffuehrendeRedaktion } from "src/app/models/openapi/model/stofffuehrende-redaktion";
import { PlanungsobjektWindowPlanungFormData } from "../planungsobjekt-window.model";

export const PLANUNGSOBJEKT_FORM_ID = "PlanungsobjektForm";
export type PlanungsobjektFormState = FormGroupState<PlanungsobjektWindowPlanungFormNgRx>;

/**
 * Wir nennen diesen Typen explizit mit NgRx am Ende,
 * da wir hier die Boxed-Typen verwenden, die von NgRx-Forms kommen.
 */
export type PlanungsobjektWindowPlanungFormNgRx = Omit<
  PlanungsobjektWindowPlanungFormData,
  "contentCommunities"
> & { contentCommunities: Boxed<ContentCommunity[]> };

const planungsinhalte = {
  titel: "",
  redaktion: null as Redaktion | null,
  genre: Genre.KEIN_GENRE,
  notiz: "",
  highlight: false,
  contentCommunities: box([] as ContentCommunity[]),
  farbgebung: PlanungsobjektFarbgebung.KEINE,
  fruehesteVeroeffentlichung: null as string | null,
  stofffuehrendeRedaktion: null as StofffuehrendeRedaktion | null,
  fsk: null as FSKEinstufung | null,
  staffelnummer: null as number | null,
  folgennummer: null as number | null,
  gesamtfolgennummer: null as string | null,
  inhaltsbeschreibung: null as string | null,
  mitwirkende: null as string | null,
};

const wannBezugAllgemein = {
  planlaenge: null as number | null,
};

const wannBezugOnDemand = {
  onlineAb: null as string | null,
  onlineAbZeit: null as string | null,
  onlineBis: null as string | null,
  verweildauerInTagen: null as number | null,
  wunschOnlineBisVsVerweildauerToggle: false,
};

const wannBezugLinear = {
  sendetag: null as string | null,
  beginnzeit: null as string | null,
  endzeit: null as string | null,
  variante: null as number | null,
};

const produktBezug = {
  getitId: null as string | null,
  letzterGetitSync: null as string | null,
  produktstatus: null as GetitProduktStatus | null,
  produkttitel: null as string | null,
  produkttitelMultipart: null as string | null,
};

export const planungsobjektEmptyForm = {
  ...planungsinhalte,
  ...wannBezugAllgemein,
  ...wannBezugOnDemand,
  ...wannBezugLinear,
  ...produktBezug,
};

export const initialPlanungsobjektFormState =
  createFormGroupState<PlanungsobjektWindowPlanungFormNgRx>(
    PLANUNGSOBJEKT_FORM_ID,
    planungsobjektEmptyForm,
  );
