import { inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { sendeplatzWindowActions } from "src/app/core/stores/sendeplatz-window/sendeplatz-window.actions";
import { sendeplatzWindowFormActions } from "src/app/core/stores/sendeplatz-window/sendeplatz-window.form";
import { sendeplatzWindowSelectors } from "src/app/core/stores/sendeplatz-window/sendeplatz-window.selectors";

@Injectable()
export class SendeplatzWindowFacade {
  private readonly store = inject(Store);

  public readonly viewModel$ = this.store.select(sendeplatzWindowSelectors.selectViewModel);

  public closeWindow(): void {
    this.store.dispatch(sendeplatzWindowActions.tryClosingWithoutSaving());
  }

  public save(): void {
    this.store.dispatch(sendeplatzWindowFormActions.trySaving());
  }
}
