import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap, take, tap } from "rxjs";
import { PlanungshinweiseService } from "src/app/services/planungshinweise.service";
import { planungshinweiseWindowActions } from "./planungshinweise-window.actions";
import { createConfirmClosingEffect } from "src/app/utils/ngrx-utils";
import { Store } from "@ngrx/store";
import { planungshinweiseWindowSelectors } from "./planungshinweise-window.selectors";

@Injectable()
export class PlanungshinweiseWindowEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly planungshinweiseService = inject(PlanungshinweiseService);

  openPlanungshinweisWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungshinweiseWindowActions.openPlanungshinweiseWindow),
      switchMap(() => {
        const windowRef = this.planungshinweiseService.openPlanungshinweiseWindow();

        return this.actions$.pipe(
          ofType(planungshinweiseWindowActions.closeWindow),
          take(1),
          tap(() => void windowRef.close()),
          map(() => planungshinweiseWindowActions.windowClosed()),
        );
      }),
    );
  });

  tryCloseWindow$ = createConfirmClosingEffect({
    tryClosingAction: planungshinweiseWindowActions.tryCloseWindow,
    preventClosingWhen$: this.store.select(planungshinweiseWindowSelectors.selectIsDirty),
    closingAction: planungshinweiseWindowActions.closeWindow,
  });
}
