import { Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { ContextMenuEvent, ContextMenuPopupEvent } from "@progress/kendo-angular-menu";
import { merklisteWindowActions } from "src/app/core/stores/merkliste/merkliste.window.actions";
import { planungsobjektWindowActions } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.actions";
import { planungsobjektActions } from "src/app/core/stores/planungsobjekt/planungsobjekt.actions";
import { serienWindowActions } from "src/app/core/stores/serien-window/serien-window.actions";
import { AktionEnum } from "src/app/models/enums/aktion";
import { AnsichtType } from "src/app/models/enums/ansicht-type";
import { Icons } from "src/app/models/icons";
import { CopyPattern } from "src/app/models/openapi/model/copy-pattern";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { PlanungsobjektLinearDto } from "src/app/models/openapi/model/planungsobjekt-linear-dto";
import { Role } from "src/app/models/openapi/model/role";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { PublitFrontendSettings } from "src/environments/environment";

@Component({
  selector: "app-planungsobjekt-vorschlag-list",
  templateUrl: "./planungsobjekt-vorgeschlagen-list.component.html",
  styleUrls: ["./planungsobjekt-vorgeschlagen-list.component.scss"],
})
export class PlanungsobjektVorgeschlagenListComponent {
  @Input() planungsobjekteInVorschlagspalte: PlanungsobjektDto[];
  @Input() sendeplatz: SendeplatzDto;

  protected AktionEnum = AktionEnum;
  protected Icons = Icons;
  protected Role = Role;

  constructor(
    private store: Store,
    protected settings: PublitFrontendSettings,
  ) {}

  onVorschlagContextMenuOpen(event: ContextMenuPopupEvent) {
    if (event.originalEvent instanceof PointerEvent) {
      event.originalEvent.stopPropagation();
    }
  }

  onPlanungsobjektContextMenuSelect(event: ContextMenuEvent) {
    const {
      data: { action: actionEnum },
    } = event.item as {
      data: {
        action: AktionEnum;
      };
    };

    const { data: planungsobjekt } = event.target as {
      data: PlanungsobjektLinearDto;
    };

    switch (actionEnum) {
      case AktionEnum.BEARBEITE_PLANUNGSOBJEKT:
        this.openPlanungsobjektLinearWithPlanungWindow(planungsobjekt);
        break;
      case AktionEnum.KOPIE_HIER:
        this.store.dispatch(
          planungsobjektActions.copyPlanungsobjekt({
            planungsobjekt,
            copyPattern: CopyPattern.NOW,
          }),
        );
        break;
      case AktionEnum.KOPIE_NAECHSTE_WOCHE:
        this.store.dispatch(
          planungsobjektActions.copyPlanungsobjekt({
            planungsobjekt,
            copyPattern: CopyPattern.NEXT_WEEK,
          }),
        );
        break;
      case AktionEnum.PLANUNGSOBJEKT_AUF_MERKLISTE:
        this.store.dispatch(
          merklisteWindowActions.openMovePlanungsobjektLinearToMerklisteDialog({
            planungsobjektLinearId: planungsobjekt.id,
            planungsobjektLinearTitel: planungsobjekt.titel,
          }),
        );
        break;
      case AktionEnum.PLANUNGSOBJEKT_IN_SERIE_UMWANDELN:
        this.openZuSerieUmwandelnWindow(planungsobjekt);
        break;
    }
  }

  /**
   * Öffnet das Planungsobjekt in der Planung mit Verlinkungstab (neues Fenster)
   * @param planungsobjekt Planungsobjekt, das geöffnet werden soll
   * @returns void
   * */
  openPlanungsobjektLinearWithPlanungWindow(planungsobjekt: PlanungsobjektDto) {
    this.store.dispatch(
      planungsobjektWindowActions.openPlanungsobjektLinearWithPlanungOnSendeplatzWindow({
        linearId: planungsobjekt.id,
      }),
    );
  }

  openZuSerieUmwandelnWindow(planungsobjekt: PlanungsobjektDto) {
    this.store.dispatch(
      serienWindowActions.openWindow({ context: AnsichtType.Liste, planungsobjekt }),
    );
  }
}
