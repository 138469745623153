import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { UnifyDurationDialogResult } from "src/app/services/duration.service";
import { UnifyDurationDialogInput } from "src/app/utils/duration-utils";

export const durationActions = createActionGroup({
  source: "Duration",
  events: {
    openUnifyDurationDialog: props<{
      groupsMitMengengeruest: UnifyDurationDialogInput[];
      sendeplatzLaengeInSeconds: number;
      planungsobjektLaengeInSeconds: number;
    }>(),
    handleUnifyDurationDialogResult: props<{
      /**
       * Wenn der Dialog geschlossen wurde, ist das Ergebnis ein DialogCloseResult,
       * sonst die aktualisierten Sendeplätze, die aber noch nicht persistiert wurden.
       */
      result: UnifyDurationDialogResult;
    }>(),
    dialogClosed: emptyProps(),
    noUnificationNeeded: emptyProps(),
  },
});
