import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { WindowCloseResult } from "@progress/kendo-angular-dialog";
import { combineLatest, filter, map, switchMap, take, tap } from "rxjs";
import { CustomDialogService } from "src/app/services/custom-dialog.service";
import { MengengeruestWindowService } from "src/app/services/mengengeruest-window.service";
import { createConfirmClosingEffect } from "src/app/utils/ngrx-utils";
import { mengengeruestActions } from "../mengengeruest/mengengeruest.actions";
import { mengengeruesteintragWindowSelectors } from "../mengengeruesteintrag-window/mengengeruesteintrag-window.selectors";
import { planungsobjektActions } from "../planungsobjekt/planungsobjekt.actions";
import { mengengeruestWindowActions } from "./mengengeruest-window.actions";
import { MengengeruestWindowUseCase } from "./mengengeruest-window.model";
import { mengengeruestWindowSelectors } from "./mengengeruest-window.selectors";

@Injectable()
export class MengengeruestWindowEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly mengengeruestWindowService = inject(MengengeruestWindowService);
  private readonly customDialogService = inject(CustomDialogService);

  openMengengeruestWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mengengeruestWindowActions.openMengengeruestWindow),
      switchMap(({ input }) => {
        const windowRef = this.mengengeruestWindowService.openMengengeruesteWindow(input);

        return this.actions$.pipe(
          ofType(mengengeruestWindowActions.closeWindow),
          take(1),
          tap(() => void windowRef.close()),
          map(() => mengengeruestWindowActions.windowClosed()),
        );
      }),
    );
  });

  tryClosingWithoutSaving$ = createConfirmClosingEffect({
    tryClosingAction: mengengeruestWindowActions.tryClosingWithoutSaving,
    preventClosingWhen$: combineLatest([
      this.store.select(mengengeruestWindowSelectors.selectMengengeruestChanged),
      this.store.select(mengengeruesteintragWindowSelectors.selectIsOpen),
    ]).pipe(
      filter(([_, isOpen]) => !isOpen),
      map(([isDirty]) => isDirty),
    ),
    closingAction: mengengeruestWindowActions.closeWindow,
  });

  loadDataOnOpen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mengengeruestWindowActions.openMengengeruestWindow),
      map(({ input }) =>
        mengengeruestActions.addMengengeruestEintraegeByAnsichtId({
          ansichtId: input.ansichtViewModel.id,
        }),
      ),
    );
  });

  handleMengengeruestWindowResult$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mengengeruestWindowActions.handleMengengeruestWindowResult),
      map(({ result }) => {
        if (result instanceof WindowCloseResult) return mengengeruestWindowActions.closeWindow();

        // Wenn sich die mengengeruesteintragId verändert hat, dann wurde ein Mengengerüst entweder zugewiesen oder entfernt
        if (
          result.zugewiesenePlanungsobjekt.mengengeruesteintragId !== result.previousMengengeruestId
        ) {
          return planungsobjektActions.assignPlanungsobjektToMengengeruest({
            sendeplatz:
              result.type === MengengeruestWindowUseCase.ZUWEISUNG_SENDEPLATZ
                ? result.sendeplatz
                : undefined,
            planungsobjekt: result.zugewiesenePlanungsobjekt,
            mengengeruesteintragId: result.zugewiesenePlanungsobjekt.mengengeruesteintragId,
          });
        } else {
          return mengengeruestWindowActions.closeWindow();
        }
      }),
    );
  });
}
