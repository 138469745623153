import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { map, switchMap, tap } from "rxjs";
import { Aktion } from "src/app/models/enums/aktion";
import { NotificationStyle } from "src/app/models/openapi/model/notification-style";
import { MengengeruestService } from "src/app/services/mengengeruest.service";
import { CustomNotificationService } from "src/app/shared/notifications/custom-notification.service";
import { mengengeruestActions } from "./mengengeruest.actions";
import { mengengeruestEintragFeature } from "./mengengeruest.reducer";

@Injectable()
export class MengengeruestEffects {
  loadMengengeruestGroupByAnsicht$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mengengeruestActions.addMengengeruestEintraegeByAnsichtId),
      switchMap(({ ansichtId }) => {
        return this.mengengeruestService.getMengengeruesteintraegeByAnsichtId$(ansichtId).pipe(
          map((mengengeruestGroups) =>
            mengengeruestActions.addMengengeruestEintraegeByAnsichtIdSuccess({
              mengengeruestEintraege: mengengeruestGroups
                .map((group) => group.mengengeruesteintraege)
                .flat(),
            }),
          ),
        );
      }),
    );
  });

  updateMengengeruestEintrag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mengengeruestActions.updateMengengeruestEintrag),
      switchMap(({ updateMengengeruestEintragCommand }) =>
        this.mengengeruestService
          .updateMengengeruesteintrag$(updateMengengeruestEintragCommand)
          .pipe(
            map((mengengeruestEintrag) =>
              mengengeruestActions.updateMengengeruestEintragSuccess({ mengengeruestEintrag }),
            ),
            tap(({ mengengeruestEintrag }) =>
              this.notificationService.showActionNotification(
                Aktion.BEARBEITE_MENGENGERUESTEINTRAG,
                NotificationStyle.SUCCESS,
                mengengeruestEintrag.titel,
              ),
            ),
          ),
      ),
    );
  });

  createMengengeruestEintrag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mengengeruestActions.createMengengeruestEintrag),
      switchMap(({ createMengengeruestEintragCommand }) =>
        this.mengengeruestService
          .createMengengeruesteintrag$(createMengengeruestEintragCommand)
          .pipe(
            map((mengengeruestEintrag) =>
              mengengeruestActions.createMengengeruestEintragSuccess({ mengengeruestEintrag }),
            ),
            tap(({ mengengeruestEintrag }) =>
              this.notificationService.showActionNotification(
                Aktion.ERSTELLE_MENGENGERUESTEINTRAG,
                NotificationStyle.SUCCESS,
                mengengeruestEintrag.titel,
              ),
            ),
          ),
      ),
    );
  });

  deleteMengengeruestEintrag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mengengeruestActions.deleteMengengeruestEintrag),
      concatLatestFrom(() => this.store.select(mengengeruestEintragFeature.selectEntities)),
      switchMap(([{ mengengeruestEintragId }, mengengerueste]) => {
        return this.mengengeruestService.deleteMengengeruesteintrag$(mengengeruestEintragId).pipe(
          tap(() => {
            const mengengeruesteintrag = mengengerueste[mengengeruestEintragId];
            if (!mengengeruesteintrag) return;
            this.notificationService.showActionNotification(
              Aktion.ENTFERNE_MENGENGERUESTEINTRAG,
              NotificationStyle.SUCCESS,
              mengengeruesteintrag.titel,
            );
          }),
          map(() =>
            mengengeruestActions.deleteMengengeruestEintragSuccess({ mengengeruestEintragId }),
          ),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store,
    private mengengeruestService: MengengeruestService,
    private notificationService: CustomNotificationService,
  ) {}
}
