import { createFormGroupState, FormGroupState } from "ngrx-forms";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";

export type SendeplatzWindowFormData = {
  abweichendeBeginnzeit: string | null;
  abweichendeLaenge: number | null;
  notiz: string;
};

export type SendeplatzWindowFormNgRx = FormGroupState<SendeplatzWindowFormData>;

export const initialSendePlatzWindowFormData: SendeplatzWindowFormData = {
  abweichendeBeginnzeit: null,
  abweichendeLaenge: null,
  notiz: "",
};

export const sendeplatzFormId = "sendeplatzWindowForm";

export const initialSendePlatzWindowFormNgRx: SendeplatzWindowFormNgRx = createFormGroupState(
  sendeplatzFormId,
  initialSendePlatzWindowFormData,
);

export type SendePlatzWindowFeatureState = {
  formState: SendeplatzWindowFormNgRx;
  windowInput: {
    sendeplatz: SendeplatzDto;
  } | null;
};

export const initialSendePlatzWindowFeatureState: SendePlatzWindowFeatureState = {
  formState: initialSendePlatzWindowFormNgRx,
  windowInput: null,
};
