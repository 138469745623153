import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MengengeruestApiService } from "../api/mengengeruest/mengengeruest.api.service";
import { CreateMengengeruesteintragCommand } from "../models/openapi/model/create-mengengeruesteintrag-command";
import { MengengeruestGroupDto } from "../models/openapi/model/mengengeruest-group-dto";
import { MengengeruesteintragDto } from "../models/openapi/model/mengengeruesteintrag-dto";
import { UpdateMengengeruesteintragCommand } from "../models/openapi/model/update-mengengeruesteintrag-command";

@Injectable({
  providedIn: "root",
})
export class MengengeruestService {
  private readonly mengengeruestApiService = inject(MengengeruestApiService);

  createMengengeruesteintrag$(
    body: CreateMengengeruesteintragCommand,
  ): Observable<MengengeruesteintragDto> {
    return this.mengengeruestApiService.createMengengeruesteintrag$(body);
  }

  updateMengengeruesteintrag$(
    body: UpdateMengengeruesteintragCommand,
  ): Observable<MengengeruesteintragDto> {
    return this.mengengeruestApiService.updateMengengeruesteintrag$(body);
  }

  deleteMengengeruesteintrag$(id: string): Observable<void> {
    return this.mengengeruestApiService.deleteMengengeruestEintrag$(id);
  }

  getMengengeruesteintraegeBySendeplatzId$(
    ansichtId: string,
    sendeplatzId: string,
  ): Observable<MengengeruestGroupDto> {
    return this.mengengeruestApiService.getMengengeruesteintraegeBySendeplatzId$(
      ansichtId,
      sendeplatzId,
    );
  }

  getMengengeruesteintraegeByAnsichtId$(ansichtId: string): Observable<MengengeruestGroupDto[]> {
    return this.mengengeruestApiService.getMengengeruesteintraegeByAnsichtId$(ansichtId);
  }
}
