import { createSelector } from "@ngrx/store";
import { Layout } from "src/app/models/openapi/model/layout";
import { MengengeruestWindowUseCase } from "./mengengeruest-window.model";
import { mengengeruestWindowFeature } from "./mengengeruest-window.reducer";

const selectWindowInput = mengengeruestWindowFeature.selectInput;
const selectActiveMengengeruesteintragId =
  mengengeruestWindowFeature.selectActiveMengengeruesteintragId;

const selectInitialMengengeruesteintragId = createSelector(selectWindowInput, (input) =>
  !!input &&
  input.type !== MengengeruestWindowUseCase.MAIN_WINDOW &&
  input.planungsobjekt.mengengeruesteintragId
    ? input.planungsobjekt.mengengeruesteintragId
    : null,
);
const selectMengengeruestChanged = createSelector(
  selectInitialMengengeruesteintragId,
  selectActiveMengengeruesteintragId,
  (initialId, activeId) => initialId !== activeId,
);

const selectIsBlockansicht = createSelector(
  selectWindowInput,
  (input) => input?.ansichtViewModel.ansichtsdefinition.layout === Layout.BLOCK,
);

export const mengengeruestWindowSelectors = {
  selectWindowInput,
  selectIsBlockansicht,
  selectInitialMengengeruesteintragId,
  selectActiveMengengeruesteintragId,
  selectMengengeruestChanged,
};
