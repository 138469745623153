import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BeziehungDto } from "src/app/models/openapi/model/beziehung-dto";
import { CreateBeziehungAbhaengigkeitCommand } from "src/app/models/openapi/model/create-beziehung-abhaengigkeit-command";
import { CreateBeziehungReihenfolgeCommand } from "src/app/models/openapi/model/create-beziehung-reihenfolge-command";
import { UnitResultDto } from "src/app/models/openapi/model/unit-result-dto";
import { PublitFrontendSettings } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PlanungsobjektBeziehungApiService {
  private apiUrl = this.settings.apiUrl;
  private readonly basePath = "PlanungsobjektBeziehung";

  constructor(
    private http: HttpClient,
    private settings: PublitFrontendSettings,
  ) {}

  getBeziehungenForPlanungsobjekt$(planungsobjektId: string): Observable<BeziehungDto[]> {
    return this.http.get<BeziehungDto[]>(
      `${this.apiUrl}${this.basePath}/${planungsobjektId}/Beziehungen`,
    );
  }
  createReihenfolgeForMarkedPlanungsobjekte$(body: CreateBeziehungReihenfolgeCommand) {
    return this.http.post<UnitResultDto>(`${this.apiUrl}${this.basePath}/Reihenfolge`, body);
  }

  createAbhaengigkeitForMarkedPlanungsobjekte$(body: CreateBeziehungAbhaengigkeitCommand) {
    return this.http.post<UnitResultDto>(`${this.apiUrl}${this.basePath}/Abhaengigkeit`, body);
  }

  deletePlanungsobjektBeziehung$(id: string): Observable<UnitResultDto> {
    return this.http.delete<UnitResultDto>(`${this.apiUrl}${this.basePath}/${id}`);
  }
}
