import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { map, switchMap, tap } from "rxjs";
import { Aktion } from "src/app/models/enums/aktion";
import { EventDto } from "src/app/models/openapi/model/event-dto";
import { NotificationStyle } from "src/app/models/openapi/model/notification-style";
import { EventService } from "src/app/services/event.service";
import { CustomNotificationService } from "src/app/shared/notifications/custom-notification.service";
import { ansichtActions } from "../ansicht/ansicht.actions";
import { ekWindowActions } from "../ek-window/ek-window.actions";
import { multiansichtActions } from "../multiansicht/multiansicht.actions";
import { multiAnsichtFeature } from "../multiansicht/multiansicht.reducer";
import { eventActions } from "./event.actions";

@Injectable()
export class EventEffects {
  handleEventWindowResult$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ekWindowActions.handleEventWindowResult),
      map(({ result }) => {
        const eventBody = result.returnValue.value as EventDto;
        switch (result.returnValue.action) {
          case Aktion.ERSTELLE_EVENT:
            return eventActions.createEvent({ event: eventBody });
          case Aktion.BEARBEITE_EVENT:
            return eventActions.updateEvent({ event: eventBody });
          case Aktion.ENTFERNE_EVENT:
            return eventActions.deleteEvent({ event: eventBody });
          case Aktion.KOPIERE_EVENT:
            return eventActions.copyEvent({
              event: eventBody,
              copyPattern: result.returnValue.copyPattern,
            });
          case Aktion.KONVERTIERE_KONKURRENZPROGRAMM:
            return eventActions.convertKonkurrenzprogrammToEvent({
              event: eventBody,
              convertedGUID: result.returnValue.convertedGUID,
            });
        }
        return ekWindowActions.closeWindow();
      }),
    );
  });

  loadEventsForEntityUpdates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ansichtActions.updateAllEntitiesInAnsicht),
      concatLatestFrom(() => this.store.select(multiAnsichtFeature.selectMultiAnsichtViewModel)),
      switchMap(([, multiAnsichtViewModel]) => {
        if (!multiAnsichtViewModel) {
          throw new Error("Es wurde kein MultiAnsichtViewModel gefunden.");
        }
        return this.service.getEventsByAnsichten$({
          ansichtenIds: multiAnsichtViewModel.ansichtViewModels
            .filter((avm) => avm.visible)
            .map((amv) => amv.ansichtViewModel.id),
        });
      }),
      map((events) => eventActions.setAllEvents({ events })),
    );
  });

  /**
   * Lade alle Events für mehrere Ansichten und füge diese in den State hinzu. Wird nur beim initialien
   * Aufrufen einer Ansicht durch die Action setMultiansichtSuccess ausgelöst.
   */
  loadEventsByAnsichten$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(multiansichtActions.setMultiansichtSuccess),
      switchMap(({ multiAnsichtViewModel }) =>
        this.service.getEventsByAnsichten$({
          ansichtenIds: multiAnsichtViewModel.ansichtViewModels
            .filter((avm) => avm.visible)
            .map((amv) => amv.ansichtViewModel.id),
        }),
      ),
      map((events) => eventActions.loadEventsByAnsichtenSuccess({ events })),
    );
  });

  loadEventsByYear$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ansichtActions.loadEventsKonkurrenzprogrammeForYear),
      switchMap(({ year }) => this.service.getEventsByYear$(year)),
      map((events) => eventActions.loadEventsByYearSuccess({ events })),
    );
  });

  loadEventsByAnsicht$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(multiansichtActions.updateAnsichtInMultiansichtSuccess),
      switchMap(({ additionalAnsichtViewModelId }) =>
        this.service.getEventsByAnsicht$(additionalAnsichtViewModelId),
      ),
      map((events) => eventActions.loadEventsByAnsichtenSuccess({ events })),
    );
  });

  createEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(eventActions.createEvent),
      switchMap(({ event }) => this.service.createEvent$(event)),
      map((event) => eventActions.createEventSuccess({ event })),
      tap(({ event }) =>
        this.notificationService.showActionNotification(
          Aktion.ERSTELLE_EVENT,
          NotificationStyle.SUCCESS,
          event.titel,
        ),
      ),
    );
  });

  updateEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(eventActions.updateEvent),
      switchMap(({ event }) => this.service.updateEvent$(event)),
      map((event) => eventActions.updateEventSuccess({ event })),
      tap(({ event }) =>
        this.notificationService.showActionNotification(
          Aktion.BEARBEITE_EVENT,
          NotificationStyle.SUCCESS,
          event.titel,
        ),
      ),
    );
  });

  deleteEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(eventActions.deleteEvent),
      switchMap(({ event }) =>
        this.service.deleteEvent$(event).pipe(
          map(() => eventActions.deleteEventSuccess({ eventId: event.id })),
          tap(() =>
            this.notificationService.showActionNotification(
              Aktion.ENTFERNE_EVENT,
              NotificationStyle.SUCCESS,
              event.titel,
            ),
          ),
        ),
      ),
    );
  });

  copyEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(eventActions.copyEvent),
      switchMap(({ event, copyPattern }) => this.service.copyEvent$(event, copyPattern)),
      map((event) => eventActions.copyEventSuccess({ event })),
      tap(({ event }) =>
        this.notificationService.showActionNotification(
          Aktion.KOPIERE_EVENT,
          NotificationStyle.SUCCESS,
          event.titel,
        ),
      ),
    );
  });

  convertKonkurrenzprogrammToEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(eventActions.convertKonkurrenzprogrammToEvent),
      switchMap(({ event, convertedGUID }) =>
        this.service
          .convertKonkurrenzprogramm$(event, convertedGUID)
          .pipe(
            map((event) =>
              eventActions.convertKonkurrenzprogrammToEventSuccess({ event, convertedGUID }),
            ),
          ),
      ),
      tap(({ event }) =>
        this.notificationService.showActionNotification(
          Aktion.KONVERTIERE_KONKURRENZPROGRAMM,
          NotificationStyle.SUCCESS,
          event.titel,
        ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private service: EventService,
    private store: Store,
    private notificationService: CustomNotificationService,
  ) {}
}
