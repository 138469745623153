import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { filter, map, tap } from "rxjs";
import { NotificationStyle } from "src/app/models/openapi/model/notification-style";
import { CustomNotificationService } from "src/app/shared/notifications/custom-notification.service";
import { planungsobjektActions } from "../planungsobjekt/planungsobjekt.actions";
import { sidebarActions } from "../sidebar/sidebar.actions";
import { mehrfachauswahlActions } from "./mehrfachauswahl.actions";
import { mehrfachauswahlFeature } from "./mehrfachauswahl.reducer";

@Injectable()
export class MehrfachauswahlEffects {
  private readonly actions$ = inject(Actions);
  private readonly customNotificationService = inject(CustomNotificationService);
  private readonly store = inject(Store);

  setPlanungsobjekteInMehrfachauswahl$ = createEffect(() => {
    // Beim Start oder Update der Planungsobjekte in der Mehrfachauswahl, wird eine gehighlightete/fokussierte
    // Planungsobjekt deselektiert und somit auch alle Beziehungen aufgelöst. Siehe Kommentar in
    // https://jira.zdf.de/jira/browse/PUBLIT-1015. Dies ist derzeit nötig, da die Beziehungen und die Mehrfachauswahl
    // die gleiche CSS Klasse `planungsobjekt-selected` für Planungsobjekt Chips verwenden.
    return this.actions$.pipe(
      ofType(
        mehrfachauswahlActions.setPlanungsobjekteInMehrfachauswahl,
        mehrfachauswahlActions.updatePlanungsobjekteInMehrfachauswahl,
      ),
      map((_) => planungsobjektActions.deselectPlanungsobjektInAnsicht({})),
    );
  });

  deselectPlanungsobjektFromMehrfachauswahl$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mehrfachauswahlActions.deselectPlanungsobjektFromMehrfachauswahl),
      concatLatestFrom(() => [
        this.store.select(mehrfachauswahlFeature.selectPlanungsobjekte),
        this.store.select(mehrfachauswahlFeature.selectSelectionOnGoing),
      ]),
      filter(([_, planungsobjekte, onGoing]) => {
        const hasSelection = Object.keys(planungsobjekte).length !== 0;
        return hasSelection && onGoing;
      }),
      map(([{ planungsobjektId }]) => {
        return mehrfachauswahlActions.removePlanungsobjektFromMehrfachauswahl({
          planungsobjektId,
        });
      }),
    );
  });

  removeAllPlanungsobjekteFromMehrfachauswahl$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mehrfachauswahlActions.removeAllPlanungsobjekteFromMehrfachauswahl),
      map((_) => sidebarActions.resetSidebarToDefaultTab()),
    );
  });

  closeMehrfachauswahl$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sidebarActions.closeMehrfachauswahlTab),
      tap(() =>
        this.customNotificationService.showNotification(
          "Keine weiteren Markierungen vorhanden.",
          NotificationStyle.INFO,
        ),
      ),
      map((_) => sidebarActions.resetSidebarToDefaultTab()),
    );
  });
}
