import { createFeature, createReducer, on } from "@ngrx/store";
import { onNgrxForms } from "ngrx-forms";
import { mergeReducers } from "src/app/utils/ngrx-utils";
import { ekWindowActions } from "./ek-window.actions";
import { ekWindowFormReducer } from "./ek-window.form";
import { EkWindowFeatureState, initalEkWindowFeatureState } from "./ek-window.model";

const ekWindowFeatureReducer = createReducer(
  initalEkWindowFeatureState,
  on(
    ekWindowActions.openWindow,
    (state, { input }): EkWindowFeatureState => ({
      ...initalEkWindowFeatureState,
      input: input,
    }),
  ),
  onNgrxForms(),
);

const reducer = mergeReducers(ekWindowFormReducer, ekWindowFeatureReducer);

export const ekWindowFeature = createFeature({
  name: "ekWindow",
  reducer,
});
