<ng-container
  *ngIf="{
    planenOnCurrentAnsicht: 'planen' | able: ({} | asSubject: 'Ansicht'),
    dropOnCurrentAnsicht:
      'planen' | able: ({} | asSubject: 'Ansicht') | cdkDropListEnterPredicateWrapper,
    ekEditieren: 'editieren' | able: 'EK',
    mengengeruestEditieren:
      'editieren'
      | able
        : ({ kanal: blockansichtDefinition.ansichtViewModel.kanal } | asSubject: 'Mengengeruest'),
  } as can"
>
  <section class="table-container bg-dark-grey">
    <table
      *ngIf="{
        columns: facade.columns$ | async,
        mehrfachauswahlActive: mehrfachauswahlActive$ | async,
        numberOfPlanungsobjekteInMehrfachauswahl:
          facade.numberOfPlanungsobjekteInMehrfachauswahl$ | async,
      } as viewModel"
      class="blockansicht-table"
    >
      <colgroup>
        <col [style.width.px]="dateColumnWidth" />
        <col [style.width.px]="gapColumnWidth" />
        <col *ngFor="let col of viewModel.columns" [style.width.px]="col.widthPx" />
        <col [style.width.px]="gapColumnWidth" />
        <col *ngIf="showNotizen" class="ek-panel" />
        <col *ngIf="showKonkurrenzEvents" class="ek-panel" />
      </colgroup>
      <thead>
        <tr>
          <th class="k-text-center sticky-left timeline-header previous-year-button">
            <button
              class="u-w-full"
              data-testid="previous-year-arrow-button"
              fillMode="flat"
              kendoButton
              size="none"
              title="Lade Ansicht für vorheriges Jahr"
              [disabled]="(facade.hasPreviousAnsicht$ | async) === false"
              [hidden]="(facade.isFirstAnsicht$(ansichtYear) | async) === false"
              [svgIcon]="Icons.arrowUpIcon"
              (click)="facade.loadPreviousAnsicht()"
            ></button>
          </th>
          <th
            class="sticky sticky-left dts-select-exclude"
            [style]="{ left: dateColumnWidth + 'px', 'z-index': 7, top: 0 }"
          ></th>
          <th
            class="sticky timeline-header first-date dts-select-exclude"
            [attr.data-testid]="'time-header-' + blockansichtDefinition.beginnzeit"
          >
            {{ blockansichtDefinition.beginnzeit }}
          </th>
          <ng-container *ngFor="let column of viewModel.columns; let index = index">
            <th
              *ngIf="column.createTableHeader"
              #times
              class="sticky timeline-header dts-select-exclude"
              [attr.colspan]="column.colspan"
              [attr.data-testid]="'time-header-' + column.title"
              [attr.id]="'time-' + column.title"
            >
              {{ column.colspan ? column.title : "" }}
            </th>
          </ng-container>
          <th class="sticky timeline-header dts-select-exclude"></th>
          <th
            *ngIf="showNotizen"
            class="ek-panel timeline-header notizen-background dts-select-exclude"
            [ngClass]="{
              'notizen-header': showKonkurrenzEvents,
            }"
          >
            Notizen
          </th>
          <th *ngIf="showKonkurrenzEvents" class="ek-panel timeline-header dts-select-exclude">
            Events & Konkurrenz
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngIf="facade.displaysMultipleYears$ | async"
          app-ansicht-divider
          [ansichtHeaderLength]="
            viewModel.columns.length +
            FIRST_COLUMN +
            (showKonkurrenzEvents ? 1 : 0) +
            (showNotizen ? 1 : 0) +
            EXTRA_COLUMNS
          "
          [showNotizen]="showNotizen"
          [year]="ansichtYear"
        ></tr>
        <ng-container
          *ngFor="
            let datumGruppe of facade.rows$ | async | rowData: blockansichtDefinition | keyvalue;
            trackBy: trackByDatumGruppe;
            let isOddDatumRow = odd
          "
        >
          <tr
            *ngFor="
              let varianteGruppe of datumGruppe.value.varianten | keyvalue;
              trackBy: trackByVarianteGruppe;
              let variantsForDatumCount = count;
              let isFirst = first
            "
            #planungsobjektContextmenuContainer="kendoContextMenuTargetContainer"
            class="border-bottom"
            kendoContextMenuTargetContainer
          >
            <!-- TODO data-row-date "hack" entfernen sobald ContextMenuTargetDirective genutzt wird -->
            <th
              *ngIf="isFirst"
              #targetContextMenu
              class="k-text-center sticky sticky-left cell-height dts-select-exclude"
              [attr.data-row-date]="datumGruppe.key"
              [attr.data-row-variante]="varianteGruppe.key"
              [attr.data-testid]="'date-column-' + datumGruppe.key"
              [ngClass]="{
                'bg-darker-grey': isOddDatumRow,
                'bg-dark-grey': !isOddDatumRow,
                'date-column-drag-preview': highlightSendetag$(datumGruppe.value.sendetag) | async,
              }"
              [rowSpan]="variantsForDatumCount"
            >
              {{ datumGruppe.value.sendetag | date: "dd.MM." }}
              <kendo-contextmenu
                [target]="targetContextMenu"
                (select)="onHandleRowContextMenuSelect($event)"
              >
                <kendo-menu-item
                  text="Neue Variantenzeile hinzufügen"
                  [data]="'add_row'"
                  [disabled]="!can.planenOnCurrentAnsicht"
                  [svgIcon]="Icons.neueVariantenzeile"
                ></kendo-menu-item>
              </kendo-contextmenu>
            </th>
            <!-- Zusätzliche Spalte für Planungshinweise und damit drag & select genügend Platz hat -->
            <td
              class="time-cell no-dragging-preview sticky"
              style="cursor: pointer"
              [ngClass]="{
                'bg-darker-grey': isOddDatumRow,
                'time-cell-dense': !isFirst,
                'cell-height': isFirst,
              }"
              [ngStyle]="{
                left: dateColumnWidth + 'px',
                'background-color':
                  datumGruppe.value.start
                  | hasPlanungshinweis
                    : datumGruppe.value.end
                    : blockansichtDefinition.ansichtViewModel.kanal
                  | async,
              }"
              [title]="
                datumGruppe.value.start
                  | planungshinweisTooltip
                    : datumGruppe.value.end
                    : blockansichtDefinition.ansichtViewModel.kanal
                  | async
              "
              (click)="onOpenPlanungshinweiseWindow(datumGruppe.value)"
            ></td>

            <!-- Tabellenzellen -->
            <td
              *ngFor="
                let data of viewModel.columns;
                trackBy: trackByIndex;
                let index = index;
                let last = last
              "
              #cellContextMenu
              cdkDropList
              class="time-cell no-dragging-preview"
              [attr.data-testid]="
                'cell_' +
                datumGruppe.value.sendetag +
                '_' +
                data.title +
                '_' +
                varianteGruppe.value.variante
              "
              [cdkDropListData]="{
                timeCellKey: {
                  variante: varianteGruppe.value.variante,
                  beginnzeit: data.title,
                  sendetag: datumGruppe.value.sendetag,
                },
                datumGruppe: datumGruppe,
                minutesPerColumn: blockansichtDefinition.minutesPerColumn,
              }"
              [cdkDropListDisabled]="!can.planenOnCurrentAnsicht"
              [cdkDropListEnterPredicate]="can.dropOnCurrentAnsicht"
              [cdkDropListSortingDisabled]="!can.planenOnCurrentAnsicht"
              [ngClass]="{
                'time-cell-accent': index === 0 || (index % 3 === 0 && index > 0),
                clickable: can.planenOnCurrentAnsicht,
                'bg-darker-grey': isOddDatumRow,
                'time-cell-dense': !isFirst,
                'cell-height': isFirst,
                'cdk-drop-list-dragging-success': dragDropService.canDrop(
                  datumGruppe,
                  varianteGruppe.value.variante,
                  data.title
                ),
                'cdk-drop-list-dragging-error': dragDropService.canNotDrop(
                  datumGruppe,
                  varianteGruppe.value.variante,
                  data.title
                ),
                'cdk-drop-list-dragging-multiselect':
                  dragDropService.isDraggingMultiplePlanungsobjekte(
                    datumGruppe,
                    varianteGruppe.value.variante,
                    data.title,
                    viewModel.numberOfPlanungsobjekteInMehrfachauswahl
                  ),
              }"
              (cdkDropListDropped)="facade.movePlanungsobjektInAnsicht($event, kanal)"
              (cdkDropListEntered)="
                dragDropService.onDropListEnter(
                  $event,
                  blockansichtDefinition.ansichtViewModel.kanal
                )
              "
              (dblclick)="
                can.planenOnCurrentAnsicht &&
                  handleCreateVpf({
                    datumGruppe,
                    variante: varianteGruppe.key,
                    minuteOffset: data.minuteOffset,
                  })
              "
            >
              <kendo-contextmenu
                [target]="cellContextMenu"
                (select)="
                  handleCellContextMenuSelect(
                    $event,
                    datumGruppe,
                    varianteGruppe.key,
                    data.minuteOffset
                  )
                "
              >
                <kendo-menu-item
                  *ngIf="settings.features.onDemandPlanung"
                  text="Erstellen über Details"
                  [data]="'create-verlinkung-details'"
                  [svgIcon]="Icons.information"
                ></kendo-menu-item>
                <kendo-menu-item
                  text="Produkt-ID eingeben"
                  [data]="'getit'"
                  [disabled]="!can.planenOnCurrentAnsicht"
                  [svgIcon]="Icons.getit"
                ></kendo-menu-item>
              </kendo-contextmenu>
            </td>

            <!-- Zusätzliche Spalte damit drag & select genügend Platz hat -->
            <td
              class="time-cell no-dragging-preview"
              [ngClass]="{
                'bg-darker-grey': isOddDatumRow,
                'time-cell-dense': !isFirst,
                'cell-height': isFirst,
              }"
            ></td>

            <!-- Notizen Spalte -->
            <td
              *ngIf="showNotizen"
              class="ek-panel notizen-background dts-select-exclude"
              [ngClass]="{
                'notizen-header': showKonkurrenzEvents,
                'notizen-position': showKonkurrenzEvents,
              }"
            >
              <p
                *ngIf="
                  varianteGruppe.value.pillen
                    | mapByKey: 'planungsobjekt' as planungsobjektFassungen
                "
                class="single-line-notes"
                [attr.data-testid]="'notiz-' + datumGruppe.key"
                [title]="planungsobjektFassungen | singleLineNotes"
              >
                {{ planungsobjektFassungen | singleLineNotes }}
              </p>
            </td>

            <!-- E&K Panel -->
            <td
              *ngIf="showKonkurrenzEvents && isFirst"
              class="ek-panel dts-select-exclude ek-cell"
              [attr.data-testid]="'ek-cell-' + datumGruppe.key"
              [rowSpan]="variantsForDatumCount"
              (dblclick)="
                can.ekEditieren &&
                  facade.openEventKonkurrenzprogrammErstellenWindow(datumGruppe.value.sendetag)
              "
            >
              <!-- Events  -->
              <app-event-list
                [zeitraum]="{ start: datumGruppe.value.start, end: datumGruppe.value.end }"
              >
              </app-event-list>

              <!-- Konkurrenzprogramme -->
              <app-konkurrenzprogramm-list
                [zeitraum]="{ start: datumGruppe.value.start, end: datumGruppe.value.end }"
              >
              </app-konkurrenzprogramm-list>
            </td>

            <!-- Planungsobjekte Pillen -->
            <div
              *ngFor="let vm of varianteGruppe.value.pillen; trackBy: trackByBlockansichtDto"
              cdkDropList
              class="no-dragging-preview"
              [cdkDropListData]="{
                timeCellKey: {
                  variante: varianteGruppe.value.variante,
                  beginnzeit: vm.planungsobjekt.publikationsplanung.beginnzeit,
                  sendetag: datumGruppe.value.sendetag,
                },
                datumGruppe: datumGruppe,
                minutesPerColumn: blockansichtDefinition.minutesPerColumn,
              }"
              [cdkDropListDisabled]="!can.planenOnCurrentAnsicht"
              [cdkDropListEnterPredicate]="can.dropOnCurrentAnsicht"
              [cdkDropListSortingDisabled]="true"
              [ngStyle]="vm.pillWrapper"
              (cdkDropListDropped)="facade.movePlanungsobjektInAnsicht($event, kanal)"
            >
              <!-- Chip: Template -->
              <ng-template #chip let-isPreview="isPreview">
                <app-planungsobjekt-on-blockansicht-chip
                  cdkDrag
                  [borderLeft]="!isPreview && vm.overflowLeft ? vm.overflowLeft : {}"
                  [borderRight]="!isPreview && vm.overflowRight ? vm.overflowRight : {}"
                  [cdkDragData]="vm.planungsobjekt"
                  [cdkDragDisabled]="vm.planungsobjekt.id | cdkDragDisabled | async"
                  [dtsSelectItem]="vm.planungsobjekt"
                  [isMengengeruestErlaubt]="
                    blockansichtDefinition.ansichtViewModel.ansichtsdefinition
                      .isMengengeruestErlaubt
                  "
                  [kendoContextMenuTarget]="{ datumGruppe, vm: vm.planungsobjekt }"
                  [planungsobjekt]="vm.planungsobjekt"
                  [title]="vm.planungsobjekt | planungsobjektTooltip"
                  [width]="isPreview ? vm.originalWidth : vm.computedWidth"
                  (cdkDragDropped)="dragDropService.resetDragDropAttributes()"
                  (cdkDragEntered)="
                    dragDropService.onDragEnter(
                      $event,
                      blockansichtDefinition.ansichtViewModel.kanal
                    )
                  "
                  (click.double)="onBlockansichtChipDoubleClicked(vm.planungsobjekt)"
                  (click.single)="
                    handleClickVpf(vm.planungsobjekt, viewModel.mehrfachauswahlActive)
                  "
                >
                  <!-- Chip: Preview -->
                  <div *cdkDragPreview class="item-drag-preview float-left">
                    <kendo-badge-container>
                      <ng-container
                        [ngTemplateOutlet]="chip"
                        [ngTemplateOutletContext]="{ isPreview: true }"
                      ></ng-container>
                      <kendo-badge
                        *ngIf="(viewModel.numberOfPlanungsobjekteInMehrfachauswahl ?? 0) > 1"
                        data-testid="mehrfachauswahl-counter-badge"
                        rounded="full"
                        size="small"
                        >{{ viewModel.numberOfPlanungsobjekteInMehrfachauswahl }}</kendo-badge
                      >
                    </kendo-badge-container>
                  </div>
                </app-planungsobjekt-on-blockansicht-chip>
              </ng-template>
              <!-- Chip: Display -->
              <ng-container
                [ngTemplateOutlet]="chip"
                [ngTemplateOutletContext]="{ isPreview: false }"
              ></ng-container>
            </div>

            <kendo-contextmenu
              [target]="planungsobjektContextmenuContainer"
              (select)="handleVpfContextMenuSelect($event)"
            >
              <kendo-menu-item
                text="Details"
                [data]="'details'"
                [svgIcon]="Icons.information"
              ></kendo-menu-item>
              <kendo-menu-item
                text="Mengengerüst zuordnen"
                [data]="'mengengeruest'"
                [svgIcon]="Icons.balance"
              ></kendo-menu-item>
              <kendo-menu-item
                text="in Serie umwandeln"
                [data]="'serie'"
                [disabled]="!can.planenOnCurrentAnsicht"
                [svgIcon]="Icons.serie"
              ></kendo-menu-item>
              <kendo-menu-item
                text="Kopie anlegen"
                [data]="'kopieanlegen_thisweek'"
                [disabled]="!can.planenOnCurrentAnsicht"
                [svgIcon]="Icons.copy"
              >
                <kendo-menu-item
                  text="diese Woche"
                  [data]="'kopieanlegen_thisweek'"
                  [svgIcon]="Icons.arrowEndDown"
                ></kendo-menu-item>
                <kendo-menu-item
                  text="nächste Woche"
                  [data]="'kopieanlegen_nextweek'"
                  [svgIcon]="Icons.arrowEndRight"
                >
                </kendo-menu-item>
              </kendo-menu-item>
              <kendo-menu-item
                text="Auf Merkliste setzen"
                [data]="'verschieben_merkliste'"
                [disabled]="!can.planenOnCurrentAnsicht"
                [svgIcon]="Icons.merklisteIcon"
              ></kendo-menu-item>
            </kendo-contextmenu>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </section>
  <button
    *ngIf="facade.isLastAnsicht$(ansichtYear) | async"
    class="next-year-button"
    data-testid="next-year-arrow-button"
    fillMode="flat"
    kendoButton
    size="none"
    title="Lade Ansicht für nächstes Jahr"
    [disabled]="(facade.hasNextAnsicht$ | async) === false"
    [svgIcon]="Icons.arrowDownIcon"
    (click)="facade.loadNextAnsicht()"
  ></button>
</ng-container>
