import { createActionGroup, props } from "@ngrx/store";
import { CreateMengengeruesteintragCommand } from "src/app/models/openapi/model/create-mengengeruesteintrag-command";
import { MengengeruesteintragDto } from "src/app/models/openapi/model/mengengeruesteintrag-dto";
import { UpdateMengengeruesteintragCommand } from "src/app/models/openapi/model/update-mengengeruesteintrag-command";

export const mengengeruestActions = createActionGroup({
  source: "Mengengeruest API",
  events: {
    addMengengeruestEintraegeByAnsichtId: props<{
      ansichtId: string;
    }>(),
    addMengengeruestEintraegeByAnsichtIdSuccess: props<{
      mengengeruestEintraege: MengengeruesteintragDto[];
    }>(),
    createMengengeruestEintrag: props<{
      createMengengeruestEintragCommand: CreateMengengeruesteintragCommand;
    }>(),
    createMengengeruestEintragSuccess: props<{
      mengengeruestEintrag: MengengeruesteintragDto;
    }>(),
    updateMengengeruestEintrag: props<{
      updateMengengeruestEintragCommand: UpdateMengengeruesteintragCommand;
    }>(),
    updateMengengeruestEintragSuccess: props<{
      mengengeruestEintrag: MengengeruesteintragDto;
    }>(),
    deleteMengengeruestEintrag: props<{
      mengengeruestEintragId: string;
    }>(),
    deleteMengengeruestEintragSuccess: props<{
      mengengeruestEintragId: string;
    }>(),
  },
});
