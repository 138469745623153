import { inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { mengengeruesteintragWindowActions } from "src/app/core/stores/mengengeruesteintrag-window/mengengeruesteintrag-window.actions";
import { mengengeruesteintragWindowFormActions } from "src/app/core/stores/mengengeruesteintrag-window/mengengeruesteintrag-window.form";
import { mengengeruesteintragWindowSelectors } from "src/app/core/stores/mengengeruesteintrag-window/mengengeruesteintrag-window.selectors";

@Injectable()
export class MengengeruesteintragWindowFacade {
  private readonly store = inject(Store);

  public readonly windowInput$ = this.store.select(mengengeruesteintragWindowSelectors.selectInput);
  public readonly formState$ = this.store.select(
    mengengeruesteintragWindowSelectors.selectFormState,
  );

  public save() {
    this.store.dispatch(mengengeruesteintragWindowFormActions.trySaving());
  }

  public closeWindow() {
    this.store.dispatch(mengengeruesteintragWindowActions.tryClosingWithoutSaving());
  }
}
