import { wrapReducerWithFormStateUpdate } from "ngrx-forms";
import { required } from "ngrx-forms/validation";
import { EventDto } from "src/app/models/openapi/model/event-dto";
import { KonkurrenzprogrammDto } from "src/app/models/openapi/model/konkurrenzprogramm-dto";
import * as CustomValidators from "src/app/shared/form-inputs/custom-validators-ngrx";
import { EKWindowInput } from "src/app/shared/windows/ek-window/ek-window.model";
import {
  ValidationMap,
  createReducerAndCustomFormActions,
  createValidationRules,
} from "src/app/utils/ngrx-forms";
import {
  EKWINDOW_FORM_ID,
  EkWindowFeatureState,
  EkWindowFormData,
  initalEkWindowFeatureState,
} from "./ek-window.model";

const ekWindowFormValidationRules: (form: EkWindowFormData) => ValidationMap<EkWindowFormData> = (
  form,
) => ({
  titel: [required, CustomValidators.minLengthTrimmed(2)],
  vonDatum: [required, CustomValidators.valueBefore(form, "bisDatum", "Datum bis")],
  vonZeit: form.isEvent ? [] : [required],
  bisDatum: [CustomValidators.valueAfter(form, "vonDatum", "Datum von")],
  bisZeit: form.isEvent ? [] : [required],
  senderPlattform: form.isEvent ? [] : [required],
});

const { actions, reducer, selectors } = createReducerAndCustomFormActions<
  EkWindowFeatureState,
  "formState"
>(initalEkWindowFeatureState, EKWINDOW_FORM_ID, "formState");

export const ekWindowFormActions = actions;
export const ekWindowFormSelectors = selectors;

export const ekWindowFormReducer = wrapReducerWithFormStateUpdate(
  reducer,
  (s) => s.formState,
  createValidationRules(ekWindowFormValidationRules),
);

export const calculateInitialFormStateFromInput = (input: EKWindowInput): EkWindowFormData => {
  const konkurrenzEvent = input.type !== "Create EK" ? input.konkurrenzEvent : undefined;
  const senderPlattform =
    input.type === "Konkurrenzprogramm" ? input.konkurrenzEvent?.senderPlattform : null;
  const kategorie = input.type !== "Create EK" ? input.konkurrenzEvent.kategorie : null;

  const vonDatum = vonDatumFürInput(input);
  const bisDatum = bisDatumFürKonkurrenzEvent(konkurrenzEvent);

  return {
    isEvent: input.type === "Event" ? true : false,
    id: konkurrenzEvent?.id ?? null,
    titel: konkurrenzEvent?.titel ?? "",
    vonDatum: vonDatum,
    vonZeit: konkurrenzEvent?.vonZeit ?? "",
    bisDatum: bisDatum,
    bisZeit: konkurrenzEvent?.bisZeit ?? "",
    senderPlattform: senderPlattform,
    senderPlattformSonstiges:
      (konkurrenzEvent as KonkurrenzprogrammDto)?.senderPlattformSonstiges ?? "",
    notiz: konkurrenzEvent?.notiz ?? "",
    pressetextLink: (konkurrenzEvent as KonkurrenzprogrammDto)?.pressetextLink ?? "",
    kategorie: kategorie,
    isAbgesetzt: (konkurrenzEvent as KonkurrenzprogrammDto)?.isAbgesetzt ?? false,
  };
};

const vonDatumFürInput = (input: EKWindowInput): string => {
  if (input.type !== "Create EK") {
    return input.konkurrenzEvent.vonDatum ?? "";
  }

  return input.defaultDate?.toISOString() ?? "";
};

const bisDatumFürKonkurrenzEvent = (
  konkurrenzEvent: KonkurrenzprogrammDto | EventDto | undefined,
): string => {
  if (konkurrenzEvent?.bisDatum) {
    if (konkurrenzEvent?.vonDatum == konkurrenzEvent?.bisDatum) {
      return "";
    }
    return konkurrenzEvent?.bisDatum ?? "";
  }
  return "";
};
