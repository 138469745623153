import { Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  ContextMenuSelectEvent,
  ContextMenuTargetDirective,
  MenuItemComponent,
} from "@progress/kendo-angular-menu";
import { NGXLogger } from "ngx-logger";
import { switchMap, take } from "rxjs";
import ansichtSelectors from "src/app/core/stores/ansicht/ansicht.selectors";
import { ekWindowActions } from "src/app/core/stores/ek-window/ek-window.actions";
import eventSelectors from "src/app/core/stores/event/event.selectors";
import { ContextMenuEvent } from "src/app/models/context-menu";
import { Aktion, AktionEnum, AktionResult } from "src/app/models/enums/aktion";
import { Icons } from "src/app/models/icons";
import { CopyPattern } from "src/app/models/openapi/model/copy-pattern";
import { EventDto } from "src/app/models/openapi/model/event-dto";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { CustomWindowService } from "src/app/services/custom-window.service";
import { SendeplatzUtils } from "src/app/utils/sendeplatz.utils";

@Component({
  selector: "app-event-list",
  templateUrl: "./event-list.component.html",
  styleUrls: ["./event-list.component.scss"],
})
export class EventListComponent {
  @Input() sendeplaetze: SendeplatzDto[];
  @Input() zeitraum: { start: Date; end: Date };

  Icons = Icons;
  CopyPattern = CopyPattern;
  AktionEnum = AktionEnum;
  Aktion = Aktion;

  events$ = this.store.select(ansichtSelectors.selectIsBlockansichtOrEkAnsicht).pipe(
    take(1),
    switchMap((isBlockOrEkAnsicht) => {
      if (isBlockOrEkAnsicht) {
        return this.store.select(eventSelectors.selectEventsForInterval(this.zeitraum));
      } else {
        return this.store.select(
          eventSelectors.selectEventsForSendeplatz(
            SendeplatzUtils.getSendeplatzKeys(this.sendeplaetze),
          ),
        );
      }
    }),
  );

  constructor(
    protected customWindowService: CustomWindowService,
    private store: Store,
    private logger: NGXLogger,
  ) {}

  openEventKonkurrenzprogrammWindow(value: { event: MouseEvent; konkurrenzEvent: EventDto }) {
    // Doppelklick wird nicht an weiter unten liegenede Elemente weitergegeben,
    // und somit wird nicht der Erstellen-Dialog für E&K geöffnet
    value.event.stopPropagation();
    this.store.dispatch(
      ekWindowActions.openWindow({
        input: { type: "Event", asNewCopy: false, konkurrenzEvent: value.konkurrenzEvent },
      }),
    );
  }

  /**
   * Verarbeitet das Context Menü für Konkurrenzprogramme und Events und öffnet ggf. den gemeinsamen Dialog
   * @param event
   * @param isEvent
   */
  onEventContextMenuSelect(event: ContextMenuSelectEvent): void {
    if (!(event.item instanceof MenuItemComponent)) {
      this.logger.error(`Unexpected type for context menu selection`);
      return;
    }

    const { action, copyPattern, systemAction } = event.item.data as ContextMenuEvent;

    const viewModel = (event.target as ContextMenuTargetDirective).data as EventDto;

    let aktion;
    switch (action) {
      case AktionEnum.DETAILS:
        aktion = ekWindowActions.openWindow({
          input: {
            type: "Event",
            konkurrenzEvent: viewModel,
            asNewCopy: false,
          },
        });
        break;
      case AktionEnum.KOPIE_BENUTZERDEFINIERT:
        aktion = ekWindowActions.openWindow({
          input: {
            type: "Event",
            konkurrenzEvent: viewModel,
            asNewCopy: true,
          },
        });
        break;
      case AktionEnum.KOPIE_HIER:
      case AktionEnum.KOPIE_NAECHSTE_WOCHE:
        aktion = ekWindowActions.handleEkWindowResult({
          result: {
            result: AktionResult.COPY,
            returnValue: {
              action: systemAction,
              value: viewModel,
              isEvent: true,
              copyPattern,
            },
          },
        });
        break;
    }
    if (aktion) {
      this.store.dispatch(aktion);
    }
  }
}
