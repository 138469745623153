import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { WindowCloseResult } from "@progress/kendo-angular-dialog";
import { EKWindowInput, EKWindowResult } from "src/app/shared/windows/ek-window/ek-window.model";

export const ekWindowActions = createActionGroup({
  source: "Ek Window",
  events: {
    // Window Handling
    openWindow: props<{ input: EKWindowInput }>(),
    tryClosingWithoutSaving: emptyProps(),
    closeWindow: emptyProps(),
    windowClosed: emptyProps(),

    // Business Logic?
    handleEkWindowResult: props<{
      result: EKWindowResult | WindowCloseResult;
    }>(),
    handleEventWindowResult: props<{
      result: EKWindowResult;
    }>(),
    handleKonkurrenzprogrammWindowResult: props<{
      result: EKWindowResult;
    }>(),
  },
});
