import { inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { filter, switchMap } from "rxjs";
import planungshinweisSelectors from "src/app/core/stores/planungshinweis/planungshinweis.selectors";
import { planungshinweiseWindowActions } from "src/app/core/stores/planungshinweise-window/planungshinweise-window.actions";
import { planungshinweiseWindowSelectors } from "src/app/core/stores/planungshinweise-window/planungshinweise-window.selectors";

@Injectable()
export class PlanungshinweiseWindowFacade {
  private readonly store = inject(Store);

  public readonly windowInput$ = this.store.select(planungshinweiseWindowSelectors.selectInput);

  public readonly planungshinweise$ = this.windowInput$.pipe(
    filter((input) => !!input),
    switchMap((input) =>
      this.store.select(
        planungshinweisSelectors.selectPlanungshinweiseByTimeRange(
          input.von,
          input.bis,
          input.kanal,
        ),
      ),
    ),
  );

  public setDirtyState(isDirty: boolean) {
    this.store.dispatch(planungshinweiseWindowActions.setIsDirty({ isDirty }));
  }

  public closeWindow() {
    this.store.dispatch(planungshinweiseWindowActions.tryCloseWindow());
  }
}
