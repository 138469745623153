import { createSelector } from "@ngrx/store";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { sendeplatzWindowFormSelectors } from "./sendeplatz-window.form";
import { sendeplatzWindowFeature } from "./sendeplatz-window.reducer";

const selectWindowInput = sendeplatzWindowFeature.selectWindowInput;
const selectFormState = sendeplatzWindowFeature.selectFormState;
const formSelectors = sendeplatzWindowFormSelectors(selectFormState);

const selectSendeplatz = createSelector(selectWindowInput, (input) => input?.sendeplatz);

const selectSendeplatzToTransfer = createSelector(
  selectSendeplatz,
  formSelectors.selectFormData,
  (sendeplatz, formValue): SendeplatzDto | undefined => {
    if (!sendeplatz) {
      return undefined;
    }

    const abweichendeBeginnzeit = formValue.abweichendeBeginnzeit
      ? formValue.abweichendeBeginnzeit
      : null;

    const abweichendeLaenge = formValue.abweichendeLaenge || null;

    return {
      ...sendeplatz,
      abweichendeBeginnzeit,
      abweichendeLaenge,
      notiz: formValue.notiz ?? "",
    };
  },
);

export const selectViewModel = createSelector(
  selectSendeplatz,
  selectFormState,
  (sendeplatz, formState) => {
    if (!sendeplatz || !formState) {
      return undefined;
    }

    return {
      sendeplatz,
      formState,
    };
  },
);

export const sendeplatzWindowSelectors = {
  selectWindowInput,
  selectSendeplatz,
  selectSendeplatzToTransfer,
  selectViewModel,

  // Form selectors
  ...formSelectors,
};
