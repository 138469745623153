import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { PlanungsobjektLinearApiService } from "../api/planungsobjekt/planungsobjekt-linear.api.service";
import { PlanungsobjektApiService } from "../api/planungsobjekt/planungsobjekt.api.service";
import { planungsobjektActions } from "../core/stores/planungsobjekt/planungsobjekt.actions";
import { serienWindowActions } from "../core/stores/serien-window/serien-window.actions";
import { SerienWindowResult } from "../core/stores/serien-window/serien-window.model";
import { GUID } from "../models";
import { GetPlanungsobjekteLinearByAnsichtenSendeplatzQuery } from "../models/openapi/model/get-planungsobjekte-linear-by-ansichten-sendeplatz-query";
import { MovePlanungsobjektLinearToVorgeplantSendeplatzCommand } from "../models/openapi/model/move-planungsobjekt-linear-to-vorgeplant-sendeplatz-command";
import { MovePlanungsobjektLinearToVorgeschlagenCommand } from "../models/openapi/model/move-planungsobjekt-linear-to-vorgeschlagen-command";
import { MovePlanungsobjekteLinearToVorgeplantSendeplatzCommand } from "../models/openapi/model/move-planungsobjekte-linear-to-vorgeplant-sendeplatz-command";
import { NotificationStyle } from "../models/openapi/model/notification-style";
import { Planungskontext } from "../models/openapi/model/planungskontext";
import { PlanungsobjektDto } from "../models/openapi/model/planungsobjekt-dto";
import { PlanungsobjektEntknuepfenVonGetitCommand } from "../models/openapi/model/planungsobjekt-entknuepfen-von-getit-command";
import { PlanungsobjektLinearDto } from "../models/openapi/model/planungsobjekt-linear-dto";
import { PlanungsobjektLinearVorgemerktAktualisierenCommand } from "../models/openapi/model/planungsobjekt-linear-vorgemerkt-aktualisieren-command";
import { PlanungsobjektLinearVorgeplantBlockZuSerieUmwandelnCommand } from "../models/openapi/model/planungsobjekt-linear-vorgeplant-block-zu-serie-umwandeln-command";
import { PlanungsobjektLinearVorgeplantErstellenMitGetitCommand } from "../models/openapi/model/planungsobjekt-linear-vorgeplant-erstellen-mit-getit-command";
import { PlanungsobjektLinearVorgeplantSendeplatzAktualisierenCommand } from "../models/openapi/model/planungsobjekt-linear-vorgeplant-sendeplatz-aktualisieren-command";
import { PlanungsobjektLinearVorgeplantSendeplatzErstellenCommand } from "../models/openapi/model/planungsobjekt-linear-vorgeplant-sendeplatz-erstellen-command";
import { PlanungsobjektLinearVorgeplantSendeplatzKopierenCommand } from "../models/openapi/model/planungsobjekt-linear-vorgeplant-sendeplatz-kopieren-command";
import { PlanungsobjektLinearVorgeplantSendeplatzZuSerieUmwandelnCommand } from "../models/openapi/model/planungsobjekt-linear-vorgeplant-sendeplatz-zu-serie-umwandeln-command";
import { PlanungsobjektLinearVorgeplantZuweisenZuMengengeruestCommand } from "../models/openapi/model/planungsobjekt-linear-vorgeplant-zuweisen-zu-mengengeruest-command";
import { PlanungsobjektLinearVorgeschlagenAktualisierenCommand } from "../models/openapi/model/planungsobjekt-linear-vorgeschlagen-aktualisieren-command";
import { PlanungsobjektLinearVorgeschlagenErsetzenZuVorgeplantSendeplatzCommand } from "../models/openapi/model/planungsobjekt-linear-vorgeschlagen-ersetzen-zu-vorgeplant-sendeplatz-command";
import { PlanungsobjektLinearVorgeschlagenErstellenCommand } from "../models/openapi/model/planungsobjekt-linear-vorgeschlagen-erstellen-command";
import { PlanungsobjektMitGetitSynchronisierenCommand } from "../models/openapi/model/planungsobjekt-mit-getit-synchronisieren-command";
import { PlanungsobjektVerknuepfenMitGetitCommand } from "../models/openapi/model/planungsobjekt-verknuepfen-mit-getit-command";
import { PlanungsobjekteDto } from "../models/openapi/model/planungsobjekte-dto";
import { SendeplatzDto } from "../models/openapi/model/sendeplatz-dto";
import {
  AnsichtYearDefinition,
  MultiAnsichtViewModel,
} from "../models/viewmodels/ansicht-viewmodel";
import { CustomNotificationService } from "../shared/notifications/custom-notification.service";
import { TemplateType } from "../shared/notifications/notification.templates";
import {
  PlanungsobjektGetitVerknuepfungAufhebenWindowComponent,
  PlanungsobjektGetitVerknuepfungAufhebenWindowResult,
} from "../shared/windows/planungsobjekt-getit-verknuepfung-aufheben/planungsobjekt-getit-verknuepfung-aufheben-window.component";
import { SerienWindowComponent } from "../shared/windows/serien-window/serien-window.component";
import { MediumWindow, SmallWindow } from "../shared/windows/window.templates";
import { PlanungsobjektUtils } from "../utils/planungsobjekt.utils";
import { SendeplatzUtils } from "../utils/sendeplatz.utils";
import { findAnsichtViewModelByYearInMultiAnsichtViewModel } from "./ansichten.service";
import { CustomWindowService } from "./custom-window.service";
import { DateFnsService } from "./date-fns.service";

@Injectable({
  providedIn: "root",
})
export class PlanungsobjektService {
  constructor(
    private planungsobjektApiService: PlanungsobjektApiService,
    private planungsobjektLinearApiService: PlanungsobjektLinearApiService,
    private notificationService: CustomNotificationService,
    private customWindowService: CustomWindowService,
    private store: Store,
  ) {}

  getPlanungsobjektById$(planungsobjektId: string): Observable<PlanungsobjektDto> {
    return this.planungsobjektApiService.getPlanungsobjektById$(planungsobjektId);
  }

  getLinearById$(planungsobjektId: string): Observable<PlanungsobjektLinearDto> {
    return this.planungsobjektLinearApiService.getPlanungsobjektLinearById$(planungsobjektId);
  }

  getPlanungsobjekteById$(planungsobjektId: string): Observable<PlanungsobjekteDto> {
    return this.planungsobjektApiService.getPlanungsobjekteById$(planungsobjektId);
  }

  getPlanungsobjekteByAnsichtId$(ansichtId: string): Observable<PlanungsobjektLinearDto[]> {
    return this.planungsobjektLinearApiService.getPlanungsobjekteLinearByAnsichtSendeplatz$(
      ansichtId,
    );
  }

  getPlanungsobjekteByAnsichtenIds$(
    query: GetPlanungsobjekteLinearByAnsichtenSendeplatzQuery,
  ): Observable<PlanungsobjektLinearDto[]> {
    return this.planungsobjektLinearApiService.getPlanungsobjekteLinearByAnsichtenSendeplatz$(
      query,
    );
  }

  planungsobjektLinearVorgeplantSendeplatzErstellen$(
    command: PlanungsobjektLinearVorgeplantSendeplatzErstellenCommand,
  ): Observable<SendeplatzDto> {
    return this.planungsobjektLinearApiService.planungsobjektLinearVorgeplantSendeplatzErstellen$(
      command,
    );
  }

  planungsobjektLinearVorgeplantErstellenMitGetit$(
    command: PlanungsobjektLinearVorgeplantErstellenMitGetitCommand,
  ) {
    return this.planungsobjektLinearApiService.planungsobjektLinearVorgeplantErstellenMitGetit$(
      command,
    );
  }

  verknuepfePlanungsobjektMitGetit$(command: PlanungsobjektVerknuepfenMitGetitCommand) {
    return this.planungsobjektApiService.verknuepfePlanungsobjektMitGetit$(command);
  }

  synchronizePlanungsobjektMitGetit$(command: PlanungsobjektMitGetitSynchronisierenCommand) {
    return this.planungsobjektApiService.synchronisierePlanungsobjektMitGetit$(command);
  }

  movePlanungsobjektLinearToVorgeplantSendeplatz$(
    command: MovePlanungsobjektLinearToVorgeplantSendeplatzCommand,
  ) {
    return this.planungsobjektLinearApiService.movePlanungsobjektLinearToVorgeplantSendeplatz$(
      command,
    );
  }

  moveMultiplePlanungsobjekte$(command: MovePlanungsobjekteLinearToVorgeplantSendeplatzCommand) {
    return this.planungsobjektLinearApiService.movePlanungsobjekteLinearToVorgeplantSendeplatz$(
      command,
    );
  }

  planungsobjektLinearVorgeplantSendeplatzAktualisieren$(
    command: PlanungsobjektLinearVorgeplantSendeplatzAktualisierenCommand,
  ) {
    return this.planungsobjektLinearApiService.planungsobjektLinearVorgeplantSendeplatzAktualisieren$(
      command,
    );
  }

  planungsobjektLinearVorgeplantSendeplatzKopieren$(
    command: PlanungsobjektLinearVorgeplantSendeplatzKopierenCommand,
  ) {
    return this.planungsobjektLinearApiService.planungsobjektLinearVorgeplantSendeplatzKopieren$(
      command,
    );
  }

  deletePlanungsobjekt$(id: GUID) {
    return this.planungsobjektApiService.deletePlanungsobjekt$(id);
  }

  planungsobjektLinearVorgemerktAktualisieren$(
    command: PlanungsobjektLinearVorgemerktAktualisierenCommand,
  ) {
    return this.planungsobjektLinearApiService.planungsobjektLinearVorgemerktAktualisieren$(
      command,
    );
  }

  planungsobjektLinearVorgeplantSendeplatzZuSerieUmwandeln$(
    command: PlanungsobjektLinearVorgeplantSendeplatzZuSerieUmwandelnCommand,
  ) {
    return this.planungsobjektLinearApiService.planungsobjektLinearVorgeplantSendeplatzZuSerieUmwandeln$(
      command,
    );
  }

  planungsobjektLinearVorgeplantBlockZuSerieUmwandeln$(
    command: PlanungsobjektLinearVorgeplantBlockZuSerieUmwandelnCommand,
  ) {
    return this.planungsobjektLinearApiService.planungsobjektLinearVorgeplantBlockZuSerieUmwandeln$(
      command,
    );
  }

  planungsobjektLinearVorgeplantZuweisenZuMengengeruest$(
    command: PlanungsobjektLinearVorgeplantZuweisenZuMengengeruestCommand,
  ) {
    return this.planungsobjektLinearApiService.planungsobjektLinearVorgeplantZuweisenZuMengengeruest$(
      command,
    );
  }

  planungsobjektLinearVorgeschlagenErsetzenZuVorgeplantSendeplatz$(
    command: PlanungsobjektLinearVorgeschlagenErsetzenZuVorgeplantSendeplatzCommand,
  ) {
    return this.planungsobjektLinearApiService.planungsobjektLinearVorgeschlagenErsetzenZuVorgeplantSendeplatz$(
      command,
    );
  }

  createLinearVorgeschlagen$(command: PlanungsobjektLinearVorgeschlagenErstellenCommand) {
    return this.planungsobjektLinearApiService.planungsobjektLinearVorgeschlagenErstellen$(command);
  }

  updateLinearVorgeschlagen$(command: PlanungsobjektLinearVorgeschlagenAktualisierenCommand) {
    return this.planungsobjektLinearApiService.planungsobjektLinearVorgeschlagenAktualisieren$(
      command,
    );
  }

  /**
   * TODO
   * Diese Action triggert den Effekt movePlanungsobjektToVorschlagspalte$ in planungsobjekt.vorschlag.effects.ts
   * Regulärer Weg sollte sein Action -> Effect -> Service -> API
   * In diesem Fall wäre es Service -> Action -> Effect -> Service -> API
   * Bringt es diese Indirektion hier wirklich?
   * Ist es Aufgabe des Service Commands zusammen zu bauen oder soll nur die API angesprochen werden?
   */
  movePlanungsobjektToVorgeschlagen(
    sendeplatzDto: SendeplatzDto,
    planungsobjekt: PlanungsobjektDto,
  ): void {
    const fromSendeplatzKey = SendeplatzUtils.getSendeplatzKeyFromPlanungsobjekt(planungsobjekt);
    const toSendeplatzKey = SendeplatzUtils.getSendeplatzKey(sendeplatzDto);

    if (
      planungsobjekt.planungskontext === Planungskontext.VORGESCHLAGEN &&
      SendeplatzUtils.isSameSendeplatzKey(fromSendeplatzKey, toSendeplatzKey)
    ) {
      return;
    }

    const command: MovePlanungsobjektLinearToVorgeschlagenCommand = {
      id: planungsobjekt.id,
      sendeplatzKey: toSendeplatzKey,
    };

    this.store.dispatch(
      planungsobjektActions.movePlanungsobjektToVorschlagspalte({
        command,
      }),
    );
  }

  movePlanungsobjektLinearToVorgeschlagen$(
    command: MovePlanungsobjektLinearToVorgeschlagenCommand,
  ) {
    return this.planungsobjektLinearApiService.movePlanungsobjektLinearToVorgeschlagen$(command);
  }

  planungsobjektEntknuepfenVonGetit$(
    planungsobjektId: string,
    produktEingenschaftenBeibehalten: boolean,
  ) {
    const command: PlanungsobjektEntknuepfenVonGetitCommand = {
      planungsobjektId,
      produktEingenschaftenBeibehalten,
    };
    return this.planungsobjektApiService.planungsobjektEntknuepfenVonGetit$(command);
  }

  /**
   * Mehrfachauswahl auf Sendeplätze (nicht auf andere Schemaplätze) verschieben
   */
  movePlanungsobjekteToSendeplatz(
    planungsobjekte: PlanungsobjektDto[],
    newSendeplatz: SendeplatzDto,
    oldSendeplatz: SendeplatzDto,
    multiAnsichtViewModel: MultiAnsichtViewModel,
    ansichtYearDefinition: AnsichtYearDefinition,
  ): {
    planungsobjekte: PlanungsobjektDto[];
    daysOffset: number;
  } {
    // Verwende den gesamten Zeitraum, der angezeigten Ansichten anstatt ein einzelnes
    // AnsichtViewmodel
    const start = findAnsichtViewModelByYearInMultiAnsichtViewModel(
      multiAnsichtViewModel,
      ansichtYearDefinition.visibleYears[0],
    ).zeitraum.start;
    const end = findAnsichtViewModelByYearInMultiAnsichtViewModel(
      multiAnsichtViewModel,
      ansichtYearDefinition.possibleYears[ansichtYearDefinition.possibleYears.length - 1],
    ).zeitraum.end;
    const zeitraum: { start: Date; end: Date } = {
      start,
      end,
    };

    return this.checkForMoveNotifications(newSendeplatz, oldSendeplatz, planungsobjekte, zeitraum);
  }

  private checkForMoveNotifications(
    newSendeplatz: SendeplatzDto,
    oldSendeplatz: SendeplatzDto,
    planungsobjekteInMehrfachauswahl: PlanungsobjektDto[],
    zeitraum: { start: Date; end: Date },
  ): undefined | { planungsobjekte: PlanungsobjektDto[]; daysOffset: number } {
    const daysOffset = DateFnsService.daysBetweenDates(
      newSendeplatz.kalendertag,
      oldSendeplatz.kalendertag,
    );

    for (const planungsobjekt of planungsobjekteInMehrfachauswahl) {
      if (PlanungsobjektUtils.isOnMerkliste(planungsobjekt)) {
        this.notificationService.showNotification(
          "Auswahl enthält mindestens ein Element einer Merkliste. Verschieben derzeit nicht möglich.",
          NotificationStyle.ERROR,
          true,
        );
        return { planungsobjekte: [], daysOffset: 0 };
      }

      if (PlanungsobjektUtils.isOnBlockansicht(planungsobjekt)) {
        // TODO: Ändern, sobald VPFs auf der Blockansicht verschoben werden können
        this.notificationService.showNotification(
          "Auswahl enthält mindestens ein Element einer Blockansicht. Verschieben derzeit nicht möglich.",
          NotificationStyle.ERROR,
          true,
        );
        return { planungsobjekte: [], daysOffset: 0 };
      }

      if (
        !DateFnsService.dateBetweenDates(
          DateFnsService.addDays(planungsobjekt.publikationsplanung.kalendertag, daysOffset),
          zeitraum.start,
          zeitraum.end,
        )
      ) {
        this.notificationService.showTemplateNotification(TemplateType.moveOutOfAnsicht);
        return { planungsobjekte: [], daysOffset: 0 };
      }
    }

    return { planungsobjekte: planungsobjekteInMehrfachauswahl, daysOffset };
  }

  openZuSerieUmwandelnWindow() {
    const windowRef = this.customWindowService.open<SerienWindowComponent, SerienWindowResult>({
      content: SerienWindowComponent,
      title: "Programm in Serie umwandeln ",
      ...SmallWindow({ dataTestId: "serie-window" }),
      preventClose: () => {
        this.store.dispatch(serienWindowActions.tryClosingWithoutSaving());
        return true;
      },
    });

    return windowRef;
  }

  openPlanungsobjektGetitVerknuepfungAufhebenWindow$(planungsobjektId: GUID) {
    const windowRef = this.customWindowService.open<
      PlanungsobjektGetitVerknuepfungAufhebenWindowComponent,
      PlanungsobjektGetitVerknuepfungAufhebenWindowResult
    >({
      content: PlanungsobjektGetitVerknuepfungAufhebenWindowComponent,
      title: "Verknüpfung mit der Produktdatenbank auflösen?",
      ...MediumWindow(),
    });

    windowRef.content.instance.data = { planungsobjektId };
    return windowRef;
  }
}
