import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { filter, map, switchMap, tap } from "rxjs";
import { Aktion } from "src/app/models/enums/aktion";
import { Layout } from "src/app/models/openapi/model/layout";
import { NotificationStyle } from "src/app/models/openapi/model/notification-style";
import { DateFnsService } from "src/app/services/date-fns.service";
import { SendeplatzService } from "src/app/services/sendeplatz.service";
import { CustomNotificationService } from "src/app/shared/notifications/custom-notification.service";
import { SendeplatzUtils } from "src/app/utils/sendeplatz.utils";
import { ansichtActions } from "../ansicht/ansicht.actions";
import { multiansichtActions } from "../multiansicht/multiansicht.actions";
import { multiAnsichtFeature } from "../multiansicht/multiansicht.reducer";
import { planungsobjektActions } from "../planungsobjekt/planungsobjekt.actions";
import { sendeplatzActions } from "./sendeplatz.actions";
import sendeplatzSelectors from "./sendeplatz.selectors";

@Injectable()
export class SendeplatzEffects {
  /**
   * Update einen einzelnen Sendeplatz.
   */
  updateSendeplatz$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendeplatzActions.updateSendeplatz),
      switchMap(({ sendeplatz }) => {
        return this.sendeplatzService.updateSendeplatz$(sendeplatz).pipe(
          map((sendeplatz) => sendeplatzActions.updateSendeplatzSuccess({ sendeplatz })),
          tap(({ sendeplatz }) => {
            this.notificationService.showActionNotification(
              Aktion.BEARBEITE_SENDEPLATZ,
              NotificationStyle.SUCCESS,
              `${DateFnsService.parseStringToGermanDateString(sendeplatz.kalendertag)} ${
                sendeplatz.beginnzeit
              }`,
            );
          }),
        );
      }),
    );
  });

  /**
   * Lade einen einzelnen Sendeplatz aus dem Backend nach. Die SendeplatzId wird ermittelt, indem über den SendeplatzKey
   * der korrekte Sendeplatz aus dem State geladen wird.
   */
  updateSendeplatzWithSendeplatzKey$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendeplatzActions.updateSendeplatzWithSendeplatzKey),
      concatLatestFrom(({ sendeplatzKey }) =>
        this.store.select(
          sendeplatzSelectors.selectSendeplatzBySendeplatzKey(
            SendeplatzUtils.createSendeplatzKey(sendeplatzKey),
          ),
        ),
      ),
      switchMap(([, sendeplatz]) => {
        return this.sendeplatzService.getSendeplatzById$(sendeplatz.id).pipe(
          map((sendeplatz) => sendeplatzActions.updateSendeplatzSuccess({ sendeplatz })),
          tap(() =>
            this.notificationService.showNotification(
              "Die Daten wurden erfolgreich nach Pharos übertragen!",
              NotificationStyle.SUCCESS,
            ),
          ),
        );
      }),
    );
  });

  /**
   * Löst nach dem erfolgreichen Update eines Sendeplatzes ein Update der Planungsobjekte aus, die auf diesem Sendeplatz
   * liegen. Wichtig z.B. wenn sich die abweichende Beginnzeit geändert hat, was Änderungen an den Planungsobjekten
   * nach sich zieht.
   */
  updatePlanungsobjekteNachSendeplatzUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendeplatzActions.updateSendeplatzSuccess),
      map(({ sendeplatz: { planungsobjekte } }) =>
        planungsobjektActions.getPlanungsobjekteByIdsSuccess({ planungsobjekte }),
      ),
    );
  });

  /**
   * Lade einen einzelnen Sendeplatz aus dem Backend nach. Die SendeplatzId wird ermittelt, indem über den SendeplatzKey
   * der korrekte Sendeplatz aus dem State geladen wird.
   */
  loadSendeplatzWithSendeplatzKey$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendeplatzActions.loadSendeplatzWithSendeplatzKey),
      switchMap(({ planungsobjekt }) => {
        const sendeplatzKey = SendeplatzUtils.getSendeplatzKeyFromPlanungsobjekt(planungsobjekt);
        return this.sendeplatzService.getSendeplatzBySendeplatzKey$(sendeplatzKey).pipe(
          map((sendeplatz) =>
            planungsobjektActions.createPlanungsobjektOnSendeplatzSuccess({
              shouldCloseWindow: true, // nicht relevant
              planungsobjekt,
              sendeplatz,
            }),
          ),
        );
      }),
    );
  });

  /**
   * Vereint mehrere Actions, die zur Folge ein neu Laden der Sendeplätze haben. Dies wird nicht für die Blockansicht
   * benötigt, da diese keine Sendeplätze benötigt.
   */
  upsertSendeplaetzeByAnsichten$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ansichtActions.updateAllEntitiesInAnsicht,
        multiansichtActions.setMultiansichtSuccess,
        multiansichtActions.updateAnsichtInMultiansichtSuccess,
      ),
      switchMap(() => this.store.select(multiAnsichtFeature.selectMultiAnsichtViewModel)),
      filter((multiAnsichtViewModel) => multiAnsichtViewModel?.layout !== Layout.BLOCK),
      switchMap((multiAnsichtViewModel) => {
        const ansichtenIds = multiAnsichtViewModel?.ansichtViewModels
          .filter((ansichtPublikationViewModel) => ansichtPublikationViewModel.visible)
          .map((ansichtPublikationViewModel) => ansichtPublikationViewModel.ansichtViewModel.id);

        if (!ansichtenIds || ansichtenIds.length === 0) {
          throw new Error("Es wurden keine Ansichten gefunden.");
        }

        return this.sendeplatzService
          .getSendeplaetzeByAnsichten$({ ansichtenIds })
          .pipe(
            map((sendeplaetze) =>
              sendeplatzActions.updateSendeplaetzeByAnsichtenSuccess({ sendeplaetze }),
            ),
          );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private sendeplatzService: SendeplatzService,
    private notificationService: CustomNotificationService,
    private store: Store,
  ) {}
}
