import { PlanungsobjektDto } from "../models/openapi/model/planungsobjekt-dto";
import { SendeplatzDto } from "../models/openapi/model/sendeplatz-dto";
import { DateFnsService } from "../services/date-fns.service";

export type UnifyDurationDialogInput = {
  planungsobjekt: PlanungsobjektDto;
  sendeplatz: SendeplatzDto;
  overrideHasMengengeruest?: boolean;
  planlaengeInSeconds?: number;
};

const defaultDeviationSeconds = 5 * 60;

export class DurationUtils {
  /**
   * Die andere Dauer ist weniger als +/- Sekunden von der Dauer entfernt
   *
   * @param duration Dauer in `'mm:ss'`, wobei `'mm'` nicht auf 60 begrenzt ist
   * @param otherDuration Andere Dauer in `'mm:ss'`, wobei `'mm'` nicht auf 60 begrenzt ist
   * @param seconds Sekunden der maximalen Abweichung (immer positiv)
   * @returns `true` wenn die andere Dauer weniger als +/- Sekunden von der Dauer entfernt ist
   */
  static isOtherDurationStringLessOrEqualSecondsAwayFromDurationString(
    duration: string,
    otherDuration: string,
    seconds?: number,
  ): boolean {
    const durationInSeconds = DateFnsService.parseDurationStringToSeconds(duration);
    const otherDurationInSeconds = DateFnsService.parseDurationStringToSeconds(otherDuration);
    return DurationUtils.isOtherDurationLessOrEqualSecondsAwayFromDuration(
      durationInSeconds,
      otherDurationInSeconds,
      seconds,
    );
  }

  /**
   * Die andere Dauer ist weniger als +/- Sekunden von der Dauer entfernt
   *
   * @param duration Dauer in Sekunden
   * @param otherDuration Dauer in Sekunden
   * @param seconds Sekunden der maximalen Abweichung (immer positiv)
   * @returns `true` wenn die andere Dauer weniger als +/- Sekunden von der Dauer entfernt ist
   */
  static isOtherDurationLessOrEqualSecondsAwayFromDuration(
    duration: number,
    otherDuration: number,
    seconds?: number,
  ): boolean {
    return Math.abs(duration - otherDuration) <= (seconds ?? defaultDeviationSeconds);
  }

  /**
   * Prüft, ob in {@link groups} eine Planungsobjekt mit Mengengerüst existiert, deren Länge sich von ihrem Sendeplatz
   * unterscheidet. Eine einzige Nichtübereinstummung der Längen reicht aus damit der Dialog ausgespielt wird.
   * @param groups Die Gruppen, die geprüft werden sollen
   */
  static checkUnifyDuration(groups: UnifyDurationDialogInput[]) {
    // Alle ohne Mengengerüst herausfiltern
    const groupsMitMengengeruest = groups.filter(
      (group) => group.overrideHasMengengeruest ?? !!group.planungsobjekt.mengengeruesteintragId,
    );

    for (let i = 0; i < groupsMitMengengeruest.length; i++) {
      const group = groupsMitMengengeruest[i];

      // Publikationsplanung.Laenge ist immer gesetzt, falls die Planungsobjekt aus dem Backend geladen wurde
      const planungsobjektLaengeInSeconds =
        group.planlaengeInSeconds ?? group.planungsobjekt.planlaenge;

      const sendeplatzLaengeInSeconds =
        group.sendeplatz?.abweichendeLaenge ?? group.sendeplatz?.laenge ?? 0;

      if (
        group.sendeplatz &&
        planungsobjektLaengeInSeconds &&
        !DurationUtils.isOtherDurationLessOrEqualSecondsAwayFromDuration(
          sendeplatzLaengeInSeconds,
          planungsobjektLaengeInSeconds,
        )
      ) {
        return {
          groupsMitMengengeruest,
          sendeplatzLaengeInSeconds,
          planungsobjektLaengeInSeconds,
        };
      }
    }

    return null;
  }
}
