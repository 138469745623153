import { wrapReducerWithFormStateUpdate } from "ngrx-forms";
import { lessThanOrEqualTo } from "ngrx-forms/validation";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { DateFnsService } from "src/app/services/date-fns.service";
import { maxSecondsForMaskedInput } from "src/app/shared/form-inputs/masked-input/masked-input.model";
import {
  createReducerAndCustomFormActions,
  createValidationRules,
  ValidationMap,
} from "src/app/utils/ngrx-forms";
import {
  initialSendePlatzWindowFeatureState,
  sendeplatzFormId,
  SendePlatzWindowFeatureState,
  SendeplatzWindowFormData,
} from "./sendeplatz-window.model";

const sendeplatzWindowFormValidationRules = (
  form: SendeplatzWindowFormData,
): ValidationMap<SendeplatzWindowFormData> => {
  return {
    abweichendeLaenge: [lessThanOrEqualTo(maxSecondsForMaskedInput)],
  };
};

const { actions, reducer, selectors } = createReducerAndCustomFormActions<
  SendePlatzWindowFeatureState,
  "formState"
>(initialSendePlatzWindowFeatureState, sendeplatzFormId, "formState");

export const sendeplatzWindowFormActions = actions;
export const sendeplatzWindowFormSelectors = selectors;

export const sendeplatzWindowFormReducer = wrapReducerWithFormStateUpdate(
  reducer,
  (s) => s.formState,
  createValidationRules(sendeplatzWindowFormValidationRules),
);

export const calculateInitialFormStateFromInput = (
  sendeplatz: SendeplatzDto,
): SendeplatzWindowFormData => {
  return {
    abweichendeBeginnzeit: sendeplatz.abweichendeBeginnzeit
      ? DateFnsService.formatDateAsTimeString(
          DateFnsService.parseDateAndTimeToDateObject(
            sendeplatz.abweichendeBeginnzeit,
            sendeplatz.kalendertag,
          ),
        )
      : null,
    abweichendeLaenge: sendeplatz.abweichendeLaenge,
    notiz: sendeplatz.notiz,
  };
};
