import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { DialogCloseResult, WindowCloseResult } from "@progress/kendo-angular-dialog";
import { map, of, switchMap } from "rxjs";
import { MerklisteService } from "src/app/services/merkliste.service";
import { onDemandActions } from "../on-demand/on-demand.actions";
import { planungsobjektActions } from "../planungsobjekt/planungsobjekt.actions";
import merklisteSelectors from "./merkliste.selectors";
import { merklisteWindowActions } from "./merkliste.window.actions";

@Injectable()
export class MerklisteWindowEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private merklisteService: MerklisteService,
  ) {}

  openMovePlanungsobjektLinearToMerklisteDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(merklisteWindowActions.openMovePlanungsobjektLinearToMerklisteDialog),
      concatLatestFrom(() =>
        this.store.select(merklisteSelectors.selectMerklistenForAnsichtIdFromQueryParam),
      ),
      switchMap(
        ([
          { planungsobjektLinearId, planungsobjektLinearTitel: planunsobjektLinearTitel },
          merklisten,
        ]) =>
          merklisten
            ? this.merklisteService
                .openVerschiebePlanungsobjektLinearZuVorgemerktDialog(
                  planunsobjektLinearTitel,
                  merklisten,
                )
                .result.pipe(
                  map((dialogResult) => {
                    if (dialogResult instanceof DialogCloseResult) {
                      return merklisteWindowActions.dialogClosed();
                    }
                    return planungsobjektActions.movePlanungsobjektToMerkliste({
                      merkliste: dialogResult,
                      planungsobjektId: planungsobjektLinearId,
                    });
                  }),
                )
            : of(merklisteWindowActions.error({ errorMessage: "Keine Merklisten gefunden" })),
      ),
    );
  });

  openMovePlanungsobjektOnDemandToMerklisteDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(merklisteWindowActions.openMovePlanungsobjektOnDemandToMerklisteDialog),
      switchMap(({ planungsobjektOnDemandId, planungsobjektOnDemandTitel, quickFilterPreset }) => {
        return this.merklisteService
          .openVerschiebePlanungsobjektOnDemandZuVorgemerktWindow$(
            planungsobjektOnDemandId,
            planungsobjektOnDemandTitel,
            quickFilterPreset,
          )
          .result.pipe(
            map((windowResult) => {
              if (windowResult instanceof WindowCloseResult) {
                return merklisteWindowActions.dialogClosed();
              }

              const { onDemandFilter, nummer, ausspielweg } = windowResult.merkliste;
              return onDemandActions.verschiebePlanungsobjektOnDemandZuVorgemerkt({
                command: {
                  planungsobjektId: windowResult.planungsobjektId,
                  merklisteOnDemandFilter: onDemandFilter,
                  merklisteNummer: nummer,
                  merklisteAusspielweg: ausspielweg,
                },
                merkliste: windowResult.merkliste,
              });
            }),
          );
      }),
    );
  });
}
