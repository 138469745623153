import { createFeature, createReducer, on } from "@ngrx/store";
import { onNgrxForms } from "ngrx-forms";
import { shiftElement, toggleValuesInArray } from "src/app/utils/array-utils";
import { mergeReducers } from "src/app/utils/ngrx-utils";
import { FeatureKey } from "../feature.keys";
import { rechercheActions } from "./recherche.actions";
import { rechercheFormReducer } from "./recherche.form";
import {
  RechercheGridResultColumn,
  RechercheState,
  initialRechercheState,
} from "./recherche.model";
import {
  calculateGridColumnWidths,
  defaultColumnWidthForField,
  determineActiveAdditionalFiltersFromQuery,
  determineAdditionalColumnsFromQuery,
  upsertManyForPlanungsobjekte,
} from "./recherche.utils";

const rechercheFeatureReducer = createReducer(
  initialRechercheState,
  on(
    rechercheActions.search,
    rechercheActions.searchByBrowserNavigation,
    /**
     * Führt mit den aktuellen Werten des States eine Suche aus.
     *
     * Falls die Query nicht valide ist, wird der State nicht verändert.
     * Dies kann (aktuell) nicht passieren, da der Such-Button nur aktiviert ist,
     * wenn das Formular der Suche valide ist.
     *
     * Wenn es sich um die erste Suche handelt, werden abhängig von den eingestellten Filtern zusätzliche Spalten eingeblendet.
     */
    (currentState): RechercheState => {
      return {
        ...currentState,
        isSearchFormDirty: false,
        isFirstSearch: false,
      };
    },
  ),
  on(
    rechercheActions.searchSuccess,
    (currentState, { results, idsWithVarianten, isParent, query }): RechercheState => {
      const additionalColumns = determineAdditionalColumnsFromQuery(query);
      const currentColumns = currentState.shownColumns;
      const shownColumns = Array.from(new Set([...additionalColumns, ...currentColumns]));

      const activeAdditionalFilters = determineActiveAdditionalFiltersFromQuery(query);

      return {
        ...currentState,
        results,
        isFirstSearch: false,
        idsWithVarianten,
        resultIds: isParent,
        expandedIds: [],
        shownColumns,
        activeAdditionalFilters,
      };
    },
  ),
  on(rechercheActions.searchFailure, (currentState): RechercheState => {
    return {
      ...currentState,
      results: { linear: [], onDemand: [] },
      idsWithVarianten: [],
      resultIds: [],
    };
  }),
  on(rechercheActions.searchReset, (): RechercheState => {
    return {
      ...initialRechercheState,
    };
  }),
  on(rechercheActions.searchChildren, (currentState, { parentIds }): RechercheState => {
    return {
      ...currentState,
      expandedIds: Array.from(new Set([...currentState.expandedIds, ...parentIds])),
    };
  }),

  on(rechercheActions.searchChildrenSuccess, (currentState, action): RechercheState => {
    const results = upsertManyForPlanungsobjekte(currentState.results, action.results);

    const idsWithVarianten = Array.from(
      new Set([...currentState.idsWithVarianten, ...action.idsWithVarianten]),
    );

    return {
      ...currentState,
      results,
      idsWithVarianten,
    };
  }),

  on(rechercheActions.collapseParents, (currentState, { parentIds }): RechercheState => {
    return {
      ...currentState,
      expandedIds: currentState.expandedIds.filter((id) => !parentIds.includes(id)),
    };
  }),

  on(rechercheActions.resizeGridColumns, (currentState, { resizedColumns }): RechercheState => {
    const resizedColumnWidths = resizedColumns.reduce(
      (accu, column) => {
        accu[column.field] = column.width;
        return accu;
      },
      {} as Record<string, number>,
    );
    const columnProperties = currentState.columnProperties.map((p) => {
      if (p.field in resizedColumnWidths) {
        return {
          ...p,
          width: resizedColumnWidths[p.field] ?? defaultColumnWidthForField(p.field),
        };
      } else {
        return p;
      }
    });

    return { ...currentState, columnProperties };
  }),

  on(rechercheActions.setGridColumns, (currentState, { columns }): RechercheState => {
    return updateStateWithShownColumns(currentState, columns);
  }),

  on(rechercheActions.toggleGridColumns, (currentState, action): RechercheState => {
    const shownColumns = toggleValuesInArray(action.columns, currentState.shownColumns);
    return updateStateWithShownColumns(currentState, shownColumns);
  }),

  on(rechercheActions.reorderRechercheColumns, (currentState, action): RechercheState => {
    const newShownColumns = shiftElement(
      currentState.shownColumns,
      action.oldIndex,
      action.newIndex,
    );

    return { ...currentState, shownColumns: newShownColumns };
  }),
  on(rechercheActions.setAdditionalFilters, (currentState, { filters }): RechercheState => {
    const newState: RechercheState = { ...currentState, activeAdditionalFilters: filters };
    return newState;
  }),
  on(rechercheActions.removeAdditionalFilter, (currentState, { filter }): RechercheState => {
    const activeAdditionalFilters = currentState.activeAdditionalFilters.filter(
      (activeFilter) => activeFilter !== filter,
    );
    const newState: RechercheState = { ...currentState, activeAdditionalFilters };
    return newState;
  }),
  onNgrxForms(),
);

const updateStateWithShownColumns = (
  state: RechercheState,
  shownColumns: RechercheGridResultColumn[],
): RechercheState => {
  return {
    ...state,
    shownColumns,
    columnProperties: calculateGridColumnWidths(
      shownColumns,
      state.columnProperties,
      state.searchForm.value.kanaeleSelected.value,
    ),
  };
};

const reducer = mergeReducers(rechercheFeatureReducer, rechercheFormReducer);

export const rechercheFeature = createFeature({
  name: FeatureKey.Recherche,
  reducer,
});
