<ng-container
  *ngIf="{
    formState: onDemandFormState$ | async,
    can: {
      beitragen: 'PlanungsobjektBeitragen' | able: 'Permission',
    },
  } as vm"
>
  <form
    *ngIf="vm.formState"
    data-testid="vorschlaege-details-dialog"
    [ngrxFormState]="vm.formState"
  >
    <div class="flex-container full-width">
      <div class="flex-container-vert over-third-width">
        <app-form-field class="full-width" hint="Kalendertag" label="Wunsch online ab">
          <kendo-formerror
            *ngIf="vm.formState.controls.wunschOnlineAb.errors?.valueBefore as valueBefore"
            >"Wunsch online ab" darf nicht vor "{{ valueBefore.otherLabel }}" liegen
          </kendo-formerror>
          <app-iso-dateonly
            data-testid="wunsch-online-ab"
            [attr.kalendertag]="linear()?.kalendertag"
            [focusedDate]="vm.formState.controls.wunschOnlineAb.value ?? linear()?.kalendertag"
            [ngrxFormControlState]="vm.formState.controls.wunschOnlineAb"
          ></app-iso-dateonly>
        </app-form-field>

        <app-form-field class="u-col-3" label="Online ab Zeit">
          <app-iso-timeonly
            clearable
            data-testid="online-ab-zeit"
            [ngrxFormControlState]="vm.formState.controls.onlineAbZeit"
          ></app-iso-timeonly>
        </app-form-field>

        <label class="select-option">
          <input
            data-testid="reihenfolge-checkbox"
            kendoCheckBox
            type="checkbox"
            [ngrxFormControlState]="vm.formState.controls.reihenfolgeHerstellen"
          />
          <span>Reihenfolge beachten</span>
        </label>
      </div>
      <div class="publikation-toggle">
        <kendo-switch
          class="wunsch-online-switch"
          data-testid="wunschonlineab-relationalzulinear-toggle"
          size="large"
          [ngrxFormControlState]="vm.formState.controls.wunschOnlineAbVsRelationalZuLinearToggle"
        ></kendo-switch>
      </div>

      <div class="flex-container-vert over-third-width">
        <app-form-field class="full-width" hint="+/- X Tage" label="Relativ zu linear">
          <kendo-formerror
            *ngIf="vm.formState.controls.relationZuLinearInTagen.errors?.valueBefore as valueBefore"
            >"Wunsch online ab" darf nicht nach "{{ valueBefore.otherLabel }}" liegen
          </kendo-formerror>
          <kendo-formerror *ngIf="vm.formState.controls.relationZuLinearInTagen.errors?.valueBefore"
            >"Relative Tage liegen nicht im angegebenen Zeitbereich."
          </kendo-formerror>
          <kendo-formerror *ngIf="vm.formState.controls.relationZuLinearInTagen.errors?.valueAfter"
            >"Relative Tage liegen nicht im angegebenen Zeitbereich."
          </kendo-formerror>
          <kendo-numerictextbox
            data-testid="relationalzulinear-textbox"
            format="n0"
            [decimals]="0"
            [ngrxFormControlState]="vm.formState.controls.relationZuLinearInTagen"
            [selectOnFocus]="false"
            [step]="1"
          >
          </kendo-numerictextbox>
        </app-form-field>

        <div class="flex-container">
          <app-form-field class="half-width" label="frühestens">
            <kendo-formerror
              *ngIf="vm.formState.controls.minDistanz.errors?.valueBefore as valueBefore"
              >"Frühestens größer als spätestens oder relativ zu linear"
            </kendo-formerror>
            <kendo-numerictextbox
              data-testid="mindistanz-textbox"
              format="n0"
              [decimals]="0"
              [ngrxFormControlState]="vm.formState.controls.minDistanz"
              [selectOnFocus]="false"
              [step]="1"
            >
            </kendo-numerictextbox>
          </app-form-field>

          <app-form-field class="half-width" label="spätestens">
            <kendo-formerror
              *ngIf="vm.formState.controls.maxDistanz.errors?.valueAfter as valueAfter"
              >"Spätestens kleiner als frühestens oder relativ zu linear"
            </kendo-formerror>
            <kendo-numerictextbox
              data-testid="maxdistanz-textbox"
              format="n0"
              [decimals]="0"
              [ngrxFormControlState]="vm.formState.controls.maxDistanz"
              [selectOnFocus]="false"
              [step]="1"
            >
            </kendo-numerictextbox>
          </app-form-field>
        </div>

        <label class="select-option">
          <input
            kendoCheckBox
            onclick="return false;"
            type="checkbox"
            [checked]="vm.formState.controls.wunschOnlineAbVsRelationalZuLinearToggle.value"
            [disabled]="!vm.formState.controls.wunschOnlineAbVsRelationalZuLinearToggle.value"
          />
          <span>Abhängigkeit</span>
        </label>
      </div>
    </div>

    <div class="flex-container align-on-baseline full-width">
      <app-form-field class="over-third-width" hint="Kalendertag" label="Wunsch online bis">
        <kendo-formerror
          *ngIf="vm.formState.controls.wunschOnlineBis.errors?.valueAfter as valueAfter"
          >"Wunsch online bis" darf nicht vor "{{ valueAfter.otherLabel }}" liegen
        </kendo-formerror>
        <app-iso-dateonly
          data-testid="wunsch-online-bis"
          [attr.kalendertag]="linear()?.kalendertag"
          [focusedDate]="
            vm.formState.controls.wunschOnlineBis.value ??
            vm.formState.controls.wunschOnlineAb.value
          "
          [minDate]="vm.formState.controls.wunschOnlineAb.value"
          [ngrxFormControlState]="vm.formState.controls.wunschOnlineBis"
        ></app-iso-dateonly>
      </app-form-field>
      <div>
        <kendo-switch
          class="wunsch-online-switch"
          data-testid="wunschonlinebis-verweildauer-toggle"
          size="large"
          [ngrxFormControlState]="vm.formState.controls.wunschOnlineBisVsVerweildauerToggle"
        ></kendo-switch>
      </div>
      <app-form-field class="over-third-width" hint="Tage zwischen Online ab und Online bis" showErrors="initial">
        <app-form-field class="full-width" label="Verweildauer">
          <kendo-numerictextbox
            data-testid="verweildauer-textbox"
            format="n0"
            [decimals]="0"
            [min]="1"
            [ngrxFormControlState]="vm.formState.controls.verweildauerInTagen"
            [selectOnFocus]="false"
            [step]="1"
          >
          </kendo-numerictextbox>
        </app-form-field>
      </app-form-field>
    </div>

    <div class="flex-container information-text k-font-italic">
      <kendo-label
        text="Alle Angaben sind unter Vorbehalt, da unter Umständen weder eine Prüfung der Rechte, noch eine Materialzulieferung stattgefunden hat"
      >
      </kendo-label>
    </div>
    <div class="k-d-flex k-justify-content-end">
      <button
        data-testid="beziehung-speichern-button"
        fillMode="solid"
        kendoButton
        themeColor="primary"
        title="Speichern"
        [disabled]="
          !vm.can.beitragen ||
          !vm.formState.isValid ||
          vm.formState.isPristine ||
          vm.formState.isDisabled
        "
        [svgIcon]="Icons.checkIcon"
        (click)="onSave()"
      ></button>
    </div>
  </form>
</ng-container>
