import { Injectable, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, combineLatest, filter, map, take } from "rxjs";
import { AppAbility } from "src/app/casl/app-ability";
import { aktivitaetenActions } from "src/app/core/stores/aktivitaeten/aktivitaeten.actions";
import { planungsobjektFormActions } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-form/planungsobjekt-window.form";
import { planungsobjektWindowActions } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.actions";
import { PlanungsobjektWindowTabEnum } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.model";
import planungsobjektWindowSelectors from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.selectors";
import { planungsobjektActions } from "src/app/core/stores/planungsobjekt/planungsobjekt.actions";
import { planungsobjektBeziehungWindowActions } from "src/app/core/stores/planungsobjekt/planungsobjekt.beziehung.window.actions";
import { BeziehungDto } from "src/app/models/openapi/model/beziehung-dto";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { PlanungsobjektLinearDto } from "src/app/models/openapi/model/planungsobjekt-linear-dto";
import { PlanungsobjektOnDemandDto } from "src/app/models/openapi/model/planungsobjekt-on-demand-dto";
import { isDefined } from "src/app/utils/object-utils";
import { Role } from "tests/common/generated/api/models/Role";
import { CanEditPlanungsobjektWindowService } from "../../../core/stores/planungsobjekt-window/can-edit-planungsobjekt-window.service";
import {
  PlanungsobjektWindowInputWithPlanungsobjekt,
  PlanungsobjektWindowUseCase,
} from "./planungsobjekt-window.model";

@Injectable()
export class PlanungsobjektWindowFacade {
  private readonly store = inject(Store);
  private readonly canEdit = inject(CanEditPlanungsobjektWindowService);
  private readonly ability = inject(AppAbility);

  public readonly formState$ = this.store.select(
    planungsobjektWindowSelectors.planungsForm.selectFormState,
  );
  public readonly onDemandFormState$ = this.store.select(
    planungsobjektWindowSelectors.onDemandForm.selectFormState,
  );
  public readonly input$ = this.store.select(planungsobjektWindowSelectors.selectInput);

  public readonly planungsobjekt$: Observable<PlanungsobjektOnDemandDto | PlanungsobjektLinearDto> =
    this.input$.pipe(
      map((input) => input?.planungsobjekt),
      filter(
        (planungsobjekt): planungsobjekt is PlanungsobjektOnDemandDto | PlanungsobjektLinearDto =>
          !!planungsobjekt,
      ),
    );

  public readonly isFormDirty$: Observable<boolean> = this.store.select(
    planungsobjektWindowSelectors.planungsForm.selectFormIsDirty,
  );

  public readonly canPlanen$ = this.input$.pipe(
    filter(isDefined),
    map((input) => this.canEdit.transform(input)),
  );
  /**
   * Aus canEdit() abweichende Berechtigung aus PUBLIT-1766.
   * get.it Ver/Entknüpfen und Synchronisation der get.it Daten von vorgeplanten Planungsobjekten
   * ist auch nur mit Beitragen Berechtigungen möglich.
   */
  private canBeitragenGetit(input: PlanungsobjektWindowInputWithPlanungsobjekt) {
    return (
      input.planungskontext === Planungskontext.VORGEPLANT &&
      this.ability.can(Role.PLANUNGSOBJEKT_BEITRAGEN, "Permission")
    );
  }

  public readonly isGetItSyncDisabled$ = combineLatest([this.isFormDirty$, this.input$]).pipe(
    map(
      ([isFormDirty, input]) =>
        isFormDirty || !input || !(this.canEdit.transform(input) || this.canBeitragenGetit(input)),
    ),
  );

  public readonly validationErrors$ = this.formState$.pipe(map((form) => form.errors));

  public updateProduktInformationenFromGetit(planungsobjekt: PlanungsobjektDto) {
    this.store.dispatch(
      planungsobjektActions.synchronisierePlanungsobjektMitGetit({
        command: { planungsobjektId: planungsobjekt.id },
      }),
    );
  }

  public tryCloseWindow() {
    this.store.dispatch(planungsobjektWindowActions.tryClosingWithoutSaving());
  }

  public deletePlanungsobjekt() {
    this.store.dispatch(planungsobjektWindowActions.deletePlanungsobjekt());
  }

  public save() {
    this.store.dispatch(planungsobjektFormActions.trySaving());
  }

  public saveAndClose() {
    this.store.dispatch(planungsobjektWindowActions.trySavingAndClosing());
  }

  public tryChangeTab(tab: PlanungsobjektWindowTabEnum, isFormValid: boolean) {
    this.store.dispatch(
      planungsobjektWindowActions.tryChangeTab({
        newTab: tab,
        isFormValid,
      }),
    );
  }

  public deleteBeziehung(beziehung: BeziehungDto) {
    this.input$.pipe(take(1)).subscribe((input) => {
      if (
        input?.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ ||
        input?.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT ||
        input?.usecase === PlanungsobjektWindowUseCase.EDIT_ONDEMAND
      ) {
        this.store.dispatch(
          planungsobjektBeziehungWindowActions.openDeletePlanungsobjektBeziehungWindow({
            planungsobjekt: input.planungsobjekt,
            beziehung,
          }),
        );
      }
    });
  }

  public loadAktivitaetenForPlanungsobjekt() {
    this.input$.pipe(take(1)).subscribe((input) => {
      if (input?.planungsobjektId) {
        this.store.dispatch(
          aktivitaetenActions.loadAktivitaetenForPlanungsobjekt({
            planungsobjektId: input.planungsobjektId,
          }),
        );
      }
    });
  }
}
