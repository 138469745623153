<ng-container
  *ngIf="{
    planenOnSendeplatz: 'planen' | able: (sendeplatz | asSubject: 'Sendeplatz'),
    beitragen: Role.PLANUNGSOBJEKT_BEITRAGEN | able: 'Permission'
  } as can"
>
  <kendo-chip-list
    #target="kendoContextMenuTargetContainer"
    class="vorschlag-liste"
    kendoContextMenuTargetContainer
  >
    <ng-container *ngFor="let planungsobjektInVorschlagspalte of planungsobjekteInVorschlagspalte">
      <app-planungsobjekt-vorschlag
        [planungsobjektInVorschlagspalte]="planungsobjektInVorschlagspalte"
        [sendeplatz]="sendeplatz"
        (doubleClicked)="openPlanungsobjektLinearWithPlanungWindow($event)"
      >
      </app-planungsobjekt-vorschlag>

      <kendo-contextmenu
        showOn="contextmenu"
        [target]="target"
        (popupOpen)="onVorschlagContextMenuOpen($event)"
        (select)="onPlanungsobjektContextMenuSelect($event)"
      >
        <kendo-menu-item
          *ngIf="settings.features.onDemandPlanung"
          text="Details"
          [data]="{ action: AktionEnum.BEARBEITE_PLANUNGSOBJEKT }"
          [svgIcon]="Icons.information"
        ></kendo-menu-item>
        <kendo-menu-item
          text="in Serie umwandeln"
          [data]="{ action: AktionEnum.PLANUNGSOBJEKT_IN_SERIE_UMWANDELN }"
          [disabled]="!can.beitragen"
          [svgIcon]="Icons.serie"
        ></kendo-menu-item>
        <kendo-menu-item
          text="Kopie anlegen"
          [data]="{
            action: AktionEnum.KOPIE_HIER,
            }"
          [disabled]="!can.beitragen"
          [svgIcon]="Icons.copy"
        >
          <kendo-menu-item
            text="diese Woche"
            [data]="{
              action: AktionEnum.KOPIE_HIER,
            }"
            [svgIcon]="Icons.copyThisWeek"
          ></kendo-menu-item>
          <kendo-menu-item
            text="nächste Woche"
            [data]="{
              action: AktionEnum.KOPIE_NAECHSTE_WOCHE,
            }"
            [svgIcon]="Icons.copyNextWeek"
          ></kendo-menu-item>
        </kendo-menu-item>
        <kendo-menu-item
          text="Auf Merkliste setzen"
          [data]="{ action: AktionEnum.PLANUNGSOBJEKT_AUF_MERKLISTE }"
          [disabled]="!(can.planenOnSendeplatz || can.beitragen)"
          [svgIcon]="Icons.merklisteIcon"
        ></kendo-menu-item>
      </kendo-contextmenu>
    </ng-container>
  </kendo-chip-list>
</ng-container>
