import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { filter, map, switchMap, tap } from "rxjs";
import { Aktion } from "src/app/models/enums/aktion";
import { Layout } from "src/app/models/openapi/model/layout";
import { NotificationStyle } from "src/app/models/openapi/model/notification-style";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { PlanungsobjektLinearDtoResultDto } from "src/app/models/openapi/model/planungsobjekt-linear-dto-result-dto";
import { BlockansichtService } from "src/app/pages/ansichten/blockansicht/blockansicht.service";
import { CustomNotificationService } from "src/app/shared/notifications/custom-notification.service";
import { multiansichtActions } from "../multiansicht/multiansicht.actions";
import { planungsobjektActions } from "../planungsobjekt/planungsobjekt.actions";
import routerSelectors from "../router/router.selectors";
import { blockansichtActions } from "./blockansicht.actions";

@Injectable()
export class BlockansichtEffects {
  resetZoomLevel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(multiansichtActions.setMultiansichtSuccess),
      filter(({ multiAnsichtViewModel }) => multiAnsichtViewModel.layout === Layout.BLOCK),
      map(() => blockansichtActions.resetZoomLevel()),
    );
  });

  /**
   * Lade alle Planungsobjekte für die Blockansicht eines neuen Jahres.
   */
  loadPlanungsobjekteByAnsicht$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(multiansichtActions.updateAnsichtInMultiansichtSuccess),
      filter(({ layout }) => layout === Layout.BLOCK),
      switchMap(({ additionalAnsichtViewModelId }) => {
        return this.blockansichtService
          .getPlanungsobjekteByBlockansichtenIds$([additionalAnsichtViewModelId])
          .pipe(
            map((planungsobjekte) =>
              planungsobjektActions.loadPlanungsobjekteForAnsichtenSuccess({
                planungsobjekte,
              }),
            ),
          );
      }),
    );
  });

  /**
   * Erstelle eine Planungsobjekt auf einer Blockansicht.
   */
  createPlanungsobjektOnBlockansicht$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.createPlanungsobjektOnBlockansicht),
      switchMap(({ planungsobjekt }) => {
        return this.blockansichtService
          .createPlanungsobjektLinearVorgeplantBlock$(planungsobjekt)
          .pipe(
            map((createdPlanungsobjekt: PlanungsobjektDto) =>
              planungsobjektActions.createPlanungsobjektOnBlockansichtSuccess({
                shouldCloseWindow: true, // Alter Dialog, alte Close Routine -> uninteressant
                planungsobjekt: createdPlanungsobjekt,
              }),
            ),
          );
      }),
    );
  });

  /**
   * Zukünftig über das neue Fenster sollte dieser Effect genuzt werden.
   */
  createPlanungsobjektOnBlockansichtNewWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.createPlanungsobjektOnBlockansichtNewWindow),
      switchMap(({ shouldCloseWindow, command }) => {
        return this.blockansichtService
          .createPlanungsobjektLinearVorgeplantBlockNewWindow$(command)
          .pipe(
            map((createdPlanungsobjekt: PlanungsobjektDto) =>
              planungsobjektActions.createPlanungsobjektOnBlockansichtSuccess({
                shouldCloseWindow,
                planungsobjekt: createdPlanungsobjekt,
              }),
            ),
          );
      }),
    );
  });

  /**
   * Update eine Planungsobjekt auf einer Blockansicht.
   */
  updatePlanungsobjektOnBlockansicht$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.updatePlanungsobjektOnBlockansicht),
      switchMap(({ shouldCloseWindow, planungsobjekt }) =>
        this.blockansichtService.updatePlanungsobjektOnBlockansicht$(planungsobjekt).pipe(
          map((updatedPlanungsobjekt) =>
            planungsobjektActions.updatePlanungsobjektOnBlockansichtSuccess({
              shouldCloseWindow,
              planungsobjekt: updatedPlanungsobjekt,
            }),
          ),
          tap(() => {
            this.notificationService.showActionNotification(
              Aktion.BEARBEITE_PLANUNGSOBJEKT,
              NotificationStyle.SUCCESS,
              planungsobjekt.titel,
            );
          }),
        ),
      ),
    );
  });

  /**
   * Zukünftig über das neue Fenster sollte dieser Effect genuzt werden.
   */
  updatePlanungsobjektOnBlockansichtNewWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.updatePlanungsobjektOnBlockansichtNewWindow),
      switchMap(({ shouldCloseWindow, command }) =>
        this.blockansichtService.updatePlanungsobjektOnBlockansichtNewWindow$(command).pipe(
          map((updatedPlanungsobjekt) =>
            planungsobjektActions.updatePlanungsobjektOnBlockansichtSuccess({
              shouldCloseWindow,
              planungsobjekt: updatedPlanungsobjekt,
            }),
          ),
          tap((result) => {
            this.notificationService.showActionNotification(
              Aktion.BEARBEITE_PLANUNGSOBJEKT,
              NotificationStyle.SUCCESS,
              result.planungsobjekt.result.titel,
            );
          }),
        ),
      ),
    );
  });

  /**
   * Kopiere eine Planungsobjekt auf einer Blockansicht.
   */
  copyPlanungsobjektOnBlockansicht$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.copyPlanungsobjektOnBlockansicht),
      switchMap(({ planungsobjektId, copyPattern }) =>
        this.blockansichtService.planungsobjektLinearVorgeplantBlockKopieren$({
          planungsobjektId,
          copyPattern,
        }),
      ),
      map(({ result: planungsobjekt }) => {
        return planungsobjektActions.copyPlanungsobjektOnBlockansichtSuccess({
          planungsobjekt,
        });
      }),
      tap(({ planungsobjekt }) => {
        this.notificationService.showActionNotification(
          Aktion.KOPIERE_PLANUNGSOBJEKT,
          NotificationStyle.SUCCESS,
          planungsobjekt.titel,
        );
      }),
    );
  });

  movePlanungsobjektInBlockansicht$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.movePlanungsobjektInBlockansicht),
      switchMap(({ planungsobjekt }) =>
        this.blockansichtService.movePlanungsobjektToBlockansicht$(planungsobjekt).pipe(
          map((result) =>
            planungsobjektActions.movePlanungsobjektInBlockansichtSuccess({
              planungsobjekt: result,
            }),
          ),
        ),
      ),
    );
  });

  moveMultiplePlanungsobjekteInBlockansicht$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.moveMultiplePlanungsobjektInBlockansicht),
      concatLatestFrom(() => this.store.select(routerSelectors.selectAnsichtIdQueryParam)),
      switchMap(([{ command }, ansichtId]) => {
        if (!ansichtId) {
          throw new Error("AnsichtId konnte für die Aktion nicht ermittelt werden.");
        }
        return this.blockansichtService
          .movePlanungsobjekteToVorgeplantBlock$({ ...command, ansichtId })
          .pipe(
            map((result) => {
              return planungsobjektActions.moveMultiplePlanungsobjektInBlockansichtSuccess({
                planungsobjekte: result.result,
              });
            }),
          );
      }),
    );
  });

  /**
   * Wenn ein Planungsobjekt manuell in der Blockansicht erstellt/verschoben wird, muss ggf. eine
   * ManuelleVariantenzeile aus dem State entfernt werden, da diese bereits durch die Planungsobjekt abgedeckt wird.
   */
  removeManuelleVariantenzeile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        planungsobjektActions.createPlanungsobjektOnBlockansichtSuccess,
        planungsobjektActions.movePlanungsobjektInBlockansichtSuccess,
      ),
      map(({ planungsobjekt }) => {
        const isPlanungsobjektDtoResultDto = (
          planungsobjekt: PlanungsobjektLinearDtoResultDto | PlanungsobjektDto,
        ): planungsobjekt is PlanungsobjektLinearDtoResultDto => {
          return "notifications" in planungsobjekt && "result" in planungsobjekt;
        };
        return isPlanungsobjektDtoResultDto(planungsobjekt)
          ? planungsobjekt.result
          : planungsobjekt;
      }),
      map((planungsobjekt) => {
        if (
          planungsobjekt.publikationsplanung === null ||
          planungsobjekt.publikationsplanung?.variante === null
        ) {
          throw new Error("Planungsobjekt besitzt keine Publikationsplanung oder Variante.");
        }
        return blockansichtActions.removeManuelleVariantenzeile({
          sendetag: planungsobjekt.publikationsplanung?.sendetag,
          variante: planungsobjekt.publikationsplanung?.variante,
        });
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store,
    private blockansichtService: BlockansichtService,
    private notificationService: CustomNotificationService,
  ) {}
}
