import { concatLatestFrom } from "@ngrx/operators";
import { debounceTime, distinctUntilChanged, filter, map, Observable, pairwise } from "rxjs";

/**
 * Pairwise the source observable when the isNeeded$ observable emits true.
 *
 * Important! The isNeeded$ observable must have an initial value. And must always return a value when subscribed.
 * e.g use NgRx Selector or ShareReplay and startWith()
 *
 * @param isNeeded$
 * @returns
 */
export const pairwiseWhen =
  <T>(isNeeded$: Observable<boolean>) =>
  (source$: Observable<T>) =>
    source$.pipe(
      concatLatestFrom(() => [isNeeded$]),
      filter(([_, isNeeded]) => isNeeded),
      map(([source]) => source),
      distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next)),
      pairwise(),
      debounceTime(0),
    );
