/* tslint:disable */
/* eslint-disable */
/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Anwendungsinterne Repräsentation der publish.it Rollen aus:
 * https://jira.zdf.de/confluence/pages/viewpage.action?pageId=161127185#ErweitertesRollen&RechteKonzept-ZBSFunktionen
 * @export
 */
export const Role = {
    UNBEKANNT: 'Unbekannt',
    PLANUNGSOBJEKT_ZDF_LESEN: 'PlanungsobjektZDFLesen',
    PLANUNGSOBJEKT_ZDF_PLANEN: 'PlanungsobjektZDFPlanen',
    PLANUNGSOBJEKT_BEITRAGEN: 'PlanungsobjektBeitragen',
    EVENT_KONKURRENZPROGRAMM_EDITIEREN: 'EventKonkurrenzprogrammEditieren',
    PHAROS_TRANSFER_DURCHFUEHREN: 'PharosTransferDurchfuehren',
    MENGENGERUEST_EDITIEREN: 'MengengeruestEditieren',
    PLANUNGSHINWEIS_EDITIEREN: 'PlanungshinweisEditieren',
    PLANUNGSOBJEKT_ZDF_MEDIATHEK_LESEN: 'PlanungsobjektZDFMediathekLesen',
    PLANUNGSOBJEKT_ZDF_MEDIATHEK_PLANEN: 'PlanungsobjektZDFMediathekPlanen',
    PLANUNGSOBJEKT_ZDF_NEO_LESEN: 'PlanungsobjektZDFNeoLesen',
    PLANUNGSOBJEKT_ZDF_NEO_PLANEN: 'PlanungsobjektZDFNeoPlanen',
    EVENT_KONKURRENZPROGRAMM_LESEN: 'EventKonkurrenzprogrammLesen',
    TECHNISCHER_ADMINISTRATOR: 'TechnischerAdministrator'
} as const;
export type Role = typeof Role[keyof typeof Role];


export function RoleFromJSON(json: any): Role {
    return RoleFromJSONTyped(json, false);
}

export function RoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Role {
    return json as Role;
}

export function RoleToJSON(value?: Role | null): any {
    return value as any;
}

