<div
  *ngIf="{
    isFirstSearch: facade.isFirstSearch$ | async,
  } as vm"
  class="recherche-container"
>
  <app-search-form></app-search-form>
  <div class="result-table">
    <app-search-results-grid *ngIf="!vm.isFirstSearch"></app-search-results-grid>
  </div>
</div>
