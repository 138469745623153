import { inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { mengengeruestWindowActions } from "src/app/core/stores/mengengeruest-window/mengengeruest-window.actions";
import { MengengeruestWindowUseCase } from "src/app/core/stores/mengengeruest-window/mengengeruest-window.model";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";

@Injectable()
export class DashboardAnsichtFacade {
  private readonly store = inject(Store);

  public openMengengeruestWindow(ansichtViewModel: AnsichtViewModel) {
    this.store.dispatch(
      mengengeruestWindowActions.openMengengeruestWindow({
        input: {
          type: MengengeruestWindowUseCase.MAIN_WINDOW,
          ansichtViewModel,
        },
      }),
    );
  }
}
