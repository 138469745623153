import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BenachrichtigungDto } from "src/app/models/openapi/model/benachrichtigung-dto";
import { BenachrichtigungVerwerfenMultipleCommand } from "src/app/models/openapi/model/benachrichtigung-verwerfen-multiple-command";
import { GetBenachrichtigungenForCurrentUserByZeitraumQuery } from "src/app/models/openapi/model/get-benachrichtigungen-for-current-user-by-zeitraum-query";
import { PublitFrontendSettings } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class BenachrichtigungApiService {
  private apiUrl = this.settings.apiUrl;

  private readonly basePath = "Benachrichtigung";

  constructor(
    private http: HttpClient,
    private settings: PublitFrontendSettings,
  ) {}

  getBenachrichtigungen$(): Observable<BenachrichtigungDto[]> {
    return this.http.get<BenachrichtigungDto[]>(`${this.apiUrl}${this.basePath}`);
  }

  getBenachrichtigungenByZeitraum$(von: string, bis: string): Observable<BenachrichtigungDto[]> {
    const command: GetBenachrichtigungenForCurrentUserByZeitraumQuery = {
      von,
      bis,
    };

    return this.http.post<BenachrichtigungDto[]>(
      `${this.apiUrl}${this.basePath}/ByZeitraum`,
      command,
    );
  }

  verwerfenMultiple$(benachrichtigungIds: string[]): Observable<void> {
    const command: BenachrichtigungVerwerfenMultipleCommand = {
      benachrichtigungIds,
    };
    return this.http.post<void>(`${this.apiUrl}${this.basePath}/verwerfen/multiple`, command);
  }
}
