import {
  PlanungsobjektWindowOnDemandFormData,
  WannBezugOnDemandFormData,
} from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.model";
import { PlanungsobjektLinearDto } from "src/app/models/openapi/model/planungsobjekt-linear-dto";
import { PlanungsobjektOnDemandDto } from "src/app/models/openapi/model/planungsobjekt-on-demand-dto";
import { DateFnsService } from "src/app/services/date-fns.service";
import { PlanungsobjektWindowInput } from "src/app/shared/windows/planungsobjekt-window/planungsobjekt-window.model";
import { getChangedKeys, isDefined } from "src/app/utils/object-utils";
import { onDemandBeziehungFormActions } from "./on-demand-beziehung-form.actions";
import { OnDemandFormNgRx } from "./on-demand.form.model";

export function berechneSynchronisierteOnDemandFormValues(opts: {
  linearKalendertag: string | undefined;
  nextFormValue: OnDemandFormNgRx;
  prevFormValue: OnDemandFormNgRx;
}) {
  const { linearKalendertag, nextFormValue, prevFormValue } = opts;

  const changedKeys = getChangedKeys(nextFormValue, prevFormValue);

  if (changedKeys.length === 0 || !linearKalendertag) {
    return null;
  }

  const changedKey = changedKeys[0];
  const { relationZuLinearInTagen, verweildauerInTagen, wunschOnlineAb, wunschOnlineBis } =
    nextFormValue;

  let calculatedValues: Partial<OnDemandFormNgRx> = {};

  switch (changedKey) {
    case "wunschOnlineAb":
      if (wunschOnlineAb === null) {
        return null;
      }
      calculatedValues = {
        relationZuLinearInTagen: DateFnsService.daysBetweenDates(wunschOnlineAb, linearKalendertag),
        ...calculateOnlineBisOrVerweildauer(nextFormValue),
      };
      break;

    case "relationZuLinearInTagen":
      if (relationZuLinearInTagen === null) {
        return null;
      }
      const newOnlineAb = DateFnsService.formatDateAsStringOrNull(
          DateFnsService.addDays(linearKalendertag, relationZuLinearInTagen),
        );
      calculatedValues = {
        wunschOnlineAb: newOnlineAb,
        ...calculateOnlineBisOrVerweildauer({...nextFormValue, wunschOnlineAb: newOnlineAb}),
      };
      break;

    case "wunschOnlineBis":
      if (wunschOnlineAb === null || wunschOnlineBis === null) {
        return null;
      }
      calculatedValues = {
        ...calculateOnlineBisOrVerweildauer(nextFormValue),
      };
      break;

    case "verweildauerInTagen":
      if (verweildauerInTagen === null || wunschOnlineAb === null) {
        return null;
      }
      calculatedValues = {
        ...calculateOnlineBisOrVerweildauer(nextFormValue),
      };
      break;
  }

  const changedValues = getChangedKeys(calculatedValues, nextFormValue).reduce(
    (accu, key) => {
      accu[key] = calculatedValues[key];
      return accu;
    },
    {} as Record<string, unknown>,
  ) as Partial<OnDemandFormNgRx>;

  return changedValues;
}

export function calculateRelationalZuLinear(
  linear: PlanungsobjektLinearDto,
  ondemand: PlanungsobjektOnDemandDto,
  distanzType: DistanzType,
) {
  // Abhängigkeit von Linear zu OnDemand mit einer Distanz -> Die Distanz entspricht RelationalZuLinear
  const relevanteAbhaengigkeiten = linear.beziehungenAusgehend.filter(
    (beziehung) =>
      beziehung.typ === "Abhaengigkeit" &&
      beziehung.zuId === ondemand.id &&
      beziehung[distanzType] !== null,
  );
  const distanzList = relevanteAbhaengigkeiten
    .map((beziehung) => beziehung[distanzType])
    .filter(isDefined);

  if (distanzList.length < 1) {
    return null;
  } else if (distanzList.length > 1) {
    throw new Error(
      "Es existieren mehrere Abhängigkeiten mit Distanz zwischen einer Linear und OnDemand Beziehung",
    );
  } else {
    // Minuten zu Tage Transformation
    return distanzList[0] / 1440;
  }
}

type DistanzType = "distanz" | "minDistanz" | "maxDistanz";

export function calculateReihenfolgeHerstellen(
  linear: PlanungsobjektLinearDto,
  ondemand: PlanungsobjektOnDemandDto,
) {
  const relevanteReihenfolge = linear.beziehungenAusgehend.filter(
    (beziehung) => beziehung.typ === "Reihenfolge" && beziehung.zuId === ondemand.id,
  );

  return relevanteReihenfolge.length > 0 ? true : false;
}

export function extractLinearOnDemandBeziehungCommandAction(args: {
  windowInput: PlanungsobjektWindowInput;
  onDemandFormValue: PlanungsobjektWindowOnDemandFormData;
  shouldCreate: boolean;
}) {
  const { windowInput, onDemandFormValue, shouldCreate } = args;
  const planungsobjektLinearId = windowInput.planungsobjektId;
  if (!planungsobjektLinearId) {
    throw new Error("LinearId ist nicht gesetzt");
  }
  switch (windowInput.planungskontext) {
    case "Vorgeplant":
    case "Vorgeschlagen":
      return shouldCreate
        ? onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenErstellen({
            command: {
              planungsobjektLinearId,
              onlineAb: onDemandFormValue.wunschOnlineAb,
              onlineAbZeit: onDemandFormValue.onlineAbZeit,
              onlineBis: onDemandFormValue.wunschOnlineBis,
              reihenfolgeHerstellen: onDemandFormValue.reihenfolgeHerstellen ?? false,
              relationZuLinearInTagen: onDemandFormValue.relationZuLinearInTagen,
              verweildauerInTagen: onDemandFormValue.verweildauerInTagen,
              minDistanz: onDemandFormValue.minDistanz ?? 0,
              maxDistanz: onDemandFormValue.maxDistanz ?? 0,
            },
          })
        : onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenAktualisieren({
            enabeledFormGroupValues: onDemandFormValue,
          });
    case "Vorgemerkt":
      return shouldCreate
        ? onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktErstellen({
            command: {
              planungsobjektLinearId,
              onlineAbZeit: onDemandFormValue.onlineAbZeit,
              reihenfolgeHerstellen: onDemandFormValue.reihenfolgeHerstellen ?? false,
              relationZuLinearInTagen: onDemandFormValue.relationZuLinearInTagen!,
              verweildauerInTagen: onDemandFormValue.verweildauerInTagen!,
              minDistanz: onDemandFormValue.minDistanz ?? 0,
              maxDistanz: onDemandFormValue.maxDistanz ?? 0,
            },
          })
        : onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktAktualisieren({
            command: {
              planungsobjektLinearId,
              onlineAbZeit: onDemandFormValue.onlineAbZeit,
              reihenfolgeHerstellen: onDemandFormValue.reihenfolgeHerstellen ?? false,
              relationZuLinearInTagen: onDemandFormValue.relationZuLinearInTagen!,
              verweildauerInTagen: onDemandFormValue.verweildauerInTagen!,
              minDistanz: onDemandFormValue.minDistanz ?? 0,
              maxDistanz: onDemandFormValue.maxDistanz ?? 0,
            },
          });
    default:
      throw new Error("Unbekannter Planungskontext " + windowInput.planungskontext);
  }
}

/**
 * Berechnet den Wert für OnlineBis oder VerweildauerInTagen, je nachdem, welcher Wert geändert werden soll.
 * @param nextFormValue Formularwerte, die geändert wurden
 * @returns 
 */
export function calculateOnlineBisOrVerweildauer<
  T extends OnDemandFormNgRx | WannBezugOnDemandFormData,
>(nextFormValue: T): Partial<T> | null {
  const { verweildauerInTagen, wunschOnlineBisVsVerweildauerToggle } = nextFormValue;
  const { onlineAb, onlineBis } = nextFormValue as WannBezugOnDemandFormData;
  const { wunschOnlineAb, wunschOnlineBis } = nextFormValue as OnDemandFormNgRx;

  const ab = wunschOnlineAb ?? onlineAb;
  const bis = wunschOnlineBis !== undefined ? wunschOnlineBis : onlineBis;

  // Wenn keine der beiden Keys (wunsch***/***) gesezt sind, dann kann auch nichts berechnet werden
  // OnlineAb ist immer gesetzt, wenn wir was an OnlineBis oder der Verweildauer berechnen wollen
  if (!ab || bis === undefined) {
    return null;
  }

  let calculatedValues = {};
  if (wunschOnlineBisVsVerweildauerToggle && verweildauerInTagen) {
    const newBis = DateFnsService.formatDateAsStringOrNull(
      DateFnsService.addDays(ab, verweildauerInTagen),
    );
    if (onlineBis !== undefined) {
      calculatedValues = {
        onlineBis: newBis,
      };
    } else {
      calculatedValues = {
        wunschOnlineBis: newBis,
      };
    }
  } else if (!wunschOnlineBisVsVerweildauerToggle && bis) {
    calculatedValues = {
      ...calculatedValues,
      verweildauerInTagen: DateFnsService.daysBetweenDates(bis, ab)
    };
  }
  return calculatedValues;
}
