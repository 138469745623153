import { Component, inject, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import planungsobjektWindowWannWoSelectors from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window-wann-wo.selectors";
import { planungsobjektWindowFeature } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.reducer";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { KanalOffsetUtils } from "src/app/utils/kanal-offset-utils";
import { PlanungsobjektWindowFacade } from "../../../planungsobjekt-window.facade";
import { PlanungsobjektWindowUseCase } from "../../../planungsobjekt-window.model";

@Component({
  selector: "app-wann-bezug-linear",
  templateUrl: "./wann-bezug-linear.component.html",
  styleUrls: ["./wann-bezug-linear.component.scss"],
})
export class WannBezugLinearComponent {
  @Input({ required: true }) usecase: PlanungsobjektWindowUseCase;
  @Input({ required: true }) planungskontext: Planungskontext | undefined;
  @Input({ required: true }) kanal: Kanal;

  readonly PlanungsobjektWindowUseCase = PlanungsobjektWindowUseCase;
  readonly Planungskontext = Planungskontext;

  private readonly store = inject(Store);
  protected readonly facade = inject(PlanungsobjektWindowFacade);

  readonly variants$ = this.store.select(planungsobjektWindowWannWoSelectors.selectVariants);

  readonly loadingVariants$ = this.store.select(planungsobjektWindowFeature.selectLoadingVariants);

  Sendetagdefinitionen = KanalOffsetUtils.getSendetagdefinitionenForKanal;
}
