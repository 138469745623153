import { wrapReducerWithFormStateUpdate } from "ngrx-forms";
import { greaterThanOrEqualTo, lessThanOrEqualTo, required } from "ngrx-forms/validation";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import * as CustomValidators from "src/app/shared/form-inputs/custom-validators-ngrx";
import {
  ValidationMap,
  createReducerAndCustomFormActions,
  createValidationRules,
} from "src/app/utils/ngrx-forms";
import {
  PlanungsobjektWindowState,
  initialPlanungsobjektWindowState,
} from "../planungsobjekt-window.model";
import { ON_DEMAND_FORM_ID, OnDemandFormNgRx } from "./on-demand.form.model";

export const onDemandFormValidationRules = (
  form: OnDemandFormNgRx,
  feature: PlanungsobjektWindowState,
): ValidationMap<OnDemandFormNgRx> => {
  const isOnMerkliste = feature.input?.planungskontext === Planungskontext.VORGEMERKT;
  const requiredOnMerkliste = isOnMerkliste ? [required] : [];

  const formValidationOnMerkliste = isOnMerkliste
    ? [
        CustomValidators.atLeastOnePerGroup([
          ["wunschOnlineAb", "relationZuLinearInTagen"],
          ["wunschOnlineBis", "verweildauerInTagen"],
        ]),
      ]
    : [];

  const isRelativ = form.wunschOnlineAbVsRelationalZuLinearToggle === true;

  return {
    wunschOnlineAb: [CustomValidators.valueBefore(form, "wunschOnlineBis", "Online bis")],
    wunschOnlineBis: [CustomValidators.valueAfter(form, "wunschOnlineAb", "Online ab")],
    relationZuLinearInTagen: [
      CustomValidators.valueBeforeWithReferenceKey(
        form,
        "wunschOnlineBis",
        "Wunsch online bis",
        "wunschOnlineAb",
      ),
      ...requiredOnMerkliste,
    ],
    minDistanz: [...(isRelativ ? [lessThanOrEqualTo(form.relationZuLinearInTagen ?? 0)] : [])],
    maxDistanz: [...(isRelativ ? [greaterThanOrEqualTo(form.relationZuLinearInTagen ?? 0)] : [])],
    verweildauerInTagen: [...requiredOnMerkliste],
    __form: [...formValidationOnMerkliste],
  };
};

const { actions, reducer, selectors } = createReducerAndCustomFormActions<
  PlanungsobjektWindowState,
  "onDemandFormState"
>(initialPlanungsobjektWindowState, ON_DEMAND_FORM_ID, "onDemandFormState");

export const onDemandFormSelectors = selectors;
export const onDemandFormActions = actions;
export const onDemandFormCustomActionReducer = wrapReducerWithFormStateUpdate(
  reducer,
  (state) => state.onDemandFormState,
  createValidationRules(onDemandFormValidationRules),
);
