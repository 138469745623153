<ng-container
  *ngIf="{
    onlineAb: onlineAb$ | async,
    onlineBis: onlineBis$ | async,
    form: facade.formState$ | async,
  } as vm"
>
  <div class="flex-container">
    <app-form-field hint="Kalendertag" label="Online ab">
      <app-iso-dateonly
        data-testid="online-ab"
        [ngrxFormControlState]="vm.form?.controls?.onlineAb"
      ></app-iso-dateonly>
    </app-form-field>

    <app-form-field label="Online ab Zeit">
      <app-iso-timeonly
        clearable
        data-testid="online-ab-zeit"
        [ngrxFormControlState]="vm.form?.controls?.onlineAbZeit"
      ></app-iso-timeonly>
    </app-form-field>

    <app-form-field hint="Kalendertag" label="Online bis">
      <app-iso-dateonly
        data-testid="online-bis"
        [focusedDate]="vm.onlineBis ?? vm.onlineAb"
        [minDate]="vm.onlineAb"
        [ngrxFormControlState]="vm.form?.controls?.onlineBis"
      ></app-iso-dateonly>
    </app-form-field>
    <kendo-switch
      class="wunsch-online-switch"
      data-testid="wunschonlinebis-verweildauer-toggle"
      size="large"
      [ngrxFormControlState]="vm.form?.controls?.wunschOnlineBisVsVerweildauerToggle"
    ></kendo-switch>
    <app-form-field class="u-max-w-32" hint="Tage zwischen Online ab und Online bis" label="Verweildauer">
      <kendo-numerictextbox
        data-testid="verweildauer-textbox"
        format="n0"
        [decimals]="0"
        [min]="1"
        [ngrxFormControlState]="vm.form?.controls?.verweildauerInTagen"
        [selectOnFocus]="false"
        [step]="1"
      >
      </kendo-numerictextbox>
    </app-form-field>
    <app-form-field class="u-w-40" label="Planlänge (min:sek)">
      <app-masked-input
        dataTestId="planlaenge"
        [ngrxFormControlState]="vm.form?.controls?.planlaenge"
      ></app-masked-input>
    </app-form-field>
  </div>
</ng-container>
