<ng-container
  *ngIf="{
    can: facade.can$ | async,
    mengengeruestTables: facade.mengengeruestTables$ | async,
    mengengeruesteintrage: facade.mengengeruesteintraege$ | async,
    isBlockansicht: facade.isBlockansicht$ | async,
    isZuweisung: facade.isMengengeruestZuweisung$ | async,
    activeMengengeruesteintragId: facade.activeMengengeruesteintragId$ | async,
  } as vm"
>
  <ng-container *ngIf="facade.windowInput$ | async as input">
    <div
      *ngFor="let singleMengengeruestTable of vm.mengengeruestTables"
      class="k-mb-lg"
      [ngClass]="{
        'k-pointer-events-none disabled-table': facade.mengengeruesteintragWindowOpen$ | async,
      }"
    >
      <h4 #mengengeruest [attr.id]="singleMengengeruestTable.schemaplatz.id">
        {{
          vm.isBlockansicht
            ? (singleMengengeruestTable | zdfTitelByBeginnzeit: input.ansichtViewModel)
            : (singleMengengeruestTable | zdfTitelBySchemaplatz: input.ansichtViewModel)
        }}
      </h4>
      <div *ngIf="singleMengengeruestTable.mengengeruesteintraege.length > 0; else emptyRow">
        <table class="mengengeruest-table">
          <thead>
            <tr class="header">
              <th class="s-col">Uhrzeit</th>
              <th class="s-col">Redaktion</th>
              <th class="l-col">Titel</th>
              <th class="m-col">Genre</th>
              <th class="s-col">ES/WH</th>
              <th class="s-col">Sendeplan</th>
              <th class="s-col">Sendesoll</th>
              <th class="s-col">Länge</th>
              <th class="l-col">Kommentar</th>
              <th
                [class]="
                  'aktionen ' +
                  (!vm.isZuweisung && vm.can?.mengengeruestEditieren ? 's-col' : 'no-col')
                "
              ></th>
            </tr>
          </thead>
          <tbody
            *ngIf="vm.mengengeruesteintrage"
            [ngClass]="{ editable: vm.isZuweisung && vm.can?.mengengeruestZuweisen }"
          >
            <tr
              *ngFor="
                let mengengeruestEintrag of singleMengengeruestTable.mengengeruesteintraege;
                let last = last
              "
              [ngClass]="{
                active: mengengeruestEintrag.id === vm.activeMengengeruesteintragId,
              }"
              (click)="
                vm.isZuweisung &&
                  vm.can?.mengengeruestZuweisen &&
                  updateSendeplanCounter(mengengeruestEintrag, vm.mengengeruesteintrage)
              "
            >
              <td>
                {{ mengengeruestEintrag.beginnzeit ?? "" }}
              </td>
              <td>{{ mengengeruestEintrag.redaktion }}</td>
              <td>{{ mengengeruestEintrag.titel }}</td>
              <td>{{ GenreRecord[mengengeruestEintrag.genre].bedeutung }}</td>
              <td>{{ mengengeruestEintrag.ausstrahlung }}</td>
              <td
                [ngClass]="{
                  'sendeplan-exceeded':
                    mengengeruestEintrag.sendeplan > mengengeruestEintrag.sendesoll,
                }"
              >
                {{ mengengeruestEintrag.sendeplan }}
              </td>
              <td>{{ mengengeruestEintrag.sendesoll }}</td>
              <td>{{ mengengeruestEintrag.laenge | secondsToMinutesOrEmpty }}</td>
              <td class="kommentar">{{ mengengeruestEintrag.kommentar }}</td>
              <td *ngIf="!vm.isZuweisung && vm.can?.mengengeruestEditieren" class="aktionen">
                <button
                  class="space-between-buttons"
                  data-testid="edit-mengengeruesteintrag"
                  fillMode="flat"
                  kendoButton
                  title="Mengengerüsteintrag bearbeiten"
                  [svgIcon]="Icons.editIcon"
                  (click)="facade.mengengeruesteintragBearbeiten(mengengeruestEintrag)"
                ></button>
                <button
                  class="space-between-buttons"
                  data-testid="delete-mengengeruesteintrag"
                  fillMode="flat"
                  kendoButton
                  title="Mengengerüsteintrag löschen"
                  [svgIcon]="Icons.xIcon"
                  (click)="facade.mengengeruesteintragLoeschen(mengengeruestEintrag)"
                ></button>
                <ng-container *ngIf="last">
                  <button
                    class="space-between-buttons"
                    data-testid="create-mengengeruesteintrag"
                    fillMode="flat"
                    kendoButton
                    title="Mengengerüsteintrag erstellen"
                    [svgIcon]="Icons.addIcon"
                    (click)="facade.mengengeruesteintragErstellen(singleMengengeruestTable)"
                  ></button>
                </ng-container>
              </td>
            </tr>
            <ng-container *ngIf="singleMengengeruestTable.mengengeruesteintraege.length > 0">
              <tr class="last">
                <td class="last" colspan="5"></td>
                <td
                  class="last"
                  [ngClass]="{
                    'sendeplan-exceeded':
                      singleMengengeruestTable.sendeplanSumme >
                      singleMengengeruestTable.sendesollSumme,
                  }"
                >
                  {{ singleMengengeruestTable.sendeplanSumme }}
                </td>
                <td class="last">{{ singleMengengeruestTable.sendesollSumme }}</td>
                <td class="last"></td>
                <td class="last">von {{ singleMengengeruestTable.betrachteteTageSumme }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>

      <ng-template #emptyRow>
        <table class="mengengeruest-table">
          <thead>
            <tr class="header">
              <th class="s-col">Uhrzeit</th>
              <th class="s-col">Redaktion</th>
              <th class="l-col">Titel</th>
              <th class="m-col">Genre</th>
              <th class="s-col">ES/WH</th>
              <th class="s-col">Sendeplan</th>
              <th class="s-col">Sendesoll</th>
              <th class="s-col">Länge</th>
              <th class="l-col">Kommentar</th>
              <th
                [class]="
                  'aktionen ' +
                  (!vm.isZuweisung && vm.can?.mengengeruestEditieren ? 's-col' : 'no-col')
                "
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="kommentar"></td>
              <td class="aktionen">
                <button
                  *ngIf="!vm.isZuweisung && vm.can?.mengengeruestEditieren"
                  data-testid="create-mengengeruesteintrag"
                  fillMode="flat"
                  kendoButton
                  title="Mengengerüsteintrag erstellen"
                  [svgIcon]="Icons.addIcon"
                  (click)="facade.mengengeruesteintragErstellen(singleMengengeruestTable)"
                ></button>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-template>
    </div>
    <div *ngIf="vm.isZuweisung && vm.mengengeruesteintrage" class="window-action-buttons">
      <!-- Wenn die Auswahl nach den Klicks auf die Tabellenzeilen gleichbleibt (in anderen Wort die MengengerüstId bleibt gleich)
    wird der Dialog als unberührt (pristine) bewertet und der "Speichern" Button deaktiviert -->
      <button
        class="primary-button"
        kendoButton
        themeColor="primary"
        type="button"
        [disabled]="(facade.mengengeruestChanged$ | async) === false"
        (click)="onSaveZuweisung()"
      >
        Speichern
      </button>
      <button fillMode="outline" kendoButton type="button" (click)="facade.closeWindow()">
        Abbrechen
      </button>
    </div>
  </ng-container>
</ng-container>
