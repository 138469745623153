import { ChangeDetectionStrategy, Component, Input, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { SVGIcon } from "@progress/kendo-svg-icons";
import { map } from "rxjs";
import { GetitWindowInput } from "src/app/core/stores/getit-window/getit-model";
import { getitWindowActions } from "src/app/core/stores/getit-window/getit-window.actions";
import { planungsobjektWindowActions } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.actions";
import planungsobjektWindowSelectors from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.selectors";
import { Icons } from "src/app/models/icons";
import { GetitProduktStatus } from "src/app/models/openapi/model/getit-produkt-status";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { PlanungsobjektWindowFacade } from "../../../planungsobjekt-window.facade";
import { PlanungsobjektWindowUseCase } from "../../../planungsobjekt-window.model";

@Component({
  selector: "app-produktinformationen-getit-link",
  templateUrl: "./produktinformationen-getit-link.component.html",
  styleUrl: "./produktinformationen-getit-link.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktinformationenGetitLinkComponent {
  private readonly store = inject(Store);
  public readonly facade = inject(PlanungsobjektWindowFacade);

  PlanungsobjektWindowUseCase = PlanungsobjektWindowUseCase;

  @Input({ required: true }) planungsobjekt: PlanungsobjektDto | null;
  @Input({ required: true }) usecase: PlanungsobjektWindowUseCase;

  readonly Icons = Icons;
  public readonly syncButtonTooltip$ = this.facade.isGetItSyncDisabled$.pipe(
    map((disabled) =>
      disabled
        ? "Speichern Sie Ihre Änderungen, um die Synchronisation zu ermöglichen."
        : "Produktinformationen aus get.it synchronisieren",
    ),
  );

  protected readonly isPlanungFormDirty$ = this.store.select(
    planungsobjektWindowSelectors.planungsForm.selectFormIsDirty,
  );

  getIconFromStatus(status: GetitProduktStatus | null): SVGIcon {
    const iconsForGetitStatus = {
      [GetitProduktStatus.ABGENOMMEN]: Icons.signatureIcon,
      [GetitProduktStatus.AUSGESTRAHLT]: Icons.toggleFullScreenModeIcon,
      [GetitProduktStatus.GENEHMIGT_PRODUKTIONSBEWILLIGUNG]: Icons.trackChangesAcceptAllIcon,
      [GetitProduktStatus.GENEHMIGT_STOFFZULASSUNG]: Icons.trackChangesAcceptIcon,
      [GetitProduktStatus.OFFEN]: Icons.kpiStatusOpenIcon,
      [GetitProduktStatus.IN_PRODUKTION]: Icons.filmIcon,
    };

    return (status && iconsForGetitStatus[status]) ?? Icons.addIcon;
  }

  getTooltipFromStatus(status: GetitProduktStatus | null): string {
    const tooltipsForGetitStatus = {
      [GetitProduktStatus.ABGENOMMEN]: "abgenommen",
      [GetitProduktStatus.AUSGESTRAHLT]: "veröffentlicht",
      [GetitProduktStatus.GENEHMIGT_PRODUKTIONSBEWILLIGUNG]: "genehmigt (Produktionsbewilligung)",
      [GetitProduktStatus.GENEHMIGT_STOFFZULASSUNG]: "genehmigt (Stoffzulassung)",
      [GetitProduktStatus.OFFEN]: "offen",
      [GetitProduktStatus.IN_PRODUKTION]: "in Produktion",
    };

    return (status && tooltipsForGetitStatus[status]) ?? "unbekannter Status";
  }

  mitGetitVerknuepfen() {
    const input: GetitWindowInput = {
      type: "GetitVerknuepfung",
      planungsobjektId: this.planungsobjekt?.id,
    };
    this.store.dispatch(getitWindowActions.openGetitWindow({ windowInputs: input }));
  }

  getitEntknuepfen() {
    this.store.dispatch(
      planungsobjektWindowActions.openConfirmGetitVerknuepfungAufhebenDialog({
        planungsobjekt: this.planungsobjekt!,
      }),
    );
  }
}
