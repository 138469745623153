import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { filter, map, switchMap, take, tap } from "rxjs";
import { AppAbility } from "src/app/casl/app-ability";
import { asSubject } from "src/app/casl/casl-utils";
import { SendeplatzWindowService } from "src/app/services/sendeplatz-window.service";
import { allValuesDefined } from "src/app/utils/array-utils";
import { createConfirmClosingEffect, createTrySavingEffect } from "src/app/utils/ngrx-utils";
import { sendeplatzActions } from "../sendeplatz/sendeplatz.actions";
import { sendeplatzWindowActions } from "./sendeplatz-window.actions";
import {
  calculateInitialFormStateFromInput,
  sendeplatzWindowFormActions,
} from "./sendeplatz-window.form";
import { sendeplatzWindowSelectors } from "./sendeplatz-window.selectors";

@Injectable()
export class SendeplatzWindowEffects {
  constructor(private readonly ability: AppAbility) {}

  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly sendeplatzWindowService = inject(SendeplatzWindowService);

  openSendeplatzWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendeplatzWindowActions.openWindow),
      switchMap(() => {
        const windowRef = this.sendeplatzWindowService.openSendeplatzWindow();

        return this.actions$.pipe(
          ofType(sendeplatzWindowActions.closeWindow),
          take(1),
          tap(() => void windowRef.close()),
          map(() => sendeplatzWindowActions.windowClosed()),
        );
      }),
    );
  });

  trySaving$ = createTrySavingEffect({
    trySavingAction: sendeplatzWindowFormActions.trySaving,
    formIsValid$: this.store.select(sendeplatzWindowSelectors.selectFormIsValid),
    saveAction: sendeplatzWindowFormActions.save,
  });

  tryClosingWithoutSaving$ = createConfirmClosingEffect({
    tryClosingAction: sendeplatzWindowActions.tryClosingWithoutSaving,
    preventClosingWhen$: this.store.select(sendeplatzWindowSelectors.selectFormIsDirty),
    closingAction: sendeplatzWindowActions.closeWindow,
  });

  setInitialFormValues$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendeplatzWindowActions.openWindow),
      concatLatestFrom(() => [this.store.select(sendeplatzWindowSelectors.selectSendeplatz)]),
      filter(allValuesDefined),
      map(([, sendeplatz]) =>
        sendeplatzWindowFormActions.patchValue({
          value: calculateInitialFormStateFromInput(sendeplatz),
        }),
      ),
    );
  });

  setFormEnablement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendeplatzWindowActions.openWindow),
      map(({ input: sendeplatz }) =>
        this.ability.can("planen", asSubject("Sendeplatz", sendeplatz)),
      ),
      filter((canPlanen) => !canPlanen),
      map(() => sendeplatzWindowFormActions.disable()),
    );
  });

  updateSendeplatz$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendeplatzWindowFormActions.save),
      concatLatestFrom(() => [
        this.store.select(sendeplatzWindowSelectors.selectSendeplatzToTransfer),
      ]),
      filter(allValuesDefined),
      // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
      switchMap(([_, sendeplatz]) => [
        sendeplatzActions.updateSendeplatz({ sendeplatz }),
        sendeplatzWindowActions.closeWindow(),
      ]),
    );
  });
}
