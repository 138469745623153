import { Injectable } from "@angular/core";
import { PlanungsobjektWindowPlanungFormNgRx } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-form/planungsobjekt-window.form.model";
import { WannBezugOnDemandFormData } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.model";

@Injectable({
  providedIn: "root",
})
export class PlanungsobjektWindowMapper {
  static mapPlanungFormToWannBezugOnDemandFormData(
    form: PlanungsobjektWindowPlanungFormNgRx,
  ): WannBezugOnDemandFormData {
    return {
      onlineAb: form.onlineAb,
      onlineAbZeit: form.onlineAbZeit,
      onlineBis: form.onlineBis,
      planlaenge: form.planlaenge,
      verweildauerInTagen: form.verweildauerInTagen,
      wunschOnlineBisVsVerweildauerToggle: form.wunschOnlineBisVsVerweildauerToggle ?? false,
    };
  }
}
