import { WindowRef } from "@progress/kendo-angular-dialog";
import { Observable } from "rxjs";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { SchemaplatzDto } from "src/app/models/openapi/model/schemaplatz-dto";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";

export enum MengengeruestWindowUseCase {
  MAIN_WINDOW,
  ZUWEISUNG_SENDEPLATZ,
  ZUWEISUNG_BLOCKANSICHT,
}

export type MengengeruestWindowInput =
  | {
      type: MengengeruestWindowUseCase.MAIN_WINDOW;
      openWindows$?: Observable<WindowRef[]>;
      ansichtViewModel: AnsichtViewModel;
    }
  | {
      type: MengengeruestWindowUseCase.ZUWEISUNG_SENDEPLATZ;
      ansichtViewModel: AnsichtViewModel;
      planungsobjekt: PlanungsobjektDto;
      sendeplatz: SendeplatzDto;
      schemaplatz: SchemaplatzDto;
    }
  | {
      type: MengengeruestWindowUseCase.ZUWEISUNG_BLOCKANSICHT;
      ansichtViewModel: AnsichtViewModel;
      planungsobjekt: PlanungsobjektDto;
      schemaplatz: SchemaplatzDto;
    };

export type MengengeruestWindowComponentResult =
  | {
      type: MengengeruestWindowUseCase.ZUWEISUNG_SENDEPLATZ;
      sendeplatz: SendeplatzDto;
      previousMengengeruestId: string | null;
      zugewiesenePlanungsobjekt: PlanungsobjektDto;
    }
  | {
      type: MengengeruestWindowUseCase.ZUWEISUNG_BLOCKANSICHT;
      previousMengengeruestId: string | null;
      zugewiesenePlanungsobjekt: PlanungsobjektDto;
    };

export type MengengeruestWindowFeatureState = {
  input: MengengeruestWindowInput | null;
  activeMengengeruesteintragId: string | null;
};

export const initialMengengeruestWindowFeatureState: MengengeruestWindowFeatureState = {
  input: null,
  activeMengengeruesteintragId: null,
};
