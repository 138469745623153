import {
  PlanungsobjektBlockansichtInput,
  PlanungsobjektCreateInput,
  PlanungsobjektEditInput,
  PlanungsobjektLinearInput,
  PlanungsobjektOnDemandInput,
  PlanungsobjektSendeplatzInput,
  PlanungsobjektWindowInput,
  PlanungsobjektWindowInputWithPlanungsobjekt,
  PlanungsobjektWindowUseCase,
  planungsobjektWindowCreateUseCases,
  planungsobjektWindowEditUseCases,
  planungsobjektWindowLinearUseCases,
  planungsobjektWindowOnDemandUseCases,
  planungsobjektWindowReadUseCases,
  planungsobjektWindowSendeplatzUseCases,
} from "src/app/shared/windows/planungsobjekt-window/planungsobjekt-window.model";
import { assertUnreachable } from "src/app/utils/function-utils";

/**
 * Extrahiert den Kanal aus dem übergebenen PlanungsobjektWindowInput.
 */
export function extractKanalFromWindowInput(input: PlanungsobjektWindowInputWithPlanungsobjekt) {
  switch (input.usecase) {
    case PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ:
    case PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT:
    case PlanungsobjektWindowUseCase.EDIT_ONDEMAND:
    case PlanungsobjektWindowUseCase.READONLY_LINEAR:
    case PlanungsobjektWindowUseCase.READONLY_ONDEMAND:
      return input.planungsobjekt.kanal;
    case PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ:
    case PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT:
    case PlanungsobjektWindowUseCase.CREATE_ONDEMAND:
      return input.kanal;
    default:
      assertUnreachable(input);
  }
}

export const isEditWindow = (
  input: PlanungsobjektWindowInput,
): input is PlanungsobjektEditInput => {
  return (planungsobjektWindowEditUseCases as string[]).includes(input.usecase);
};

export const isCreateWindow = (
  input: PlanungsobjektWindowInput,
): input is PlanungsobjektCreateInput => {
  return (planungsobjektWindowCreateUseCases as string[]).includes(input.usecase);
};

export const isReadOnlyWindow = (
  input: PlanungsobjektWindowInput,
): input is PlanungsobjektWindowInput => {
  return (planungsobjektWindowReadUseCases as string[]).includes(input.usecase);
};

export const isLinearWindow = (
  input: PlanungsobjektWindowInput,
): input is PlanungsobjektLinearInput => {
  return (planungsobjektWindowLinearUseCases as string[]).includes(input.usecase);
};

export const isSendeplatzWindow = (
  input: PlanungsobjektWindowInput,
): input is PlanungsobjektSendeplatzInput => {
  return (planungsobjektWindowSendeplatzUseCases as string[]).includes(input.usecase);
}

export const isOnDemandWindow = (
  input: PlanungsobjektWindowInput | null,
): input is PlanungsobjektOnDemandInput => {
  return !!input && (planungsobjektWindowOnDemandUseCases as string[]).includes(input.usecase);
};

export const isBlockansichtWindow = (
  input: PlanungsobjektWindowInput | null,
): input is PlanungsobjektBlockansichtInput => {
  return (
    !!input &&
    (input.usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT ||
      input.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT)
  );
};
