import { createFeature, createReducer, on } from "@ngrx/store";
import { planungshinweiseWindowActions } from "./planungshinweise-window.actions";
import {
  PlanungshinweiseWindowFeatureState,
  initialPlanungshinweiseWindowFeatureState,
} from "./planungshinweise-window.model";

const reducer = createReducer(
  initialPlanungshinweiseWindowFeatureState,
  on(
    planungshinweiseWindowActions.openPlanungshinweiseWindow,
    (_, { input }): PlanungshinweiseWindowFeatureState => ({
      ...initialPlanungshinweiseWindowFeatureState,
      input,
    }),
  ),
  on(
    planungshinweiseWindowActions.setIsDirty,
    (state, { isDirty }): PlanungshinweiseWindowFeatureState => ({
      ...state,
      isDirty,
    }),
  )
);

export const planungshinweiseWindowFeature = createFeature({
  name: "planungshinweisWindow",
  reducer,
});
