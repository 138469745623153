import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { SendeplatzKeyDto } from "src/app/models/openapi/model/sendeplatz-key-dto";
import { PharosWindowInput } from "./pharos-export-window.model";

export const pharosExportWindowActions = createActionGroup({
  source: "PharosExportWindow",
  events: {
    // Wir möchten das Fenster öffnen, aber ggf. geht das ja garnicht,
    // Weil z.B. der Sendeplatz in Pharos nicht existiert
    tryOpenWindow: props<{ input: SendeplatzKeyDto }>(),

    openWindow: props<{ windowInput: PharosWindowInput }>(),
    tryClosingWithoutSaving: emptyProps(),
    closeWindow: emptyProps(),
    windowClosed: emptyProps(),
  },
});
