<div
  *ngIf="{
    formState: facade.formState$ | async,
    activeAdditionalFilters: facade.activeAdditionalFilters$ | async,
    isFirstSearch: facade.isFirstSearch$ | async,
    activeAdditionalFiltersRecord: activeAdditionalFiltersRecord$ | async,
  } as vm"
  class="search-form-container"
>
  <ng-container *ngIf="vm.formState">
    <form autocomplete="off" [ngrxFormState]="vm.formState">
      <div class="filter-row">
        <!-- https://www.telerik.com/kendo-angular-ui/components/dateinputs/daterange/ -->
        <!-- https://www.telerik.com/kendo-angular-ui/components/dateinputs/daterange/selection-range/ -->
        <kendo-daterange class="u-w-auto">
          <app-form-field label="Zeitraum von">
            <kendo-dateinput
              autoCorrectOn="blur"
              data-testid="recherche-suchen-von"
              kendoDateRangeStartInput
              [ngrxFormControlState]="vm.formState.controls.sendetagVon"
              [ngrxValueConverter]="dateValueConverter"
            ></kendo-dateinput>
          </app-form-field>
          <app-form-field label="bis">
            <kendo-dateinput
              autoCorrectOn="blur"
              data-testid="recherche-suchen-bis"
              kendoDateRangeEndInput
              [ngrxFormControlState]="vm.formState.controls.sendetagBis"
              [ngrxValueConverter]="dateValueConverter"
            ></kendo-dateinput>
          </app-form-field>
          <!-- Für "Heute" ansonsten "today" angezeigt,
          obwohl das Control an allen anderen Stellen korrekt lokalisiert ist -->
          <!-- https://www.telerik.com/kendo-angular-ui/components/dateinputs/globalization/ -->
          <kendo-daterange-popup>
            <ng-template kendoDateRangePopupTemplate>
              <kendo-multiviewcalendar kendoDateRangeSelection>
                <kendo-multiviewcalendar-messages today="Heute"></kendo-multiviewcalendar-messages>
              </kendo-multiviewcalendar>
            </ng-template>
          </kendo-daterange-popup>
        </kendo-daterange>

        <app-form-field class="u-w-full" label="Ausspielweg">
          <app-multiselect
            data-testid="recherche-ausspielweg"
            [ngrxFormControlState]="vm.formState.controls.kanaeleSelected"
            [options]="KanalOptions"
            [summaryTagMaxItems]="0"
          ></app-multiselect>
        </app-form-field>

        <app-form-field class="u-w-full" label="planende Redaktionen">
          <app-multiselect
            data-testid="recherche-redaktion"
            [ngrxFormControlState]="vm.formState.controls.redaktionenSelected"
            [options]="RedaktionOptions"
            [summaryTagMaxItems]="0"
          >
          </app-multiselect>
        </app-form-field>

        <div class="publit-checkbox checkbox">
          <input
            #highlightsOnly
            data-testid="recherche-highlight"
            kendoCheckBox
            type="checkbox"
            [ngrxFormControlState]="vm.formState.controls.highlightsOnly"
          />
          <kendo-label
            class="u-text-white"
            text="Nur Highlights"
            [for]="highlightsOnly"
          ></kendo-label>
        </div>

        <!-- Weitere Filter auswählen -->

        <app-additional-filters
          [selectedFilters]="vm.activeAdditionalFilters"
          (selectionChange)="onFilterSelectionChange($event, vm.activeAdditionalFilters ?? [])"
        ></app-additional-filters>

        <button
          class="primary-button"
          data-testid="recherche-suchen-button"
          kendoButton
          themeColor="primary"
          type="button"
          [disabled]="!vm.formState.isValid"
          (click)="onClickSearch()"
        >
          Suchen
        </button>
      </div>

      <!-- Weitere Filter -->
      <div class="filter-row">
        <app-additional-filter-wrapper
          *ngIf="vm.activeAdditionalFiltersRecord?.TITEL"
          data-testid="recherche-titel-filter"
          (removeFilter)="onRemoveFilter(FilterEnum.TITEL)"
        >
          <app-form-field class="u-w-full" label="Titel">
            <kendo-textbox
              class="u-w-full"
              [ngrxFormControlState]="vm.formState.controls.titelFilter"
            ></kendo-textbox>
          </app-form-field>
        </app-additional-filter-wrapper>
        <app-additional-filter-wrapper
          *ngIf="vm.activeAdditionalFiltersRecord?.GENRE"
          data-testid="recherche-genre-filter"
          (removeFilter)="onRemoveFilter(FilterEnum.GENRE)"
        >
          <app-form-field class="u-w-full" label="PLP Genres">
            <app-multiselect
              [ngrxFormControlState]="vm.formState.controls.genreSelected"
              [options]="GenreOptions"
              [summaryTagMaxItems]="0"
            >
            </app-multiselect>
          </app-form-field>
        </app-additional-filter-wrapper>
        <app-additional-filter-wrapper
          *ngIf="vm.activeAdditionalFiltersRecord?.PLANUNGSKONTEXT"
          data-testid="recherche-planungskontext-filter"
          (removeFilter)="onRemoveFilter(FilterEnum.PLANUNGSKONTEXT)"
        >
          <app-form-field class="u-w-full" label="Planungskontext">
            <app-multiselect
              [ngrxFormControlState]="vm.formState.controls.planungskontexteSelected"
              [options]="PlanungskontextOptions"
              [summaryTagMaxItems]="0"
            >
            </app-multiselect>
          </app-form-field>
        </app-additional-filter-wrapper>
        <app-additional-filter-wrapper
          *ngIf="vm.activeAdditionalFiltersRecord?.CONTENT_COMMUNITY"
          data-testid="recherche-contentCommunities-filter"
          (removeFilter)="onRemoveFilter(FilterEnum.CONTENT_COMMUNITY)"
        >
          <app-form-field class="u-w-full" label="Zielgruppe">
            <app-multiselect
              [ngrxFormControlState]="vm.formState.controls.contentCommunitiesSelected"
              [options]="ContentCommunityOptions"
              [summaryTagMaxItems]="0"
            >
            </app-multiselect>
          </app-form-field>
        </app-additional-filter-wrapper>
      </div>
    </form>

    <div class="vertical-spacer"></div>

    <app-filter-chip-list></app-filter-chip-list>

    <div class="vertical-spacer"></div>

    <span *ngIf="!vm.isFirstSearch && vm.formState.isDirty" class="u-text-white text-trigger-search"
      >Filter haben sich geändert. "SUCHEN" drücken, um anhand dieser Kriterien zu filtern.</span
    >
  </ng-container>
</div>
