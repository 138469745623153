import { Component, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import planungsobjektWindowWannWoSelectors from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window-wann-wo.selectors";
import { Icons } from "src/app/models/icons";
import { PlanungsobjektWindowFacade } from "../../../planungsobjekt-window.facade";

@Component({
  selector: "app-wann-bezug-on-demand",
  templateUrl: "./wann-bezug-on-demand.component.html",
  styleUrls: ["./wann-bezug-on-demand.component.scss"],
})
export class WannBezugOnDemandComponent {
  public readonly facade = inject(PlanungsobjektWindowFacade);
  private readonly store = inject(Store);

  readonly onlineAb$ = this.store.select(planungsobjektWindowWannWoSelectors.selectOnlineAb);
  readonly onlineBis$ = this.store.select(planungsobjektWindowWannWoSelectors.selectOnlineBis);

  Icons = Icons;
}
