<ng-container *ngIf="facade.viewModel$ | async as vm">
  <form [ngrxFormState]="vm.formState">
    <div class="flex-container">
      <div class="flex-item">Sendeplatz Datum: {{ vm.sendeplatz.sendetag | parseDate }}</div>
    </div>
    <div class="flex-container">
      <div class="flex-item">Schemaplatz Beginnzeit: {{ vm.sendeplatz.beginnzeit }}</div>
      <div class="flex-item">Schemaplatz Länge (min): {{ vm.sendeplatz.laenge / 60 }}</div>
    </div>
    <div class="flex-container">
      <app-form-field class="flex-item" label="Abweichende Beginnzeit">
        <app-iso-timeonly
          data-testid="abweichendeBeginnzeit"
          [ngrxFormControlState]="vm.formState.controls.abweichendeBeginnzeit"
        ></app-iso-timeonly>
      </app-form-field>

      <app-form-field
        class="flex-item"
        data-testid="abweichendeLaenge"
        label="Abweichende Länge (min:sek)"
      >
        <app-masked-input
          [ngrxFormControlState]="vm.formState.controls.abweichendeLaenge"
        ></app-masked-input>
      </app-form-field>
    </div>
    <kendo-label class="flex-item notiz" text="Notiz">
      <kendo-textarea
        class="k-disabled"
        data-testid="notiz"
        resizable="auto"
        [ngrxFormControlState]="vm.formState.controls.notiz"
        [readonly]="true"
      ></kendo-textarea>
    </kendo-label>
  </form>
  <div class="window-action-buttons">
    <button
      class="primary-button"
      kendoButton
      themeColor="primary"
      type="button"
      [disabled]="vm.formState.isPristine"
      (click)="facade.save()"
    >
      Speichern
    </button>
    <button fillMode="outline" kendoButton type="button" (click)="facade.closeWindow()">
      Abbrechen
    </button>
  </div>
</ng-container>
