import { inject, Injectable } from "@angular/core";
import { PureAbility } from "@casl/ability";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { DialogCloseResult, WindowCloseResult } from "@progress/kendo-angular-dialog";
import { box, unbox } from "ngrx-forms";
import { combineLatest, concat, concatMap, debounceTime, EMPTY, filter, map, of, switchMap, take, tap } from "rxjs";
import { Aktion, AktionEnum } from "src/app/models/enums/aktion";
import { PlanungsobjektLinearDto } from "src/app/models/openapi/model/planungsobjekt-linear-dto";
import { PlanungsobjektOnDemandDto } from "src/app/models/openapi/model/planungsobjekt-on-demand-dto";
import {
  CONFIRM_PLANUNGSOBJKET_SAVE_DIALOG_ACTIONS,
  CustomDialogService,
} from "src/app/services/custom-dialog.service";
import { LinearOnDemandService } from "src/app/services/linear-on-demand.service";
import { OnDemandService } from "src/app/services/on-demand.service";
import { PlanungsobjektWindowService } from "src/app/services/planungsobjekt-window.service";
import { PlanungsobjektService } from "src/app/services/planungsobjekt.service";
import {
  CreateUseCase,
  EditPlanungsobjektLinearBlockansichtInput,
  EditPlanungsobjektLinearInput,
  EditPlanungsobjektOnDemandInput,
  EditUseCase,
  PlanungsobjektCreateInput,
  PlanungsobjektEditInput,
  PlanungsobjektWindowInputWithPlanungsobjekt,
  PlanungsobjektWindowUseCase,
} from "src/app/shared/windows/planungsobjekt-window/planungsobjekt-window.model";
import { allValuesDefined } from "src/app/utils/array-utils";
import { assertUnreachable } from "src/app/utils/function-utils";
import { createConfirmClosingEffect, createTrySavingEffect } from "src/app/utils/ngrx-utils";
import { isDefined } from "src/app/utils/object-utils";
import { PlanungsobjektUtils } from "src/app/utils/planungsobjekt.utils";
import { beziehungActions } from "../beziehung/beziehung.actions";
import { merklisteWindowActions } from "../merkliste/merkliste.window.actions";
import { notificationActions } from "../notification/notification.actions";
import { onDemandActions } from "../on-demand/on-demand.actions";
import { onDemandFeature } from "../on-demand/on-demand.reducer";
import { planungsobjektActions } from "../planungsobjekt/planungsobjekt.actions";
import {
  extractGetitFieldsFromPlanungsobjektDto,
  planungsobjektFieldsFromGetit,
} from "../planungsobjekt/planungsobjekt.model";
import { shellActions } from "../shell/shell.actions";
import { CanEditPlanungsobjektWindowService } from "./can-edit-planungsobjekt-window.service";
import { onDemandBeziehungFormActions } from "./on-demand-form/on-demand-beziehung-form.actions";
import { onDemandFormActions } from "./on-demand-form/on-demand.form";
import {
  getInitalPlanungsobjektFormValue,
  planungsobjektFormActions,
  planungsobjektToFormValue,
} from "./planungsobjekt-form/planungsobjekt-window.form";
import planungsobjektWindowWannWoSelectors from "./planungsobjekt-window-wann-wo.selectors";
import { planungsobjektWindowActions } from "./planungsobjekt-window.actions";
import { extractPlanungsobjektCommandAction } from "./planungsobjekt-window.effects.utils";
import { PlanungsobjektWindowTabEnum, SLIDER_LEFT_POSITION, SLIDER_RIGHT_POSITION } from "./planungsobjekt-window.model";
import { planungsobjektWindowFeature } from "./planungsobjekt-window.reducer";
import planungsobjektWindowSelectors from "./planungsobjekt-window.selectors";
import { isCreateWindow, isEditWindow, isReadOnlyWindow, isSendeplatzWindow } from "./planungsobjekt-window.utils";
import { planungsobjektWindowWannWoActions } from "./planungsobjekt-window-wann-wo.actions";

const createSuccessActions = [
  planungsobjektActions.createPlanungsobjektOnMerklisteSuccess,
  planungsobjektActions.createPlanungsobjektOnSendeplatzSuccess,
  planungsobjektActions.createPlanungsobjektOnBlockansichtSuccess,
  planungsobjektActions.createPlanungsobjektLinearVorgeschlagenSuccess,

  onDemandActions.createOnDemandSuccess,
];

const updateSuccessActions = [
  planungsobjektActions.updatePlanungsobjektOnMerklisteSuccess,
  planungsobjektActions.updatePlanungsobjektOnSendeplatzSuccess,
  planungsobjektActions.updatePlanungsobjektOnBlockansichtSuccess,
  planungsobjektActions.updatePlanungsobjektLinearVorgeschlagenSuccess,

  onDemandActions.updateOnDemandSuccess,
];

const editUseCaseAfterCreateAction = {
  [PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ]:
    PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ,
  [PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT]:
    PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT,
  [PlanungsobjektWindowUseCase.CREATE_ONDEMAND]: PlanungsobjektWindowUseCase.EDIT_ONDEMAND,
} satisfies Record<CreateUseCase, EditUseCase>;

const editInputForCreateInput = <CreateInput extends PlanungsobjektCreateInput>(
  createInput: CreateInput,
  planungsobjektId: string,
): PlanungsobjektEditInput => {
  switch (createInput.usecase) {
    case PlanungsobjektWindowUseCase.CREATE_ONDEMAND:
      return {
        ...createInput,
        usecase: editUseCaseAfterCreateAction[createInput.usecase],
        planungsobjektId: planungsobjektId,
      } satisfies EditPlanungsobjektOnDemandInput;
    case PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ:
      return {
        ...createInput,
        usecase: editUseCaseAfterCreateAction[createInput.usecase],
        planungsobjektId: planungsobjektId,
      } satisfies EditPlanungsobjektLinearInput;
    case PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT:
      return {
        ...createInput,
        usecase: editUseCaseAfterCreateAction[createInput.usecase],
        planungsobjektId: planungsobjektId,
      } satisfies EditPlanungsobjektLinearBlockansichtInput;
    default:
      assertUnreachable(createInput);
  }
};

const planungsobjektSaveSuccessActions = [...createSuccessActions, ...updateSuccessActions];

const onDemandBeziehungFormSaveSuccessActions = [
  onDemandBeziehungFormActions.updateOnDemandBeziehungFormValuesSuccess,
  onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktErstellenSuccess,
  onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktAktualisierenSuccess,
  onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenErstellenSuccess,
  onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenAktualisierenSuccess,
];

@Injectable()
export class PlanungsobjektWindowEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly planungsobjektService = inject(PlanungsobjektService);
  private readonly planungsobjektWindowService = inject(PlanungsobjektWindowService);
  private readonly linearOnDemandService = inject(LinearOnDemandService);
  private readonly onDemandService = inject(OnDemandService);
  private readonly customDialogService = inject(CustomDialogService);
  private readonly canEditPlanungsobjektWindowService = inject(CanEditPlanungsobjektWindowService);
  private readonly appAbility = inject(PureAbility);

  openDeletePlanungsobjektWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openDeletePlanungsobjektWindow),
      switchMap(({ planungsobjekt }) =>
        this.planungsobjektWindowService
          .openDeletePlanungsobjektDialog(planungsobjekt.titel)
          .result.pipe(
            map((result) => {
              if (result instanceof DialogCloseResult)
                return planungsobjektWindowActions.closeWindow();
              switch (result.action) {
                case Aktion.ENTFERNE_PLANUNGSOBJEKT:
                  return planungsobjektActions.deletePlanungsobjekt({
                    planungsobjektId: planungsobjekt.id,
                  });
                case Aktion.PLANUNGSOBJEKT_AUF_MERKLISTE:
                  if (PlanungsobjektUtils.isLinear(planungsobjekt)) {
                    return merklisteWindowActions.openMovePlanungsobjektLinearToMerklisteDialog({
                      planungsobjektLinearId: planungsobjekt.id,
                      planungsobjektLinearTitel: planungsobjekt.titel,
                    });
                  } else if (PlanungsobjektUtils.isOnDemand(planungsobjekt)) {
                    return merklisteWindowActions.openMovePlanungsobjektOnDemandToMerklisteDialog({
                      planungsobjektOnDemandId: planungsobjekt.id,
                      planungsobjektOnDemandTitel: planungsobjekt.titel,
                      quickFilterPreset: undefined,
                    });
                  }
                  throw new Error("Unexpected discriminator");
                default:
                  return planungsobjektWindowActions.closeWindow();
              }
            }),
          ),
      ),
    );
  });

  openDeletePlanungsobjektOnSendeplatzWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openDeletePlanungsobjektOnSendeplatzWindow),
      switchMap(({ planungsobjektId, titel }) =>
        this.planungsobjektWindowService.openDeletePlanungsobjektDialog(titel).result.pipe(
          map((result) => {
            if (result instanceof DialogCloseResult)
              return planungsobjektWindowActions.closeWindow();
            switch (result.action) {
              // VP-Fassung wurde gelöscht
              case Aktion.ENTFERNE_PLANUNGSOBJEKT:
                return planungsobjektActions.deletePlanungsobjekt({
                  planungsobjektId,
                });

              // VP-Fassung wurde auf Merkliste gesetzt
              case Aktion.PLANUNGSOBJEKT_AUF_MERKLISTE:
                return merklisteWindowActions.openMovePlanungsobjektLinearToMerklisteDialog({
                  planungsobjektLinearId: planungsobjektId,
                  planungsobjektLinearTitel: titel,
                });
              default:
                return planungsobjektWindowActions.closeWindow();
            }
          }),
        ),
      ),
    );
  });

  openDeletePlanungsobjektOnBlockansichtWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openDeletePlanungsobjektOnBlockansichtDialog),
      map(({ planungsobjektId, titel }) =>
        planungsobjektWindowActions.openDeletePlanungsobjektOnSendeplatzWindow({
          planungsobjektId,
          titel,
        }),
      ),
    );
  });

  openPlanungsobjektLinearWithPlanungOnSendeplatzWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openPlanungsobjektLinearWithPlanungOnSendeplatzWindow),
      switchMap((action) =>
        this.planungsobjektService.getLinearById$(action.linearId).pipe(
          // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
          switchMap((planungsobjekt) => [
            planungsobjektActions.getPlanungsobjekteLinearAndOnDemandByIdSuccess({
              planungsobjekte: {
                linear: [planungsobjekt],
                onDemand: [],
              },
            }),
            planungsobjektWindowActions.openPlanungsobjektWindow({
              input: {
                usecase: PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ,
                planungsobjektId: planungsobjekt.id,
                planungsobjekt: planungsobjekt,
                planungskontext: planungsobjekt.planungskontext,
              },
            }),
          ]),
        ),
      ),
    );
  });

  openUpdateOnDemandOnVODWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openUpdateOnDemandOnVODWindow),
      switchMap(({ onDemandId }) => {
        return this.onDemandService.getOnDemandById$(onDemandId).pipe(
          // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
          switchMap((planungsobjektOnDemand) => [
            planungsobjektActions.getPlanungsobjekteLinearAndOnDemandByIdSuccess({
              planungsobjekte: {
                linear: [],
                onDemand: [planungsobjektOnDemand],
              },
            }),
            planungsobjektWindowActions.openPlanungsobjektWindow({
              input: {
                usecase: PlanungsobjektWindowUseCase.EDIT_ONDEMAND,
                planungsobjektId: planungsobjektOnDemand.id,
                planungsobjekt: planungsobjektOnDemand,
                planungskontext: planungsobjektOnDemand.planungskontext,
              },
            }),
          ]),
        );
      }),
    );
  });

  openPlanungsobjektLinearWithPlanungOnBlockansichtWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openPlanungsobjektLinearWithPlanungOnBlockansichtWindow),
      switchMap((action) => {
        return this.planungsobjektService.getLinearById$(action.linearId).pipe(
          switchMap((planungsobjekt) => {
            // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
            return [
              planungsobjektActions.getPlanungsobjekteLinearAndOnDemandByIdSuccess({
                planungsobjekte: {
                  linear: [planungsobjekt],
                  onDemand: [],
                },
              }),
              planungsobjektWindowActions.openPlanungsobjektWindow({
                input: {
                  usecase: PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT,
                  planungsobjektId: planungsobjekt.id,
                  planungsobjekt: planungsobjekt,
                  planungskontext: planungsobjekt.planungskontext,
                  blockansichtDefinition: action.blockansichtDefinition,
                  blockansichtDefinitionen: action.blockansichtDefinitionen,
                },
              }),
            ];
          }),
        );
      }),
    );
  });

  openLinearOnDemandAcceptWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openLinearOnDemandAcceptWindow),
      switchMap(({ windowInput }) => {
        const windowRef = this.linearOnDemandService.openLinearOnDemandAcceptWindow$(windowInput);
        return windowRef.result;
      }),
      map((result) =>
        planungsobjektWindowActions.handleLinearOnDemandAcceptWindowResult({ result }),
      ),
    );
  });

  handleLinearOnDemandAcceptWindowResult$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.handleLinearOnDemandAcceptWindowResult),
      map(({ result }) => {
        if (result instanceof WindowCloseResult) return planungsobjektWindowActions.closeWindow();
        // LinearOnDemand Annehmen
        if (result.action === AktionEnum.VORSCHLAG_ANNEHMEN_ERGAENZEN) {
          return planungsobjektActions.movePlanungsobjektVorgeschlagenToVorgeplant({
            sendeplatz: result.sendeplatz,
            planungsobjektId: result.linearId,
          });
        } else {
          // Planungsobjekt mit LinearOnDemand Ersetzen
          return planungsobjektActions.replacePlanungsobjektWithVorschlag({
            command: {
              linearId: result.linearId,
              planungsobjektId: result.planungsobjektId,
            },
            sendeplatz: result.sendeplatz,
          });
        }
      }),
    );
  });

  private actionsThatClosePlaunungsobjektWindow = [
    planungsobjektWindowActions.closePlanungsobjektWindow,
    onDemandActions.createOnDemandSuccess,
    onDemandActions.updateOnDemandSuccess,
    onDemandActions.verschiebePlanungsobjektOnDemandZuVorgemerktSuccess,
    planungsobjektActions.createPlanungsobjektOnBlockansichtSuccess,
    planungsobjektActions.updatePlanungsobjektOnBlockansichtSuccess,
    planungsobjektActions.createPlanungsobjektOnSendeplatzSuccess,
    planungsobjektActions.updatePlanungsobjektOnSendeplatzSuccess,
    planungsobjektActions.updatePlanungsobjektOnMerklisteSuccess,
    planungsobjektActions.movePlanungsobjektToMerkliste,
    planungsobjektActions.deletePlanungsobjekt,
    planungsobjektActions.createPlanungsobjektLinearVorgeschlagenSuccess,
    planungsobjektActions.updatePlanungsobjektLinearVorgeschlagenSuccess,
  ];

  openPlanungsobjektWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openPlanungsobjektWindow),
      concatLatestFrom(() => this.store.select(planungsobjektWindowSelectors.selectInput)),
      switchMap(([_, input]) => {
        if (!input) {
          return EMPTY;
        }

        const windowRef = this.planungsobjektWindowService.openPlanungsobjektWindow();

        return this.actions$.pipe(
          // Für das Zwischenspeichern ist take(x) problematisch, weil sich dann das Fenster evtl. nichtmehr schließt danach.
          ofType(...this.actionsThatClosePlaunungsobjektWindow),
          /**
           * Zusätzlicher filter für die beiden onDemand Actions, die beim Wechseln des Tabs ausgelöst werden.
           * Hierbei handelt es sich um ein Zwischenspeichern des Formulars, welches nicht das Fenster schließen soll.
           */
          filter(
            (resultAction) =>
              !("shouldCloseWindow" in resultAction && !resultAction.shouldCloseWindow),
          ),
          take(1),
          tap(() => void windowRef.close()),
          map(() => planungsobjektWindowActions.planungsobjektWindowClosed()),
        );
      }),
    );
  });

  initalizeDataAfterPlanungsobjektWindowOpened$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openPlanungsobjektWindow),
      switchMap(({ input }) => {
        const initialFormValue = getInitalPlanungsobjektFormValue(input);

        const disableFormIfNotEditable = !this.canEditPlanungsobjektWindowService.transform(input)
          ? [planungsobjektFormActions.disable()]
          : [];

        const disableSendetagAndBeginnzeitOnSendeplatz = isSendeplatzWindow(input)
          ? [planungsobjektFormActions.disableControls({
            controls: ["sendetag", "beginnzeit"],
          })]
          : [];

        // Cleanup, Vorbelegung und Enablement basierend auf Berechtigungen...
        // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
        return [
          planungsobjektFormActions.reset(),
          planungsobjektFormActions.setValue({
            value: planungsobjektToFormValue(initialFormValue),
          }),
          planungsobjektWindowWannWoActions.setInitialFormEnablements(),
          ...disableFormIfNotEditable,
          ...disableSendetagAndBeginnzeitOnSendeplatz
        ];
      }),
    );
  });

  tryClosingWithoutSaving$ = createConfirmClosingEffect({
    tryClosingAction: planungsobjektWindowActions.tryClosingWithoutSaving,
    preventClosingWhen$: combineLatest([
      this.store.select(planungsobjektWindowSelectors.planungsForm.selectFormIsDirty),
      this.store.select(planungsobjektWindowSelectors.onDemandForm.selectFormIsDirty),
    ]).pipe(
      map(([isPlaungFormDirty, isOndemandFormDirty]) => isPlaungFormDirty || isOndemandFormDirty),
    ),
    closingAction: planungsobjektWindowActions.closePlanungsobjektWindow,
  });

  trySaving$ = createTrySavingEffect({
    trySavingAction: planungsobjektFormActions.trySaving,
    formIsValid$: this.store.select(planungsobjektWindowSelectors.planungsForm.selectFormIsValid),
    saveAction: planungsobjektFormActions.save,
  });

  trySavingAndClosing$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.trySavingAndClosing),
      switchMap(() => concat(
          of(planungsobjektFormActions.trySaving()), 
          this.actions$.pipe(
            ofType(planungsobjektFormActions.save),
            take(1),
            map(() => planungsobjektWindowActions.closePlanungsobjektWindow())
          )
        )
      ),
    );
  })

  openPlanungsobjektWindowReadonlyForId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openPlanungsobjektWindowReadonlyForId),
      switchMap(({ planungsobjektId, planungsobjektType }) => {
        switch (planungsobjektType) {
          case "linear":
            return this.planungsobjektService.getLinearById$(planungsobjektId).pipe(
              switchMap((planungsobjekt) => {
                // Wir wollen halt zwei Dinge tun... 🤷🏼‍♀️
                // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
                return [
                  planungsobjektActions.getPlanungsobjektByIdSuccess({ planungsobjekt }),
                  planungsobjektWindowActions.openPlanungsobjektWindow({
                    input: {
                      usecase: PlanungsobjektWindowUseCase.READONLY_LINEAR,
                      planungsobjektId: planungsobjekt.id,
                      planungsobjekt: planungsobjekt,
                      planungskontext: planungsobjekt.planungskontext,
                    },
                  }),
                ];
              }),
            );
          case "ondemand":
            return this.onDemandService.getOnDemandById$(planungsobjektId).pipe(
              switchMap((planungsobjektOnDemand) => {
                // Wir wollen halt zwei Dinge tun... 🤷🏼‍♀️
                // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
                return [
                  onDemandActions.getPlanungsobjektOnDemandByIdSuccess({
                    planungsobjektOnDemand,
                  }),
                  planungsobjektWindowActions.openPlanungsobjektWindow({
                    input: {
                      usecase: PlanungsobjektWindowUseCase.READONLY_ONDEMAND,
                      planungsobjektId: planungsobjektOnDemand.id,
                      planungsobjekt: planungsobjektOnDemand,
                      planungskontext: planungsobjektOnDemand.planungskontext,
                    },
                  }),
                ];
              }),
            );

          default:
            assertUnreachable(planungsobjektType);
        }
      }),
    );
  });

  /**
   * Speichert das Formular und schließt das Fenster, falls es sich nicht um ein Zwischenspeichern handeln
   */
  savePlanungsobjektWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        planungsobjektFormActions.save,
        planungsobjektWindowActions.confirmPlanungsobjektSaveDialog,
      ),
      concatLatestFrom(() => [
        this.store.select(planungsobjektWindowSelectors.planungsForm.selectFormData),
        this.store.select(planungsobjektWindowSelectors.selectInput),
      ]),
      filter(allValuesDefined),
      map(([action, formValue, windowInput]) => {
        const unboxedValue = {
          ...formValue,
          contentCommunities: unbox(formValue.contentCommunities ?? []),
        };

        const shouldCloseWindow = false;
        const nextAction = extractPlanungsobjektCommandAction(
          unboxedValue,
          windowInput,
          shouldCloseWindow,
        );
        return nextAction;
      }),
    );
  });

  transitionCreateWindowToEditWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(...createSuccessActions),
      concatLatestFrom(() => [this.store.select(planungsobjektWindowSelectors.selectInput)]),
      filter(([action, input]) => !!input && isCreateWindow(input)),
      map(([action, input]) => {
        return planungsobjektWindowActions.transitionToEditWindow({
          input: editInputForCreateInput(
            input as PlanungsobjektCreateInput,
            action.planungsobjekt.id,
          ),
        });
      }),
    );
  });

  setInputValueAfterTransitionToEdit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.transitionToEditWindow),
      concatLatestFrom(() => this.store.select(planungsobjektWindowSelectors.selectInput)),
      filter(([_, input]) => !!input && isEditWindow(input)),
      switchMap(([_, input]) => {
        return [
          planungsobjektFormActions.setValue({
            value: planungsobjektToFormValue(
              getInitalPlanungsobjektFormValue(
                input as PlanungsobjektWindowInputWithPlanungsobjekt,
              ),
            ),
          }),
          planungsobjektFormActions.markAllAsPristine(),
        ];
      }),
    );
  });

  resetFormDirtyStateAfterSafe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(...planungsobjektSaveSuccessActions),
      concatLatestFrom(() => [
        this.store.select(planungsobjektWindowSelectors.selectPlanungsobjektId),
      ]),
      filter(([action, currentId]) => {
        const planungsobjekt = action.planungsobjekt;
        const updatedId = "id" in planungsobjekt ? planungsobjekt.id : planungsobjekt.result.id;

        return updatedId === currentId;
      }),
      map(() => planungsobjektFormActions.markAllAsPristine()),
    );
  });

  resetOnDemandFormDirtyStateAfterSafe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(...onDemandBeziehungFormSaveSuccessActions),
      concatLatestFrom(() => [
        this.store.select(planungsobjektWindowSelectors.selectIsVerlinkungTab),
      ]),
      filter(([_, isVerlinkungTab]) => isVerlinkungTab),
      map(() => onDemandFormActions.markAllAsPristine()),
    );
  });

  openConfirmPlanungsobjektSaveDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openConfirmPlanungsobjektSaveDialog),
      concatLatestFrom(() => [this.store.select(planungsobjektWindowSelectors.selectInput)]),
      filter(allValuesDefined),
      switchMap(([{ newTab }, input]) => {
        return this.customDialogService.openConfirmPlanungsobjektSaveDialog(newTab).result.pipe(
          switchMap((result) => {
            if (result instanceof DialogCloseResult || !result)
              return of(planungsobjektWindowActions.cancelChangeTab());

            if (result.id === CONFIRM_PLANUNGSOBJKET_SAVE_DIALOG_ACTIONS.DISCARD) {
              // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
              return [
                planungsobjektFormActions.reset(),
                planungsobjektFormActions.setValue({
                  value: planungsobjektToFormValue(
                    getInitalPlanungsobjektFormValue(
                      input as PlanungsobjektWindowInputWithPlanungsobjekt,
                    ),
                  ),
                }),
                planungsobjektWindowActions.changePlanungsobjektWindowTab({ newTab }),
              ];
            }

            return of(planungsobjektWindowActions.confirmPlanungsobjektSaveDialog({ newTab }));
          }),
        );
      }),
    );
  });

  /**
   * Alle Aktionen, die das Zwischenspeichern erlauben
   */
  confirmPlanungsobjektSaveDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.confirmPlanungsobjektSaveDialog),
      switchMap(({ newTab }) =>
        this.actions$.pipe(
          ofType(...planungsobjektSaveSuccessActions),
          take(1),
          map(() => planungsobjektWindowActions.changePlanungsobjektWindowTab({ newTab })),
        ),
      ),
    );
  });

  tryChangeTab$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.tryChangeTab),
      concatLatestFrom(() => [
        this.store.select(planungsobjektWindowFeature.selectSelectedTab),
        this.store.select(planungsobjektWindowSelectors.selectInput),
        this.store.select(planungsobjektWindowSelectors.planungsForm.selectFormIsDirty),
      ]),
      filter(allValuesDefined),
      map(([{ newTab, isFormValid }, currentTab, windowInput, isPlanungFormDirty]) => {
        if (
          // wenn der Tab bereits geöffnet ist, oder ...
          currentTab === newTab ||
          // wenn der Usecase einer der CREATE Usecases ist ...
          windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ ||
          windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT ||
          windowInput.usecase === PlanungsobjektWindowUseCase.READONLY_LINEAR ||
          windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_ONDEMAND ||
          windowInput.usecase === PlanungsobjektWindowUseCase.READONLY_ONDEMAND ||
          // oder wenn das Formular nicht dirty ist ...
          !isPlanungFormDirty
        ) {
          // ... dann wechsle den Tab ohne weitere Prüfungen
          return planungsobjektWindowActions.changePlanungsobjektWindowTab({ newTab });
        }

        return isFormValid
          ? planungsobjektWindowActions.openConfirmPlanungsobjektSaveDialog({ newTab })
          : notificationActions.showNotification({
              message: "Das Formular enthält Änderungen mit Validierungsfehlern.",
              notificationType: "Error",
            });
      }),
    );
  });

  changePlanungsobjektWindowTab$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.changePlanungsobjektWindowTab),
      filter(
        ({ newTab }) =>
          newTab === PlanungsobjektWindowTabEnum.VERLINKUNG ||
          newTab === PlanungsobjektWindowTabEnum.PLANUNG,
      ),
      concatLatestFrom(() => [
        this.store.select(planungsobjektWindowSelectors.selectInput),
        this.store.select(onDemandFeature.selectEntities),
      ]),
      map(([{ newTab }, windowInput, onDemandEntities]) => {
        if (
          newTab === PlanungsobjektWindowTabEnum.VERLINKUNG &&
          (windowInput?.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ ||
            windowInput?.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT ||
            windowInput?.usecase === PlanungsobjektWindowUseCase.READONLY_LINEAR ||
            windowInput?.usecase === PlanungsobjektWindowUseCase.EDIT_ONDEMAND ||
            windowInput?.usecase === PlanungsobjektWindowUseCase.READONLY_ONDEMAND)
        ) {
          {
            return beziehungActions.getBeziehungenForPlanungsobjekt({
              planungsobjektId: windowInput.planungsobjektId,
            });
          }
        }
        // OnlineAb und OnlineBis müssen aktualisiert werden, um synchron mit Änderungen auf dem Verlinkungstab zu bleiben
        if (
          newTab === PlanungsobjektWindowTabEnum.PLANUNG &&
          windowInput?.usecase === PlanungsobjektWindowUseCase.EDIT_ONDEMAND
        ) {
          const currentOnDemandPlanung = onDemandEntities[windowInput.planungsobjektId];
          return currentOnDemandPlanung
            ? planungsobjektFormActions.patchValue({
                value: {
                  onlineAb: currentOnDemandPlanung.onlineAb,
                  onlineAbZeit: currentOnDemandPlanung.onlineAbZeit,
                  onlineBis: currentOnDemandPlanung.onlineBis,
                  verweildauerInTagen: currentOnDemandPlanung.verweildauerInTagen ?? 0,
                  wunschOnlineBisVsVerweildauerToggle: currentOnDemandPlanung.verweildauerInTagen !== null
                    ? SLIDER_RIGHT_POSITION
                    : SLIDER_LEFT_POSITION,
                },
              })
            : planungsobjektWindowActions.changeTabWithoutSideeffects();
        }

        return planungsobjektWindowActions.changeTabWithoutSideeffects();
      }),
    );
  });

  getLinearOnDemandFürVerlinkungTab$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.changePlanungsobjektWindowTab),
      filter(({ newTab }) => newTab === PlanungsobjektWindowTabEnum.VERLINKUNG),
      concatLatestFrom(() => this.store.select(planungsobjektWindowSelectors.selectInput)),
      filter(allValuesDefined),
      map(([action, windowInput]) => {
        switch (windowInput.usecase) {
          case PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ:
          case PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT:
          case PlanungsobjektWindowUseCase.READONLY_LINEAR:
            return planungsobjektActions.getPlanungsobjekteLinearAndOnDemandById({
              planungsobjektId: windowInput.planungsobjektId,
            });
          case PlanungsobjektWindowUseCase.EDIT_ONDEMAND:
          case PlanungsobjektWindowUseCase.READONLY_ONDEMAND:
          case PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ:
          case PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT:
          case PlanungsobjektWindowUseCase.CREATE_ONDEMAND:
            return shellActions.noop({
              sourceAction: action.type,
            });
          default:
            assertUnreachable(windowInput);
        }
      }),
    );
  });

  deletePlanungsobjekt$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.deletePlanungsobjekt),
      concatLatestFrom(() => this.store.select(planungsobjektWindowSelectors.selectInput)),
      map(([, windowInput]) => {
        if (!windowInput) {
          throw new Error("WindowInput not set.");
        }
        switch (windowInput.usecase) {
          case PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ:
          case PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT:
          case PlanungsobjektWindowUseCase.EDIT_ONDEMAND:
            return planungsobjektWindowActions.openDeletePlanungsobjektWindow({
              planungsobjekt: windowInput.planungsobjekt,
            });
          case PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ:
          case PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT:
          case PlanungsobjektWindowUseCase.READONLY_LINEAR:
          case PlanungsobjektWindowUseCase.CREATE_ONDEMAND:
          case PlanungsobjektWindowUseCase.READONLY_ONDEMAND:
            throw new Error("Usecase not supported.");
          default:
            assertUnreachable(windowInput);
        }
      }),
    );
  });

  openConfirmGetitVerknuepfungAufhebenDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openConfirmGetitVerknuepfungAufhebenDialog),
      filter(allValuesDefined),
      switchMap(({ planungsobjekt }) => {
        return this.planungsobjektService
          .openPlanungsobjektGetitVerknuepfungAufhebenWindow$(planungsobjekt.id)
          .result.pipe(
            map((result) => {
              if (result instanceof WindowCloseResult) {
                return planungsobjektWindowActions.rejectGetitVerknuepfungAufhebenDialog();
              }

              return planungsobjektWindowActions.confirmGetitVerknuepfungAufhebenDialog({
                planungsobjektId: result.planungsobjektId,
                produktEingenschaftenBeibehalten: result.produktEingenschaftenBeibehalten,
              });
            }),
          );
      }),
    );
  });

  confirmGetitVerknuepfungAufhebenDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.confirmGetitVerknuepfungAufhebenDialog),
      filter(allValuesDefined),
      map(({ planungsobjektId, produktEingenschaftenBeibehalten }) =>
        planungsobjektActions.entknuepfePlanungsobjektVonGetit({
          planungsobjektId,
          produktEingenschaftenBeibehalten,
        }),
      ),
    );
  });

  /**
   * Das Form aktualisieren, wenn das geöffnete Planungsobjekt aktualisiert wurde
   */
  updateEditFormAfterEntityUpdated$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.getPlanungsobjekteLinearAndOnDemandByIdSuccess),
      concatLatestFrom(() => [this.store.select(planungsobjektWindowSelectors.selectInput)]),
      // Wenn das Fenster offen ist, es ein Edit Dialog ist
      filter(([_, window]) => !!window && isEditWindow(window)),
      // Das Objekt herausfiltern, das ggf. gerade geöffnet ist.
      map(([{ planungsobjekte }, window]) =>
        [...planungsobjekte.linear, ...planungsobjekte.onDemand].find(
          (planungsobjekt) => planungsobjekt.id === window?.planungsobjektId,
        ),
      ),
      // Nur, wenn das geöffnete Objekt geändert wurde
      filter(
        (planungsobjekt): planungsobjekt is PlanungsobjektLinearDto | PlanungsobjektOnDemandDto => {
          return !!planungsobjekt;
        },
      ),
      // Das Form aktualisieren, danach ist es wieder pristine
      map((planungsobjekt) => {
        const formValue = extractGetitFieldsFromPlanungsobjektDto(planungsobjekt);
        const newValue = {
          ...formValue,
          contentCommunities: box(planungsobjekt.contentCommunities ?? []),
        };
        return planungsobjektFormActions.patchValue({
          value: newValue,
        });
      }),
    );
  });

  // Wenn das Planungsobjekt aktualisiert wurde, das Formular als pristine markieren
  // Das muss nach dem Update des Formulars passieren, damit das Formular nicht wieder dirty wird
  // Die MultiSelects werfen bei Änderung eine MarkAsDirty Aktion, die das Formular wieder dirty machen würde
  markFormAsDirtyAfterGetitVerknuepfung$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.getPlanungsobjekteLinearAndOnDemandByIdSuccess),
      debounceTime(50),
      map(() => planungsobjektFormActions.markAllAsPristine()),
    );
  });

  setFormControlsFromGetitEnabledState$ = createEffect(() => {
    return this.store.select(planungsobjektWindowSelectors.planungsForm.selectFormData).pipe(
      concatLatestFrom(() => [this.store.select(planungsobjektWindowSelectors.selectInput)]),
      filter(
        ([_, input]) =>
          !!input &&
          this.canEditPlanungsobjektWindowService.transform(input) &&
          !isReadOnlyWindow(input),
      ),
      map(([formValue]) =>
        formValue.getitId
          ? planungsobjektFormActions.disableControls
          : planungsobjektFormActions.enableControls,
      ),
      map((actionCreator) =>
        actionCreator({
          controls: planungsobjektFieldsFromGetit,
        }),
      ),
    );
  });

  setUpVariantenSubscription$ = createEffect(() => {
    return this.store.select(planungsobjektWindowSelectors.selectInput).pipe(
      filter(isDefined),
      filter(
        (windowInput) =>
          windowInput.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT ||
          windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT,
      ),
      concatLatestFrom(() => [
        this.store.select(planungsobjektWindowWannWoSelectors.selectAvailableVariant),
        this.store.select(planungsobjektWindowSelectors.planungsForm.selectFormState),
      ]),
      filter(
        ([_, availableVariant, form]) =>
          availableVariant !== undefined && availableVariant !== form.controls.variante.value,
      ),
      map(([_, availableVarianten]) => {
        const variante = availableVarianten as number | null;
        return planungsobjektFormActions.patchValue({
          value: {
            variante,
          },
        });
      }),
    );
  });
}
