import { createFormGroupState, FormGroupState } from "ngrx-forms";
import { PrepareTransferToPharosQuery } from "src/app/models/openapi/model/prepare-transfer-to-pharos-query";
import { Redaktion } from "src/app/models/openapi/model/redaktion";
import { PharosTransferTableViewModel } from "src/app/models/viewmodels/pharos-viewmodel";

export interface PharosWindowInput {
  prepareTransferToPharosQuery: PrepareTransferToPharosQuery;
  pharosContent: PharosTransferTableViewModel;
}

export type PharosExportWindowFormData = {
  planzeit: string;
  laenge: string;
  sendetitel: string;
  redaktion: Redaktion;
  folgenNrInfo: string;
  dachzeile: string;
};

export type PharosExportWindowFormState = FormGroupState<PharosExportWindowFormData>;
export const pharosExportFormId = "PharosExportWindowForm";

export const emptyPharosExportWindowForm: PharosExportWindowFormData = {
  planzeit: "",
  laenge: "",
  sendetitel: "",
  redaktion: Redaktion.KEINE_REDAKTION,
  folgenNrInfo: "",
  dachzeile: "",
};

export const initialPharosExportWindowState: PharosExportWindowFormState = createFormGroupState(
  pharosExportFormId,
  emptyPharosExportWindowForm,
);

export type PharosExportWindowFeatureState = {
  formState: PharosExportWindowFormState;
  windowInput: PharosWindowInput | null;
};
export const initialPharosExportWindowFeatureState: PharosExportWindowFeatureState = {
  formState: initialPharosExportWindowState,
  windowInput: null,
};
