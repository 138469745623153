import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { routerRequestAction } from "@ngrx/router-store";
import { Store } from "@ngrx/store";
import { catchError, delay, distinctUntilChanged, filter, map, of, switchMap, tap } from "rxjs";
import { PlanungsobjekteDto } from "src/app/models/openapi/model/planungsobjekte-dto";
import { SearchResultsDto } from "src/app/models/openapi/model/search-results-dto";
import { RechercheService } from "src/app/services/recherche.service";
import { CustomNotificationService } from "src/app/shared/notifications/custom-notification.service";
import { allValuesDefined } from "src/app/utils/array-utils";
import { assertUnreachable } from "src/app/utils/function-utils";
import { filterAngularNavigationEvent } from "src/app/utils/observable.utils";
import { filterByUrlFromNavigationEvent, filterNavigatedToPage } from "src/app/utils/route.utils";
import { Planungskontext } from "tests/common/generated/api";
import { notificationActions } from "../notification/notification.actions";
import { planungsobjektWindowActions } from "../planungsobjekt-window/planungsobjekt-window.actions";
import { rechercheActions } from "./recherche.actions";
import { rechercheFormActions } from "./recherche.form";
import {
  FilterEnum,
  RechercheSearchFormData,
  initialRechercheSearchFormData,
} from "./recherche.model";
import { rechercheFeature } from "./recherche.reducer";
import rechercheSelectors from "./recherche.selectors";
import {
  extractRechercheGridFormattingOptionsVMFromParams,
  extractRecherchePageQueryParamsFromUrlParams,
  rechercheSearchQueryVmToSearchFormData,
} from "./recherche.utils";

const resultIds = (planungsobjekte: PlanungsobjekteDto) => [
  ...planungsobjekte.linear.map((po) => po.id),
  ...planungsobjekte.onDemand.map((po) => po.id),
];

@Injectable()
export class RechercheEffects {
  loadSearchResults$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(rechercheActions.search, rechercheActions.searchByBrowserNavigation),
      // Wegen Zeitlicher Abhängigkeit Sync URL->Store
      delay(0),
      concatLatestFrom(() =>
        this.store.select(rechercheSelectors.selectSearchQueryAndFormattingParams),
      ),
      map(([action, params]) => ({ ...action, ...params })),
      filter(allValuesDefined),
      switchMap(({ query }) =>
        this.service.search$(query).pipe(
          map(({ planungsobjekte, idsWithVarianten }: SearchResultsDto) =>
            rechercheActions.searchSuccess({
              results: planungsobjekte,
              idsWithVarianten: idsWithVarianten,
              // Bei einer regulären Suche sollten wir eigentlich keine Kinder laden  / aktuelle überschreiben?
              isChild: [],
              // Bei einer regulären Suche laden wir nur Eltern
              isParent: resultIds(planungsobjekte),
              query,
            }),
          ),
          catchError((error: unknown) => {
            // Ohne throw kommen wir nicht mehr in den Error Handler im
            // Loading Interceptor und zeigen daher auch keine Error Notification an
            // throw error;
            return of(
              notificationActions.showNotification({
                message: "Fehler beim Laden der Ergebnisse",
                notificationType: "Error",
              }),
            );
          }),
        ),
      ),
    );
  });

  loadSearchResultsSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(rechercheActions.searchSuccess),
        concatLatestFrom(() => [
          this.store.select(rechercheSelectors.selectSearchQueryAndFormattingParams),
        ]),
        tap(
          ([_, { query, formattingOptions }]) =>
            query && this.service.navigateBySearch(query, formattingOptions),
        ),
      );
    },
    {
      dispatch: false,
    },
  );

  resetFormDirtyStateAfterSearch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(rechercheActions.searchSuccess),
      map(() => rechercheFormActions.markAllAsPristine()),
    );
  });

  searchChildren$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(rechercheActions.searchChildren),
      filter(allValuesDefined),
      switchMap(({ childrenIds }) => {
        return this.service.searchChildren$(childrenIds).pipe(
          map(({ planungsobjekte, idsWithVarianten }: SearchResultsDto) => {
            const action = rechercheActions.searchChildrenSuccess({
              results: planungsobjekte,
              idsWithVarianten: idsWithVarianten,
              isChild: resultIds(planungsobjekte),
            });

            const linear = planungsobjekte.linear.filter(
              (po) => po.planungskontext !== Planungskontext.VORGEMERKT,
            );
            const onDemand = planungsobjekte.onDemand.filter(
              (po) => po.planungskontext !== Planungskontext.VORGEMERKT,
            );

            // Wir haben vorgemerkte Planungsobjekte gefunden, die nicht gesucht werden sollen
            if (
              linear.length !== planungsobjekte.linear.length ||
              onDemand.length !== planungsobjekte.onDemand.length
            ) {
              this.notificationService.showNotification(
                "Vorgemerkte Planungsobjekte sollen nicht gesucht werden.",
                "Error",
              );

              action.results = {
                linear,
                onDemand,
              };
            }
            return action;
          }),
          catchError((error: unknown) => {
            // Ohne throw kommen wir nicht mehr in den Error Handler im
            // Loading Interceptor und zeigen daher auch keine Error Notification an
            // throw error;
            return of(
              notificationActions.showNotification({
                message: "Fehler beim Laden der Ergebnisse",
                notificationType: "Error",
              }),
            );
          }),
        );
      }),
    );
  });

  updateFormattingOptions$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          rechercheActions.toggleGridColumns,
          rechercheActions.reorderRechercheColumns,
          rechercheActions.updateQuery,
        ),
        concatLatestFrom(() =>
          this.store.select(rechercheSelectors.selectSearchQueryAndFormattingParams),
        ),
        tap(([_, formattingParams]) =>
          this.service.updateFormattingOptionsInUrl(formattingParams.formattingOptions),
        ),
      );
    },
    { dispatch: false },
  );

  /**
   * Effect, der auf Browser-Navigation-Events reagiert und die Recherche-Query und die
   * Formatierungsoptionen aus der URL extrahiert und die Suche startet.
   */
  handleBrowserNavigation$ = createEffect(() => {
    return this.actions$.pipe(
      filterNavigatedToPage("recherche"),
      map(({ url }) => {
        const { queryParams } = this.router.parseUrl(url);
        const query = extractRecherchePageQueryParamsFromUrlParams(queryParams);
        const formattingOptions = extractRechercheGridFormattingOptionsVMFromParams(queryParams);
        return { query, formattingOptions };
      }),
      filter(allValuesDefined),
      distinctUntilChanged(
        (prev, next) => JSON.stringify(prev.query) === JSON.stringify(next.query),
      ),
      map(({ query, formattingOptions }) =>
        rechercheActions.searchByBrowserNavigation({
          query,
          shownColumns: formattingOptions.shownColumns ?? [],
        }),
      ),
    );
  });

  syncSearchParamsToStore$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(rechercheActions.searchByBrowserNavigation),
      concatLatestFrom(() => [this.store.select(rechercheSelectors.selectSearchFormValue)]),
      map(([{ query }, currentValue]) => ({
        ...currentValue,
        ...rechercheSearchQueryVmToSearchFormData(query),
      })),
      distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next)),
      map((value) =>
        rechercheFormActions.patchValue({
          value,
        }),
      ),
    );
  });

  syncShownColumnsToStore$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(rechercheActions.searchByBrowserNavigation),
      concatLatestFrom(() => [this.store.select(rechercheFeature.selectShownColumns)]),
      distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next)),
      map(([{ shownColumns }]) => rechercheActions.setGridColumns({ columns: shownColumns })),
    );
  });

  /**
   * Wenn der Nutzer die Recherche-Seite über den Link in der Navigation aufruft (also
   * "/recherche" ohne Query-Parameter), dann soll die Suche zurückgesetzt werden.
   */
  handleImperativeNavigation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerRequestAction),
      map((event) => event.payload.event),
      filterByUrlFromNavigationEvent("recherche"),
      filterAngularNavigationEvent(),
      map((event) => {
        const { queryParams } = this.router.parseUrl(event.url);
        return extractRecherchePageQueryParamsFromUrlParams(queryParams);
      }),
      filter((query): query is null => !query),
      map(() => rechercheActions.searchReset()),
    );
  });

  openRechercheResultDetails$ = createEffect(() => {
    // TODO: Effect testen
    return this.actions$.pipe(
      ofType(rechercheActions.openRechercheResultDetails),
      concatLatestFrom(() => this.store.select(rechercheFeature.selectResults)),
      map(([{ resultId }, results]) => {
        const isLinear = results.linear.some((linear) => linear.id === resultId);
        const isOnDemand = results.onDemand.some((onDemand) => onDemand.id === resultId);

        const planungsobjektType = isLinear ? "linear" : isOnDemand ? "ondemand" : null;

        return planungsobjektType === null
          ? notificationActions.showNotification({
              message: "Planungsobjekt nicht gefunden",
              notificationType: "Error",
            })
          : planungsobjektWindowActions.openPlanungsobjektWindowReadonlyForId({
              planungsobjektId: resultId,
              planungsobjektType,
            });
      }),
    );
  });

  resetFormValuesOnSetAdditionalFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(rechercheActions.setAdditionalFilters),
      map(({ filters, previousFilters }) => {
        const removedFilters = previousFilters.filter((filter) => !filters.includes(filter));

        return removedAdditionalFiltersToPatchAction(removedFilters);
      }),
    );
  });

  resetFormValuesOnRemoveAdditionalFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(rechercheActions.removeAdditionalFilter),
      map(({ filter }) => {
        return removedAdditionalFiltersToPatchAction([filter]);
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store,
    private service: RechercheService,
    private notificationService: CustomNotificationService,
    private router: Router,
  ) {}
}

const removedAdditionalFiltersToPatchAction = (removedFilters: FilterEnum[]) => {
  const patchedValues = removedFilters.reduce((accu, filter) => {
    const property = filterToFormProperty(filter);
    (accu[property] as unknown) = initialRechercheSearchFormData[property];

    return accu;
  }, {} as Partial<RechercheSearchFormData>);

  return rechercheFormActions.patchValue({
    value: patchedValues,
  });
};

const filterToFormProperty = (filter: FilterEnum): keyof RechercheSearchFormData => {
  switch (filter) {
    case FilterEnum.TITEL:
      return "titelFilter";
    case FilterEnum.CONTENT_COMMUNITY:
      return "contentCommunitiesSelected";
    case FilterEnum.GENRE:
      return "genreSelected";
    case FilterEnum.PLANUNGSKONTEXT:
      return "planungskontexteSelected";
    default:
      assertUnreachable(filter);
  }
};
