import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { parse } from "date-fns";
import { Subject, takeUntil, tap } from "rxjs";
import { ansichtActions } from "src/app/core/stores/ansicht/ansicht.actions";
import ansichtSelectors from "src/app/core/stores/ansicht/ansicht.selectors";
import { ekWindowActions } from "src/app/core/stores/ek-window/ek-window.actions";
import routerSelectors from "src/app/core/stores/router/router.selectors";
import { Icons } from "src/app/models/icons";
import { DateFnsService } from "src/app/services/date-fns.service";
import { wochentagProgrammwocheOrder } from "src/app/utils/kanal-offset-utils";

@Component({
  selector: "app-ek-ansicht",
  templateUrl: "./ek-ansicht.component.html",
  styleUrls: ["./ek-ansicht.component.scss"],
})
export class EkAnsichtComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();
  public readonly selectedYear$ = this.store.select(
    routerSelectors.selectYearFromQueryParamOrDefault,
  );
  protected years$ = this.store.select(ansichtSelectors.selectAnsichtViewModelYearsZDF);
  protected eks$ = this.store.select(ansichtSelectors.selectEksForEkAnsicht);
  protected loadingEks$ = this.store.select(ansichtSelectors.selectIsLoadingEks);

  Icons = Icons;
  ekAnsichtHeader = Object.keys(wochentagProgrammwocheOrder);

  constructor(
    private titleService: Title,
    private router: Router,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(`Event & Konkurrenzkalender`);

    // Lade die initialen E&Ks anhand des Jahres aus dem Query Parameter der URL und
    // setze diesen als Wert im Formular
    this.selectedYear$
      .pipe(
        tap((year) =>
          this.store.dispatch(ansichtActions.loadEventsKonkurrenzprogrammeForYear({ year })),
        ),
        takeUntil(this.onDestroy$),
      )
      .subscribe();
  }

  /**
   * Lade die E&Ks für das ausgewählte Jahr
   * @param year
   */
  protected loadNewYear(year: number) {
    this.store.dispatch(ansichtActions.loadEventsKonkurrenzprogrammeForYear({ year }));
    void this.router.navigate([], {
      queryParams: { year },
      queryParamsHandling: "merge",
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getMonth(date: string): string {
    return DateFnsService.getMonth(parse(date, "MM", new Date()));
  }

  onOpenCreateEkWindow(defaultDate?: Date) {
    this.store.dispatch(
      ekWindowActions.openWindow({
        input: { type: "Create EK", defaultDate: defaultDate ?? null },
      }),
    );
  }
}
