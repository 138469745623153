import { NgModule } from "@angular/core";
import { NgrxFormsModule } from "ngrx-forms";
import { CanEditPlanungsobjektWindowService } from "../../../core/stores/planungsobjekt-window/can-edit-planungsobjekt-window.service";
import { SharedModule } from "../../shared.module";
import { AktivitaetenTabComponent } from "./aktivitaeten-tab/aktivitaeten-tab.component";
import { PlanungTabComponent } from "./planung-tab/planung-tab.component";
import { PlanungsbezugComponent } from "./planung-tab/planungsbezug/planungsbezug.component";
import { PlanungsinhalteComponent } from "./planung-tab/planungsinhalte/planungsinhalte.component";
import { ProduktinformationenGetitLinkComponent } from "./planung-tab/produktinformationen/produktinformationen-getit-link/produktinformationen-getit-link.component";
import { ProduktinformationenComponent } from "./planung-tab/produktinformationen/produktinformationen.component";
import { WannBezugLinearComponent } from "./planung-tab/wann-bezug/wann-bezug-linear/wann-bezug-linear.component";
import { WannBezugOnDemandComponent } from "./planung-tab/wann-bezug/wann-bezug-on-demand/wann-bezug-on-demand.component";
import { WannBezugComponent } from "./planung-tab/wann-bezug/wann-bezug.component";
import { PlanungsobjektWindowComponent } from "./planungsobjekt-window.component";
import { OnDemandBeziehungFormComponent } from "./verlinkung-tab/on-demand-beziehung-form/on-demand-beziehung-form.component";
import { PlanungsobjektBeziehungenComponent } from "./verlinkung-tab/planungsobjekt-beziehungen/planungsobjekt-beziehungen.component";
import { VerlinkungTabComponent } from "./verlinkung-tab/verlinkung-tab.component";

const COMPONENTS = [
  PlanungsobjektWindowComponent,
  PlanungTabComponent,
  WannBezugComponent,
  WannBezugOnDemandComponent,
  WannBezugLinearComponent,
  ProduktinformationenComponent,
  PlanungsinhalteComponent,
  PlanungsbezugComponent,
  VerlinkungTabComponent,
  AktivitaetenTabComponent,
  OnDemandBeziehungFormComponent,
  PlanungsobjektBeziehungenComponent,
  ProduktinformationenGetitLinkComponent,
];

@NgModule({
  declarations: [COMPONENTS],
  providers: [CanEditPlanungsobjektWindowService],
  imports: [SharedModule, NgrxFormsModule],
  exports: [PlanungsobjektWindowComponent],
})
export class PlanungsobjektWindowModule {}
